/**
* ReportRowExpansionTemplate.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file ReportRowExpansionTemplate.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
* @modified Rafael Rodrigues - 2022
*/
import React from 'react';
import { translate } from '../../../Utils/Translate';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { IReport } from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * @interface IReportRowExpansionTemplateProperties
 */
interface IReportRowExpansionTemplateProperties {
  rowData: IReport /**< The current row data clicked */;
}
const ReportRowExpansionTemplate = ({
  rowData
}: IReportRowExpansionTemplateProperties): JSX.Element => {
  const RenderExpansionRows = () =>
    rowData && (
      <>
        <tr>
          <th>{translate('I18N.admin.page.reports.table.title')}</th>
          <td>{rowData.title}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.reports.table.status')}</th>
          <td>{rowData.status}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.reports.table.description')}</th>
          <td>{rowData.description}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.reports.table.created')}</th>
          <td>{formatDate(rowData.created)}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.reports.table.lastReply')}</th>
          <td>{formatDate(rowData.lastReply, { isTime: true, isTwentyFour: true })}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.reports.table.lastComment')}</th>
          <td>{rowData.lastComment}</td>
        </tr>
      </>
    );

  return (
    <>
      <ExpansionRow>
        <RenderExpansionRows />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <RenderExpansionRows />
      </ExpansionRow>
    </>
  );
};

export default ReportRowExpansionTemplate;
