/**
 * Client.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Client.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import { mapSortingFields } from '../Utils/Pagination';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Create or update a client
 * @param client Client information
 * @returns Promise
 */
export const createOrUpdate = async (client: any): Promise<IAPIEntityResponse<IUser>> => {
  //TODO: on update
  if (client._id) {
    return httpClient.put<IUser>(`/client/create/${client._id}`, client);
  }
  return httpClient.post<IUser>(`/client/create`, client);
};

/**
 * Used for drop downs mostly
 * @param filterValue Filter
 * @param clientIDValue clientID
 * @returns Promise
 */
export const getAll = async (
  payload: Record<string, any>
): Promise<PaginatedAPIEntityResponse<IUser>> => {
  const skip: number = 0;
  const limit: number = 0; // show all
  const filterValue: string = payload.filter ?? ''; /**< Filter Value. */
  const clientIDValue: string = payload.clientId ?? ''; /**< Client ID. */
  let searchTerm: string = payload?.searchTerm ?? ''; /**< Searchterm */

  const filter: any[] = [];
  if (clientIDValue) {
    filter.push({
      column: 'userUUID',
      operator: 'LIKE',
      value: clientIDValue
    });
  } else {
    if (filterValue !== '') {
      searchTerm = filterValue;
    }
  }

  const url: string = `/clients/all?skip=${skip}&limit=${limit}&filter=${encodeURIComponent(
    JSON.stringify(filter)
  )}&searchTerm=${encodeURIComponent(searchTerm ? searchTerm : '')}`;
  return httpClient.get<IUser>(url);
};

/**
 * Fetch clients by clientUUIDs
 * @param clientUUIDs clientUUIDs
 * @returns Promise
 */
export const fetchClientsWithUUIDs = async (
  clientUUIDs: string[]
): Promise<IAPIEntityResponse<IUser[]>> => {
  return httpClient.post<IUser[]>('/clients/find', { clientUUIDs });
};

/**
 * Fetch one client with clientUUID
 * @param clientUUID clientUUID
 * @returns Promise
 */
export const fetchClientsWithUUID = async (
  clientUUID: string
): Promise<IAPIEntityResponse<IUser[]>> => {
  return httpClient.get<IUser[]>(`/client/${clientUUID}`);
};

/**
 *
 * @param skip Skip
 * @param limit Limit
 * @param sort Sort
 * @param sortOrder Sort order
 * @param filter Filter
 * @param searchTerm Search term
 * @returns Promise
 */
export const read = async (
  skip = 0,
  limit = 10,
  sort = 'created',
  sortOrder = 'desc',
  filter: any[] = [],
  searchTerm: string
): Promise<PaginatedAPIEntityResponse<IUser>> => {
  const url: string = `/clients/all`;
  const mappedSort: string = mapSortingFields(sort, sortOrder);

  if (filter.length > 0) {
    url.concat(`&filter=${encodeURIComponent(JSON.stringify(filter))}`);
  }

  return httpClient.get<IUser>(
    `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&searchTerm=${encodeURIComponent(
      searchTerm ? searchTerm : ''
    )}`
  );
};

/**
 * Deletes a client by id
 * @param id client id
 * @returns Promise
 */
export const remove = (id?: string): Promise<IAPIEntityResponse<IUser>> => {
  if (id) {
    return httpClient.get<IUser>(`/client/${id}/delete`);
  }
};

/**
 * Fetches total clients count by application private key
 * @returns Promise<ILicense>
 */
export const getTotalClientCount = (): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.get<IUser>(`/clients/get/count`);
};

/**
 * Read non license clients
 *
 * @param searchTerm Search term
 * @returns Promise
 */
export const readNonLicenseClients = async (
  searchTerm: string
): Promise<PaginatedAPIEntityResponse<IUser>> => {
  const skip: number = 0;
  const limit: number = 0; // show all
  const url: string = `/clients/all/nonLicense`;

  return httpClient.get<IUser>(
    `${url}?skip=${skip}&limit=${limit}&searchTerm=${encodeURIComponent(
      searchTerm ? searchTerm : ''
    )}`
  );
};
