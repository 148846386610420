/**
* userDetails.ts (abstractuser) *

* Copyright © 2024 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file userDetails.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { IUser } from "../../Shared/interfaces/user/user";

/**
 * Shared file for user details helpers.
 * @module UserDetails
 */
export class UserDetails {
  /**
   * Method to get the user details as string. Usually used in the auto complete component.
   * @param {string} userDetails User details to extract information.
   * @returns {string}
  */
  // TODO: We should update all existing instances using this format to this method.
  public static getUserDetails(userDetails: IUser): string { return `${userDetails.firstName} ${userDetails.lastName} (${userDetails.username}) - ${userDetails.email}` }
}