/*
 * LicenseTemplateForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file LicenseTemplateForm.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section LicenseTemplate
 */

import React, { useEffect, useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { InstaInputMultiselect } from '@abstract/abstractwebcommon-client/FormControl/InstaInputMultiselect';
import InstaDropdown from '@abstract/abstractwebcommon-client/FormControl/InstaDropdown';
import i18n from '../../../Services/I18n';
import { durations } from '@abstract/abstractwebcommon-shared/enum/license/durationDate';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { deleteLicenseTemplate } from '../../../Store/LicenseTemplates';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'primereact/checkbox';

interface ILicenseTemplateOtherProperties {
  handleSubmit?: any;
  isLoading?: boolean;
  licenseTemplateEntitlements?: Array<any>;
  licenseTemplate?: any;
  templateLicenseTemplateUUID?: any; // on new forms only (when triggered from page)
  hideDialog: () => void /**< Hide the dialog/form component. */;
}

interface ILicenseTemplateFormValues {
  projectName: string;
  duration: string;
  sku: string;
  entitlements: Array<string>;
  isUsingDurationFromLicense?: boolean /**< If checked, when creation license, your end date will be the duration from ecommerce + License start date */;
}

interface ILicenseTemplateMyFormProperties {
  initialProjectName?: string;
  initialSKU?: string;
  initialEntitlements?: Array<string>;
  initialDuration?: string;
  handleSubmit?: any;
  isLoading?: boolean;
  licenseTemplateEntitlements?: Array<any>;
  licenseTemplate?: any;
  templateLicenseTemplateUUID?: any;
  hideDialog: () => void /**< Hide the dialog/form component. */;
}

const LicenseTemplateForm = (
  properties: ILicenseTemplateOtherProperties & FormikProps<ILicenseTemplateFormValues>
) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    isLoading,
    licenseTemplateEntitlements = [],
    licenseTemplate,
    hideDialog
  } = properties;
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const [durationOptions, setDurationOptions] = useState<any[]>([]);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [isShowingConfirmation, setIsShowingConfirmation] = useState<boolean>(false);

  useEffect(() => {
    const durationOptionsValues: string[] = Object.keys(durations);
    const durationOptionsLabels: string[] = Object.values(durations);
    const mappedDurationOptions: any = durationOptionsLabels.map(
      (durationLabel: string, index: number) => {
        return { label: durationLabel, value: durationOptionsValues[index] };
      }
    );
    setDurationOptions(mappedDurationOptions);
  }, []);

  /// show delete popup
  const deleteButtonClick = (event: any) => {
    setIsShowingConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete entitlement on Accept
  const onAccept = async (): Promise<void> => {
    dispatch(deleteLicenseTemplate([licenseTemplate.licenseTemplateUUID]));
    setIsShowingConfirmation(false);
    hideDialog();
  };

  return (
    <>
      <FormWrapper
        controlButtonLabel={licenseTemplate && Object.keys(licenseTemplate).length > 0}
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonClick(event)
        }
        handleSubmitButton={() => handleSubmit(values)}>
        <Row>
          <Form.Group as={Col} sm="6" md="6">
            <InstaDropdown
              value={values.duration}
              label={t('admin.page.licenseTemplates.form.duration')}
              labelClassName="required"
              name="duration"
              id={'duration'}
              dataKey={'id'}
              inputId={'duration'}
              isLoading={isLoading}
              options={durationOptions}
              onBlur={handleBlur}
              onChange={handleChange}
              optionLabel="label"
              placeholder={t('admin.page.licenseTemplates.placeholders.duration')}
              errors={errors}
              touched={touched}
              isFiltering={true}
              isRequired={true}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6" md="6">
            <InstaInputText
              label={t('admin.page.licenseTemplates.form.licenseTemplateProjectName')}
              labelClassName="required"
              name="projectName"
              id={'projectName'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.projectName}
              errors={errors.projectName}
              value={values.projectName}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6" md="6">
            <InstaInputText
              label={t('admin.page.licenseTemplates.form.sku')}
              labelClassName="required"
              name="sku"
              id={'sku'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.sku}
              errors={errors.sku}
              value={values.sku}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
          <InstaInputMultiselect
            sizing={{ sm: 6, md: 6 }}
            value={values.entitlements}
            label={t('admin.page.licenseTemplates.form.licenseTemplateEntitlement')}
            fieldName="entitlements"
            isLoading={isLoading}
            options={licenseTemplateEntitlements}
            handleChange={handleChange}
            handleBlur={handleBlur}
            optionLabel="name"
            placeholder={t('admin.page.licenseTemplates.placeholders.licenseTemplateEntitlement')}
            errors={errors.entitlements}
          />

          <Form.Group as={Col} sm="10" md="8" className="d-flex align-items-center">
            <Checkbox
              inputId="isUsingDurationFromLicense"
              onChange={handleChange}
              name="isUsingDurationFromLicense"
              checked={values.isUsingDurationFromLicense}
            />
            &nbsp;&nbsp;
            <label htmlFor="isUsingDurationFromLicense" className="mb-0">
              {t('admin.page.licenseTemplates.form.isUsingDurationFromLicense')}
            </label>
          </Form.Group>
        </Row>

        <ConfirmationPopup
          target={confirmPopupTarget}
          isShow={isShowingConfirmation}
          title={t('/confirm_messages.delete_records')}
          onAccept={onAccept}
          onReject={() => setIsShowingConfirmation(false)}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
          popupPosition="top"
        />
      </FormWrapper>
    </>
  );
};

const LicenseTemplate = withFormik<ILicenseTemplateMyFormProperties, ILicenseTemplateFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (properties) => {
    const { licenseTemplate, licenseTemplateEntitlements } = properties;

    if (licenseTemplate) {
      // filter none live [entitlements] and don't show null values
      const formattedEntitlements = licenseTemplate.entitlements.map((entitlement: any) => {
        const foundEntitlement = licenseTemplateEntitlements?.find(
          (originalEntitlement) => originalEntitlement?.name === entitlement?.name
        );
        return foundEntitlement;
      });

      return {
        duration: licenseTemplate.duration,
        projectName: licenseTemplate.projectName,
        sku: licenseTemplate.sku,
        entitlements: formattedEntitlements,
        isUsingDurationFromLicense: licenseTemplate.isUsingDurationFromLicense
      };
    }

    return {
      duration: properties.initialDuration,
      projectName: properties.initialProjectName,
      sku: properties.initialSKU,
      isUsingDurationFromLicense: false,
      entitlements: properties.initialEntitlements || []
    };
  },
  validationSchema: () => {
    // checks if string contains only numbers
    const checkForJustNumber = (value: any) => !/^\d+$/.test(value);
    // validation schema
    const validationSpec = {
      projectName: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(100, i18n.t('validation.max', { field: '100' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.licenseTemplates.form.licenseTemplateProjectName')
          })
        )
        .test('is-only-number', i18n.t('validation.cant_number'), checkForJustNumber),
      sku: Yup.string()
        .min(3, i18n.t('validation.min', { field: '3' }))
        .required(
          i18n.t('validation.required', { field: i18n.t('admin.page.licenseTemplates.form.sku') })
        ),
      entitlements: Yup.array().optional(),
      duration: Yup.string().required(
        i18n.t('validation.required', {
          field: i18n.t('admin.page.licenseTemplates.form.duration')
        })
      ),
      isUsingDurationFromLicense: Yup.boolean()
    };

    return Yup.object(validationSpec);
  },
  handleSubmit: (values, bags) => {
    if (bags.props.licenseTemplate) {
      let updatedFieldsCount: number = 0;
      Object.keys(values).forEach(async (key: string) => {
        if (key == 'entitlements') {
          const newEntitlementsUuidsString: string = JSON.stringify(
            (values[key] as IEntitlement[])
              .map((entitlement: IEntitlement) => entitlement.entitlementUUID)
              .sort()
          );
          const initialEntitlementsUuidsString: string = JSON.stringify(
            bags.props.licenseTemplate[key]
              .map((entitlement: IEntitlement) => entitlement.entitlementUUID)
              .sort()
          );
          if (newEntitlementsUuidsString !== initialEntitlementsUuidsString) {
            updatedFieldsCount++;
          }
        } else if (
          values[key as keyof typeof bags.props.licenseTemplate] !==
          bags.props.licenseTemplate[key as keyof typeof bags.props.licenseTemplate]
        ) {
          updatedFieldsCount++;
        }
      });

      if (updatedFieldsCount > 0) {
        return bags.props.handleSubmit(
          {
            id: bags.props.licenseTemplate.id,
            licenseTemplateUUID: bags.props.licenseTemplate.licenseTemplateUUID,
            ...values
          },
          bags.resetForm
        );
      }
      return;
    }
    return bags.props.handleSubmit(
      {
        ...values
      },
      bags.resetForm
    );
  }
})(LicenseTemplateForm);

export default LicenseTemplate;
