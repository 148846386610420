import ActivationPage from '../Components/Admin/Activations/ActivationPage';
import LogsPage from '../Components/Admin/Logs/LogsPage';
import NotFoundRedirect from '@abstract/abstractwebcommon-client/NotFound/NotFoundRedirect';
import DashboardPage from '../Components/Admin/Dashboard/ClientPage';
import AccountPage from '../Components/Admin/Account/AccountPage';
import EntitlementTable from '../Components/Admin/Entitlements/EntitlementTable';
import FileRecordTablePage from '../Components/Admin/FileRecord/FileRecordTablePage';
import LicenseTable from '../Components/Admin/License/LicenseTable';
import SettingPage from '../Components/Admin/Setting/SettingPage';
import TemplateEditor from '../Components/Admin/Template-Editor/TemplateEditor';
import DeactivationPage from '../Components/Admin/Activations/DeactivationPage';
import CssEditor from '../Components/Admin/Template-Editor/CssEditor';
import LicenseTemplateTable from '../Components/Admin/LicenseTemplate/LicenseTemplateTable';
import ReportsPage from '../Components/Admin/Reports/ReportsPage';
import ReportPage from '../Components/Admin/Report/ReportPage';
import { SharedMainRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { RouteName } from '../Utils/routesNames';
import NewsletterPage from '../Components/Admin/Newsletter/NewsletterPage';
import NewsletterForm from '../Components/Admin/Newsletter/NewsletterForm';
import { DashboardPage as ClientDashboardPage } from '../Components/Client/Dashboard/DashboardPage';
import NewsletterCustomListTable from '../Components/Admin/Newsletter/NewsletterCustomListTable';
import NewsletterAnalyticsPage from '../Components/Admin/Newsletter/NewsletterAnalyticsPage';
import NewsletterAnaylticsTable from '../Components/Admin/Newsletter/NewsletterAnaylticsTable';
import NewsletterBlacklistTable from '../Components/Admin/Newsletter/NewsletterBlacklistTable';
import i18n from '../Services/I18n';

/**
 * Get admin routes
 * @returns adminRoutes
 */
export const getAdminRoutes = () => {
  const adminRoutes = [
    {
      path: SharedMainRouteName.adminRoute,
      Component: DashboardPage,
      authenticated: true
    },
    {
      path: RouteName.adminAccountRoute,
      Component: AccountPage,
      authenticated: true,
      headingKey: i18n.t('admin.page.accountPage.profile')
    },
    {
      path: RouteName.adminActivationsRoute,
      Component: ActivationPage,
      authenticated: true,
      headingKey: i18n.t('admin.menu.activation')
    },
    {
      path: RouteName.adminDeactivationsRoute,
      Component: DeactivationPage,
      authenticated: true,
      headingKey: i18n.t('admin.menu.bulkDeactivate')
    },
    {
      path: RouteName.adminLicensesRoute,
      Component: LicenseTable,
      authenticated: true,
      headingKey: i18n.t('admin.page.licenses.page_title')
    },
    {
      path: RouteName.adminFilesRoute,
      Component: FileRecordTablePage,
      authenticated: true,
      headingKey: i18n.t('admin.page.files.page_title')
    },
    {
      path: RouteName.adminEntitlementRoute,
      Component: EntitlementTable,
      authenticated: true,
      headingKey: i18n.t('admin.page.entitlement.page_title')
    },
    {
      path: RouteName.adminLogsRoute,
      Component: LogsPage,
      authenticated: true,
      headingKey: i18n.t('admin.page.logs.page_title')
    },
    {
      path: RouteName.adminSettingsRoute,
      Component: SettingPage,
      authenticated: false
    },
    {
      path: RouteName.adminTemplateEditorRoute,
      Component: TemplateEditor,
      authenticated: true,
      headingKey: i18n.t('admin.page.templates.page_title')
    },
    {
      path: RouteName.adminTemplateCSSEditorRoute,
      Component: CssEditor,
      authenticated: true,
      headingKey: i18n.t('admin.page.templates.card.cssTitle')
    },

    {
      path: RouteName.adminTemplateLicenseRoute,
      Component: LicenseTemplateTable,
      authenticated: true,
      headingKey: i18n.t('admin.page.licenseTemplates.page_title')
    },
    {
      path: RouteName.adminReportsRoute,
      Component: ReportsPage,
      authenticated: true,
      headingKey: i18n.t('I18N.admin.page.reports.page_title')
    },
    {
      path: `${RouteName.adminReportsRoute}/:reportUUID`,
      Component: ReportPage,
      authenticated: true,
      headingKey: i18n.t('I18N.admin.page.reports.page_title'),
      isHeaderLabelHide: false
    },
    {
      path: RouteName.adminNewsletterRoute,
      Component: NewsletterPage,
      authenticated: true,
      headingKey: i18n.t('I18N.admin.page.newsletter.page_title')
    },
    {
      path: `${RouteName.adminNewsletterRoute}/new`,
      Component: NewsletterForm,
      authenticated: true,
      headingKey: i18n.t('I18N.admin.page.newsletter.add_page_title')
    },
    {
      path: RouteName.adminNewsletterCustomListRoute,
      Component: NewsletterCustomListTable,
      authenticated: true,
      headingKey: i18n.t('I18N.admin.page.newsletter.customlist_page_title')
    },
    {
      path: RouteName.adminNewsletterBlackListRoute,
      Component: NewsletterBlacklistTable,
      authenticated: true,
      headingKey: i18n.t('I18N.admin.page.newsletter.blacklist_page_title')
    },
    {
      path: RouteName.adminNewsletterAnalyticsRoute,
      Component: NewsletterAnalyticsPage,
      authenticated: true,
      headingKey: i18n.t('I18N.admin.page.newsletter.newsletter_analytics_page_title')
    },
    {
      path: `${RouteName.adminNewsletterAnalyticsRoute}/:newsletterID`,
      Component: NewsletterAnaylticsTable,
      authenticated: true,
      headingKey: i18n.t('I18N.admin.page.newsletter.analytics_page_title')
    },
    {
      path: `${RouteName.adminNewsletterRoute}/:newsletterUUID`,
      Component: NewsletterForm,
      authenticated: true,
      headingKey: i18n.t('I18N.admin.page.newsletter.edit_page_title')
    },
    {
      path: `${RouteName.adminClientHomeRoute}/:userUUID`,
      Component: ClientDashboardPage,
      isAuthenticated: true
    },
    {
      Component: NotFoundRedirect,
      authenticated: false
    }
  ];

  return adminRoutes;
};
