/*
 * InstaaDropdown.tsx (AbstractUser)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file InstaaDropdown.tsx
 * @author Sai Charan K
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from "react";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { IInstaFormControlProperties } from "./form-control.interface";
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';


interface IInstaDropdownProperties extends Omit<IInstaFormControlProperties, 'onChange'> {
  dataKey: string;
  inputId: string;
  optionLabel: string;
  options: any[];
  placeholder: string;
  showClear?: boolean;
  labelClassName?: string; /**< ClassName for label. */
  highlightLabel?: boolean; /**< Highlight ClassName for label. */
  isLoading?: boolean; /**< Enable/Disable based on loading. */
  isRequired?: boolean; /**< To make required field.  */
  isFiltering?: boolean; /**< Add filter to Dropdown. */
  onChange?(e: DropdownChangeEvent): void; /**< OnChange handler */
}

const InstaDropdown = ({
  name,
  dataKey,
  inputId,
  optionLabel,
  value,
  options,
  placeholder,
  showClear = false,
  onChange,
  className,
  onBlur,
  errors,
  touched,
  id,
  label,
  labelClassName = '',
  highlightLabel = false,
  isLoading,
  isRequired = false,
  isFiltering = false,
  ...properties
}: IInstaDropdownProperties): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Label htmlFor={id} className={`${highlightLabel ? 'text-danger' : ''} ${labelClassName}`}>{label}</Form.Label>
      <Dropdown
        name={name}
        dataKey={dataKey}
        id={id}
        inputId={inputId}
        optionLabel={optionLabel}
        value={value}
        options={options}
        placeholder={placeholder}
        showClear={showClear}
        onChange={onChange}
        className={classnames(className, {
          'p-invalid': touched[name] && errors[name]
        })}
        onBlur={onBlur}
        disabled={isLoading}
        required={isRequired}
        filter={isFiltering}
        {...properties}
      />
      {touched[name] && errors[name] ? 
        (
          <small id="email-invalid" className="p-invalid error-text">
            {t(errors[name])}
          </small>
        ) : 
        null
      }
    </>
  );
};

export default InstaDropdown;