/**
 * Activation.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Activation.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import { mapSortingFields, mapFilterFields } from '../Utils/Pagination';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IActivation } from '@abstract/abstractwebcommon-shared/interfaces/license/activation';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Service fetches all activations.
 * @function
 * @name read
 * @param {number} skip - Amount of records to skip in pagination.
 * @param {number} limit - The limit of records to collect in pagination.
 * @param {string} sort - The sort field.
 * @param {string} sortOrder - The sort order.
 * @param {object} data - filter data.
 * @param {string} searchTerm - search term to filter.
 * @returns Promise.
 */
export const read = async (
  skip = 0,
  limit = 10,
  sort = 'created',
  sortOrder = 'desc',
  data = {},
  searchTerm: string
): Promise<PaginatedAPIEntityResponse<IActivation>> => {
  const mappedSort: string = mapSortingFields(sort, sortOrder);
  const mappedFilter: string = mapFilterFields(data, []);

  return httpClient.get<IActivation>(
    `/activation?skip=${skip}&limit=${limit}&sort=${mappedSort}&filter=${mappedFilter}&searchTerm=${encodeURIComponent(
      searchTerm ? searchTerm : ''
    )}`
  );
};

/**
 * Service fetches a user's activations.
 * @function
 * @name readUserActivation
 * @param {number} skip - Amount of records to skip in pagination.
 * @param {number} limit - The limit of records to collect in pagination.
 * @param {string} sort - The sort field.
 * @param {string} sortOrder - The sort order.
 * @param {object} data - filter data.
 * @param {string} searchTerm - search term to filter.
 * @param {string} userUUID - user UUID to match specific data.
 * @returns Promise.
 */
export const readUserActivation = async (
  skip = 0,
  limit = 10,
  sort = 'created',
  sortOrder = 'desc',
  data = {},
  searchTerm: string
): Promise<PaginatedAPIEntityResponse<IActivation>> => {
  const url: string = `/activation/my`;

  const mappedSort: string = mapSortingFields(sort, sortOrder);
  const mappedFilter: string = mapFilterFields(data, []);

  return httpClient.get<IActivation>(
    `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&filter=${mappedFilter}&searchTerm=${encodeURIComponent(
      searchTerm ? searchTerm : ''
    )}`
  );
};

/**
 * Service fetches all user's activations.
 * @function
 * @name getAllUserActivations
 * @param {number} userUUID - The userUUID.
 * @param {number} skip - Amount of records to skip in pagination.
 * @param {number} limit - The limit of records to collect in pagination.
 * @param {string} sort - The sort field.
 * @param {string} sortOrder - The sort order.
 * @param {object} data - filter data.
 * @param {string} searchTerm - search term to filter.
 * @returns Promise.
 */
export const getAllUserActivations = async (
  userUUID: string,
  skip = 0,
  limit = 10,
  sort = 'created',
  sortOrder = 'desc',
  data: any = {},
  searchTerm: string
): Promise<PaginatedAPIEntityResponse<IActivation>> => {
  const url: string = `/activation/user/${userUUID}`;
  const mappedSort: string = mapSortingFields(sort, sortOrder);
  const mappedFilter: string = mapFilterFields(data, []);

  return httpClient.get<IActivation>(
    `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&filter=${mappedFilter}&searchTerm=${encodeURIComponent(
      searchTerm ? searchTerm : ''
    )}`
  );
};

/**
 * Service deactivates all user's activations. (Admin)
 * @function
 * @name deactivateAll
 * @param {number} userUUID - The userUUID.
 * @returns Promise.
 */
export const deactivateAll = async (userUUID: string): Promise<IAPIEntityResponse<void>> => {
  return httpClient.get<void>(`/activation/deactivate/${userUUID}`);
};
/**
 * Service deactivates all user's activations. (Client)
 * @param userUUID - The userUUID.
 * @returns Promise.
 */
export const deactivateAllClientsActivations = async (
  userUUID: string
): Promise<IAPIEntityResponse<void>> => {
  return httpClient.get<void>(`/activation/user/${userUUID}/deactivate`);
};

/**
 * Service deactivates selected activations.
 * @param activationUUIDs - The activationUUIDs.
 * @returns Promise.
 */
export const deactivateSelected = async (
  activationUUIDs: string[]
): Promise<IAPIEntityResponse<void>> => {
  return httpClient.put<void>('/activation/deactivate', { activationUUIDs });
};

/**
 * Fetches total activations count
 * @returns Promise<IActivation>
 */
export const getTotalActivationCount = (): Promise<IAPIEntityResponse<IActivation>> => {
  return httpClient.get<void>(`/activation/get/count`);
};

/**
 * Fetches the activations of a license with pagination
 * @param skip Pagination parameter
 * @param limit Pagination parameter
 * @param sort Pagination parameter
 * @param sortOrder Pagination parameter
 * @param licenseUUID UUID of the license
 * @returns License activations with the requested pagination options
 */
export const fetchLicenseActivationsAPI = async (
  skip = 0,
  limit = defaultTableLimit,
  sort = 'activationDate',
  sortOrder = 'DESC',
  licenseUUID: string
): Promise<PaginatedAPIEntityResponse<IActivation>> => {
  const url: string = `/activation/${licenseUUID}/licenseActivations`;
  sort = sort || 'activationDate';
  const mappedSort: string = mapSortingFields(
    sort,
    sortOrder
  ); /**< To map sortFields and sortOrder.*/

  return httpClient.get<IActivation>(`${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}`);
};

/**
 * To update activation
 * @param payload
 * @returns
 */
export const updateActivationAPI = (
  payload: IActivation
): Promise<IAPIEntityResponse<IActivation>> => {
  const url: string = `/activation/${payload.activationUUID}/update`;

  return httpClient.post<IActivation>(url, payload);
};
