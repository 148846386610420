/*
 * LicenseTemplateTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file LicenseTemplateTable.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { Column } from 'primereact/column';
import Row from 'react-bootstrap/Row';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import LicenseTemplateForm from './LicenseTemplateForm';
import {
  copyLicenseTemplateAction,
  createLicenseTemplate,
  deleteLicenseTemplate,
  getAllLicenseTemplates,
  updateLicenseTemplate
} from '../../../Store/LicenseTemplates';
import { useTranslation } from 'react-i18next';
import { getAllEntitlementsWithoutPagination } from '../../../Store/Entitlements';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { getSafeSettings } from '../../../Store/Settings';
import {
  IEntitlementStateSelector,
  ILicenseTemplateStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import { durations } from '@abstract/abstractwebcommon-shared/enum/license/durationDate';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { ILicenseTemplate } from '@abstract/abstractwebcommon-shared/interfaces/license/licenseTemplate';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const LicenseTemplateTable = (): ReactElement => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const licenseTemplates: ILicenseTemplateStateSelector = useSelector(
    (state: IStateSelectors) => state.licenseTemplates
  );
  const [isEditPage, toggleIsEditPage] = useState<boolean>(false);
  const [licenseTemplate, setLicenseTemplate] = useState<any>();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);

  const licenseTemplateList: any = licenseTemplates.list;
  const [selectedLicenseTemplates, setSelectedLicenseTemplates] =
    useState<ILicenseTemplate[]>(null); /**< Selected License Templates. */
  const deleteButtonReference: any = useRef(null); /**< Delete Button Reference. */

  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'created',
      sortOrder: -1
    },
    filter: {}
  }); /**< Default Payload */

  const entitlements: IEntitlementStateSelector = useSelector(
    (state: IStateSelectors) => state.entitlements
  );
  let activeEntitlements: any;

  if (!entitlements.entitlementIsFetching && entitlements.entitlements) {
    activeEntitlements = entitlements.entitlements.filter((entitlement: any) => entitlement.live);
  }

  /// Action to trigger licenseTemplate add or edit process
  const handleAddOrEdit = async (event: any, isEdit = false): Promise<void> => {
    await asyncErrorHandler(dispatch(getAllEntitlementsWithoutPagination()));
    if (isEdit) {
      setLicenseTemplate(event);
    } else {
      setLicenseTemplate(null);
    }
    toggleIsEditPage(true);
  };

  const hideDialog = () => toggleIsEditPage(false);

  /// LicenseTemplate update actions.
  const handleUpdate = async (licenseTemplateData: any): Promise<void> => {
    const createOrUpdate: any = licenseTemplate ? updateLicenseTemplate : createLicenseTemplate;
    const onRefresh = (): void => {
      dispatch(getSafeSettings()); // get default licenseTemplate template
      if (!licenseTemplate) {
        // on create from grid, refresh grid
        const updatedPayload: any = {
          skip: payload.skip,
          limit: payload.limit,
          sort: {
            sortField: payload.sort.sortField,
            sortOrder: payload.sort.sortOrder
          },
          filter: payload.filter,
          searchTerm: payload.searchTerm
        };
        dispatch(getAllLicenseTemplates(updatedPayload));
      }
    };

    await asyncErrorHandler(
      dispatch(
        createOrUpdate({
          licenseTemplate: licenseTemplateData,
          refresh: onRefresh
        })
      )
    );
    toggleIsEditPage(false);
  };

  /// LicenseTemplate sorting handler
  const handleSortUpdate = (e: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllLicenseTemplates(updatedPayload));
  };

  /// LicenseTemplate filter handler
  const handleFilterUpdate = (e: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: e.filters
    };
    setPayload(updatedPayload);
    dispatch(getAllLicenseTemplates(updatedPayload));
  };

  /// LicenseTemplate pagination handler
  const handlePageUpdate = (e: any): void => {
    const { first, rows } = e;
    const updatedPayload = {
      skip: first,
      limit: rows,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllLicenseTemplates(updatedPayload));
  };

  /// Fetch all licenseTemplates
  useEffect(() => {
    const updatedPayload: any = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    dispatch(getAllLicenseTemplates(updatedPayload));
    dispatch(getSafeSettings()); // get default licenseTemplate template
  }, [dispatch]);

  const onAccept = (): void => {
    const licenseTemplateUUIDs: string[] = selectedLicenseTemplates?.map(
      (eachLicenseTemplate: ILicenseTemplate) => eachLicenseTemplate.licenseTemplateUUID
    ); /**< licenseTemplateUUIDs */
    dispatch(deleteLicenseTemplate(licenseTemplateUUIDs));
    setShowConfirmation(false);
    setSelectedLicenseTemplates(null);
  };

  const onReject = (): void => {
    setShowConfirmation(false);
  };

  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={t('/confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };

  const onFilter = (event: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: event.filters,
      searchTerm: event
    };
    setPayload(updatedPayload);
    dispatch(getAllLicenseTemplates(updatedPayload));
  };

  /**
   * copy button clicked handler
   */
  const copyButtonClicked = async () => {
    const licenseTemplateUUIDs: string[] = selectedLicenseTemplates?.map(
      (eachLicenseTemplate: ILicenseTemplate) => eachLicenseTemplate.licenseTemplateUUID
    );
    /**< licenseTemplateUUIDs */
    await asyncErrorHandler(dispatch(copyLicenseTemplateAction(licenseTemplateUUIDs)));
    setSelectedLicenseTemplates(null);
    await asyncErrorHandler(dispatch(getAllLicenseTemplates(payload)));
  };

  const isButtonDisabled: boolean =
    ((selectedLicenseTemplates && Object.keys(selectedLicenseTemplates).length === 0) ||
      !selectedLicenseTemplates) ??
    false;

  const header = (
    <div className="d-flex justify-content-between align-items-center row-direction wrap-header">
      <div className="headerTableContainer margin-bottom-on-wrap">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={isButtonDisabled}
          buttonReference={deleteButtonReference}
        />{' '}
        {/* To delete License Templates. */}
        <ActionButton onClick={handleAddOrEdit} />
        <Button
          onClick={copyButtonClicked}
          disabled={isButtonDisabled}
          className="d-none d-sm-flex align-items-center mr-2 button-without-icon">
          {t('I18N.admin.page.newsletter.table.copy')}
        </Button>
      </div>
      <div className="d-flex">
        <Button
          onClick={copyButtonClicked}
          disabled={isButtonDisabled}
          className="d-flex d-sm-none align-items-center mr-2 button-without-icon">
          {t('I18N.admin.page.newsletter.table.copy')}
        </Button>
        <SearchBar onSearchTermChanged={(data: string) => onFilter(data)} />
      </div>
    </div>
  );

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Handles selection change event
  const onSelectionChange = (event: any) => {
    const rows: ILicenseTemplate[] = event.value;
    if (Array.isArray(rows)) {
      const selectedRows: ILicenseTemplate[] = rows.map((row: ILicenseTemplate) => {
        return row;
      });
      setSelectedLicenseTemplates(selectedRows);
    }
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <BaseDatatable
            value={licenseTemplateList?.records}
            parentClass="licenseTemplateDataTable licenseDataTable" /**< ClassName for div Component.*/
            header={header}
            filters={payload.filter}
            onFilter={handleFilterUpdate}
            sortField={payload.sort.sortField}
            onSort={handleSortUpdate}
            sortOrder={payload.sort.sortOrder}
            first={payload.skip}
            rows={payload.limit}
            isLoading={!licenseTemplateList?.records}
            onPage={handlePageUpdate}
            totalRecords={licenseTemplateList?.totalRecords}
            emptyMessage={t('I18N.admin.licenseTemplate.empty_msg')}
            selection={selectedLicenseTemplates}
            onSelectionChange={(event: any) => onSelectionChange(event)}>
            <Column selectionMode="multiple" className="col-width-3 mt-1" />
            <Column
              field="projectName"
              className="col-width-20"
              header={t('admin.page.licenseTemplates.form.licenseTemplateProjectName')}
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.licenseTemplates.form.licenseTemplateProjectName')}
                  data={rows.projectName}
                />
              )}
              sortable
            />
            <Column
              sortable
              field="sku"
              className="col-width-25"
              header={t('admin.page.licenseTemplates.form.sku')}
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.licenseTemplates.form.sku')}
                  data={rows.sku}
                />
              )}
            />
            <Column
              sortable
              field="name"
              header={t('admin.page.licenseTemplates.form.licenseTemplateEntitlement')}
              className="d-table-cell d-sm-none d-xl-table-cell"
              body={(rows: any) => {
                const details = rows.entitlements.map((entitlement: any, index: number) => {
                  return (
                    <span key={index + 1}>
                      <span className="badge badge-secondary">{entitlement.name}</span> &nbsp;{' '}
                    </span>
                  );
                });
                return (
                  <DatatableColumn
                    title={t('admin.page.licenseTemplates.form.licenseTemplateEntitlement')}
                    data={details}
                  />
                );
              }}
            />
            <Column
              sortable
              field="duration"
              className="d-table-cell d-sm-none d-md-table-cell custom-header-min-width-allowed"
              headerClassName="d-table-cell d-sm-none d-md-table-cell custom-header-min-width-allowed"
              header={t('admin.page.licenseTemplates.form.duration')}
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.licenseTemplates.form.duration')}
                  data={durations[rows.duration]}
                />
              )}
            />
            <Column
              sortable
              field="isUsingDurationFromLicense"
              className="d-table-cell d-sm-none d-md-table-cell custom-header-min-width-allowed"
              headerClassName="d-table-cell d-sm-none d-md-table-cell custom-header-min-width-allowed"
              header={t('admin.page.licenseTemplates.table.isUsingDurationFromLicense')}
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.licenseTemplates.table.isUsingDurationFromLicense')}
                  data={<ShowCheckOrUncheckIcon value={!rows.isUsingDurationFromLicense} />}
                />
              )}
            />
            <Column
              field="edit"
              body={(rowData: any) => {
                return (
                  <Button
                    className="custom-action-column-action-position custom-action-column-margin-mobile-view"
                    variant="outline"
                    onClick={() => handleAddOrEdit(rowData, true)}>
                    <i className="bi bi-pencil-square editIcon fa-lg"></i>
                  </Button>
                );
              }}
              className="p-0 col-width-3 absolute-position-responsive-screen"
            />
          </BaseDatatable>
        </Col>
      </Row>

      <Col sm={12}>
        <DialogWrapper
          isDialogVisible={isEditPage}
          headerTitle={
            licenseTemplate
              ? t('admin.page.licenseTemplates.form.header.edit_licenseTemplates_dialog')
              : t('admin.page.licenseTemplates.form.header.add_licenseTemplates_dialog')
          }
          onHide={() => {
            toggleIsEditPage(false);
            setLicenseTemplate(null);
          }}>
          <LicenseTemplateForm
            handleSubmit={handleUpdate}
            isLoading={licenseTemplates.licenseTemplateIsChanging}
            licenseTemplateEntitlements={activeEntitlements}
            licenseTemplate={licenseTemplate}
            hideDialog={hideDialog}
          />
        </DialogWrapper>
      </Col>
      {getConfirmPopup()}
    </>
  );
};

export default LicenseTemplateTable;
