/*
 * NewsletterCustomListForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterCustomListForm.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import React, { useEffect, useRef, useState } from 'react';
import { ILicense } from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import { useFormik } from 'formik';
import {
  INewsletterCustomList,
  NewsletterTypeCustomList
} from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import * as Yup from 'yup';
import { translate } from '../../../Utils/Translate';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { Button, Col, Form } from 'react-bootstrap';
import NewsletterLicenseTable from './NewsletterLicenseTable';
import { INewsletterStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';

/**
 * @interface
 */
interface INewsletterCustomListFormProperties {
  isLoading?: boolean /**< True if state is loading, false otherwise */;
  customList?: INewsletterCustomList /**< Custom List */;
  hideDialog: () => void /**< Hide the dialog/form component. */;
  handleAddOrEditCustomList: (
    payload: INewsletterCustomList
  ) => void /**< Add or Edit custom list(s). */;
  handleDeleteCustomList: (
    customLists: INewsletterCustomList[],
    isConfirmedDelete: boolean
  ) => void /**< Delete custom list(s). */;
  linkedNewsletters: string[] /**< Linked newsletter */;
  licenses: Record<string, any> /**< License list */;
  userData: IUser[] /**< User date */;
  licenseTablePayload: ITablePayload /**< License table Paylooad */;
  handleLicenseTableFilterUpdate: (event: string) => void /**< Handle filter event */;
  handleLicenseTableSortUpdate: (event: ISortEvent) => void /**< Handle sort event */;
  handleLicenseTablePageUpdate: (event: IPageEvent) => void /**< Handle page event */;
  handleLicenseTableFilterChange: (
    filterValue: Record<string, any>
  ) => void /**< Handle license filter change event */;
}

const NewsletterCustomListForm = (properties: INewsletterCustomListFormProperties): JSX.Element => {
  const [isShowConfirmation, setShowConfirmation] =
    useState<boolean>(false); /**< Show Confirmation Popup. */
  const [confirmPopupTarget, setConfirmPopupTarget] =
    useState<any>(null); /**< ConfirmationPopup Target. */
  const newsletterState: INewsletterStateSelector = useSelector(
    (state: IStateSelectors) => state.newsletter
  ); /**< Newsletter state */
  const [customLicenses, setCustomLicenses] = useState<ILicense[]>(
    (properties.customList && properties.customList.customLicenses) ?? []
  ); /**< Custom licenses */
  const [isShowDeleteWarningDialog, setShowDeleteWarningDialog] =
    useState<boolean>(false); /**< To show the warning dialog. */
  const deleteButtonReference = useRef<HTMLButtonElement>(null);
  const saveOrUpdateButtonReference = useRef<HTMLButtonElement>(null);
  const inputFileRef: React.MutableRefObject<undefined> = React.useRef();
  const [importedCSVFile, setImportedCSVFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    '' | 'File is being uploaded...' | 'File loaded successfully.'
  >('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      customListName: properties.customList?.customListName || '',
      customLicenses: properties.customList?.customLicenses || []
    },
    validationSchema: Yup.object({
      customListName: Yup.string()
        .min(2, translate('validation.min', { field: '2' }))
        .max(100, translate('validation.max', { field: '100' }))
        .required(
          translate('validation.required', {
            field: translate('I18N.admin.page.newsletterCustomList.form.name')
          })
        )
    }),
    onSubmit: (data: INewsletterCustomList) => {
      const payload: INewsletterCustomList = {};

      if (properties.customList) {
        Object.keys(data).forEach((key: string, index: number) => {
          if (key === 'customLicenses') {
            const newSelectedLicenseUUIDs: string = JSON.stringify(
              customLicenses &&
                customLicenses.map((license: ILicense) => license.licenseUUID).sort()
            );
            const initialSelectedLicenseUUIDs: string = JSON.stringify(
              formik.initialValues?.customLicenses
                .map((license: ILicense) => license.licenseUUID)
                .sort()
            );
            if (newSelectedLicenseUUIDs !== initialSelectedLicenseUUIDs) {
              payload.customLicenses = customLicenses;
            }
          } else if (
            data[key as keyof typeof formik.initialValues] !==
            formik.initialValues[key as keyof typeof formik.initialValues]
          ) {
            payload[key] = data[key as keyof typeof formik.initialValues];
          }
          if (index === Object.keys(data).length - 1) {
            if (payload && Object.keys(payload).length > 0) {
              handleSubmit({
                id: properties.customList.id,
                customListUUID: properties.customList.customListUUID,
                ...payload
              });
            }
          }
        });
      } else {
        data.type =
          importedCSVFile != null
            ? NewsletterTypeCustomList.EmailList
            : NewsletterTypeCustomList.LicenseList;

        handleSubmit(data);
      }
    }
  });

  /// Handle submit handler
  const handleSubmit = async (payload: INewsletterCustomList) => {
    if (importedCSVFile) {
      (payload as any).file = importedCSVFile;
    }
    await asyncErrorHandler(properties.handleAddOrEditCustomList(payload));
  };

  /// Show delete popup window
  const deleteButtonClickHandler = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete newsletter on Accept
  const onAccept = async (): Promise<void> => {
    setShowConfirmation(false);
    await asyncErrorHandler(properties.handleDeleteCustomList([properties.customList], false));
    setShowDeleteWarningDialog(true);
  };

  /// Hide popup on reject
  const onReject = (): void => {
    setShowConfirmation(false);
    setShowDeleteWarningDialog(false);
  };

  const onConfirmDelete = async (): Promise<void> => {
    await asyncErrorHandler(properties.handleDeleteCustomList([properties.customList], true));
    setShowDeleteWarningDialog(false);
    properties.hideDialog();
  };

  const renderRowDeleteDialogFooter = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => onReject()}
          autoFocus
          variant="secondary"
          className="d-flex align-items-center">
          <i className="bi bi-x-circle btn-icon"></i>
          {translate(
            'I18N.admin.page.newsletterCustomList.delete_customlist_dialog.confirmDeleteCustomlistsNo'
          )}
        </Button>
        <Button
          onClick={() => onConfirmDelete()}
          autoFocus
          variant="danger"
          className="d-flex align-items-center m-0">
          <i className="bi bi-trash btn-icon"></i>
          {translate(
            'I18N.admin.page.newsletterCustomList.delete_customlist_dialog.confirmDeleteCustomlistsYes'
          )}
        </Button>
      </div>
    );
  };

  const onImportCSVFile = async (): Promise<void> => {
    const file: any = document.querySelector('#file');
    let fileType;
    if (file && file.files[0] && file.files[0].name) {
      const originalFilename = file.files[0].name;
      if (originalFilename && originalFilename.includes('.')) {
        const splits = originalFilename.split('.');
        fileType = splits[splits.length - 1];
      }
    } else {
      return;
    }

    if (!fileType?.includes('csv')) {
      handleError({ message: 'only .csv' });
      return;
    }
    setUploadStatus('File is being uploaded...');

    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setImportedCSVFile(file.files[0]);
      setUploadStatus('File loaded successfully.');

      file.value = '';
    } catch (error) {
      // nothing to do here now
    }

    return;
  };

  useEffect(() => {
    formik.setFieldValue('customLicenses', customLicenses);
  }, [customLicenses]);

  return (
    <>
      <Form.Group as={Col} sm="12" md="12" className="pl-0">
        <InstaInputText
          label={translate('I18N.admin.page.newsletterCustomList.form.name')}
          labelClassName="required"
          name="customListName"
          id={'customListName'}
          isLoading={properties.isLoading}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.customListName}
          errors={formik.errors.customListName}
          value={formik.values.customListName}
        />
      </Form.Group>
      <Form.Group className="p-0" as={Col}>
        <Form.Label>{'Import CSV'}</Form.Label>
        <Form.File
          ref={inputFileRef}
          className="mb-5 d-flex align-items-center justify-content-center"
          id="file"
          name="file"
          label={translate('admin.page.files.versions.chooseFile')}
          onChange={onImportCSVFile}
          custom
          disabled={uploadStatus === 'File is being uploaded...'}
          title=""
        />
      </Form.Group>
      <Form.Group as={Col} sm="12" md="12" className="pl-0">
        {importedCSVFile == null ? (
          <>
            <Form.Label className="required">
              {translate('I18N.admin.page.newsletterCustomList.form.license')}
            </Form.Label>
            <NewsletterLicenseTable
              customLicenses={customLicenses}
              setCustomLicenses={setCustomLicenses}
              customLicensesFilter={newsletterState && newsletterState.customLicensesFilter}
              licenses={properties.licenses}
              userData={properties.userData}
              tablePayload={properties.licenseTablePayload}
              handleFilterUpdate={properties.handleLicenseTableFilterUpdate}
              handleSortUpdate={properties.handleLicenseTableSortUpdate}
              handlePageUpdate={properties.handleLicenseTablePageUpdate}
              handleLicenseFilterChange={properties.handleLicenseTableFilterChange}
            />
          </>
        ) : (
          <div className="text-center">{uploadStatus}</div>
        )}
        {formik.touched.customLicenses && formik.errors.customLicenses ? (
          <small id="html-invalid" className="p-invalid error-text">
            {formik.errors.customLicenses}
          </small>
        ) : null}
      </Form.Group>
      <div className="d-flex align-items-center justify-content-between">
        {properties.isLoading && <Loader />}
        {!properties.isLoading && (
          <>
            {properties.customList && Object.keys(properties.customList).length > 0 ? (
              <Button
                type="button"
                className="d-flex align-items-center"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  deleteButtonClickHandler(event)
                }
                ref={deleteButtonReference}
                variant="danger">
                <i className="bi bi-trash btn-icon"></i>
                {translate('awc:/.button.delete')}
              </Button>
            ) : (
              <></>
            )}
          </>
        )}
        {!properties.isLoading && (
          <>
            <div />
            <Button
              className="d-flex align-items-center"
              ref={saveOrUpdateButtonReference}
              type="button"
              variant="primary"
              onClick={() => formik.handleSubmit()}>
              <i className="bi bi-check2-circle btn-icon"></i>
              {properties.customList && Object.keys(properties.customList).length > 0
                ? translate('awc:/.button.update')
                : translate('awc:/.button.save')}
            </Button>
          </>
        )}
      </div>
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isShowConfirmation}
        title={translate('/confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translate('/confirm_messages.no')}
        acceptLabel={translate('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="top"
      />
      <Dialog
        header={translate(
          'I18N.admin.page.newsletterCustomList.delete_customlist_dialog.confirmDeleteCustomlistsHeader'
        )}
        visible={isShowDeleteWarningDialog && properties.linkedNewsletters.length > 0}
        className="custom-dialog-container"
        footer={renderRowDeleteDialogFooter()}
        onHide={() => setShowDeleteWarningDialog(false)}
        draggable={false}>
        <Col className="mb-2 px-0">
          {translate(
            'I18N.admin.page.newsletterCustomList.delete_customlist_dialog.confirmDeleteCustomlists'
          )}
        </Col>
        {properties.linkedNewsletters.length > 0 && (
          <Col className="mb-2 px-0">
            Total Affected Newsletters: <b>{properties.linkedNewsletters.length}</b> - [
            {properties.linkedNewsletters.join(', ')}]
          </Col>
        )}
      </Dialog>
    </>
  );
};

export default NewsletterCustomListForm;
