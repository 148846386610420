/*
 * FileRecordTableExpansion.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file FileRecordTableExpansion.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section FileRecord
 */

import React, { ReactElement, useState } from 'react';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { sortCompare } from '../../../Utils/Formatter';
import { ISortEvent } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';

const FileRecordTableExpansion = ({ data }: any): ReactElement => {
  const { t } = useTranslation();
  let linkedEntilements: IEntitlement[] =
    data && data.length > 0 ? [...data] : []; /**< Linked Entitlements. */
  const [sort, setSort] = useState<ISortEvent>({
    sortField: 'name',
    sortOrder: 1
  }); /**< Default Sort. */

  const header: JSX.Element = (
    <div className="text-center">{t('admin.page.files.table.linkedEntitlements')}</div>
  );

  // Sort the table
  linkedEntilements = linkedEntilements.sort((a: any, b: any) =>
    sortCompare(a, b, sort.sortField, sort.sortOrder)
  );

  return (
    <>
      <Row>
        <Col>
          {linkedEntilements && linkedEntilements.length > 0 ? (
            <BaseDatatable
              header={header}
              value={linkedEntilements}
              parentClass="fileLinkedEntitlementsDataTable nestedDatatable nestedDatatable-withoutPagination" /**< ClassName for div Component.*/
              responsive /**< Datatable responsive layout.*/
              paginator={false} /** No paginator */
              onSort={(entitlement: any) => setSort(entitlement)}
              sortField={sort.sortField}
              sortOrder={sort.sortOrder}>
              <Column selectionMode="multiple" className="d-none" />
              <Column
                sortable
                field="name"
                className="pl-0 w-50"
                header={t('admin.page.files.form.name')}
                body={(rows: any) => (
                  <DatatableColumn title={t('admin.page.files.form.name')} data={rows.name} />
                )}
              />
              <Column
                sortable
                field="description"
                className="pl-0"
                header={t('admin.page.files.form.description')}
                body={(rows: any) => (
                  <DatatableColumn
                    title={t('admin.page.files.form.description')}
                    data={rows.description}
                  />
                )}
              />
            </BaseDatatable>
          ) : (
            <div className="text-center">{t('admin.page.files.table.noLinksFound')}</div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default FileRecordTableExpansion;
