/*
 * ClientContent.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file ClientContent.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { getClientRoutes, getSupportRoutes } from '../../Routes/ClientRoutes';
import PageHeader from '@abstract/abstractwebcommon-client/PageHeader/PageHeader';
import Col from 'react-bootstrap/Col';
import { IStateSelectors, ISettingsStateSelector } from '../../Interfaces/Selectors';
import { RouteName } from '../../Utils/routesNames';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';

/**
 * Interface for route
 */
interface IRouteSelector {
  path?: string /**< page route >*/;
  Component: React.ReactNode /**< component >*/;
  isAuthenticated: boolean /**< True if page require authentication and false otherwise>*/;
  headingKey?: string /**< Page header >*/;
}
const ClientContent = () => {
  const user = useSelector((state: any) => state.auth);
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const languageSettingsMode: string =
    LocalStorage.getLanguageSettingsMode() ||
    LanguageSettingsMode.english; /**< Language settings mode */
  const [clientRoutes, setClientRoutes] = useState<any>([]); /**< Client Routes */
  const [supportRoutes, setSupportRoutes] = useState<any>(); /**< Support Routes */

  // Get client routes when language changes
  useEffect(() => {
    const updatedClientRoutes = getClientRoutes();
    setClientRoutes([...updatedClientRoutes]); /**< Client routes */

    const updatedSupportRoutes = getSupportRoutes();
    setSupportRoutes(updatedSupportRoutes); /**< Support routes */
  }, [languageSettingsMode]);

  clientRoutes.map((route: IRouteSelector, index: number) => {
    if (
      settings &&
      settings.safeSettings.isSupportRole &&
      route.path === RouteName.clientReportsRoute
    ) {
      clientRoutes[index] = supportRoutes;
    }
  });

  //Note: Get client routes when localstorage changes
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.languageSettingsModeKey) {
      const updatedClientRoutes = getClientRoutes();
      setClientRoutes([...updatedClientRoutes]); /**< Client routes */

      const updatedSupportRoutes = getSupportRoutes();
      setSupportRoutes(updatedSupportRoutes); /**< Support routes */
    }
  });

  const renderComponent = clientRoutes.map(
    ({ path, Component, headingKey, isAuthenticated, isHeaderLabelHide = true }, key) => {
      if (isAuthenticated && !user.isAuthenticated) {
        return <Redirect key={key} to={{ pathname: SharedCommomRouteName.validateRoute }} />;
      }

      return (
        <Route exact path={path} key={key}>
          {headingKey && isHeaderLabelHide ? <PageHeader title={headingKey} /> : null}
          <Col sm={12}>
            <Component />
          </Col>
        </Route>
      );
    }
  );

  return <Switch>{renderComponent}</Switch>;
};

export default ClientContent;
