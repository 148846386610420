/**
* routesNames.ts (abstractuser) *

* Copyright © 2023 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2023
* @file routesNames.ts
* @author Rafael Rodrigues
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import { SharedMainRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';

/**
 * @enum RouteName
 * Use this file to centralize all route names in the application
 * Add route value below as needed.
 */
export enum RouteName {
  dashboardRoute = 'dashboard' /**< /dashboard route name */,
  technicalSupportRoute = 'technical-support' /**< /technical-support route name */,

  adminActivationsRoute = `${SharedMainRouteName.adminRoute}/activations` /**< /admin/activations route name */,
  adminDeactivationsRoute = `${SharedMainRouteName.adminRoute}/activations/bulk-deactivations` /**< /admin/activations/bulk-deactivations route name */,
  adminFilesRoute = `${SharedMainRouteName.adminRoute}/files` /**< /admin/files route name */,
  adminEntitlementRoute = `${SharedMainRouteName.adminRoute}/entitlement` /**< /admin/entitlement route name */,
  adminLicensesRoute = `${SharedMainRouteName.adminRoute}/licenses` /**< /admin/licenses route name */,
  adminTemplateEditorRoute = `${SharedMainRouteName.adminRoute}/templates/template-editor` /**< /admin/templates/template-editor route name */,
  adminTemplateCSSEditorRoute = `${SharedMainRouteName.adminRoute}/templates/css` /**< /admin/templates/css route name */,
  adminTemplateLicenseRoute = `${SharedMainRouteName.adminRoute}/license-templates` /**< /admin/license-templates route name */,
  adminSettingsRoute = `${SharedMainRouteName.adminRoute}/settings` /**< /admin/settings route name */,
  adminLogsRoute = `${SharedMainRouteName.adminRoute}/logs` /**< /admin/logs route name */,
  adminReportsRoute = `${SharedMainRouteName.adminRoute}/${technicalSupportRoute}` /**< /admin/technical-support route name */,
  adminAccountRoute = `${SharedMainRouteName.adminRoute}/account` /**< /admin/account route name */,
  adminClientNewRoute = `${SharedMainRouteName.adminRoute}/clients/new` /**< /admin/clients/new route name */,
  adminNewsletterRoute = `${SharedMainRouteName.adminRoute}/newsletter` /**< /admin/newsletter route name */,
  adminNewsletterCustomListRoute = `${SharedMainRouteName.adminRoute}/newsletter/custom-list` /**< /admin/newsletter/custom-list route name */,
  adminNewsletterAnalyticsRoute = `${SharedMainRouteName.adminRoute}/newsletter/analytics` /**< /admin/newsletter/analytics route name */,
  adminClientHomeRoute = `${SharedMainRouteName.adminRoute}/client-files` /**< /admin/client/home route name */,
  newsletterUnsubscribeRoute = '/newsletter/unsubscribe' /**< /newsletter/unsubscribe route name */,
  newsletterUnsubscribeByEmailRoute = '/newsletter/unsubscribeByEmail' /**< /newsletter/unsubscribeByEmail route name */,
  adminNewsletterBlackListRoute = `${SharedMainRouteName.adminRoute}/newsletter/blacklist` /**< /admin/newsletter/blacklist route name */,

  eulaPermissionRoute = '/eula/Permission/' /**< /eula/Permission/ route name */,
  slaPermissionRoute = '/sla/Permission/' /**< /sla/Permission/ route name */,
  clientHomeRoute = `${SharedMainRouteName.clientRoute}/home` /**< /client/home route name */,
  clientActivationsRoute = `${SharedMainRouteName.clientRoute}/activations` /**< /client/activations route name */,
  clientReportsRoute = `${SharedMainRouteName.clientRoute}/${technicalSupportRoute}` /**< /client/technical-support route name */,
  clientLicenseRoute = `${SharedMainRouteName.clientRoute}/licenses` /**< /client/licenses route name */
}
