/*
 * SettingSMTPForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file SettingSMTPForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { Fieldset } from 'primereact/fieldset';
import { ISMTPSettingFormValues } from './ISettingFormValues';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { validators } from '@abstract/abstractwebcommon-shared/validators';
import SharedSettingsSMTPForm from '@abstract/abstractwebcommon-client/SettingsPage/SettingsSMTPForm';
import { DatabaseType } from '@abstract/abstractwebcommon-shared/constants/connectionDatabaseType';
import { CheckboxProps } from 'primereact/checkbox';
import { ISetting } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import Button from 'react-bootstrap/Button';

interface ISMTPSettingOtherProperties {
  handleSubmit: (data: any) => void;
  handleTestSmtp?: any;
  isLoading?: boolean;
  settingsState?: any;
  isLoadingTestSMTP?: boolean /**< Loading state for test smtp */;
  translation?: any;
}

interface ISMTPSettingMyFormProperties {
  initialSmtpHost?: string;
  initialSmtpUser?: string;
  initialSmtpPassword?: string;
  initialSmtpPort?: string;
  initialSmtpSecure?: boolean;
  initialFromEmail?: string;
  initialFromName?: string;
  translation?: any;
  handleSubmit: (data: any) => void;
  isLoading?: boolean;
  settingsState?: any;
  handleTestSmtp?: any;
  isLoadingTestSMTP?: boolean /**< Loading state for test smtp */;
}

const SettingSMTPForm = (
  properties: ISMTPSettingOtherProperties & FormikProps<ISMTPSettingFormValues>
) => {
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
    touched,
    values,
    errors,
    isLoading,
    handleTestSmtp,
    settingsState,
    isLoadingTestSMTP
  } = properties;
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [isSMTPUpdatesExist, setSMTPUpdatesExist] =
    useState<boolean>(false); /**< isSMTPUpdatesExist. */
  const isSMTPErrorsExists: boolean =
    errors && Object.keys(errors).length > 0; /**< isSMTPErrorsExists. */

  /// show save popup
  const saveBtnClick = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event?.target);
  };

  /// save on Accept
  const onAccept = () => {
    setShowConfirmation(false);
    handleSubmit();
    setSMTPUpdatesExist(false);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={t('/confirm_messages.save_record')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  /// Handle change event
  const onChange = (event: any): void => {
    handleChange(event);
    revalidateSMTPUpdates(event.target);
  };

  /// Set Field value
  const setFieldValueLocal = (key: string, value: any): void => {
    setFieldValue(key, value);
    revalidateSMTPUpdates({
      id: key,
      value
    });
  };

  /// Validate SMTP updates
  const revalidateSMTPUpdates = (currentUpdate: any): void => {
    const smtpValues: ISetting = { ...values };
    if (smtpValues) {
      smtpValues[currentUpdate.id] = currentUpdate.value;
    }
    setSMTPUpdatesExist(checkIfSMTPUpdatesExists(smtpValues));
  };

  /// Check SMTP updates exists or not.
  const checkIfSMTPUpdatesExists = (smtpValues: ISetting): boolean => {
    return smtpValues && Object.keys(mapSMTPValues(smtpValues)).length !== 0;
  };

  /// SMTP Values
  const mapSMTPValues = (smtpValues: ISetting) => {
    const payload: ISetting = {};
    const setting: ISetting = settingsState.setting; /**< Setting */
    if (!setting) {
      return {};
    }
    if (setting.smtpHost !== smtpValues.smtpHost) {
      payload['smtpHost'] = smtpValues.smtpHost;
    }
    if (setting.smtpPort !== smtpValues.smtpPort) {
      payload['smtpPort'] = smtpValues.smtpPort;
    }
    if (setting.smtpSecure !== smtpValues.smtpSecure) {
      payload['smtpSecure'] = smtpValues.smtpSecure;
    }
    if (setting.smtpUser !== smtpValues.smtpUser) {
      payload['smtpUser'] = smtpValues.smtpUser;
    }
    if (setting.smtpPassword !== smtpValues.smtpPassword) {
      payload['smtpPassword'] = smtpValues.smtpPassword;
    }
    if (setting.fromEmail !== smtpValues.fromEmail) {
      payload['fromEmail'] = smtpValues.fromEmail;
    }
    if (setting.fromName !== smtpValues.fromName) {
      payload['fromName'] = smtpValues.fromName;
    }
    return payload;
  };

  /// Handles SMTP test
  const testSMTPOnClick = (smtpValues: any): void => {
    handleTestSmtp(smtpValues);
  };

  const { t } = useTranslation();

  return (
    <Col xs={12} className="px-0">
      <Form>
        <Fieldset legend={t('admin.page.settings.category.smtpSettings')}>
          <Row>
            <SharedSettingsSMTPForm
              handleChange={onChange}
              handleBlur={handleBlur}
              isLoading={isLoading}
              touched={touched}
              errors={errors}
              values={values}
              databaseType={DatabaseType.mySQL}
              handleOnChangeSecureCheckbox={(e: CheckboxProps) =>
                setFieldValueLocal('smtpSecure', e.checked)
              }
            />
            <Col sm={6} className="pb-0 mb-2 mb-sm-0">
              <Button
                className={`w-100 ${
                  isLoading ||
                  isLoadingTestSMTP ||
                  showConfirmation ||
                  !isSMTPUpdatesExist ||
                  isSMTPErrorsExists
                    ? 'custom-disabled-button'
                    : ''
                }`}
                onClick={saveBtnClick}
                disabled={
                  isLoading ||
                  isLoadingTestSMTP ||
                  showConfirmation ||
                  !isSMTPUpdatesExist ||
                  isSMTPErrorsExists
                }>
                {isLoadingTestSMTP
                  ? t('admin.page.settings.form.loading')
                  : t('admin.page.settings.buttons.save')}
              </Button>
            </Col>
            <Col sm={6} className="pb-0">
              <Button
                className={`w-100 ${
                  isLoadingTestSMTP || showConfirmation || isSMTPUpdatesExist
                    ? 'custom-disabled-button'
                    : ''
                }`}
                onClick={() => testSMTPOnClick(values)}
                disabled={isLoadingTestSMTP || showConfirmation || isSMTPUpdatesExist}>
                {isLoadingTestSMTP
                  ? t('admin.page.settings.form.loading')
                  : t('admin.page.settings.form.testSmtp')}
              </Button>
            </Col>
          </Row>
        </Fieldset>
        {getConfirmPopup()}
      </Form>
    </Col>
  );
};

const SMTPSetting = withFormik<ISMTPSettingMyFormProperties, ISMTPSettingFormValues>({
  enableReinitialize: true,
  mapPropsToValues: (properties) => {
    const { setting } = properties.settingsState;
    if (setting) {
      return {
        smtpHost: setting.smtpHost,
        smtpUser: setting.smtpUser,
        smtpPassword: setting.smtpPassword,
        smtpPort: setting.smtpPort,
        smtpSecure: setting.smtpSecure,
        fromEmail: setting.fromEmail,
        fromName: setting.fromName
      };
    }
    return {
      smtpHost: properties.initialSmtpHost || '',
      smtpUser: properties.initialSmtpUser || '',
      smtpPassword: properties.initialSmtpPassword || '',
      smtpPort: properties.initialSmtpPort || '',
      smtpSecure: properties.initialSmtpSecure || false,
      fromEmail: properties.initialFromEmail || '',
      fromName: properties.initialFromName || ''
    };
  },
  validationSchema: (properties: any) => {
    const t = properties.translation;

    // validation schema
    const validationSpec = {
      smtpHost: Yup.string()
        .min(2, t('validation.min', { field: '2' }))
        .required(t('validation.required', { field: t('admin.page.settings.form.smtpHost') })),
      smtpUser: Yup.string()
        .min(2, t('validation.min', { field: '2' }))
        .required(t('validation.required', { field: t('admin.page.settings.form.smtpUser') })),
      smtpPassword: Yup.string()
        .min(2, t('validation.min', { field: '2' }))
        .required(t('validation.required', { field: t('admin.page.settings.form.smtpPassword') })),
      smtpPort: Yup.number().required(
        t('validation.required', { field: t('admin.page.settings.form.smtpPort') })
      ),
      smtpSecure: Yup.boolean().oneOf([true, false]),
      fromEmail: Yup.string()
        .email()
        .min(2, t('validation.min', { field: '2' }))
        .required(t('validation.required', { field: t('admin.page.settings.form.fromEmail') })),
      fromName: Yup.string()
        .nullable()
        .min(2, t('validation.min', { field: '2' }))
        .matches(validators.NOT_GT_OR_LT, t('validation.not_lt_or_gt'))
    };

    return Yup.object(validationSpec);
  },
  handleSubmit: (values, bags) => {
    if (bags.props && bags.props.settingsState.setting) {
      return bags.props.handleSubmit({
        ...values,
        id: bags.props.settingsState.setting.id
      });
    }
    return bags.props.handleSubmit({
      ...values
    });
  }
})(SettingSMTPForm);

export default SMTPSetting;
