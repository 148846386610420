/*
 * Settings.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Settings.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import {
  IAPIEntityResponse,
  IAPIErrorData
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IPublicEnvironmentVariables } from '@abstract/abstractwebcommon-shared/interfaces/license/environmentVariables';
import {
  IFavouriteIconResponse,
  IImageUploadResponse,
  ILogoResponse,
  ISafeSettingsResponse,
  ISettingSafe
} from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  createOrUpdate,
  fetchUserApplications,
  getApplicationEnvironmentVariablesAPI,
  getFavouriteIcon,
  getLogo,
  processUnmarkedTransactions,
  read,
  readApplicationDetails,
  readSafe,
  testSmtp,
  uploadFavouriteIcon,
  uploadLogo
} from '../Services/Setting';
import {
  IGetSystemSettingsResponse,
  IUpdateSystemSettingsResponse
} from '@abstract/abstractwebcommon-shared/interfaces/license/api';
import { IStaticLink } from '@abstract/abstractwebcommon-shared/interfaces/staticLinks';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { fetchSystemWarningsAction } from './Dashboard';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { getUsersFromUserAPI } from '../Services/UserCache';

/**
 * Request payload for update system settings
 */
interface IUpdateSettingsRequest {
  uploadLogoImage?: boolean /**< True if action is upload logo, false otherwise. */;
  uploadFavouriteIconImage?: boolean /**< True if action is upload favicon, false otherwise. */;
  deleteLogoImage?: boolean /**< True if action is delete logo, false otherwise. */;
  deleteFavouriteIconImage?: boolean /**< True if action is delete favicon, false otherwise. */;
}

export const SETTING_FEATURE_KEY = 'settings';
interface ISettingState {
  setting: any;
  settingsIsFetching: boolean | null;
  favouriteIconIsChanging: boolean;
  isFavouriteIconUploaded: boolean;
  isFavouriteIconDeleted: boolean;
  logoIsChanging: boolean;
  isLogoUploaded: boolean;
  isLogoDeleted: boolean;
  settingIsChanging: boolean;
  environmentIsFetching: boolean;
  environmentVariables: any;
  isCheckingNewPurchases: any;
  isCheckingUsersFromUserAPI: boolean;
  resizedFavouriteIconImageURLs: {
    favouriteIconImageURLSmall: string;
    favouriteIconImageURLMedium: string;
    favouriteIconImageURLLarge: string;
  };
  staticLinks: IStaticLink[];
  isStaticLinksLoading: boolean;
  isStaticLinkCreated: boolean;
  isStaticLinkUpdated: boolean;
  isStaticLinksDeleted: boolean;
  userApplicationsIsFetching: boolean;
  userApplications: IApplications[];
  experimentalFeatures: string | null /** Experimental features */;
  isSettingsUpdated: boolean /**< Is settings update. */;
  isLoadingTestSMTP: boolean /**< Loading state for test smtp */;
  safeSettings: ISettingSafe | null /**< Safe settings. */;
  isApplicationDetailsFetching: boolean /**< Application details fetching */;
  applicationDetails: IApplications | null /**< Application details */;
}

const INITIAL_STATE: ISettingState = {
  setting: null,
  settingsIsFetching: false,
  favouriteIconIsChanging: false,
  isFavouriteIconUploaded: false,
  isFavouriteIconDeleted: false,
  logoIsChanging: false,
  isLogoUploaded: false,
  isLogoDeleted: false,
  settingIsChanging: false,
  environmentIsFetching: false,
  environmentVariables: null,
  isCheckingNewPurchases: false,
  isCheckingUsersFromUserAPI: false,
  resizedFavouriteIconImageURLs: {
    favouriteIconImageURLSmall: '',
    favouriteIconImageURLMedium: '',
    favouriteIconImageURLLarge: ''
  },
  staticLinks: [],
  isStaticLinksLoading: false,
  isStaticLinkCreated: false,
  isStaticLinkUpdated: false,
  isStaticLinksDeleted: false,
  userApplications: [],
  userApplicationsIsFetching: true,
  experimentalFeatures: null,
  isSettingsUpdated: false,
  isLoadingTestSMTP: false,
  safeSettings: null,
  isApplicationDetailsFetching: false,
  applicationDetails: null
};

export const settingSlice = createSlice({
  name: SETTING_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    getSettingsRequest(state: ISettingState) {
      state.settingsIsFetching = true;
      state.favouriteIconIsChanging = true;
      state.logoIsChanging = true;
    },
    getSettingsSuccess(
      state: ISettingState,
      action: IReducerAction<IAPIEntityResponse<IGetSystemSettingsResponse>>
    ) {
      state.settingsIsFetching = false;
      state.favouriteIconIsChanging = false;
      state.logoIsChanging = false;
      state.setting = action.payload;
      state.experimentalFeatures = action.payload.experimentalFeatures;
    },
    getSettingsFailure(state: ISettingState) {
      state.favouriteIconIsChanging = false;
      state.logoIsChanging = false;
      state.settingsIsFetching = false;
    },
    getAppEnvironmentVariablesActionRequest(state: ISettingState) {
      state.environmentIsFetching = true;
    },
    getAppEnvironmentVariablesActionSuccess(
      state: ISettingState,
      action: IReducerAction<IPublicEnvironmentVariables>
    ) {
      state.environmentIsFetching = false;
      state.environmentVariables = action.payload;
    },
    getAppEnvironmentVariablesActionFailure(state: ISettingState) {
      state.environmentIsFetching = false;
    },
    updateSettingsRequest(state: ISettingState, action: IReducerAction<IUpdateSettingsRequest>) {
      const actions = action.payload;
      state.settingIsChanging = true;

      if (actions.uploadLogoImage) {
        state.logoIsChanging = false;
        state.isLogoUploaded = true;
      }

      if (actions.uploadFavouriteIconImage) {
        state.favouriteIconIsChanging = true;
        state.isFavouriteIconUploaded = false;
      }

      if (actions.deleteLogoImage) {
        state.logoIsChanging = true;
      }

      if (actions.deleteFavouriteIconImage) {
        state.favouriteIconIsChanging = true;
      }
    },
    updateSettingsSuccess(
      state: ISettingState,
      action: IReducerAction<IAPIEntityResponse<IUpdateSystemSettingsResponse>>
    ) {
      const responses = action.payload;

      state.settingIsChanging = false;
      state.logoIsChanging = false;
      state.favouriteIconIsChanging = false;
      state.isSettingsUpdated = true;

      if (responses.data) {
        state.resizedFavouriteIconImageURLs = {
          favouriteIconImageURLSmall: responses.data.favouriteIconImageURLSmall,
          favouriteIconImageURLMedium: responses.data.favouriteIconImageURLMedium,
          favouriteIconImageURLLarge: responses.data.favouriteIconImageURLLarge
        };
        state.setting = responses.data;
        state.experimentalFeatures = responses.data.experimentalFeatures;
      }
    },
    updateSettingsFailure(state: ISettingState) {
      state.settingIsChanging = false;
    },
    testSmtpRequest(state: ISettingState) {
      state.isLoadingTestSMTP = true;
    },
    testSmtpSuccess(state: ISettingState) {
      state.isLoadingTestSMTP = false;
    },
    testSmtpFailure(state: ISettingState) {
      state.isLoadingTestSMTP = false;
    },
    uploadFavouriteIconFailure(state: ISettingState) {
      state.favouriteIconIsChanging = false;
      state.settingIsChanging = false;
    },
    deleteFavouriteIconFailure(state: ISettingState) {
      state.favouriteIconIsChanging = false;
      state.settingIsChanging = false;
    },
    uploadLogoFailure(state: ISettingState) {
      state.logoIsChanging = false;
      state.settingIsChanging = false;
    },
    deleteLogoFailure(state: ISettingState) {
      state.logoIsChanging = false;
      state.settingIsChanging = false;
    },
    getLogoActionRequest(state: ISettingState) {
      state.logoIsChanging = true;
    },
    getLogoActionSuccess(state: ISettingState) {
      state.logoIsChanging = false;
    },
    getLogoActionFailure(state: ISettingState) {
      state.logoIsChanging = false;
    },
    getFavouriteIconActionRequest(state: ISettingState) {
      state.favouriteIconIsChanging = true;
    },
    getFavouriteIconActionSuccess(
      state: ISettingState,
      action: IReducerAction<IAPIEntityResponse<IFavouriteIconResponse>>
    ) {
      state.favouriteIconIsChanging = false;

      state.resizedFavouriteIconImageURLs = {
        favouriteIconImageURLSmall: action.payload.data.favouriteIconImageURLSmall,
        favouriteIconImageURLMedium: action.payload.data.favouriteIconImageURLMedium,
        favouriteIconImageURLLarge: action.payload.data.favouriteIconImageURLLarge
      };
    },
    getFavouriteIconActionFailure(state: ISettingState) {
      state.favouriteIconIsChanging = false;
    },
    processUnmarkedTransactionsRequest(state: ISettingState) {
      state.isCheckingNewPurchases = true;
    },
    processUnmarkedTransactionsSuccess(state: ISettingState) {
      state.isCheckingNewPurchases = false;
    },
    processUnmarkedTransactionsFailure(state: ISettingState) {
      state.isCheckingNewPurchases = false;
    },

    processGetUsersFromUserAPIRequest(state: ISettingState) {
      state.isCheckingUsersFromUserAPI = true;
    },
    processGetUsersFromUserAPISuccess(state: ISettingState) {
      state.isCheckingUsersFromUserAPI = false;
    },
    processGetUsersFromUserAPIFailure(state: ISettingState) {
      state.isCheckingUsersFromUserAPI = false;
    },

    createStaticLinkActionRequest(state: ISettingState) {
      state.staticLinks = [];
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.isStaticLinkCreated = false;
    },
    createStaticLinkActionSuccess(state: ISettingState) {
      state.isStaticLinksLoading = false;
      state.isStaticLinkCreated = true;
    },
    createStaticLinkActionFailure(state: ISettingState) {
      state.isStaticLinksLoading = false;
    },
    updateStaticLinkActionRequest(state: ISettingState) {
      state.staticLinks = [];
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.isStaticLinkUpdated = false;
    },
    updateStaticLinkActionSuccess(state: ISettingState) {
      state.isStaticLinksLoading = false;
      state.isStaticLinkUpdated = true;
    },
    updateStaticLinkActionFailure(state: ISettingState) {
      state.isStaticLinksLoading = false;
    },
    getStaticLinksActionRequest(state: ISettingState) {
      state.isStaticLinkCreated = false;
      state.isStaticLinkUpdated = false;
      state.isStaticLinksLoading = true;
      state.isStaticLinksDeleted = false;
      state.staticLinks = [];
    },
    getStaticLinksActionSuccess(state: ISettingState, action: IReducerAction<IStaticLink[]>) {
      state.isStaticLinksLoading = false;
      state.staticLinks = action.payload;
    },
    getStaticLinksActionFailure(state: ISettingState) {
      state.isStaticLinksLoading = false;
    },
    deleteStaticLinksActionRequest(state: ISettingState) {
      state.isStaticLinkCreated = false;
      state.isStaticLinkUpdated = false;
      state.isStaticLinksDeleted = false;
      state.isStaticLinksLoading = true;
      state.staticLinks = [];
    },
    deleteStaticLinksActionSuccess(state: ISettingState) {
      state.isStaticLinksLoading = false;
      state.isStaticLinksDeleted = true;
    },
    deleteStaticLinksActionFailure(state: ISettingState) {
      state.isStaticLinksLoading = false;
    },
    uploadStaticLinkIconFailure(state: ISettingState) {
      state.isStaticLinksLoading = false;
      state.isStaticLinkUpdated = false;
    },
    getUserApplicationsRequest(state: ISettingState) {
      state.userApplicationsIsFetching = true;
    },
    getUserApplicationsSuccess(state: ISettingState, action: IReducerAction<IApplications[]>) {
      state.userApplications = action.payload ?? [];
      state.userApplicationsIsFetching = false;
    },
    getUserApplicationsFailure(state: ISettingState) {
      state.userApplicationsIsFetching = false;
    },
    getSafeSettingsRequest(state: ISettingState) {
      state.settingsIsFetching = true;
      state.favouriteIconIsChanging = true;
      state.logoIsChanging = true;
    },
    getSafeSettingsSuccess(
      state: ISettingState,
      action: IReducerAction<IAPIEntityResponse<IGetSystemSettingsResponse>>
    ) {
      state.settingsIsFetching = false;
      state.favouriteIconIsChanging = false;
      state.logoIsChanging = false;
      state.safeSettings = action.payload as ISettingSafe;
      state.experimentalFeatures = action.payload.experimentalFeatures;
    },
    getSafeSettingsFailure(state: ISettingState) {
      state.favouriteIconIsChanging = false;
      state.logoIsChanging = false;
      state.settingsIsFetching = false;
    },
    getApplicationDetailsRequest(state: ISettingState) {
      state.isApplicationDetailsFetching = true;
    },
    getApplicationDetailsSuccess(state: ISettingState, action: IReducerAction<IApplications>) {
      state.applicationDetails = action.payload;
      state.isApplicationDetailsFetching = false;
    },
    getApplicationDetailsFailure(state: ISettingState) {
      state.isApplicationDetailsFetching = false;
    }
  },
  extraReducers: {}
});

export const settingReducer = settingSlice.reducer;
export const settingActions = settingSlice.actions;

/**
 * Get all settings Action.
 */
export const getSettings = createAsyncThunk('setting/all', async (payload: void, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getSettingsSuccess, getSettingsRequest, getSettingsFailure } = settingActions;

  try {
    dispatch(getSettingsRequest());
    const response: IAPIEntityResponse<IGetSystemSettingsResponse> = await asyncErrorHandler(
      read()
    );
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getSettingsFailure());
    } else {
      dispatch(getSettingsSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getSettingsFailure());
    handleError({ message: error.message });
  }
});

/**
 * Get all settings Action.
 */
export const getApplicationEnvironmentVariables = createAsyncThunk(
  'setting/environmental/variable/get',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getAppEnvironmentVariablesActionSuccess,
      getAppEnvironmentVariablesActionRequest,
      getAppEnvironmentVariablesActionFailure
    } = settingActions;

    try {
      dispatch(getAppEnvironmentVariablesActionRequest());
      const response: IAPIEntityResponse<IPublicEnvironmentVariables> = await asyncErrorHandler(
        getApplicationEnvironmentVariablesAPI()
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getAppEnvironmentVariablesActionFailure());
      } else {
        dispatch(getAppEnvironmentVariablesActionSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getAppEnvironmentVariablesActionFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Get all safe settings Action.
 */
export const getSafeSettings = createAsyncThunk('setting/all', async (payload: void, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getSafeSettingsSuccess, getSafeSettingsRequest, getSafeSettingsFailure } = settingActions;

  try {
    dispatch(getSafeSettingsRequest());
    const response: IAPIEntityResponse<ISafeSettingsResponse> = await asyncErrorHandler(readSafe());
    if (response.error) {
      handleError({ message: (response.error as IAPIErrorData).message });
      dispatch(getSafeSettingsFailure());
    } else {
      if (response.data) {
        if (response.data.logoImageURL) {
          LocalStorage.setLogoImageURL(response.data && response.data.logoImageURLSmall);
        }
        if (response.data.favouriteIconImageURL) {
          LocalStorage.setFavouriteIconImageURLKey(
            response.data && response.data.favouriteIconImageURL
          );
        }
      }
      dispatch(getSafeSettingsSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getSafeSettingsFailure());
    handleError({ message: error.message });
  }
});

/**
 * Update settings Action.
 * @param payload
 */
export const updateSettings = createAsyncThunk('setting/update', async (payload: any, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const {
    updateSettingsSuccess,
    updateSettingsRequest,
    updateSettingsFailure,
    uploadLogoFailure,
    uploadFavouriteIconFailure: uploadFavouriteIconFailure
  } = settingActions;

  try {
    const { uploadLogoImage, uploadFavouriteIconImage, values } = payload;
    dispatch(updateSettingsRequest(payload));

    if (uploadLogoImage) {
      const response: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
        uploadLogo(uploadLogoImage.file)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(uploadLogoFailure());
        return;
      }
      values['logoImageName'] = response.data.imageName;
    }

    if (uploadFavouriteIconImage) {
      const response: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
        uploadFavouriteIcon(uploadFavouriteIconImage.file)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(uploadFavouriteIconFailure());
        return;
      }
      values['favouriteIconImageName'] = response.data.imageName;
    }

    if (values) {
      const response: IAPIEntityResponse<IUpdateSystemSettingsResponse> = await asyncErrorHandler(
        createOrUpdate(values)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(updateSettingsFailure());
        return;
      }
      LocalStorage.setLogoImageURL(response.data.logoImageURLSmall);
      LocalStorage.setFavouriteIconImageURLKey(response.data.favouriteIconImageURLSmall);
      dispatch(updateSettingsSuccess(response));
    }
    showSuccessToast(`Application Settings Updated`);
    dispatch(fetchSystemWarningsAction());
  } catch (error: any) {
    dispatch(updateSettingsFailure());
    handleError({ message: error.message });
  }
});

/**
 * Test SMTP Action.
 * @param payload
 */
export const testSmtpAction = createAsyncThunk(
  'setting/testSmtp',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { testSmtpRequest, testSmtpSuccess, testSmtpFailure } = settingActions;

    try {
      dispatch(testSmtpRequest());
      const response: IAPIEntityResponse<void> = await asyncErrorHandler(testSmtp(payload));
      if (response.error) {
        handleError({ message: response.error as unknown as string });
        dispatch(testSmtpFailure());
      } else {
        dispatch(testSmtpSuccess());
        dispatch(fetchSystemWarningsAction());
        showSuccessToast(response.message);
      }
    } catch (error: any) {
      dispatch(testSmtpFailure());
      handleError({ message: error.message });
    }
  }
);

export const getLogoAction = createAsyncThunk('setting/logo', async (payload: void, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const getLogoActionSuccess: any = settingActions.getLogoActionSuccess;
  const getLogoActionRequest: any = settingActions.getLogoActionRequest;
  const getLogoActionFailure: any = settingActions.getLogoActionFailure;

  try {
    dispatch(getLogoActionRequest());
    const response: IAPIEntityResponse<ILogoResponse> = await asyncErrorHandler(getLogo());
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getLogoActionFailure());
    } else {
      LocalStorage.setLogoImageURL(response.data && response.data.logoImageURLSmall);
      dispatch(getLogoActionSuccess(response));
    }
  } catch (error: any) {
    dispatch(getLogoActionFailure());
    handleError({ message: error.message });
  }
});

export const getFavouriteIconAction = createAsyncThunk(
  'setting/favicon',
  async (payload: void, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const {
      getFavouriteIconActionSuccess,
      getFavouriteIconActionRequest,
      getFavouriteIconActionFailure
    } = settingActions;

    try {
      dispatch(getFavouriteIconActionRequest());
      const response: IAPIEntityResponse<IFavouriteIconResponse> = await asyncErrorHandler(
        getFavouriteIcon()
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getFavouriteIconActionFailure());
      }
      LocalStorage.setFavouriteIconImageURLKey(response.data.favouriteIconImageURL);
      dispatch(getFavouriteIconActionSuccess(response));
    } catch (error: any) {
      dispatch(getFavouriteIconActionFailure());
      handleError({ message: error.message });
    }
  }
);

export const processNewPurchases = createAsyncThunk(
  'setting/transactions',
  async (payload: void, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const {
      processUnmarkedTransactionsSuccess,
      processUnmarkedTransactionsRequest,
      processUnmarkedTransactionsFailure
    } = settingActions;

    try {
      dispatch(processUnmarkedTransactionsRequest());
      const response: IAPIEntityResponse<void> = await asyncErrorHandler(
        processUnmarkedTransactions()
      );

      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(processUnmarkedTransactionsFailure());
      } else {
        dispatch(processUnmarkedTransactionsSuccess());
        showSuccessToast(`Transactions processed succesfully`);
      }
    } catch (error: any) {
      dispatch(processUnmarkedTransactionsFailure());
      handleError({ message: error.message });
    }
  }
);

export const getUsersFromUserAPIAction = createAsyncThunk(
  'users/get/paginated',
  async (payload: void, thunkAPI) => {
    const dispatch = thunkAPI.dispatch;
    const {
      processGetUsersFromUserAPISuccess,
      processGetUsersFromUserAPIRequest,
      processGetUsersFromUserAPIFailure
    } = settingActions;

    try {
      dispatch(processGetUsersFromUserAPIRequest());
      const response: IAPIEntityResponse<string> = await asyncErrorHandler(getUsersFromUserAPI());

      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(processGetUsersFromUserAPIFailure());
      } else {
        dispatch(processGetUsersFromUserAPISuccess());
        showSuccessToast(response.data);
      }
    } catch (error: any) {
      dispatch(processGetUsersFromUserAPIFailure());
      handleError({
        message:
          'An error occurred while trying to get users from the User API. Please check logs for more details.'
      });
    }
  }
);

/**
 * Get user's applications action.
 * @param payload
 */
export const getUserApplicationsAction = createAsyncThunk(
  'user/get/applications',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const getUserApplicationsRequest: any = settingActions.getUserApplicationsRequest;
    const getUserApplicationsSuccess: any = settingActions.getUserApplicationsSuccess;
    const getUserApplicationsFailure: any = settingActions.getUserApplicationsFailure;

    try {
      dispatch(getUserApplicationsRequest());
      const response: IAPIEntityResponse<IApplications[]> = await asyncErrorHandler(
        fetchUserApplications()
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getUserApplicationsFailure());
      } else {
        dispatch(getUserApplicationsSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getUserApplicationsFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Get Application details Action
 * @param payload
 */
export const getApplicationDetailsAction = createAsyncThunk(
  'user/get/applications',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const getApplicationDetailsRequest: any = settingActions.getApplicationDetailsRequest;
    const getApplicationDetailsSuccess: any = settingActions.getApplicationDetailsSuccess;
    const getApplicationDetailsFailure: any = settingActions.getApplicationDetailsFailure;

    try {
      dispatch(getApplicationDetailsRequest());
      const response: IAPIEntityResponse<IApplications> = await asyncErrorHandler(
        readApplicationDetails()
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getApplicationDetailsFailure());
      } else {
        dispatch(getApplicationDetailsSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getApplicationDetailsFailure());
      handleError({ message: error.message });
    }
  }
);
