/**
* UserList.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file UserList.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useState } from 'react';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { translate } from '../../../Utils/Translate';
import './UserList.css';
import { Chip } from 'primereact/chip';
import {
  getInitials,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { Row } from 'react-bootstrap';
import { Card } from 'primereact/card';

/**
 * @interface IUserListProperties
 */
interface IUserListProperties {
  participants: IUser[] /**< Array of user */;
  handleDelete: (payload: string) => void;
  auth: IUser /** authenticated user information */;
  user: IUser /**< user who created the report. */;
  isSupportRole: boolean /** True if use have support role and false otherwise.*/;
}

/**
 * Report participants component.
 */
const UserList = (properties: IUserListProperties): JSX.Element => {
  const [isShowConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [userEmail, setUserEmail] = useState<string>(null);

  /**
   * handleDeleteDialog
   * @param event
   * @param email
   */
  const handleDeleteDialog = (event: any, email: string): void => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event?.target);
    setUserEmail(email);
  };

  const onAccept = () => {
    properties.handleDelete(userEmail);
    setShowConfirmation(false);
  };

  const onReject = (): void => {
    setShowConfirmation(false);
  };

  /// Custom Chip component
  const customChip = (participant: IUser) => {
    let firstNameInitial: string = ''; /**< firstNameInitial */
    let lastNameInitial: string = ''; /**< lastNameInitial */
    let userInitials: string = ''; /**< userInitials */

    /// UserInitials
    if (isStringEmptyOrNullOrUndefined(participant.imageUrl)) {
      if (participant.username) {
        if (!isStringEmptyOrNullOrUndefined(participant.firstName)) {
          firstNameInitial = participant.firstName.charAt(0);
        }
        if (!isStringEmptyOrNullOrUndefined(participant.lastName)) {
          lastNameInitial = participant.lastName.charAt(0);
        }
        userInitials = getInitials(`${firstNameInitial} ${lastNameInitial}`);
      } else if (participant.email) {
        userInitials = participant.email.substring(0, 2).toUpperCase();
      } else {
        userInitials = getInitials('Deleted User');
      }
    }

    return (
      <>
        {!isStringEmptyOrNullOrUndefined(participant.imageUrl) ? (
          <img src={participant.imageUrl} alt="" />
        ) : (
          <div className="initial" data-initials={userInitials}></div>
        )}
        <span className="p-chip-text">{`${participant.firstName || ''} ${
          participant.lastName || ''
        } (${participant.email || ''})`}</span>
        {properties.isSupportRole || properties.auth.isAdmin ? (
          <i
            className={`pi pi-times-circle clickable ${
              participant.userUUID &&
              (participant.userUUID === properties.auth?.userUUID ||
                participant.userUUID === properties.user?.userUUID)
                ? 'custom-disabled-icon disabled-remove-participant'
                : 'text-danger'
            }`}
            aria-hidden="true"
            onClick={(event: any) => handleDeleteDialog(event, participant.email)}></i>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <>
      <Row className="active-participants">
        <label htmlFor="activeParticipants">
          {translate('client.page.reports.table.activeParticipants')}
        </label>
      </Row>
      <Row className="pb-3 active-participants">
        <Card className="w-100">
          <div className="user-list-container">
            {properties.participants &&
              properties.participants
                .filter((participant: IUser) => participant.email !== null)
                .map((participant: IUser, index: number) => (
                  <Chip key={index} template={customChip(participant)} />
                ))}
          </div>
          <ConfirmationPopup
            target={confirmPopupTarget}
            isShow={isShowConfirmation}
            title={translate('/confirm_messages.delete_email', { email: userEmail })}
            onAccept={onAccept}
            onReject={onReject}
            acceptBtnClass="danger"
            rejectBtnClass="secondary"
            rejectLabel={translate('/confirm_messages.no')}
            acceptLabel={translate('/confirm_messages.yes')}
            acceptBtnIcon="bi bi-check2-circle"
            rejectBtnIcon="bi bi-x-circle"
          />
        </Card>
      </Row>
    </>
  );
};

export default UserList;
