/**
 * Report.ts (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file Report.ts
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IImageUploadResponse } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import {
  IReport,
  IComment,
  IReportFile
} from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import { paginationRequest } from '@abstract/abstractwebcommon-client/Pagination/paginationRequest';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Upload file
 * @param file Payload required for the API request
 * @returns JSON Object of the API response
 */
export const uploadFileAPI = async (
  file: File,
  reportUUID?: string
): Promise<IAPIEntityResponse<IImageUploadResponse>> => {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  // We use report/${reportUUID}/upload/file when the report is created and users are adding comments.
  // We use /report/upload/file when creating a report. To bound the files to the created request, the code is first uploading the file, and its return response is used in the create request/endpoint.
  return httpClient.imageUpload<IImageUploadResponse>(
    `${reportUUID ? `/report/${reportUUID}/upload/file` : '/report/upload/file'}`,
    formData
  );
};

/**
 * Create a report
 * @param report Payload required for the API request
 * @returns JSON Object of the API response
 */
export const createReportAPI = async (report: IReport): Promise<IAPIEntityResponse<IReport>> => {
  return httpClient.post<IReport>('/report/create', report);
};

/**
 * get reports of a user.
 * @returns Promise<HttpResponse<IReport[]>>
 */
export const getUserReport = (
  payload: Record<string, any>
): Promise<IAPIEntityResponse<IReport[]>> => {
  const url: string = paginationRequest(`/reports/find`, payload);
  return httpClient.get<IReport[]>(url);
};

/**
 * get all reports.
 * @returns Promise<HttpResponse<IReport[]>>
 */
export const getAllReport = (
  payload: Record<string, any>
): Promise<IAPIEntityResponse<IReport[]>> => {
  const url: string = paginationRequest(`/reports/all`, payload);
  return httpClient.get<IReport[]>(url);
};

/**
 * get report by id.
 * @param reportUUID report id.
 * @returns Promise<HttpResponse<IReport>>
 */
export const getReportByID = (reportUUID: string): Promise<IAPIEntityResponse<IReport>> => {
  return httpClient.get<IReport>(`/report/${reportUUID}`);
};

/**
 * Add comment
 * @param comment Payload required for the API request
 * @returns JSON Object of the API response
 */
export const addCommentAPI = async (comment: IComment): Promise<IAPIEntityResponse<IComment>> => {
  return httpClient.post<IComment>('/report/comment/add', comment);
};

/**
 * Change report status.
 * @param payload IReport.
 * @returns Promise
 */
export const changeStatusAPI = (payload: IReport): Promise<IAPIEntityResponse<IReport>> => {
  const reportUUID = payload.reportUUID;
  delete payload.reportUUID;
  return httpClient.put<IReport>(`/report/${reportUUID}/status`, payload);
};

/**
 * Update report.
 * @param payload IReport.
 * @returns Promise
 */
export const addEmailOnReportAPI = (payload: IReport): Promise<IAPIEntityResponse<IReport>> => {
  const reportUUID = payload.reportUUID;
  delete payload.reportUUID;
  return httpClient.put<IReport>(`/report/${reportUUID}/addEmail`, payload);
};

/**
 * Update report.
 * @param payload IReport.
 * @returns Promise
 */
export const updateReportAPI = (payload: IReport): Promise<IAPIEntityResponse<IReport>> => {
  const reportUUID = payload.reportUUID;
  delete payload.reportUUID;
  return httpClient.put<IReport>(`/report/${reportUUID}/update`, payload);
};

/**
 * Update report.
 * @param payload IReport.
 * @returns Promise
 */
export const deleteEmailOnReportAPI = (payload: IReport): Promise<IAPIEntityResponse<IReport>> => {
  const reportUUID = payload.reportUUID;
  delete payload.reportUUID;
  return httpClient.put<IReport>(`/report/${reportUUID}/deleteEmail`, payload);
};

/**
 * Download file.
 * @param fileUUID.
 * @returns Promise
 */
export const downloadFile = (
  fileUUID: string,
  reportUUID: string
): Promise<IAPIEntityResponse<{ signedDownloadURL: string }>> => {
  return httpClient.get(`/report/${reportUUID}/download/file/${fileUUID}`);
};

/**
 * Update report.
 * @param payload IReport.
 * @returns Promise
 */
export const deleteFileOnReportAPI = (
  payload: IReportFile
): Promise<IAPIEntityResponse<IReport>> => {
  return httpClient.put<IReport>(`/report/delete/file`, payload);
};

/**
 * Service to remove a report(s)
 * @param payload reportUUIDs
 * @returns Promise
 */
export const deleteReportAPI = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post('/reports/delete', { reportUUIDs: payload });
};
