/*
 * Newsletter.ts (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file Newsletter.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ICustomLicenseList,
  INewsletter,
  INewsletterAnalytics,
  INewsletterBlacklist,
  INewsletterCustomList
} from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import {
  IReducerAction,
  PaginationResponseAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {
  IAPIEntityResponse,
  IAPIErrorData
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { NewsletterAPI } from '../Services/Newsletter';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import {
  IPaginationResponse,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { formatTableSortOrder } from '../Utils/Formatter';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const NEWSLETTER_FEATURE_KEY = 'newsletter';

/**
 * Interface newsletter State
 */
interface INewsletterState {
  newsletterList: IPaginationResponse<INewsletter> | null /** Newsletters. */;
  newsletter: INewsletter | null /** Newsletter. */;
  isLoading: boolean /** True if loading and false otherwise. */;
  isNewsletterChanging: boolean /** True if loading and false otherwise. */;
  isNewsletterCreatedSuccess: boolean /** True if created is successful and false otherwise. */;
  isNewsletterUpdatedSuccess: boolean /** True if updated is successful and false otherwise. */;
  isNewsletterDeletedSuccess: boolean /** True if deleted is successful and false otherwise. */;
  customLicensesFilter: ICustomLicenseList[] | null /**< Custom licenses filter */;
  isNewsletterSending: boolean /**<  True if sending and false otherwise. */;
  newsletterCustomList: IPaginationResponse<INewsletterCustomList> | null /** Newsletter custom list. */;
  isNewsletterCustomListLoading: boolean /** True if loading and false otherwise. */;
  isNewsletterCustomListChanging: boolean /** True if changing and false otherwise. */;
  isNewsletterCustomListCreatedSuccess: boolean /** True if created is successful and false otherwise. */;
  isNewsletterCustomListUpdatedSuccess: boolean /** True if updated is successful and false otherwise. */;
  isNewsletterCustomListDeletedSuccess: boolean /** True if deleted is successful and false otherwise. */;
  linkedNewsletters: string[] /**< Linked Newsletters */;
  isNewsletterAnalyticsLoading: boolean /** True if loading and false otherwise. */;
  newsletterAnalytics: IPaginationResponse<INewsletterAnalytics> | null /** Newsletter analytics. */;
  isUnsubscribed: boolean /**< True if unsubscribed and false otherwise. */;
  unsubscribedMessage: string /**< Unsubscribed message */;
  unsubscribeNewsletterError: string /**< Unsubscribe error */;
  isNewsletterBlacklistLoading: boolean /** True if loading and false otherwise. */;
  newsletterBlacklist: IPaginationResponse<INewsletterBlacklist> | null /** Newsletter blacklist. */;
}

/**
 * Newsletter Intial state
 */
const INITIAL_STATE: INewsletterState = {
  newsletterList: null,
  newsletter: null,
  isLoading: false,
  isNewsletterChanging: false,
  isNewsletterCreatedSuccess: false,
  isNewsletterUpdatedSuccess: false,
  isNewsletterDeletedSuccess: false,
  customLicensesFilter: null,
  isNewsletterSending: false,
  newsletterCustomList: null,
  isNewsletterCustomListLoading: false,
  isNewsletterCustomListChanging: false,
  isNewsletterCustomListCreatedSuccess: false,
  isNewsletterCustomListUpdatedSuccess: false,
  isNewsletterCustomListDeletedSuccess: false,
  linkedNewsletters: [],
  isNewsletterAnalyticsLoading: false,
  newsletterAnalytics: null,
  isUnsubscribed: false,
  unsubscribeNewsletterError: null,
  isNewsletterBlacklistLoading: false,
  newsletterBlacklist: null,
  unsubscribedMessage: null
};

export const newsletterSlice = createSlice({
  name: NEWSLETTER_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    addNewsletterRequest(state: INewsletterState) {
      state.isNewsletterChanging = true;
      state.isNewsletterCreatedSuccess = false;
    },
    addNewsletterSuccess(state: INewsletterState) {
      state.isNewsletterChanging = false;
      state.isNewsletterCreatedSuccess = true;
    },
    addNewsletterFailure(state: INewsletterState) {
      state.isNewsletterChanging = false;
    },
    getNewletterRequest(state: INewsletterState) {
      state.isLoading = true;
      state.newsletter = null;
    },
    getNewsletterSuccess(state: INewsletterState, action: PaginationResponseAction<INewsletter>) {
      state.isLoading = false;
      state.newsletter = action.payload as INewsletter;
    },
    getNewslettersSuccess(state: INewsletterState, action: PaginationResponseAction<INewsletter>) {
      state.isLoading = false;
      state.newsletterList = action.payload as IPaginationResponse<INewsletter>;
      state.isNewsletterCreatedSuccess = false;
      state.isNewsletterUpdatedSuccess = false;
      state.isNewsletterDeletedSuccess = false;
    },
    getNewsletterFailure(state: INewsletterState) {
      state.isLoading = false;
    },
    updateNewsletterRequest(state: INewsletterState) {
      state.isNewsletterChanging = true;
      state.isNewsletterUpdatedSuccess = false;
    },
    updateNewsletterSuccess(state: INewsletterState) {
      state.isNewsletterChanging = false;
      state.isNewsletterUpdatedSuccess = true;
    },
    updateNewsletterFailure(state: INewsletterState) {
      state.isNewsletterChanging = false;
    },
    deleteNewsletterRequest(state: INewsletterState) {
      state.isNewsletterChanging = true;
      state.isNewsletterDeletedSuccess = false;
    },
    deleteNewsletterSuccess(state: INewsletterState) {
      state.isNewsletterChanging = false;
      state.isNewsletterDeletedSuccess = true;
    },
    deleteNewsletterFailure(state: INewsletterState) {
      state.isNewsletterChanging = false;
    },
    sendNewsletterRequest(state: INewsletterState) {
      state.isNewsletterSending = true;
    },
    sendNewsletterSuccess(state: INewsletterState) {
      state.isNewsletterSending = false;
    },
    sendNewsletterFailure(state: INewsletterState) {
      state.isNewsletterSending = false;
    },
    getCustomLicensesFilterSuccess(
      state: INewsletterState,
      action: IReducerAction<ICustomLicenseList[]>
    ) {
      state.customLicensesFilter = action.payload;
    },
    addNewsletterCustomListRequest(state: INewsletterState) {
      state.isNewsletterCustomListChanging = true;
      state.isNewsletterCustomListCreatedSuccess = false;
    },
    addNewsletterCustomListSuccess(state: INewsletterState) {
      state.isNewsletterCustomListChanging = false;
      state.isNewsletterCustomListCreatedSuccess = true;
    },
    addNewsletterCustomListFailure(state: INewsletterState) {
      state.isNewsletterCustomListChanging = false;
    },
    getNewletterCustomListsRequest(state: INewsletterState) {
      state.isNewsletterCustomListLoading = true;
    },
    getNewsletterCustomListsSuccess(
      state: INewsletterState,
      action: PaginationResponseAction<INewsletterCustomList>
    ) {
      state.isNewsletterCustomListLoading = false;
      state.newsletterCustomList = action.payload as IPaginationResponse<INewsletterCustomList>;
      state.isNewsletterCustomListCreatedSuccess = false;
      state.isNewsletterCustomListUpdatedSuccess = false;
      state.isNewsletterCustomListDeletedSuccess = false;
    },
    getNewletterCustomListsFailure(state: INewsletterState) {
      state.isNewsletterCustomListLoading = false;
    },
    updateNewsletterCustomListRequest(state: INewsletterState) {
      state.isNewsletterCustomListChanging = true;
      state.isNewsletterCustomListUpdatedSuccess = false;
    },
    updateNewsletterCustomListSuccess(state: INewsletterState) {
      state.isNewsletterCustomListChanging = false;
      state.isNewsletterCustomListUpdatedSuccess = true;
    },
    updateNewsletterCustomListFailure(state: INewsletterState) {
      state.isNewsletterCustomListChanging = false;
    },
    deleteNewsletterCustomListRequest(state: INewsletterState) {
      state.isNewsletterCustomListChanging = true;
      state.isNewsletterCustomListDeletedSuccess = false;
    },
    deleteNewsletterCustomListSuccess(state: INewsletterState) {
      state.isNewsletterCustomListChanging = false;
      state.isNewsletterCustomListDeletedSuccess = true;
    },
    deleteNewsletterCustomListFailure(state: INewsletterState) {
      state.isNewsletterCustomListChanging = false;
    },
    getNewslettersByCustomlistIDRequest(state: INewsletterState) {
      state.linkedNewsletters = [];
    },
    getNewslettersByCustomlistIDSuccess(state: INewsletterState, action: IReducerAction<string[]>) {
      state.linkedNewsletters = action.payload;
    },
    getNewletterAnalyticsRequest(state: INewsletterState) {
      state.isNewsletterAnalyticsLoading = true;
      state.newsletterAnalytics = null;
    },
    getNewsletterAnalyticsSuccess(
      state: INewsletterState,
      action: PaginationResponseAction<INewsletterCustomList>
    ) {
      state.isNewsletterAnalyticsLoading = false;
      state.newsletterAnalytics = action.payload as IPaginationResponse<INewsletterAnalytics>;
    },
    getNewletterAnalyticsFailure(state: INewsletterState) {
      state.isNewsletterAnalyticsLoading = false;
    },
    unsubscribeNewsletterRequest(state: INewsletterState) {
      state.isLoading = true;
      state.isUnsubscribed = false;
      state.unsubscribedMessage = null;
      state.unsubscribeNewsletterError = null;
    },
    unsubscribeNewsletterSuccess(state: INewsletterState, action: IReducerAction<any>) {
      state.isLoading = false;
      state.isUnsubscribed = true;
      state.unsubscribedMessage = action.payload.message;
      state.unsubscribeNewsletterError = null;
    },
    unsubscribeNewsletterFailure(state: INewsletterState, action: IReducerAction<IAPIErrorData>) {
      state.isLoading = false;
      state.isUnsubscribed = false;
      state.unsubscribedMessage = null;
      state.unsubscribeNewsletterError = action.payload.message;
    },
    getNewletterBlacklistRequest(state: INewsletterState) {
      state.isNewsletterBlacklistLoading = true;
    },
    getNewletterBlacklistSuccess(
      state: INewsletterState,
      action: PaginationResponseAction<INewsletterBlacklist>
    ) {
      state.isNewsletterBlacklistLoading = false;
      state.newsletterBlacklist = action.payload as IPaginationResponse<INewsletterBlacklist>;
    },
    getNewletterBlacklistFailure(state: INewsletterState) {
      state.isNewsletterBlacklistLoading = false;
    }
  },
  extraReducers: {}
});

export const newsletterReducer = newsletterSlice.reducer;
export const newsletterActions = newsletterSlice.actions;

/**
 * Create newsletter.
 * @param payload
 */
export const createNewsletter = createAsyncThunk(
  'newsletter/create',
  async (payload: INewsletter, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addNewsletterRequest, addNewsletterFailure, addNewsletterSuccess } = newsletterActions;

    try {
      dispatch(addNewsletterRequest());
      const result: IAPIEntityResponse<INewsletter> = await asyncErrorHandler(
        NewsletterAPI.createOrUpdate(payload)
      );
      if (result.error) {
        dispatch(addNewsletterFailure());
        handleError({ message: result.error.message });
      } else {
        showSuccessToast(result.message);
        dispatch(addNewsletterSuccess());
      }
    } catch (error: any) {
      dispatch(addNewsletterFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Fetch all newsletters with pagniation Action.
 * @param payload
 */
export const fetchAllNewsletters = createAsyncThunk(
  'newsletter/all',
  async (payload: ITablePayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getNewletterRequest, getNewslettersSuccess, getNewsletterFailure } = newsletterActions;

    try {
      dispatch(getNewletterRequest());
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */
      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';

      const result: IAPIEntityResponse<INewsletter> = await asyncErrorHandler(
        NewsletterAPI.getAllNewsletter(
          payload.skip,
          payload.limit,
          sortField,
          formattedSortOrder,
          searchTerm
        )
      );
      if (result.error) {
        dispatch(getNewsletterFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(getNewslettersSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getNewsletterFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Get newsletter by id.
 */
export const fetchNewsletter = createAsyncThunk('newsletter/one', async (id: string, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getNewletterRequest, getNewsletterFailure, getNewsletterSuccess } = newsletterActions;

  try {
    dispatch(getNewletterRequest());
    const result: IAPIEntityResponse<INewsletter> = await asyncErrorHandler(
      NewsletterAPI.getNewsletterByID(id)
    );
    if (result.error) {
      dispatch(getNewsletterFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(getNewsletterSuccess(result.newsletter));
    }
  } catch (error: any) {
    dispatch(getNewsletterFailure());
    handleError({ message: error.message });
  }
});

/**
 * update newsletter.
 * @param payload INewsletter
 */
export const updateNewsletter = createAsyncThunk(
  'newsletter/newsletterUUID/update',
  async (payload: INewsletter, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { updateNewsletterRequest, updateNewsletterFailure, updateNewsletterSuccess } =
      newsletterActions;
    try {
      dispatch(updateNewsletterRequest());
      const result: IAPIEntityResponse<INewsletter> = await asyncErrorHandler(
        NewsletterAPI.createOrUpdate(payload)
      );
      if (result.error) {
        dispatch(updateNewsletterFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateNewsletterSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(updateNewsletterFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Delete newsletter(s) Action.
 * @param payload
 */
export const deleteNewsletter = createAsyncThunk(
  'newsletter/delete',
  async (payload: string[], thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { deleteNewsletterRequest, deleteNewsletterSuccess, deleteNewsletterFailure } =
      newsletterActions;

    try {
      dispatch(deleteNewsletterRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        NewsletterAPI.deleteNewsletter(payload)
      );
      if (result.error) {
        dispatch(deleteNewsletterFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deleteNewsletterSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(deleteNewsletterFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Copy newsletter(s) Action.
 * @param payload
 */
export const copyNewsletter = createAsyncThunk(
  'newsletter/copy',
  async (payload: string[], thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addNewsletterRequest, addNewsletterSuccess, addNewsletterFailure } = newsletterActions;

    try {
      dispatch(addNewsletterRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        NewsletterAPI.copyNewsletter(payload)
      );
      if (result.error) {
        dispatch(addNewsletterFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(addNewsletterSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(addNewsletterFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Fetch custom licenses filter.
 */
export const fetchCustomLicensesFilter = createAsyncThunk(
  'newsletter/customLicensesFilter',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getCustomLicensesFilterSuccess } = newsletterActions;

    try {
      const result: IAPIEntityResponse<ICustomLicenseList[]> = await asyncErrorHandler(
        NewsletterAPI.customLicensesFilter()
      );
      if (result.error) {
        handleError({ message: result.error.message });
      } else {
        dispatch(getCustomLicensesFilterSuccess(result.data));
      }
    } catch (error: any) {
      handleError({ message: error.message });
    }
  }
);

/**
 * Send newsletter to license users.
 */
export const sendNewsletter = createAsyncThunk(
  'newsletter/send',
  async (payload: string, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { sendNewsletterRequest, sendNewsletterSuccess, sendNewsletterFailure } =
      newsletterActions;

    try {
      dispatch(sendNewsletterRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        NewsletterAPI.sendNewsletter(payload)
      );
      if (result.error) {
        dispatch(sendNewsletterFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(sendNewsletterSuccess());
      }
    } catch (error: any) {
      dispatch(sendNewsletterFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Create newsletter custom list.
 * @param payload
 */
export const createNewsletterCustomList = createAsyncThunk(
  'newsletter/customList/create',
  async (payload: INewsletterCustomList, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      addNewsletterCustomListRequest,
      addNewsletterCustomListFailure,
      addNewsletterCustomListSuccess
    } = newsletterActions;

    try {
      dispatch(addNewsletterCustomListRequest());
      const result: IAPIEntityResponse<INewsletterCustomList> = await asyncErrorHandler(
        NewsletterAPI.createOrUpdateNewsletterCustomList(payload)
      );
      if (result.error) {
        dispatch(addNewsletterCustomListFailure());
        handleError({ message: result.error.message });
      } else {
        showSuccessToast(result.message);
        dispatch(addNewsletterCustomListSuccess());
      }
    } catch (error: any) {
      dispatch(addNewsletterCustomListFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Fetch all newsletter customLists with pagination Action.
 * @param payload
 */
export const fetchAllNewsletterCustomLists = createAsyncThunk(
  'newsletter/customList/all',
  async (payload: ITablePayload, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getNewletterCustomListsRequest,
      getNewsletterCustomListsSuccess,
      getNewletterCustomListsFailure
    } = newsletterActions;

    try {
      dispatch(getNewletterCustomListsRequest());
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */
      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';

      const result: IAPIEntityResponse<INewsletterCustomList> = await asyncErrorHandler(
        NewsletterAPI.getAllNewsletterCustomLists(
          payload.skip,
          payload.limit,
          sortField,
          formattedSortOrder,
          searchTerm
        )
      );
      if (result.error) {
        dispatch(getNewletterCustomListsFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(getNewsletterCustomListsSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getNewletterCustomListsFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * update newsletter custom list.
 * @param payload INewsletterCustomList
 */
export const updateNewsletterCustomList = createAsyncThunk(
  'newsletter/customList/customListUUID/update',
  async (payload: INewsletterCustomList, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      updateNewsletterCustomListRequest,
      updateNewsletterCustomListFailure,
      updateNewsletterCustomListSuccess
    } = newsletterActions;
    try {
      dispatch(updateNewsletterCustomListRequest());
      const result: IAPIEntityResponse<INewsletterCustomList> = await asyncErrorHandler(
        NewsletterAPI.createOrUpdateNewsletterCustomList(payload)
      );
      if (result.error) {
        dispatch(updateNewsletterCustomListFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateNewsletterCustomListSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(updateNewsletterCustomListFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Delete newsletter customList(s) Action.
 * @param payload
 */
export const deleteNewsletterCustomList = createAsyncThunk(
  'newsletter/customList/delete',
  async (payload: string[], thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      deleteNewsletterCustomListRequest,
      deleteNewsletterCustomListSuccess,
      deleteNewsletterCustomListFailure
    } = newsletterActions;

    try {
      dispatch(deleteNewsletterCustomListRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        NewsletterAPI.deleteNewsletterCustomList(payload)
      );
      if (result.error) {
        dispatch(deleteNewsletterCustomListFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deleteNewsletterCustomListSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(deleteNewsletterCustomListFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Copy newsletter custom list(s) Action.
 * @param payload
 */
export const copyNewsletterCustomList = createAsyncThunk(
  'newsletter/customList/copy',
  async (payload: string[], thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      addNewsletterCustomListRequest,
      addNewsletterCustomListSuccess,
      addNewsletterCustomListFailure
    } = newsletterActions;

    try {
      dispatch(addNewsletterCustomListRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        NewsletterAPI.copyNewsletterCustomList(payload)
      );
      if (result.error) {
        dispatch(addNewsletterCustomListFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(addNewsletterCustomListSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(addNewsletterCustomListFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Get newsletters by customlist ID(s) Action.
 * @param payload
 */
export const getNewslettersByCustomlistID = createAsyncThunk(
  'get/newsletters/customListID',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getNewslettersByCustomlistIDRequest, getNewslettersByCustomlistIDSuccess } =
      newsletterActions;

    try {
      dispatch(getNewslettersByCustomlistIDRequest());

      const newsletterPaginationPayload: ITablePayload = {
        limit: 0,
        skip: 0,
        sort: {
          sortField: 'created',
          sortOrder: 'DESC'
        },
        searchTerm: '',
        filter: {
          customLicenseList: payload.customListUUIDs
        }
      }; /**< Pagination payload */
      const result: IAPIEntityResponse<INewsletter> = await asyncErrorHandler(
        NewsletterAPI.getAllNewsletter(
          newsletterPaginationPayload.skip,
          newsletterPaginationPayload.limit,
          newsletterPaginationPayload.sort.sortField,
          newsletterPaginationPayload.sort.sortOrder,
          newsletterPaginationPayload.searchTerm,
          newsletterPaginationPayload.filter
        )
      );
      if (result.error) {
        handleError({ message: result.error.message || `Error in delete custom list(s)` });
      } else {
        const newsletters: IPaginationResponse<INewsletter> =
          result.data as IPaginationResponse<INewsletter>;
        const newslettersCount: number = newsletters.totalRecords;
        const linkedNewsletters: string[] =
          newsletters !== undefined &&
          newsletters !== null &&
          newsletters.records.map((eachNewsletter: INewsletter) => eachNewsletter.title);

        if (newslettersCount === 0) {
          await asyncErrorHandler(dispatch(deleteNewsletterCustomList(payload.customListUUIDs)));
          await asyncErrorHandler(dispatch(fetchAllNewsletterCustomLists(payload.tablePayload)));
          return;
        } else {
          dispatch(getNewslettersByCustomlistIDSuccess(linkedNewsletters));
          return;
        }
      }
    } catch (error: any) {
      handleError({ message: error.message });
    }
  }
);

/**
 * Send newsletter preview to loggedin user.
 */
export const sendNewsletterPreview = createAsyncThunk(
  'newsletter/preview/send',
  async (payload: string) => {
    try {
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        NewsletterAPI.sendNewsletterPreview(payload)
      );
      if (result.error) {
        handleError({ message: result.error.message });
      } else {
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      handleError({ message: error.message });
    }
  }
);

/**
 * Fetch newsletter analytics using ID with pagination Action.
 * @param payload
 */
export const fetchNewsletterAnalytics = createAsyncThunk(
  'newsletter/analytics',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getNewletterAnalyticsRequest,
      getNewsletterAnalyticsSuccess,
      getNewletterAnalyticsFailure
    } = newsletterActions;

    try {
      dispatch(getNewletterAnalyticsRequest());
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */
      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';
      const newsletterID: number = payload.newsletterID; /**< Newsletter ID. */

      const result: IAPIEntityResponse<INewsletterAnalytics> = await asyncErrorHandler(
        NewsletterAPI.getNewsletterAnalytics(
          newsletterID,
          payload.skip,
          payload.limit,
          sortField,
          formattedSortOrder,
          searchTerm
        )
      );
      if (result.error) {
        dispatch(getNewletterAnalyticsFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(getNewsletterAnalyticsSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getNewletterAnalyticsFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Unsubscribe newsletter.
 * @param payload
 */
export const unsubscribeNewsletter = createAsyncThunk(
  'newsletter/unsubscribe',
  async (payload: string, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      unsubscribeNewsletterRequest,
      unsubscribeNewsletterSuccess,
      unsubscribeNewsletterFailure
    } = newsletterActions;

    try {
      dispatch(unsubscribeNewsletterRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        NewsletterAPI.unsubscribeNewsletter(payload)
      );
      if (result.error) {
        dispatch(unsubscribeNewsletterFailure(result.error));
      } else {
        dispatch(unsubscribeNewsletterSuccess(result));
      }
    } catch (error: any) {
      dispatch(unsubscribeNewsletterFailure(error));
    }
  }
);

/**
 * Unsubscribe newsletter using an email.
 * @param payload
 */
export const unsubscribeNewsletterByEmail = createAsyncThunk(
  'newsletter/unsubscribe',
  async (payload: string, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      unsubscribeNewsletterRequest,
      unsubscribeNewsletterSuccess,
      unsubscribeNewsletterFailure
    } = newsletterActions;

    try {
      dispatch(unsubscribeNewsletterRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        NewsletterAPI.unsubscribeNewsletterByEmail(payload)
      );
      if (result.error) {
        dispatch(unsubscribeNewsletterFailure(result.error));
      } else {
        dispatch(unsubscribeNewsletterSuccess(result));
      }
    } catch (error: any) {
      dispatch(unsubscribeNewsletterFailure(error));
    }
  }
);

/**
 * Fetch newsletter blacklist with pagination Action.
 * @param payload
 */
export const fetchNewsletterBlacklist = createAsyncThunk(
  'newsletter/blacklist',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getNewletterBlacklistRequest,
      getNewletterBlacklistSuccess,
      getNewletterBlacklistFailure
    } = newsletterActions;

    try {
      dispatch(getNewletterBlacklistRequest());
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */
      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';

      const result: IAPIEntityResponse<INewsletterBlacklist> = await asyncErrorHandler(
        NewsletterAPI.getNewsletterBlacklist(
          payload.skip,
          payload.limit,
          sortField,
          formattedSortOrder,
          searchTerm
        )
      );
      if (result.error) {
        dispatch(getNewletterBlacklistFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(getNewletterBlacklistSuccess(result.data));
      }
    } catch (error: any) {
      dispatch(getNewletterBlacklistFailure());
      handleError({ message: error.message });
    }
  }
);
