/*
 * NewsletterBlacklistTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterBlacklistTable.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { INewsletterStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { Column } from 'primereact/column';
import { translate } from '../../../Utils/Translate';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { fetchNewsletterBlacklist } from '../../../Store/Newsletter';
import { INewsletterBlacklist } from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * NewsletterBlacklistTable component.
 */
const NewsletterBlacklistTable = (): JSX.Element => {
  const newsletterState: INewsletterStateSelector = useSelector(
    (state: IStateSelectors) => state.newsletter
  ); /**< Newsletter state */
  const dispatch: Dispatch<any> = useDispatch();
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'unsubscribeDate',
      sortOrder: 1
    },
    searchTerm: ''
  }); /**< Default Payload */

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(fetchNewsletterBlacklist(updatedPayload));
  };

  /// Handle page update event
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(fetchNewsletterBlacklist(updatedPayload));
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(fetchNewsletterBlacklist(updatedPayload));
  };

  useEffect(() => {
    dispatch(fetchNewsletterBlacklist(payload));
  }, []);

  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer"></div>
      <SearchBar onSearchTermChanged={(data: string) => handleFilterUpdate(data)} />
    </div>
  );

  return (
    <>
      <Row>
        <Col>
          <BaseDatatable
            value={
              newsletterState.newsletterBlacklist && newsletterState.newsletterBlacklist.records
            }
            totalRecords={
              newsletterState.newsletterBlacklist &&
              newsletterState.newsletterBlacklist.totalRecords
            }
            isLoading={!newsletterState.newsletterBlacklist}
            header={header}
            parentClass="newsletterBlacklistDataTable" /**< ClassName for div Component.*/
            first={payload.skip}
            emptyMessage={translate('I18N.admin.page.newsletterBlacklist.table.empty_msg')}
            rows={payload.limit}
            onPage={handlePageUpdate}
            sortField={payload.sort.sortField}
            onSort={handleSortUpdate}
            sortOrder={payload.sort.sortOrder}
            onFilter={handleFilterUpdate}
            bodyStyle={'text-center'}
            responsive>
            <Column selectionMode="multiple" className="d-none" />
            <Column
              sortable
              field="username"
              body={(rowData: INewsletterBlacklist) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletterBlacklist.table.username')}
                  data={rowData.username}
                />
              )}
              header={translate('I18N.admin.page.newsletterBlacklist.table.username')}
            />
            <Column
              sortable
              field="email"
              body={(rowData: INewsletterBlacklist) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletterBlacklist.table.email')}
                  data={rowData.email}
                />
              )}
              header={translate('I18N.admin.page.newsletterBlacklist.table.email')}
            />
            <Column
              sortable
              field="unsubscribeDate"
              body={(rowData: INewsletterBlacklist) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletterBlacklist.table.unsubscribeDate')}
                  data={formatDate(rowData.unsubscribeDate)}
                />
              )}
              header={translate('I18N.admin.page.newsletterBlacklist.table.unsubscribeDate')}
              className="createdDateCol"
            />
          </BaseDatatable>
        </Col>
      </Row>
    </>
  );
};

export default NewsletterBlacklistTable;
