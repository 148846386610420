/**
 * Setting.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Setting.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import Resizer from 'react-image-file-resizer';
import { diff } from 'deep-object-diff';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IGetSystemSettingsResponse,
  IUpdateSystemSettingsResponse
} from '@abstract/abstractwebcommon-shared/interfaces/license/api';
import {
  IFavouriteIconResponse,
  IImageUploadResponse,
  ILogoResponse,
  ISafeSettingsResponse,
  ISetting
} from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import { IPublicEnvironmentVariables } from '@abstract/abstractwebcommon-shared/interfaces/license/environmentVariables';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Update settings
 * @param setting Settings information with new data
 * @returns Promise
 */
export const createOrUpdate = async (
  setting: any
): Promise<IAPIEntityResponse<IUpdateSystemSettingsResponse>> => {
  return httpClient.post<IUpdateSystemSettingsResponse>('/system/settings/update', setting);
};

/**
 * Test smtp
 * @param setting Settings information with new data
 * @returns Promise
 */
export const testSmtp = async (setting: any): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post<void>('/system/setting/smtp/test', setting);
};

/**
 * Fetch settings
 * @returns Promise
 */
export const read = async (): Promise<IAPIEntityResponse<IGetSystemSettingsResponse>> => {
  return httpClient.get<IGetSystemSettingsResponse>('/system/settings');
};

/**
 * Fetch environment variables
 * @returns Promise
 */
export const getApplicationEnvironmentVariablesAPI = async (): Promise<
  IAPIEntityResponse<IPublicEnvironmentVariables>
> => {
  return httpClient.get<IPublicEnvironmentVariables>('/system/environment/information');
};

/**
 * Fetch settings
 * @returns Promise
 */
export const readSafe = async (): Promise<IAPIEntityResponse<ISafeSettingsResponse>> => {
  return httpClient.get<ISafeSettingsResponse>('/system/information');
};

/**
 * Fetch application
 * @returns Promise
 */
export const readApplicationDetails = async (): Promise<IAPIEntityResponse<IApplications>> => {
  return httpClient.get<IApplications>('/system/setting/application/details');
};

/**
 * Upload favouriteIcon Image
 * @param file  File to upload
 * @returns Promise
 */
export const uploadFavouriteIcon = (
  file: any
): Promise<IAPIEntityResponse<IImageUploadResponse>> => {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  formData.append('mode', 'favouriteIcon');
  return httpClient.imageUpload<IImageUploadResponse>('/system/setting/image/upload', formData);
};

/**
 * Upload logo Image
 * @param file  File to upload
 * @returns Promise
 */
export const uploadLogo = (file: any): Promise<IAPIEntityResponse<IImageUploadResponse>> => {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  formData.append('mode', 'logo');
  return httpClient.imageUpload<IImageUploadResponse>('/system/setting/image/upload', formData);
};

/**
 * Get logoImageURL
 * @returns Promise
 */
export const getLogo = async (): Promise<IAPIEntityResponse<ILogoResponse>> => {
  return httpClient.get<ILogoResponse>('/system/logo');
};

/**
 * Get favouriteIconImageURL
 * @returns Promise
 */
export const getFavouriteIcon = async (): Promise<IAPIEntityResponse<IFavouriteIconResponse>> => {
  return httpClient.get<ILogoResponse>('/system/favicon');
};

/**
 * Resize logic
 * @param image Image to resize
 * @param maxWidth Maximum width
 * @param maxHeight Maximum height
 * @returns Image
 */
export const resizerImage = (
  image: any,
  maxWidth: number,
  maxHeight: number
): Promise<string | Blob | ProgressEvent<FileReader>> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      image,
      maxWidth,
      maxHeight,
      'PNG',
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });

/**
 * Get resized favicon
 * @param faviconImage Favicon image to resize
 * @param size Size of the image
 * @returns Image
 */
export const getFaviconUrlAndResize = async (
  faviconImage: any,
  size: number
): Promise<string | Blob | ProgressEvent<FileReader> | undefined> => {
  if (!faviconImage) {
    return;
  }
  const newImage = await asyncErrorHandler(resizerImage(faviconImage, size, size));
  return newImage;
};

/**
 * Check if there's a difference in the two setting object
 * @param firstObject First object to compare
 * @param secondObject Second object to compare
 * @returns Promise
 */
export const getObjectDifferences = async (
  firstObject: object,
  secondObject: object
): Promise<object> => {
  const differences: object = await asyncErrorHandler(diff(firstObject, secondObject));
  return differences;
};

/**
 * Process unmarked purchases
 * @returns Promise
 */
export const processUnmarkedTransactions = async (): Promise<IAPIEntityResponse<void>> => {
  return httpClient.get<void>('/system/admin/transactions');
};

/**
 * Fetch logged in user's applications
 * @returns Promise
 */
export const fetchUserApplications = async (): Promise<IAPIEntityResponse<IApplications[]>> => {
  return httpClient.get<IApplications[]>(`/system/user/applications`);
};

/**
 * Get Configuration settings
 * @returns Promise
 */
export const getConfigurationSettings = (): Promise<IAPIEntityResponse<ISetting>> => {
  return httpClient.get<ISetting>('/system/settings/configuration');
};
