/*
 * NewsletterLicenseTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterLicenseTable.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { Col, Row } from 'react-bootstrap';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { ILicense } from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import { translate } from '../../../Utils/Translate';
import { Column } from 'primereact/column';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import { MultiSelect } from 'primereact/multiselect';
import { ICustomLicenseList } from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import UserDetailsPopup, { PopupMode } from '@abstract/abstractwebcommon-client/UserDetailsPopup';

/**
 * @interface ILicenseTableProperties
 */
interface ILicenseTableProperties {
  customLicenses: ILicense[] /**< Custom Licenses. */;
  setCustomLicenses: React.Dispatch<React.SetStateAction<ILicense[]>> /**< Set custom licenses. */;
  customLicensesFilter: ICustomLicenseList[] | null /**< Custom licenses filter */;
  licenses: Record<string, any> /**< License list */;
  userData: IUser[] /**< User date */;
  tablePayload: ITablePayload /**< Table Paylooad */;
  handleFilterUpdate: (event: string) => void /**< Handle filter event */;
  handleSortUpdate: (event: ISortEvent) => void /**< Handle sort event */;
  handlePageUpdate: (event: IPageEvent) => void /**< Handle page event */;
  handleLicenseFilterChange: (
    filterValue: Record<string, any>
  ) => void /**< Handle license filter change event */;
}

/**
 * LicenseTable component for Newsletter.
 */
const NewsletterLicenseTable = (properties: ILicenseTableProperties): JSX.Element => {
  const [selectedLicenses, setSelectedLicenses] =
    useState<ILicense[]>(null); /**< Selected Licenses. */
  const [selectedLicenseFilter, setSelectedLicenseFilter] = useState<ICustomLicenseList[]>(
    []
  ); /** Selected license column filter value of Mutilselect */

  /// Handles selection change event
  const onSelectionChange = (event: any) => {
    const rows: ILicense[] = event.value;
    if (Array.isArray(rows)) {
      const selectedRows: ILicense[] = rows.map((row: ILicense) => {
        return row;
      });
      setSelectedLicenses(selectedRows);
      properties.setCustomLicenses(selectedRows);
    }
  };

  /// Fetch all licenses
  useEffect(() => {
    if (properties.customLicenses && properties.customLicenses.length) {
      setSelectedLicenses(properties.customLicenses);
    }
  }, []);

  /// Custom licenses filter change handler.
  const onLicenseFilterChange = (event: any) => {
    const selectedCustomLicenses: ICustomLicenseList[] = event.value;
    setSelectedLicenseFilter(event.value);
    let licenseIDs: number[] = []; /**< License IDs */
    if (selectedCustomLicenses && selectedCustomLicenses.length) {
      selectedCustomLicenses.forEach((customLicense: ICustomLicenseList) => {
        if (customLicense.licenses && customLicense.licenses.length) {
          const customLicenseIDs: number[] = customLicense.licenses.map((license: ILicense) => {
            return license.id;
          });
          licenseIDs.push(...customLicenseIDs);
        }
      });
    }
    let filterValue: Record<string, any>;
    if (licenseIDs.length) {
      licenseIDs = [...Array.from(new Set(licenseIDs.flat()))]; // To remove duplicate ids.
      filterValue = {
        id: {
          value: licenseIDs
        }
      };
    } else {
      filterValue = {};
    }
    properties.handleLicenseFilterChange(filterValue);
  };

  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer"></div>
      <div className="headerTableContainer header-search-filter">
        <SearchBar onSearchTermChanged={(data: string) => properties.handleFilterUpdate(data)} />

        <MultiSelect
          optionLabel="type"
          name="license"
          inputId="licenseFilter"
          value={selectedLicenseFilter}
          options={properties.customLicensesFilter}
          onChange={onLicenseFilterChange}
          placeholder={translate('I18N.admin.page.newsletterCustomList.form.list_placeholder')}
          className="p-column-filter w-100"
          filter
          maxSelectedLabels={1}
          appendTo="self"
          id="custom-newsletter-multi-select"
        />
      </div>
    </div>
  );

  return (
    <Row>
      <Col xs={12}>
        <BaseDatatable
          value={properties.licenses?.records}
          parentClass="licenseDataTable" /**< ClassName for div Component.*/
          header={header}
          filters={properties.tablePayload.filter}
          onFilter={properties.handleFilterUpdate}
          sortField={properties.tablePayload.sort.sortField}
          onSort={properties.handleSortUpdate}
          sortOrder={properties.tablePayload.sort.sortOrder}
          first={properties.tablePayload.skip}
          rows={properties.tablePayload.limit}
          onPage={properties.handlePageUpdate}
          totalRecords={properties.licenses?.totalRecords}
          emptyMessage={translate('I18N.admin.license.empty_msg')}
          selection={selectedLicenses}
          dataKey="licenseUUID"
          onSelectionChange={(event: any) => onSelectionChange(event)}>
          <Column selectionMode="multiple" className="col-width-45 mt-1" />
          <Column
            field="userUUID"
            header={translate('admin.page.licenses.form.licenseOwner')}
            className="col-width-15"
            body={(rows: ILicense) => {
              return (
                <DatatableColumn
                  title={translate('admin.page.licenses.form.licenseOwner')}
                  data={
                    <UserDetailsPopup
                      users={rows.user != null ? [rows.user] : []}
                      userUUID={rows.user.userUUID}
                      key={rows.userUUID}
                      mode={PopupMode.Complete}
                    />
                  }
                />
              );
            }}
          />
          <Column
            sortable
            field="name"
            header={translate('admin.page.licenses.form.licenseEntitlement')}
            className="d-md-table-cell"
            headerClassName="d-md-table-cell"
            body={(rows: ILicense) => {
              const details = rows.entitlements.map((entitlement: IEntitlement, index: number) => {
                return (
                  <span key={index + 1}>
                    <span className="badge badge-secondary mr-2">{entitlement.name}</span>
                  </span>
                );
              });
              return (
                <DatatableColumn
                  title={translate('admin.page.licenses.form.licenseEntitlement')}
                  data={details}
                />
              );
            }}
          />
          <Column
            sortable
            field="active"
            className="d-table-cell d-sm-none d-xl-table-cell col-width-10"
            headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-10"
            header={translate('admin.page.licenses.form.active')}
            body={(rows: ILicense) => (
              <DatatableColumn
                title={translate('admin.page.licenses.form.active')}
                data={<ShowCheckOrUncheckIcon value={new Date(rows.licenseEndDate) > new Date()} />}
              />
            )}
          />
        </BaseDatatable>
      </Col>
    </Row>
  );
};

export default NewsletterLicenseTable;
