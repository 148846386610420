/**
* SLAPermissionPage.tsx (InstaLOD GmbH) *

* Copyright © 2024 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2024 
* @file SLAPermissionPage.tsx
* @author Alaguvelammal Alagusubbiah
* @copyright 2024 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { useEffect, useState } from 'react';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/Log';
import PermissionPage from '@abstract/abstractwebcommon-client/PermissionPage/PermissionPage';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import {
  IAuthStateSelector,
  ILicenseStateSelector,
  ISettingsStateSelector,
  IStateSelectors
} from '../../Interfaces/Selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { removeSLAEntitlementFromUserLicensesAction } from '../../Store/Licenses';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { RouteName } from '../../Utils/routesNames';
import { useHistory } from 'react-router-dom';
import { authActions } from '../../Store/Auth';
import { getApplicationDetailsAction } from '../../Store/Settings';
import Loader from '@abstract/abstractwebcommon-client/Loader';

const SLAPermissionPage = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const translation: TFunction = useTranslation().t;
  const history = useHistory();
  const [application, setApplication] = useState<IApplications>(); /**< Application details. */
  const [isSLAAccepted, setSLAAccepted] = useState<boolean>(false); /**< SLA Accepted or not */
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const authState: IAuthStateSelector = useSelector((state: IStateSelectors) => state.auth);
  const licenseState: ILicenseStateSelector = useSelector(
    (state: IStateSelectors) => state.licenses
  );

  // Get application details
  useEffect(() => {
    getApplicationDetails();
  }, []);

  /**
   * Handle remove eula entitlement error
   */
  useEffect(() => {
    if (licenseState.removeEntitlementStatusCode) {
      showToast({
        severity: 'error',
        summary: translation('I18N.error_messages.error'),
        detail: translation('I18N.error_messages.remove_eula_entitlement_failure')
      });
    }
  }, [licenseState.removeEntitlementStatusCode]);

  useEffect(() => {
    // Once the SLA is accepted by the user, remove the SLA Entitlement from the license and redirect to the client page
    if (licenseState.isSLAEntitlementRemoved) {
      dispatch(authActions.updateSLAEntitlementState());
      history.push(RouteName.clientHomeRoute);
    }
  }, [licenseState.isSLAEntitlementRemoved]);

  /**
   * Get application details
   */
  const getApplicationDetails = async () => {
    dispatch(getApplicationDetailsAction());
  };

  useEffect(() => {
    setApplication(settings.applicationDetails);
  }, [settings.applicationDetails]);

  /**
   * Accept SLA handler event
   */
  const handleAcceptSLA = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // When accepted and user access the app, should remove the ShowEULA entitlement from the license project.
    if (isSLAAccepted) {
      dispatch(removeSLAEntitlementFromUserLicensesAction(authState.userUUID));
    }
  };

  // NOTE: Validation to prevent page flick.
  if (application == null) {
    return <Loader />;
  }

  return (
    <PermissionPage
      logoURL={application?.logoImageURL}
      description={application?.description}
      applicationName={application?.applicationName}
      isLoading={licenseState.licenseIsChanging}
      isSLAPermissionPage={true}
      isSLAAccepted={isSLAAccepted}
      setSLAAccepted={setSLAAccepted}
      slaTermsText={settings?.safeSettings?.slaTermsText}
      handleAcceptSLA={handleAcceptSLA}
    />
  );
};

export default withErrorBoundary(SLAPermissionPage, createLogApi);
