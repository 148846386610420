/*
 * AdminPanel.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file AdminPanel.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdminContent from './AdminContent';
import MenuWrapper from '../Menu/MenuWrapper';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { Row } from 'react-bootstrap';
import {
  addClassNameToDropdownMenu,
  dropDownMenuClickHandler,
  IDropdownMenuReference,
  menuItemClick,
  removeClassNameFromDropdownMenu
} from '@abstract/abstractwebcommon-client/Sidebar/menuItemUtils';
import { validateTokenAPI } from '../../Services/Auth';
import { useDispatch, useSelector } from 'react-redux';
import FullPageLoader from '@abstract/abstractwebcommon-client/Loader/FullPageLoader';
import { getPublicTemplates } from '../../Store/Templates';
import { Dispatch } from 'redux';
import {
  IAuthStateSelector,
  IStateSelectors,
  ITemplateStateSelector
} from '../../Interfaces/Selectors';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import LicenseTotalCount from './MenuBadgeCount/LicenseTotalCount';
import ActivationTotalCount from './MenuBadgeCount/ActivationTotalCount';
import { createLogApi } from '../../Services/Log';
import { getLogoAction } from '../../Store/Settings';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import {
  SharedCommomRouteName,
  SharedMainRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { RouteName } from '../../Utils/routesNames';
import { IMenuItem } from '@abstract/abstractwebcommon-client/Sidebar/SidebarPage';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

interface IAdminPanelProperties {
  fnLogout: () => void;
  themeMode?: string /**< theme mode to use */;
  didChangeTheme?: (theme: string) => void /**< change theme function */;
  languageSettingsMode?: string /**< Language settings mode to use */;
  didChangeLanguage?: (language: string) => void /**< change language function */;
}

const AdminPanel: React.FC<any> = (properties: IAdminPanelProperties) => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();

  const authState: IAuthStateSelector = useSelector((state: IStateSelectors) => state.auth);

  const templates: ITemplateStateSelector = useSelector(
    (state: IStateSelectors) => state.templates
  );
  const [logoURL, setLogoURL] = useState<string>(''); /**< Logo URL. */
  const [isLogoLoaded, setLogoLoaded] = useState<boolean>(false); /**< Logo Loading state. */

  const menu: any = useRef(null);
  const editNewsletterID: string =
    localStorage.getItem('edit_newsletterID'); /**< Edit newsletter ID */
  const analyticsNewsletterID: string =
    localStorage.getItem('analytics_newsletterID'); /**< Analytics newsletter ID */
  const templateEditorMenuReference: IDropdownMenuReference =
    useRef(null); /**< Template Editor Menu Reference. */
  const activationsMenuReference: IDropdownMenuReference =
    useRef(null); /**< Activations Menu Reference. */
  const newsletterMenuReference: IDropdownMenuReference =
    useRef(null); /**< Newsletter Menu Reference. */
  const activationsPathNames: string[] = [
    RouteName.adminActivationsRoute,
    RouteName.adminDeactivationsRoute
  ]; /**< Activations PathNames. */
  const templatesPathNames: string[] = [
    RouteName.adminTemplateEditorRoute,
    RouteName.adminTemplateCSSEditorRoute
  ]; /**< Templates Editor PathNames. */
  const staticNewsletterPathNames: string[] = [
    RouteName.adminNewsletterRoute,
    RouteName.adminNewsletterCustomListRoute,
    RouteName.adminNewsletterAnalyticsRoute,
    `${RouteName.adminNewsletterRoute}/new`,
    RouteName.adminNewsletterBlackListRoute
  ]; /**< Static Newsletter PathNames. */
  const newsletterPathNames: string[] = [
    ...staticNewsletterPathNames
  ]; /**< Newsletter PathNames. */
  if (editNewsletterID) {
    newsletterPathNames.push(
      `${RouteName.adminNewsletterRoute}/${editNewsletterID}`
    ); /**< Add edit-newsletter pathname with ID to highlight the newsletter submenu */
  }
  if (analyticsNewsletterID) {
    newsletterPathNames.push(
      `${RouteName.adminNewsletterAnalyticsRoute}/${analyticsNewsletterID}`
    ); /**< Add newsletter-analytics pathname with ID to highlight the analytics submenu */
  }

  /// Get Activations Menu element by classname.
  const getActivationsParentMenu = (): HTMLCollection => {
    return document.getElementsByClassName('activations');
  };

  /// Get Templates Editor Menu element by classname.
  const getTemplatesEditorParentMenu = (): HTMLCollection => {
    return document.getElementsByClassName('template-editor');
  };

  /// Get Newsletter Menu element by classname.
  const getNewsletterParentMenu = (): HTMLCollection => {
    return document.getElementsByClassName('newsletter');
  };

  /// To remove classname from activations
  const removeClassNameFromActivations = () => {
    if (!activationsMenuReference.current) {
      activationsMenuReference.current = getActivationsParentMenu();
    }
    removeClassNameFromDropdownMenu(activationsMenuReference.current);
  };

  /// To remove classname from template editor
  const removeClassNameFromTemplateEditor = () => {
    if (!templateEditorMenuReference.current) {
      templateEditorMenuReference.current = getTemplatesEditorParentMenu();
    }
    removeClassNameFromDropdownMenu(templateEditorMenuReference.current);
  };

  /// To remove classname from newsletter
  const removeClassNameFromNewsletter = () => {
    if (!newsletterMenuReference.current) {
      newsletterMenuReference.current = getNewsletterParentMenu();
    }
    removeClassNameFromDropdownMenu(newsletterMenuReference.current);
    localStorage.removeItem('edit_newsletterID');
    localStorage.removeItem('analytics_newsletterID');
  };

  /// Remove 'panel-menu-activated-item' className from dropdown menu
  const removeDropdownMenusClassName = () => {
    removeClassNameFromActivations();
    removeClassNameFromTemplateEditor();
    removeClassNameFromNewsletter();
  };

  /// Activations menu Click event handler.
  const onActivationsMenuClick = (event: any) => {
    addClassNameToDropdownMenu(
      templateEditorMenuReference.current,
      templatesPathNames
    ); /**< If current path is in Templates Editor, add 'panel-menu-activated-item' className. */
    addClassNameToDropdownMenu(
      newsletterMenuReference.current,
      newsletterPathNames
    ); /**< If current path is in Newsletter, add 'panel-menu-activated-item' className. */
    const activationsMenu: HTMLCollection = getActivationsParentMenu(); /**< Activations menu */
    activationsMenuReference.current = activationsMenu;
    dropDownMenuClickHandler(event, activationsPathNames, activationsMenuReference.current);
  };

  /// Templates Editor menu Clcik event handler.
  const onTemplatesEditorMenuClick = (event: any) => {
    addClassNameToDropdownMenu(
      activationsMenuReference.current,
      activationsPathNames
    ); /**< If current path is in Activations, add 'panel-menu-activated-item' className. */
    addClassNameToDropdownMenu(
      newsletterMenuReference.current,
      newsletterPathNames
    ); /**< If current path is in Newsletter, add 'panel-menu-activated-item' className. */
    const templateEditorMenu: HTMLCollection =
      getTemplatesEditorParentMenu(); /**< Template editor menu */
    templateEditorMenuReference.current = templateEditorMenu;
    dropDownMenuClickHandler(event, templatesPathNames, templateEditorMenuReference.current);
  };

  /// Newsletter menu click event handler.
  const onNewsletterMenuClick = (event: any) => {
    addClassNameToDropdownMenu(
      templateEditorMenuReference.current,
      templatesPathNames
    ); /**< If current path is in Templates Editor, add 'panel-menu-activated-item' className. */
    addClassNameToDropdownMenu(
      activationsMenuReference.current,
      activationsPathNames
    ); /**< If current path is in Activations, add 'panel-menu-activated-item' className. */
    const NewsletterMenu: HTMLCollection = getNewsletterParentMenu(); /**< Newsletter menu */
    newsletterMenuReference.current = NewsletterMenu;
    const pathNames: string[] = [...staticNewsletterPathNames];
    if (localStorage.getItem('edit_newsletterID')) {
      pathNames.push(
        `${RouteName.adminNewsletterRoute}/${localStorage.getItem('edit_newsletterID')}`
      ); /**< Add edit-newsletter pathname with ID to highlight the newsletter submenu */
    }
    if (localStorage.getItem('analytics_newsletterID')) {
      pathNames.push(
        `${RouteName.adminNewsletterAnalyticsRoute}/${localStorage.getItem(
          'analytics_newsletterID'
        )}`
      ); /**< Add newsletter-analytics pathname with ID to highlight the analytics submenu */
    }
    dropDownMenuClickHandler(event, pathNames, newsletterMenuReference.current);
  };

  // Get admin menu items
  const getAdminMenuItems = (): Array<IMenuItem> => {
    const items: IMenuItem[] = [
      {
        id: 'dashboard',
        label: t('admin.menu.dashboard'),
        icon: 'far fa-home sidebar_icon',
        pathToHighlight: SharedMainRouteName.adminRoute,
        url: SharedMainRouteName.adminRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeDropdownMenusClassName();
          menuItemClick(menu, setMenuItems, history, SharedMainRouteName.adminRoute, 'dashboard');
        }
      },
      {
        id: 'activations',
        label: t('admin.menu.activation'),
        icon: 'far fa-sitemap sidebar_icon',
        menuClassName:
          activationsPathNames.indexOf(window.location.pathname) > -1
            ? 'activations panel-menu-activated-item'
            : 'activations',
        command: (event) => {
          onActivationsMenuClick(event);
        },
        items: [
          {
            id: 'viewActivations',
            url: RouteName.adminActivationsRoute,
            label: (
              <div className="count-items-container">
                {t('admin.menu.activation')}
                <ActivationTotalCount />
              </div>
            ),
            icon: 'far fa-list sidebar_icon',
            pathToHighlight: RouteName.adminActivationsRoute,
            command: (event: any) => {
              event.originalEvent.preventDefault();
              removeClassNameFromTemplateEditor();
              removeClassNameFromNewsletter();
              menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminActivationsRoute,
                'activations'
              );
            }
          },
          {
            id: 'bulkDeactivateActivations',
            label: t('admin.menu.bulkDeactivate'),
            icon: 'far fa-minus-circle sidebar_icon',
            url: RouteName.adminDeactivationsRoute,
            pathToHighlight: RouteName.adminDeactivationsRoute,
            command: (event: any) => {
              event.originalEvent.preventDefault();
              removeClassNameFromTemplateEditor();
              removeClassNameFromNewsletter();
              menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminDeactivationsRoute,
                'activations'
              );
            }
          }
        ]
      },
      {
        id: 'files',
        label: t('admin.menu.fileRecordManagement'),
        icon: 'fas fa-download sidebar_icon',
        url: RouteName.adminFilesRoute,
        pathToHighlight: RouteName.adminFilesRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeDropdownMenusClassName();
          menuItemClick(menu, setMenuItems, history, RouteName.adminFilesRoute, 'files');
        }
      },
      {
        id: 'entitlement',
        label: t('admin.menu.entitlementManagement'),
        icon: 'fas fa-file-certificate sidebar_icon',
        url: RouteName.adminEntitlementRoute,
        pathToHighlight: RouteName.adminEntitlementRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeDropdownMenusClassName();
          menuItemClick(
            menu,
            setMenuItems,
            history,
            RouteName.adminEntitlementRoute,
            'entitlement'
          );
        }
      },
      {
        id: 'licenses',
        label: (
          <div className="count-items-container">
            {t('admin.menu.licenseManagement')}
            <LicenseTotalCount />
          </div>
        ),
        icon: 'fas fa-id-badge sidebar_icon',
        url: RouteName.adminLicensesRoute,
        pathToHighlight: RouteName.adminLicensesRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeDropdownMenusClassName();
          menuItemClick(menu, setMenuItems, history, RouteName.adminLicensesRoute, 'licenses');
        }
      },
      {
        id: 'licenseTemplate',
        label: t('admin.menu.licenseTemplates'),
        icon: 'far fa-file sidebar_icon',
        url: RouteName.adminTemplateLicenseRoute,
        pathToHighlight: RouteName.adminTemplateLicenseRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeDropdownMenusClassName();
          menuItemClick(
            menu,
            setMenuItems,
            history,
            RouteName.adminTemplateLicenseRoute,
            'templates'
          );
        }
      },
      {
        id: 'newsletter',
        label: t('admin.menu.newsletter'),
        icon: 'far fa-inbox sidebar_icon',
        menuClassName:
          newsletterPathNames.indexOf(window.location.pathname) > -1
            ? 'newsletter panel-menu-activated-item'
            : 'newsletter',
        command: (event) => {
          onNewsletterMenuClick(event);
        },
        items: [
          {
            id: 'newsletterPage',
            label: t('admin.menu.newsLetterSubMenu.newsletter'),
            icon: 'far fa-inbox sidebar_icon',
            url: RouteName.adminNewsletterRoute,
            pathToHighlight: [
              RouteName.adminNewsletterRoute,
              `${RouteName.adminNewsletterRoute}/new`
            ],
            command: (event: any) => {
              event.originalEvent.preventDefault();
              removeClassNameFromActivations();
              removeClassNameFromTemplateEditor();
              menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminNewsletterRoute,
                'newsletter'
              );
            }
          },
          {
            id: 'customList',
            label: t('admin.menu.newsLetterSubMenu.customList'),
            icon: 'far fa-list sidebar_icon',
            url: RouteName.adminNewsletterCustomListRoute,
            pathToHighlight: RouteName.adminNewsletterCustomListRoute,
            command: (event: any) => {
              event.originalEvent.preventDefault();
              removeClassNameFromActivations();
              removeClassNameFromTemplateEditor();
              menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminNewsletterCustomListRoute,
                'newsletter'
              );
            }
          },
          {
            id: 'analytics',
            label: t('admin.menu.newsLetterSubMenu.analytics'),
            icon: 'far fa-file sidebar_icon',
            url: RouteName.adminNewsletterAnalyticsRoute,
            pathToHighlight: RouteName.adminNewsletterAnalyticsRoute,
            command: (event: any) => {
              event.originalEvent.preventDefault();
              removeClassNameFromActivations();
              removeClassNameFromTemplateEditor();
              menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminNewsletterAnalyticsRoute,
                'newsletter'
              );
            }
          },
          {
            id: 'blacklist',
            label: t('admin.menu.newsLetterSubMenu.blacklist'),
            icon: 'far fa-list sidebar_icon',
            url: RouteName.adminNewsletterBlackListRoute,
            pathToHighlight: RouteName.adminNewsletterBlackListRoute,
            command: (event: any) => {
              event.originalEvent.preventDefault();
              removeClassNameFromActivations();
              removeClassNameFromTemplateEditor();
              menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminNewsletterBlackListRoute,
                'newsletter'
              );
            }
          }
        ]
      },
      {
        id: 'reports',
        label: t('I18N.admin.menu.reports'),
        icon: 'far fa-flag sidebar_icon',
        url: RouteName.adminReportsRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeDropdownMenusClassName();
          menuItemClick(menu, setMenuItems, history, RouteName.adminReportsRoute, 'reports');
        },
        pathToHighlight: RouteName.adminReportsRoute
      },
      {
        id: 'templates',
        label: t('admin.menu.templates'),
        icon: 'fas fa-drafting-compass sidebar_icon',
        menuClassName:
          templatesPathNames.indexOf(window.location.pathname) > -1
            ? 'template-editor panel-menu-activated-item'
            : 'template-editor',
        command: (event) => {
          onTemplatesEditorMenuClick(event);
        },
        items: [
          {
            id: 'emailTemplates',
            label: t('admin.menu.templateSubMenu.templateEditor'),
            icon: 'fas fa-drafting-compass sidebar_icon',
            url: RouteName.adminTemplateEditorRoute,
            pathToHighlight: RouteName.adminTemplateEditorRoute,
            command: (event: any) => {
              event.originalEvent.preventDefault();
              removeClassNameFromActivations();
              removeClassNameFromNewsletter();
              menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminTemplateEditorRoute,
                'templates'
              );
            }
          },
          {
            id: 'cssEditor',
            label: t('admin.menu.templateSubMenu.cssEditor'),
            icon: 'fas fa-drafting-compass sidebar_icon',
            url: RouteName.adminTemplateCSSEditorRoute,
            pathToHighlight: RouteName.adminTemplateCSSEditorRoute,
            command: (event: any) => {
              event.originalEvent.preventDefault();
              removeClassNameFromActivations();
              removeClassNameFromNewsletter();
              menuItemClick(
                menu,
                setMenuItems,
                history,
                RouteName.adminTemplateCSSEditorRoute,
                'templates'
              );
            }
          }
        ]
      },
      {
        id: 'settings',
        label: t('admin.menu.settings'),
        icon: 'far fa-cog sidebar_icon',
        url: RouteName.adminSettingsRoute,
        pathToHighlight: RouteName.adminSettingsRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeDropdownMenusClassName();
          menuItemClick(menu, setMenuItems, history, RouteName.adminSettingsRoute, 'settings');
        }
      },
      {
        id: 'logs',
        label: t('admin.menu.logs'),
        icon: 'far fa-calendar sidebar_icon',
        url: RouteName.adminLogsRoute,
        pathToHighlight: RouteName.adminLogsRoute,
        command: (event: any) => {
          event.originalEvent.preventDefault();
          removeDropdownMenusClassName();
          menuItemClick(menu, setMenuItems, history, RouteName.adminLogsRoute, 'logs');
        }
      }
    ];

    return items;
  };

  const [menuItems, setMenuItems] = useState<any[]>(getAdminMenuItems());

  const [userIsAuthenticated, setUserIsAuthenticated] = useState(
    sessionStorage.getItem('initialLoginTime') ? true : false
  );
  const [fullLoaderTemplate, setFullLoaderTemplate] = useState<any>(null);
  const logoutHandler = (): void => {
    properties.fnLogout();
  };

  useEffect(() => {
    dispatch(getPublicTemplates());
    if (!sessionStorage.getItem('initialLoginTime')) {
      const timeoutID: any = setTimeout(async () => {
        const response: any = await asyncErrorHandler(validateTokenAPI());
        if (authState.isAuthenticated && response && response.status === 200) {
          setUserIsAuthenticated(true);
        } else {
          logoutHandler();
          setUserIsAuthenticated(false);
          window.location.href = `${SharedCommomRouteName.validateRoute}?autoLogoutReason=403`;
        }
      }, 700);
      sessionStorage.setItem('initialLoginTime', `${new Date()}`);
      return () => clearTimeout(timeoutID);
    }
  }, []);

  /// Get Logo URL.
  useEffect(() => {
    const getLogoURL = async () => {
      if (LocalStorage.getLogoImageURL() === null) {
        await asyncErrorHandler(dispatch(getLogoAction()));
        setLogoLoaded(true);
      } else {
        setLogoURL(LocalStorage.getLogoImageURL() || '');
        setLogoLoaded(true);
      }
    };
    if (authState.isAuthenticated) {
      getLogoURL();
    } else {
      setLogoLoaded(true);
    }
  }, [LocalStorage.getLogoImageURL()]);

  const getPageContent = () => {
    return (
      <>
        <MenuWrapper
          menuItems={menuItems}
          menu={menu}
          fnLogout={properties.fnLogout}
          setMenuItems={setMenuItems}
          history={history}
          isAdmin={true}
          didChangeTheme={properties.didChangeTheme}
          themeMode={properties.themeMode}
          logoURL={logoURL}
          languageSettingsMode={properties.languageSettingsMode}
          didChangeLanguage={properties.didChangeLanguage}
        />
        <Col className="main-body" id="main-body">
          {<AdminContent />}
        </Col>
      </>
    );
  };

  useEffect(() => {
    if (templates && templates.publicTemplates && templates.publicTemplates.template) {
      setFullLoaderTemplate(
        templates.publicTemplates.template.find(
          (template: any) => template.type === 'splashLoader'
        ) || null
      );
    }
  }, [templates.publicTemplates]);

  if (authState.isAuthenticated && !authState.isAdmin) {
    return <Redirect to={SharedMainRouteName.clientRoute} />;
  }

  if (!userIsAuthenticated && authState.isAuthenticated && fullLoaderTemplate) {
    return <FullPageLoader template={(fullLoaderTemplate && fullLoaderTemplate.html) || ''} />;
  }

  /// Populate menuItems when language changes
  useEffect(() => {
    const updatedMenuItems: IMenuItem[] = getAdminMenuItems();
    setMenuItems([...updatedMenuItems]);
  }, [properties.languageSettingsMode]);

  //Note: Get admin menu items when localstorage changes
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.languageSettingsModeKey) {
      const updatedMenuItems: IMenuItem[] = getAdminMenuItems();

      if (updatedMenuItems) {
        setMenuItems([...updatedMenuItems]);
      }
    }
  });

  return (
    <>
      {isLogoLoaded && (
        <main className="d-flex flex-column h-100">
          <Container fluid={true}>
            <Row>{getPageContent()}</Row>
          </Container>
        </main>
      )}
    </>
  );
};

export default withErrorBoundary(AdminPanel, createLogApi);
