/*
 * ActivationForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file ActivationForm.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';
import { IActivation } from '@abstract/abstractwebcommon-shared/interfaces/license/activation';

/**
 * IActivationFormProperties
 */
interface IActivationFormProperties {
  isLoading: boolean /**< Loading State. */;
  activation: IActivation /**< Activation data. */;
  handleSubmit: (
    payload: IActivation,
    activationUUID: string
  ) => void /**< Handlesubmit event handler. */;
}

const ActivationForm = (properties: IActivationFormProperties): JSX.Element => {
  const activation: IActivation = properties.activation; /**< Activation data. */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      notes: activation.notes || ''
    },
    validationSchema: Yup.object({
      notes: Yup.string()
    }),
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          if (Object.keys(payload).length) {
            properties.handleSubmit(payload, activation.activationUUID);
          }
        }
      });
    }
  });

  return (
    <FormWrapper
      handleSubmitButton={() => formik.handleSubmit()}
      controlButtonLabel={false}
      isLoading={properties.isLoading}>
      <div className="mb-3">
        <InputTextarea
          id="notes"
          value={formik.values.notes}
          onChange={formik.handleChange}
          className={formik.touched.notes && formik.errors.notes ? 'p-invalid' : ''}
          onBlur={formik.handleBlur}
          rows={5}
          autoResize
        />
        {formik.touched.notes && formik.errors.notes ? (
          <small id="notes-invalid" className="p-invalid">
            {formik.errors.notes}
          </small>
        ) : null}
      </div>
    </FormWrapper>
  );
};

export default ActivationForm;
