import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import { initializeStore } from '../Store';
import '../Scss/App.scss';
import '@abstract/abstractwebcommon-client/common.css';
import '@abstract/abstractwebcommon-client/theme.scss';
import Main from './Main';

const store = initializeStore();
function App(): ReactElement {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}

export default App;
