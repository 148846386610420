/**
* ConfigurationSettingsPage.ts (InstaLOD GmbH) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2022 
* @file ConfigurationSettingsPage.ts
* @author Alaguvelammal Alagusubbiah
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React, { useEffect } from 'react';
import ConfigurationSettingsPageComponent from '@abstract/abstractwebcommon-client/ConfigurationSettingsPage/ConfigurationSettingsPage';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ISettingsStateSelector, IStateSelectors } from '../../Interfaces/Selectors';
import { updateSettings } from '../../Store/Settings';
import { ISetting } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/Log';
import { SharedMainRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';

const ConfigurationSettingsPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation<any>();
  const configurationSettings: ISetting = location.state; /**< Settings to configure application. */
  const settings: ISettingsStateSelector = useSelector(
    (state: IStateSelectors) => state.settings
  ); /**< Settings State. */
  const history: any = useHistory();

  /// Update configuration settings.
  const handleUpdateSettings = async (values: ISetting): Promise<void> => {
    const payload: any = {
      values
    }; /**< Payload to update application settings. */
    dispatch(updateSettings(payload));
  };

  useEffect(() => {
    if (settings.isSettingsUpdated) {
      history.push({ pathname: SharedMainRouteName.adminRoute });
    }
  }, [settings, dispatch]);

  return (
    <>
      <ConfigurationSettingsPageComponent
        configurationSettings={configurationSettings}
        handleUpdateSettings={handleUpdateSettings}
      />
    </>
  );
};

export default withErrorBoundary(ConfigurationSettingsPage, createLogApi);
