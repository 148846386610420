/*
 * VersionsTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file VersionsTable.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import {
  formatDate,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { Column } from 'primereact/column';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import Button from 'react-bootstrap/Button';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { IFileRecordVersionProperties } from '../../../Interfaces/FileRecord';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { fetchVersions } from '../../../Store/FileRecords';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { IFileRecordVersion } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecordVersion';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import VersionForm from './VersionForm';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const VersionsTable = ({
  fileRecord,
  fileRecordState,
  handleUploadFileVersion,
  handleSetActive,
  handleDownload,
  handleDeleteVersion,
  handleUpdateFileRecordVersion,
  handleActiveItemState
}: IFileRecordVersionProperties): ReactElement => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();

  const inputFileRef: any = React.useRef();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [isWarningDialog, setShowWarningDialog] = useState<boolean>(false);
  const [selectedFileVersions, setSelectedFileVersions] =
    useState<IFileRecordVersion[]>(null); /**< Selected FileRecord versions. */
  const deleteButtonReference: any = useRef(null); /**< Delete Button Reference. */
  const totalAvailableVersions: number =
    fileRecordState.availableVersionsList &&
    fileRecordState.availableVersionsList.totalRecords; /**< Total AvailableVersions */

  const [payload, setPayload] = useState<ITablePayload>({
    limit: 10,
    skip: 0,
    ///Note: By default,the activated version should be at the top, followed by newly created versions. But the sorting icon in the ActivatedAt column.
    sort: {
      sortField: 'lastActivatedAt',
      sortOrder: -1
    },
    searchTerm: ''
  }); /**< Default Payload */
  const [isShowEditDialog, setShowEditDialog] =
    useState<boolean>(false); /**< To Show Edit Dialog. */
  const [versionRowData, setVersionRowData] =
    useState<IFileRecordVersion>(); /**< Version row data. */
  const [isShowVersionNameEditDialog, setShowVersionNameEditDialog] =
    useState<boolean>(false); /**< To Show version name Edit Dialog. */

  const onChangeFile = async (): Promise<void> => {
    const file: any = document.querySelector('#file');
    let fileType;
    if (file && file.files[0] && file.files[0].name) {
      const originalFilename = file.files[0].name;
      if (originalFilename && originalFilename.includes('.')) {
        const splits = originalFilename.split('.');
        fileType = splits[splits.length - 1];
      }
    } else {
      return;
    }

    if (!fileType?.includes(fileRecord.extension)) {
      handleError({ message: t('admin.page.files.errors.invalidExtension') });
      return;
    }

    await asyncErrorHandler(
      handleUploadFileVersion({
        fileRecordUUID: fileRecord.fileRecordUUID,
        file: file.files[0]
      })
    );

    // whenever the upload is over, clear Form.File => to detect new files with the same name
    file.value = '';

    ///Note: After uploading the file version, the activated version should be at the top, followed by newly created versions. But the sorting icon in the ActivatedAt column.
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: {
        sortField: 'lastActivatedAt',
        sortOrder: -1
      }
    });
    setPayload(updatedPayload);

    return;
  };

  const prepareAndSetActive = async (versionData: any) => {
    await asyncErrorHandler(
      handleSetActive({
        fileRecordUUID: fileRecord.fileRecordUUID,
        versionID: versionData.versionID
      })
    );

    ///Note: After activating the file version, the activated version should be at the top, followed by newly created versions. But the sorting icon in the ActivatedAt column.
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: {
        sortField: 'lastActivatedAt',
        sortOrder: -1
      }
    });
    setPayload(updatedPayload);

    return;
  };

  const prepareAndHandleDownload = (versionData: any) => {
    return handleDownload({
      fileRecordUUID: fileRecord.fileRecordUUID,
      versionID: versionData.versionID
    });
  };

  const onAccept = async () => {
    /// If you delete all versions, show warning popup
    if (selectedFileVersions?.length === totalAvailableVersions) {
      setShowWarningDialog(true);
    } else {
      onWarningAccepted();
    }
    setShowConfirmation(false);
  };

  // Dispatch delete action on warning accepted
  const onWarningAccepted = async () => {
    const versionIDs: string[] = selectedFileVersions?.map(
      (eachVersion: IFileRecordVersion) => eachVersion.versionID
    ); /**< versionIDs */

    await asyncErrorHandler(
      handleDeleteVersion({
        fileRecordUUID: fileRecord.fileRecordUUID,
        versionIDs: versionIDs
      })
    );
    setShowConfirmation(false);
    setShowWarningDialog(false);
    setSelectedFileVersions([]);
  };

  const renderWarningDialogFooter = (): JSX.Element => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => onReject()}
          autoFocus
          variant="secondary"
          className="d-flex align-items-center">
          <i className="bi bi-x-circle btn-icon"></i>
          {t('/confirm_messages.cancel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => onWarningAccepted()}
          autoFocus
          className="d-flex align-items-center m-0">
          <i className="bi bi-trash btn-icon"></i>
          {t('admin.page.files.versions.delete')}
        </Button>
      </div>
    );
  };

  const onReject = () => {
    setShowConfirmation(false);
    setShowWarningDialog(false);
  };

  const getWarningPopup = () => {
    return (
      <DialogWrapper
        isCustomClassName
        headerTitle={t('admin.page.files.versions.uploadInProgressHeader')}
        isDialogVisible={isWarningDialog}
        footer={renderWarningDialogFooter()}
        onHide={() => setShowWarningDialog(false)}>
        <div className="display-linebreak">{t('admin.page.files.versions.confirmMessage')}</div>
        <div className="display-linebreak">
          {t('admin.page.files.versions.delete_header')}
          {fileRecord && fileRecord.linkedEntitlements && fileRecord.linkedEntitlements.length > 0
            ? t('admin.page.files.versions.warningMessage', {
                field: fileRecord.linkedEntitlements
                  .map((eachEntitlement: IEntitlement) => `- ${eachEntitlement.name}`)
                  .join('\n')
              })
            : t('admin.page.files.versions.confirmMessage')}
        </div>
      </DialogWrapper>
    );
  };

  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={t('/confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={
            ((selectedFileVersions && Object.keys(selectedFileVersions).length === 0) ||
              !selectedFileVersions) ??
            false
          }
          buttonReference={deleteButtonReference}
        />{' '}
        {/* To delete FileRecord Versions. */}
      </div>
      <SearchBar onSearchTermChanged={(data: string) => handleFilterUpdate(data)} />
    </div>
  );

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(
      fetchVersions({
        ...updatedPayload,
        fileRecordUUID: fileRecord.fileRecordUUID
      })
    );
  };

  /// Handle page update event
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(
      fetchVersions({
        ...updatedPayload,
        fileRecordUUID: fileRecord.fileRecordUUID
      })
    );
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(
      fetchVersions({
        ...updatedPayload,
        fileRecordUUID: fileRecord.fileRecordUUID
      })
    );
  };

  /// Handles selection change event
  const onSelectionChange = (event: any) => {
    const rows: IFileRecordVersion[] = event.value;
    if (Array.isArray(rows)) {
      const selectedRows: IFileRecordVersion[] = rows.map((row: IFileRecordVersion) => {
        return row;
      });
      setSelectedFileVersions(selectedRows);
    }
  };

  /**
   * handleEditDialog
   * @param data
   */
  const handleEditDialog = (data: IFileRecordVersion): void => {
    setVersionRowData(data);
    setShowEditDialog(true);
  };

  /**
   * handleVersionNameEditDialog
   * @param data
   */
  const handleVersionNameEditDialog = (data: IFileRecordVersion): void => {
    setVersionRowData(data);
    setShowVersionNameEditDialog(true);
  };

  const handleUpdate = (updatedPayload: IFileRecordVersion, versionID: string) => {
    if (updatedPayload.description != null || updatedPayload.description == '') {
      handleUpdateFileRecordVersion({
        fileRecordUUID: fileRecord.fileRecordUUID,
        versionID: versionID,
        description: updatedPayload.description
      });
      setShowEditDialog(false);
    } else if (updatedPayload.versionName != null || updatedPayload.versionName == '') {
      handleUpdateFileRecordVersion({
        fileRecordUUID: fileRecord.fileRecordUUID,
        versionID: versionID,
        versionName: updatedPayload.versionName
      });
      setShowVersionNameEditDialog(false);
    }
  };

  const getEditDialog = () => {
    return (
      <DialogWrapper
        isDialogVisible={isShowEditDialog}
        headerTitle={
          versionRowData && !isStringEmptyOrNullOrUndefined(versionRowData?.description)
            ? t('admin.page.files.versions.editDescriptionHeader')
            : t('admin.page.files.versions.addDescriptionHeader')
        }
        onHide={() => {
          setShowEditDialog(false);
        }}>
        <VersionForm
          isLoading={fileRecordState.fileRecordIsFetchingVersions}
          handleSubmit={handleUpdate}
          filerecordVersion={versionRowData}
          isEditDescription={true}
        />
      </DialogWrapper>
    );
  };

  const getVersionNameEditDialog = () => {
    return (
      <DialogWrapper
        isDialogVisible={isShowVersionNameEditDialog}
        headerTitle={
          versionRowData && !isStringEmptyOrNullOrUndefined(versionRowData?.versionName)
            ? t('admin.page.files.versions.editVersionNameHeader')
            : t('admin.page.files.versions.addVersionNameHeader')
        }
        onHide={() => {
          setShowVersionNameEditDialog(false);
        }}>
        <VersionForm
          isLoading={fileRecordState.fileRecordIsFetchingVersions}
          handleSubmit={handleUpdate}
          filerecordVersion={versionRowData}
          isEditDescription={false}
        />
      </DialogWrapper>
    );
  };

  return (
    <Row className="d-flex justify-content-center">
      <Col>
        <Col sm={12}>
          <Form.Group className="p-0" as={Col}>
            <Form.Label>{t('admin.page.files.versions.addNew')}</Form.Label>
            <Form.File
              ref={inputFileRef}
              className="mb-5 d-flex align-items-center justify-content-center"
              id="file"
              name="file"
              label={t('admin.page.files.versions.chooseFile')}
              onChange={onChangeFile}
              custom
              disabled={fileRecordState.fileRecordIsUploading}
              title=""
            />
          </Form.Group>
        </Col>
        <Col className="negate-grid-margins">
          <BaseDatatable
            value={
              fileRecordState.availableVersionsList && fileRecordState.availableVersionsList.records
            }
            totalRecords={totalAvailableVersions}
            header={header}
            parentClass="fileRecordDataTable" /**< ClassName for div Component.*/
            sortField={payload.sort.sortField}
            onSort={handleSortUpdate}
            sortOrder={payload.sort.sortOrder}
            first={payload.skip}
            rows={payload.limit}
            bodyStyle={'text-center'}
            onPage={handlePageUpdate}
            emptyMessage={t('I18N.admin.fileManagement.empty_msg')}
            lazy
            responsive /**< Datatable responsive layout.*/
            selection={selectedFileVersions}
            onSelectionChange={(event: any) => onSelectionChange(event)}>
            <Column selectionMode="multiple" className="mt-2 col-width-45" />
            <Column
              sortable
              field="name"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.files.versions.name')}
                  data={rows.name}
                  isEllipsisVisible={false}
                />
              )}
              header={t('admin.page.files.versions.name')}
              className="col-width-300"
              headerClassName="col-width-300"
            />
            <Column
              sortable
              field="description"
              className="d-table-cell d-sm-none d-md-table-cell editableDescription col-width-350"
              headerClassName="d-table-cell d-sm-none d-md-table-cell col-width-350"
              body={(rowData: any) => (
                <DatatableColumn
                  title={t('admin.page.files.versions.description')}
                  onClickEventHandler={() => handleEditDialog(rowData)}
                  data={
                    !isStringEmptyOrNullOrUndefined(rowData.description) ? (
                      rowData.description
                    ) : (
                      <span className="editableDescription-placeholder datatablecolumn-screen-l">
                        {t('admin.page.files.versions.addDescription')}
                      </span>
                    )
                  }
                />
              )}
              header={t('admin.page.files.versions.description')}
            />
            <Column
              sortable
              field="versionName"
              className="d-table-cell d-sm-none d-lg-table-cell editableDescription col-width-350"
              headerClassName="d-table-cell d-sm-none d-lg-table-cell col-width-350"
              body={(rowData: IFileRecordVersion) => (
                <DatatableColumn
                  title={t('admin.page.files.versions.versionName')}
                  onClickEventHandler={() => handleVersionNameEditDialog(rowData)}
                  data={
                    !isStringEmptyOrNullOrUndefined(rowData.versionName) ? (
                      rowData.versionName
                    ) : (
                      <span className="editableDescription-placeholder datatablecolumn-screen-l">
                        {t('admin.page.files.versions.addDescription')}
                      </span>
                    )
                  }
                />
              )}
              header={t('admin.page.files.versions.versionName')}
            />
            <Column
              sortable
              field="lastActivatedBy"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.files.versions.lastActivatedBy')}
                  data={rows.lastActivatedBy}
                  isEllipsisVisible={false}
                />
              )}
              header={t('admin.page.files.versions.lastActivatedBy')}
              className="d-table-cell d-sm-none d-xl-table-cell col-width-250"
              headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-250"
            />
            <Column
              sortable
              field="lastActivatedAt"
              className="activatedDateColumn d-table-cell d-sm-none d-xl-table-cell createdDateCol"
              headerClassName="activatedDateColumn d-table-cell d-sm-none d-xl-table-cell createdDateCol"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.files.versions.lastActivatedAt')}
                  data={formatDate(rows.lastActivatedAt)}
                />
              )}
              header={t('admin.page.files.versions.lastActivatedAt')}
            />
            <Column
              field="created"
              header={t('admin.page.files.versions.created')}
              sortable
              className="d-table-cell d-sm-none d-xxl-table-cell createdDateCol"
              headerClassName="d-table-cell d-sm-none d-xxl-table-cell createdDateCol"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.files.versions.created')}
                  data={formatDate(rows.created)}
                />
              )}
            />
            <Column
              field="setactive"
              header={t('admin.page.files.versions.active')}
              body={(rowData: any) => {
                return (
                  <div className="d-flex justify-content-center mr-4">
                    <div className="d-block d-sm-none">
                      <DatatableColumn
                        className="active-column-height-mobile-screen"
                        title={t('admin.page.files.versions.active')}
                        data={
                          <label className="radio_button_container responsive">
                            <input
                              type="checkbox"
                              disabled={rowData.isActive}
                              checked={rowData.isActive}
                              onClick={() => {
                                prepareAndSetActive(rowData);
                                handleActiveItemState(false);
                              }}
                            />
                            <span className="checkmark responsive-custom-margin-checkbox-field" />
                          </label>
                        }
                      />
                    </div>

                    <div className="d-none d-sm-block">
                      <label className="radio_button_container">
                        <input
                          type="checkbox"
                          disabled={rowData.isActive}
                          checked={rowData.isActive}
                          onClick={() => {
                            prepareAndSetActive(rowData);
                            handleActiveItemState(false);
                          }}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                  </div>
                );
              }}
              className="custom-header-min-width-allowed"
            />
            <Column
              field="download"
              body={(rowData: any) => {
                return (
                  <Button
                    className="custom-action-column-action-position"
                    variant="outline"
                    disabled={fileRecordState.fileRecordIsDownloading}
                    onClick={() => prepareAndHandleDownload(rowData)}>
                    <i className="bi bi-cloud-download editIcon fa-lg"></i>
                  </Button>
                );
              }}
              className="p-0 col-width-45 absolute-position-responsive-screen"
            />
          </BaseDatatable>
        </Col>
      </Col>
      {getConfirmPopup()}
      {getWarningPopup()}
      {getEditDialog()}
      {getVersionNameEditDialog()}
    </Row>
  );
};

export default VersionsTable;
