/*
 * ActivationPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file ActivationPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'primereact/column';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import {
  formatDate,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import {
  deactivateAllClientActivations,
  deactivateSelectedActivations,
  getMyActivations,
  updateActivation
} from '../../../Store/Activations';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { IActivationStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { IActivation } from '@abstract/abstractwebcommon-shared/interfaces/license/activation';
import ActivationForm from '../../Admin/Activations/ActivationForm';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import UserDetailsPopup, { PopupMode } from '@abstract/abstractwebcommon-client/UserDetailsPopup';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

import './ActivationPage.css';

const ActivationPage = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activations: IActivationStateSelector = useSelector(
    (state: IStateSelectors) => state.activations
  );

  const activationsList = activations.myActivations;
  const sort = activations.sort;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedActivations, setSelectedActivations] = useState(null);
  const ref: any = useRef(null);
  const [isDeactivateSelectedConfirmationVisible, setDeactivateSelectedConfirmationVisible] =
    useState<boolean>(false);
  const [confirmDeactivateSelectedPopupTarget, setConfirmDeactivateSelectedPopupTarget] =
    useState<any>(null);
  const [isDeactivateAllConfirmationVisible, setDeactivateAllConfirmationVisible] =
    useState<boolean>(false); /**< DeactivateAll Confirmation popup visibility. */
  const [confirmDeactivateAllPopupTarget, setConfirmDeactivateAllPopupTarget] =
    useState<any>(null); /**< DeactivateAll Confirmation popup target. */
  const [activationRowData, setActivationRowData] =
    useState<IActivation>(); /**< Activation row data. */
  const [isShowEditDialog, setShowEditDialog] =
    useState<boolean>(false); /**< To Show Edit Dialog. */
  const [isDisplayingSublicenseeActivations, setDisplaySublicenseeActivations] = useState<
    boolean | null
  >(
    null
  ); /**< Defines if the page should display sublicensee activations that don't have the logged user as their license owner . It can happens if the logged user is a parent license owner. */

  /// Triggers on every datatable sorting,
  const handleSortUpdate = (e: any) => {
    const payload = {
      skip: activations.skip,
      limit: activations.limit,
      sortOrder: e.sortOrder,
      sortField: e.sortField,
      filter: activations.filter,
      searchTerm: searchTerm
    };
    dispatch(getMyActivations(payload));
  };

  /// Triggers on every datatable pagination,
  const handlePageUpdate = (e: any) => {
    const { first, rows } = e;
    const payload = {
      skip: first,
      limit: rows,
      sortField: activations.sort.sortField,
      sortOrder: activations.sort.sortOrder,
      filter: activations.filter,
      searchTerm: searchTerm
    };
    dispatch(getMyActivations(payload));
  };

  /// Delete roles on Accept
  const onAcceptDeleteSelected = (): void => {
    dispatch(deactivateSelectedActivations({ selectedActivations, client: true }));
    setDeactivateSelectedConfirmationVisible(false);
    setSelectedActivations(null);
  };

  /// Hide confirmation on reject
  const onRejectDeleteSelected = (): void => {
    setDeactivateSelectedConfirmationVisible(false);
  };

  /// Deactivate all user activations
  const onAcceptDeleteAll = (): void => {
    dispatch(deactivateAllClientActivations({}));
    setDeactivateAllConfirmationVisible(false);
  };

  /// Hide confirmation on reject
  const onRejectDeleteAll = (): void => {
    setDeactivateAllConfirmationVisible(false);
  };

  /// show delete selected popup
  const deactivateSelectedActivationsHandler = (event: any): void => {
    setDeactivateSelectedConfirmationVisible(true);
    setConfirmDeactivateSelectedPopupTarget(event?.target);
  };

  // show confirmation popup
  const deactivateAllActivationsHandler = (event: any): void => {
    setDeactivateAllConfirmationVisible(true);
    setConfirmDeactivateAllPopupTarget(event?.target);
  };

  // show delete popup for deactivate all
  const onSelectionChange = (event: any): void => {
    /// Triggers on every checkbox selection change in the UI.
    const selectedValues: IActivation[] = event.value;
    if (Array.isArray(selectedValues)) {
      const selectedRows: IActivation[] = selectedValues.map((row: IActivation) => {
        return row;
      });
      setSelectedActivations(selectedRows);
    }
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <>
        <ConfirmationPopup
          target={confirmDeactivateSelectedPopupTarget}
          isShow={isDeactivateSelectedConfirmationVisible}
          title={t('/confirm_messages.deactivate_activation')}
          onAccept={onAcceptDeleteSelected}
          onReject={onRejectDeleteSelected}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
          popupPosition="bottom"
        />

        <ConfirmationPopup
          target={confirmDeactivateAllPopupTarget}
          isShow={isDeactivateAllConfirmationVisible}
          title={t('/confirm_messages.deactivate_activations_all')}
          onAccept={onAcceptDeleteAll}
          onReject={onRejectDeleteAll}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-square"
          rejectBtnIcon="bi bi-x-square"
          popupPosition="bottom"
        />
      </>
    );
  };

  useEffect(() => {
    const payload = {
      skip: activations.skip,
      limit: activations.limit,
      sortField: activations.sort.sortField,
      sortOrder: activations.sort.sortOrder,
      filter: activations.filter,
      searchTerm: searchTerm
    };
    dispatch(getMyActivations(payload));
  }, [dispatch]);

  useEffect(() => {
    if (activationsList.records) {
      setDisplaySublicenseeActivations(
        activationsList.records.some(
          (activation: IActivation) => activation.userUUID !== LocalStorage.getXUserUUID()
        )
      );
    }
  }, [activationsList.records]);

  const onFilter = (termToFind: string) => {
    const payload = {
      ...activations,
      searchTerm: termToFind
    };
    setSearchTerm(termToFind);
    dispatch(getMyActivations(payload));
  };

  /// Div containing header action buttons
  const header = (
    <div className="d-flex justify-content-between align-items-center mobile-screen-width-search-bar">
      <div className="headerTableContainer d-flex mobile-screen-width-search-bar">
        <Button
          onClick={(event: any) => deactivateSelectedActivationsHandler(event)}
          ref={ref}
          disabled={
            ((selectedActivations && Object.keys(selectedActivations).length === 0) ||
              !selectedActivations) ??
            false
          }
          variant="danger"
          className="mr-2 d-flex align-items-center">
          <i className="bi bi-x btn-icon"></i>
          {t('admin.page.activations.deactivate')}
        </Button>

        <Button
          onClick={(event: any) => deactivateAllActivationsHandler(event)}
          ref={ref}
          disabled={false}
          className="d-flex align-items-center mr-2 btn-nowrap"
          variant="danger">
          <i className="bi bi-x btn-icon"></i>
          {t('admin.page.activations.deactivateAll')}
        </Button>
      </div>

      <SearchBar onSearchTermChanged={(data: string) => onFilter(data)} />
    </div>
  );

  /**
   * Notes edit handler
   * @param data
   */
  const handleNotesEditDialog = (data: IActivation): void => {
    setActivationRowData(data);
    setShowEditDialog(true);
  };

  /**
   * Handler to update activation
   * @param updatedPayload
   * @param activationUUID
   */
  const handleUpdate = async (
    updatedPayload: IActivation,
    activationUUID: string
  ): Promise<void> => {
    await asyncErrorHandler(
      dispatch(
        updateActivation({
          ...updatedPayload,
          activationUUID
        })
      )
    );
    setShowEditDialog(false);
    const payload: Record<string, any> = {
      skip: activations.skip,
      limit: activations.limit,
      sortField: activations.sort.sortField,
      sortOrder: activations.sort.sortOrder,
      filter: activations.filter,
      searchTerm: searchTerm
    };
    await asyncErrorHandler(dispatch(getMyActivations(payload)));
  };

  // Edit notes dialog
  const getEditDialog = () => {
    return (
      <DialogWrapper
        isDialogVisible={isShowEditDialog}
        headerTitle={
          activationRowData && !isStringEmptyOrNullOrUndefined(activationRowData?.notes)
            ? t('admin.page.activations.editNotesHeader')
            : t('admin.page.activations.addNotesHeader')
        }
        onHide={() => {
          setShowEditDialog(false);
        }}>
        <ActivationForm
          isLoading={!activationsList.records}
          handleSubmit={handleUpdate}
          activation={activationRowData}
        />
      </DialogWrapper>
    );
  };

  /**
   * Defines if the data table row is selectable or not.
   * We should define if it's not selectable if the 'data.userUUID' is different from the logged in user.
   * We can have different userUUIDs if the logged in user is a parent license owner and the activation list is displaying sublicensee activations that don't belong to him.
   * @param data Defines the activation record
   * @returns boolean
   */
  const isRowSelectable = ({ data }: { data: IActivation }): boolean =>
    data.userUUID === LocalStorage.getXUserUUID();

  /**
   * Defines if the data table row should have the 'activation-custom-row-disabled' class name.
   * We should define the 'activation-custom-row-disabled' class name if the 'activation.userUUID' is different from the logged in user.
   * @param activation Defines the activation record
   * @returns string
   */
  const rowClassName = (activation: IActivation): string =>
    activation.userUUID === LocalStorage.getXUserUUID() ? '' : 'activation-custom-row-disabled';

  return (
    <>
      <Row>
        <Col sm={12}>
          <BaseDatatable
            value={activationsList.records}
            totalRecords={activationsList.totalRecords}
            isLoading={!activationsList.records}
            parentClass="clientActivationDataTable" /**< ClassName for div Component.*/
            header={header}
            dataKey="activationUUID"
            selection={selectedActivations}
            onSelectionChange={(event: any) => onSelectionChange(event)}
            onPage={handlePageUpdate}
            emptyMessage={t('I18N.admin.activations.empty_msg')}
            sortField={sort.sortField}
            onSort={handleSortUpdate}
            sortOrder={sort.sortOrder}
            first={activations.skip}
            rows={activations.limit}
            isDataSelectable={isRowSelectable}
            rowClassName={rowClassName}>
            <Column
              selectionMode="multiple"
              bodyClassName={(row: IActivation) =>
                `col-width-3 ${isRowSelectable({ data: row }) ? '' : 'p-disabled'}`
              }
            />
            {/* NOTE: We should only display the below column if the 'activationsList.records' has sublicensee activations that are not from the logged user. */}
            {isDisplayingSublicenseeActivations && (
              <Column
                field="userUUID"
                header={t('client.page.activations.licenseOwner')}
                className="col-width-15"
                body={(rows: IActivation) => {
                  return (
                    <DatatableColumn
                      title={t('client.page.activations.licenseOwner')}
                      data={
                        <UserDetailsPopup
                          users={rows.license.user != null ? [rows.license.user] : []}
                          userUUID={rows.license.user.userUUID}
                          key={String(rows.license.user.id)}
                          mode={PopupMode.Complete}
                          isHoverEffectDisabled
                        />
                      }
                    />
                  );
                }}
              />
            )}
            <Column
              field="machine"
              header={t('client.page.activations.form.machine')}
              sortable
              body={(rows: IActivation) => (
                <DatatableColumn
                  title={t('client.page.activations.form.machine')}
                  data={rows.machine}
                />
              )}
              className="col-width-25"
            />
            <Column
              sortable
              field="machineIP"
              header={t('client.page.activations.form.ip')}
              className="col-width-10"
              body={(rows: IActivation) => (
                <DatatableColumn
                  title={t('client.page.activations.form.ip')}
                  data={rows.machineIP}
                />
              )}
            />
            <Column
              field="licenseProject"
              header={t('client.page.activations.form.licenseProject')}
              sortable
              body={(rows: IActivation) => (
                <DatatableColumn
                  title={t('client.page.activations.form.licenseProject')}
                  data={rows.license ? rows.license.projectName : '-'}
                />
              )}
              className="col-width-15"
            />
            <Column
              sortable
              field="notes"
              header={t('client.page.activations.form.notes')}
              bodyClassName={(row: IActivation) =>
                `d-table-cell d-sm-none d-xl-table-cell col-width-25 ${
                  isRowSelectable({ data: row }) ? 'cursor-pointer' : ''
                }`
              }
              headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-25"
              body={(rows: IActivation) => (
                <DatatableColumn
                  title={t('client.page.activations.form.notes')}
                  onClickEventHandler={() =>
                    isRowSelectable({ data: rows }) ? handleNotesEditDialog(rows) : null
                  }
                  data={
                    !isStringEmptyOrNullOrUndefined(rows.notes) ? (
                      rows.notes
                    ) : (
                      <span className="editableDescription-placeholder datatablecolumn-screen-l">
                        {t('admin.page.files.versions.addDescription')}
                      </span>
                    )
                  }
                />
              )}
            />
            <Column
              field="activationDate"
              header={t('client.page.activations.form.activationDate')}
              sortable
              body={(rows: IActivation) => (
                <DatatableColumn
                  title={t('client.page.activations.form.activationDate')}
                  data={formatDate(rows.activationDate)}
                />
              )}
              className="createdDateCol d-table-cell d-sm-none d-lg-table-cell"
              headerClassName="createdDateCol d-table-cell d-sm-none d-lg-table-cell"
            />
            <Column
              field="deactivationDate"
              header={t('client.page.activations.form.deactivationDate')}
              sortable
              className="createdDateCol d-table-cell d-sm-none d-xl-table-cell"
              headerClassName="createdDateCol d-table-cell d-sm-none d-xl-table-cell"
              body={(rows: IActivation) => (
                <DatatableColumn
                  title={t('client.page.activations.form.deactivationDate')}
                  data={formatDate(rows.deactivationDate)}
                />
              )}
            />
          </BaseDatatable>
        </Col>
        {getConfirmPopup()}
        {getEditDialog()}
      </Row>
    </>
  );
};

export default ActivationPage;
