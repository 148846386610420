/**
* EmailAutoComplete.tsx (abstractlicense) *

* Copyright © 2020 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Alaguvelammal Alagusubbiah, 2023
* @file EmailAutoComplete.tsx
* @author Alaguvelammal Alagusubbiah
* @copyright 2023 InstaMaterial GmbH. All rights reserved.
* @section License
*/
import React from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import './EmailAutoComplete.css';
import {
  isStringEmptyOrNullOrUndefined,
  removeDuplicatesCaseInsensitive
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { isEmail } from 'react-multi-email';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import Loader from '@abstract/abstractwebcommon-client/Loader';

/**
 * Interface for EmailAutoComplete properties.
 */
interface IEmailAutoCompleteProperties {
  name: string /**< Name of the element */;
  value: string[] /**< Value of the component. */;
  suggestions: string[] /**< An array of suggestions to display. */;
  completeMethod: (
    event: any
  ) => Promise<void> /**< Callback to invoke to search for suggestions. */;
  onChange: (event: any) => void /**< Callback to invoke when autocomplete value changes. */;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void /**< To set formik field value */;
  enteredEmails: string[] /**< Entered emails */;
  setEnteredEmails: React.Dispatch<
    React.SetStateAction<string[]>
  > /**< Set state of entered emails */;
  setEmailSuggestions: React.Dispatch<
    React.SetStateAction<string[]>
  > /**< Set state of email suggestions */;
  isAddButtonVisible: boolean /**< Add button visibility */;
  handleSubmit?: (event?: React.FormEvent<HTMLFormElement>) => void /**< Submit handler */;
  [key: string]: any /**< Any other properties. */;
}

const EmailAutoComplete = (properties: IEmailAutoCompleteProperties): JSX.Element => {
  /// Handle Add Email to report participants.
  const handleAddEmail = (): void => {
    const validEmails: string[] = [];
    /// If the entered text is a valid email, add it into cc.
    for (const email of properties.enteredEmails) {
      if (!isStringEmptyOrNullOrUndefined(email) && isEmail(email)) {
        validEmails.push(email);
      }
    }
    if (validEmails.length) {
      const emails: string[] = removeDuplicatesCaseInsensitive([
        ...properties.value,
        ...validEmails
      ]); // To remove duplicate emails.
      properties.setFieldValue('emailTo', emails);
      const emailInputElement: HTMLInputElement = document.getElementById(
        'email'
      ) as HTMLInputElement;
      if (emailInputElement) {
        emailInputElement.value = '';
      }
      properties.setEnteredEmails([]);
      properties.setEmailSuggestions([]);
    }
  };

  /// Gets the submit button or loader.
  const getSubmitButton = (): JSX.Element => {
    if (properties.isLoading) {
      return <Loader />;
    }
    return (
      <div className="add-participant">
        <ActionButton
          onClick={async () => {
            await handleAddEmail();
            properties.handleSubmit && properties.handleSubmit();
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className="email-autocomplete p-fluid">
        <AutoComplete
          inputId="email"
          multiple
          value={properties && properties.value}
          suggestions={properties && properties.suggestions}
          completeMethod={properties && properties.completeMethod}
          onChange={properties && properties.onChange}
          onClick={handleAddEmail}
          onKeyPress={(event) => {
            if (event.key === ' ' || event.key === 'Spacebar' || event.key === 'Enter') {
              event.preventDefault();
              handleAddEmail();
            }
          }}
          onKeyUp={(event) => {
            if (event.key === 'Tab') {
              event.preventDefault();
              handleAddEmail();
            }
          }}
          onUnselect={(event) => {
            // Filter the deleted email tag from formik value
            const emails: string[] = properties?.value.filter((email: string) => {
              return email !== event.value;
            });
            properties.setFieldValue(properties.name, emails);
          }}
          {...properties}
        />
      </div>
      {properties.isAddButtonVisible ? getSubmitButton() : <></>}
    </>
  );
};

export default EmailAutoComplete;
