/**
 * template.ts (InstaLOD GmbH)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file template.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */
export enum templateTypes {
  licenseCreation = "licenseCreation",
  licenseTemplateCreation = "licenseTemplateCreation",
  masterTemplate = "masterTemplate",
  CSS = "CSS",
  splashLoader = "splashLoader",
  subLicenseUserInvitation = "subLicenseUserInvitation",
  errorHandler = "errorHandler",
  reportNotification = "reportNotification",
  reportComment = "reportComment",
  communicationMasterTemplate = "communicationMasterTemplate",
  smtpTestEmail = "smtpTestEmail", // SMTP Test Email Template
}
