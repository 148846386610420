/**
 * Template.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Template.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ITemplate } from '@abstract/abstractwebcommon-shared/interfaces/license/template';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 *
 * @param template Template informationo
 * @returns Promise
 */
export const createOrUpdate = async (template: any): Promise<IAPIEntityResponse<ITemplate>> => {
  if (template.templateUUID) {
    return httpClient.post<ITemplate>(`/template/${template.templateUUID}/update`, template);
  }
  return httpClient.post<ITemplate>(`/template/create`, template);
};

/**
 *
 * @returns Promise
 */
export const read = async (): Promise<IAPIEntityResponse<ITemplate[]>> => {
  return httpClient.get<ITemplate[]>('/templates/all');
};

/**
 *
 * @returns Promise
 */
export const fetchPublicTemplate = async (): Promise<IAPIEntityResponse<ITemplate[]>> => {
  return httpClient.get<ITemplate[]>('/templates/public');
};

/**
 *
 * @param templateID Template id
 * @returns Promise
 */
export const getCSS = async (templateID: string): Promise<IAPIEntityResponse<ITemplate>> => {
  return httpClient.get<ITemplate>(`/template/${templateID}/css`);
};

/**
 *
 * @returns Promise
 */
export const getAllCSSTemplates = (): Promise<IAPIEntityResponse<ITemplate[]>> => {
  return httpClient.get<ITemplate[]>(`/templates/css/all`);
};

/**
 *
 * @param cssData Updated CSS Data
 * @returns Promise
 */
export const updateCss = async (cssData: string): Promise<IAPIEntityResponse<ITemplate>> => {
  return httpClient.put<ITemplate>(`/template/css`, { css: cssData });
};

/**
 *
 * @param url URL of the page to load css into
 * @param id id to provide to the page
 * @returns Promise
 */
export const loadCss = (url: string, id: string): void => {
  //Note: Remove current CSS link to avoid stacking unnecessary stylesheets and overrides.
  const cssLinkToRemove = document.getElementById(id);
  if (cssLinkToRemove) {
    cssLinkToRemove.remove();
  }

  const link = document.createElement('link');
  if (id !== '') link.id = id;
  link.rel = 'stylesheet';
  link.href = url;
  document.head.appendChild(link);
};
