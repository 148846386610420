/**
* ReportPageSkeleton.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained Rafael Rodrigues, 2022 
* @file ReportPageSkeleton.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react'

import SkeletonWrapper from '../SkeletonWrapper'

import './ReportPageSkeleton.css'

/**
 * Render a skeleton for Report Page when data is loading for it.
 */
const ReportPageSkeleton = () => {
  return (
    <div className="w-100">
      <SkeletonWrapper height="35px" />

      <SkeletonWrapper width="75px" className="mt-4 mb-3" />

      <div>
        <SkeletonWrapper circle width="50px" height="50px" className="mr-2" />
        <SkeletonWrapper circle width="50px" height="50px" className="mr-2" />
        <SkeletonWrapper circle width="50px" height="50px" />
      </div>

      <div className="custom-max-width-skeletons">
        <SkeletonWrapper height="25px" className="mt-4" />
        <SkeletonWrapper height="25px" className="mt-3" />
        <SkeletonWrapper height="25px" className="mt-3" />
        <SkeletonWrapper height="100px" className="mt-3" />
        <SkeletonWrapper height="50px" className="mt-3" />
      </div>

      <SkeletonWrapper height="2px" className="mt-5" />

      <div className="d-flex">
        <SkeletonWrapper circle width="50px" height="50px" className="mr-2" />

        <div className="custom-max-width-skeletons">
          <SkeletonWrapper height="75px" />
          <SkeletonWrapper height="25px" width="150px" className="mt-2" />
          <SkeletonWrapper height="25px" className="mt-2" />
        </div>
      </div>
    </div>
  )
}

export default ReportPageSkeleton