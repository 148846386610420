/**
* UserForm.tsx (abstractlicense) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file UserForm.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-multi-email/style.css';
import Row from 'react-bootstrap/Row';
import { translate } from '../../../Utils/Translate';
import { IReport } from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClientsWithoutPagination } from '../../../Store/Clients';
import EmailAutoComplete from '../../SubComponents/EmailAutoComplete';
import {
  IAuthStateSelector,
  ISettingsStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import './UserForm.css';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

/**
 * @interface IUserFormProperties
 */
interface IReportFormProperties {
  handleSubmit: (payload: IReport) => void /**< handle file report submit function*/;
  isLoading: boolean /**< checks for any report API request. True if there is, false otherwise */;
}

/**
 * ReportForm form component.
 */
const UserForm = (properties: IReportFormProperties): JSX.Element => {
  const [emailSuggestions, setEmailSuggestions] = useState<string[]>(
    []
  ); /**< Email Suggestions list. */
  const [enteredEmails, setEnteredEmails] = useState<string[]>(
    []
  ); /**< This variable is used to store the entered emails in the EmailAutoComplete component. */
  const dispatch: Dispatch<any> = useDispatch();
  const authState: IAuthStateSelector = useSelector(
    (state: IStateSelectors) => state.auth
  ); /**< Authstate. */
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailTo: []
    },
    validationSchema: Yup.object({
      emailTo: Yup.array().min(
        1,
        translate('validation.required', { field: translate('client.page.reports.form.email') })
      )
    }),
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          if (payload.emailTo) {
            payload.emailTo = payload.emailTo.join(', ');
          }
          properties.handleSubmit(payload);
        }
      });
    }
  });

  /// Search User email to show suggestions
  const searchUserEmail = async (event: any) => {
    const emails: string[] = event?.query
      .split(/(\s+)/)
      .filter((email) => email.trim().length > 0); /**< Entered emails. */
    const userEmailList: string[] = []; /**< Get searched email list */

    // Only admin and user with the supportt role can view the suggestions of own users when adding participant.
    if (authState.isAdmin || (settings && settings.safeSettings?.isSupportRole)) {
      const lastEmailToSearch: string =
        emails[emails.length - 1]; /** Email to search in user backend */
      const response: any = await asyncErrorHandler(
        dispatch(getAllClientsWithoutPagination({ searchTerm: lastEmailToSearch }))
      ); /**< Get filtered users. */

      if (response && response.payload && response.payload.status === 200) {
        if (response.payload.data && response.payload.data.records) {
          response.payload.data.records.map((user: IUser) => {
            userEmailList.push(user && user.email);
          });
        }
      }
    }
    setEmailSuggestions(userEmailList);
    setEnteredEmails(emails);
  };

  /// Prevent tab to next element
  useEffect(() => {
    const allButtons: NodeListOf<Element> = document.querySelectorAll(
      '.add-participant-dialog button'
    );
    for (let i = 0; i < allButtons.length; i++) {
      allButtons[i].setAttribute('tabindex', '-1');
    }
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Row className="email-label">
          <label className="required" htmlFor="title">
            {translate('client.page.reports.form.email')}
          </label>
        </Row>
        <Row className="pb-3">
          <div className="user-form email-container">
            <EmailAutoComplete
              name="emailTo"
              value={formik.values.emailTo}
              suggestions={emailSuggestions}
              completeMethod={searchUserEmail}
              onChange={(event) => {
                formik.setFieldValue('emailTo', event.value);
                setEnteredEmails([]);
              }}
              setFieldValue={formik.setFieldValue}
              className={formik.touched.emailTo && formik.errors.emailTo ? 'p-invalid' : ''}
              enteredEmails={enteredEmails}
              setEnteredEmails={setEnteredEmails}
              setEmailSuggestions={setEmailSuggestions}
              isAddButtonVisible={true}
              isLoading={properties.isLoading}
              handleSubmit={formik.handleSubmit}
            />
          </div>
          <div className="pl-3">
            {formik.touched.emailTo && formik.errors.emailTo ? (
              <small id="emailTo-invalid" className="p-invalid">
                {formik.errors.emailTo}
              </small>
            ) : null}
          </div>
        </Row>
      </form>
      <div className="d-flex">
        {formik.status && formik.status.message !== undefined ? (
          <small className="p-invalid">{formik.status.message}</small>
        ) : null}
      </div>
    </div>
  );
};

export default UserForm;
