/**
 * UserCache.ts (AbstractLicensingBackend)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2024
 *
 * @file UserCache.ts
 * @author Rafael Rodrigues
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Get users from User API
 * @returns
 */
export const getUsersFromUserAPI = (): Promise<IAPIEntityResponse<string>> => {
  return httpClient.get<string>(`/users/getAll/withDeleted/paginated?limit=1000&skip=0`);
};
