/*
 * ClientPanel.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file ClientPanel.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClientContent from './ClientContent';
import MenuWrapper from '../Menu/MenuWrapper';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { menuItemClick } from '@abstract/abstractwebcommon-client/Sidebar/menuItemUtils';
import { Redirect, useHistory } from 'react-router-dom';
import '../App.css';
import { getPublicTemplates } from '../../Store/Templates';
import { isTokenValid, validateTokenAPI } from '../../Services/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import FullPageLoader from '@abstract/abstractwebcommon-client/Loader/FullPageLoader';
import {
  IAuthStateSelector,
  ISettingsStateSelector,
  IStateSelectors,
  ITemplateStateSelector
} from '../../Interfaces/Selectors';
import Container from 'react-bootstrap/Container';
import { createLogApi } from '../../Services/Log';
import { IMenuItem } from '@abstract/abstractwebcommon-client/Sidebar/SidebarPage';
import { getLogoAction } from '../../Store/Settings';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { RouteName } from '../../Utils/routesNames';
import {
  SharedCommomRouteName,
  SharedMainRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import LicenseTotalCount from '../Admin/MenuBadgeCount/LicenseTotalCount';

interface IClientPanelProperties {
  fnLogout: () => void;
  themeMode?: string /**< theme mode to use */;
  didChangeTheme?: (theme: string) => void /**< change theme function */;
  languageSettingsMode?: string /**< Language settings mode to use */;
  didChangeLanguage?: (language: string) => void /**< change language function */;
}

const ClientPanel: React.FC<any> = (properties: IClientPanelProperties) => {
  const { t } = useTranslation();
  // useHistory is passed to ConsoleMenu instead of being used there because that way we can avoid menu rerender
  const history = useHistory();

  const menu: any = useRef(null);

  const dispatch: Dispatch<any> = useDispatch();

  const authState: IAuthStateSelector = useSelector((state: IStateSelectors) => state.auth);
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const templates: ITemplateStateSelector = useSelector(
    (state: IStateSelectors) => state.templates
  );

  const [logoURL, setLogoURL] = useState<string>(''); /**< Logo URL. */
  const [isLogoLoaded, setLogoLoaded] = useState<boolean>(false); /**< Logo Loading state. */

  // Get client menu items
  const getClientMenuItems = (): Array<IMenuItem> => {
    const items: IMenuItem[] = [
      {
        id: 'dashboard',
        label: t('client.menu.dashboard'),
        icon: 'far fa-home sidebar_icon',
        command: (event: any) => {
          event.originalEvent.preventDefault();
          menuItemClick(menu, setMenuItems, history, RouteName.clientHomeRoute, 'dashboard');
        },
        url: RouteName.clientHomeRoute,
        pathToHighlight: RouteName.clientHomeRoute
      },
      {
        id: 'activations',
        label: t('client.menu.activations'),
        icon: 'far fa-sitemap sidebar_icon',
        command: (event: any) => {
          event.originalEvent.preventDefault();
          menuItemClick(
            menu,
            setMenuItems,
            history,
            RouteName.clientActivationsRoute,
            'activations'
          );
        },
        url: RouteName.clientActivationsRoute,
        pathToHighlight: RouteName.clientActivationsRoute
      }
    ];

    return items;
  };

  const [menuItems, setMenuItems] = useState<any[]>([]);

  const [userIsAuthenticated, setUserIsAuthenticated] = useState(
    sessionStorage.getItem('initialLoginTime') ? true : false
  );
  const [fullLoaderTemplate, setFullLoaderTemplate] = useState<any>(null);
  const logoutHandler = (): void => {
    properties.fnLogout();
  };

  // NOTE: If the user has a support entitlement and an active license, the user can see the Technical Support/Reports page.
  // NOTE: If the user has the sublicense entitlement and an active license, the user can see the License Management page.
  const getConditionSidebarMenus = () => {
    if (authState?.isProfileLoaded) {
      let newMenuItems: IMenuItem[] = getClientMenuItems();

      if (authState?.isSupportPresent || settings?.safeSettings?.isSupportRole) {
        newMenuItems = newMenuItems.concat([
          {
            id: 'reports',
            label: t('I18N.admin.menu.reports'),
            icon: 'pi pi-flag',
            command: (event: any) => {
              event.originalEvent.preventDefault();
              menuItemClick(menu, setMenuItems, history, RouteName.clientReportsRoute, 'reports');
            },
            url: RouteName.clientReportsRoute,
            pathToHighlight: RouteName.clientReportsRoute
          }
        ]);
      }

      if (authState?.isSublicensePresent) {
        newMenuItems = newMenuItems.concat([
          {
            id: 'licenses',
            label: (
              <div className="count-items-container">
                {t('admin.menu.licenseManagement')}
                <LicenseTotalCount />
              </div>
            ),
            icon: 'fas fa-id-badge sidebar_icon',
            command: (event: any) => {
              event.originalEvent.preventDefault();
              menuItemClick(menu, setMenuItems, history, RouteName.clientLicenseRoute, 'licenses');
            },
            url: RouteName.clientLicenseRoute,
            pathToHighlight: RouteName.clientLicenseRoute
          }
        ]);
      }

      if (
        !authState?.isSupportPresent &&
        !authState?.isSublicensePresent &&
        !settings?.safeSettings?.isSupportRole
      ) {
        newMenuItems = getClientMenuItems();
      }

      setMenuItems(newMenuItems);
    }
  };

  useEffect(() => {
    // If Eula Entitlement is present, ask the user to accept the EULA terms
    if (authState.isEulaRequired) {
      history.push(RouteName.eulaPermissionRoute);
    }

    if (authState.isSLARequired) {
      history.push(RouteName.slaPermissionRoute);
    }

    getConditionSidebarMenus();
    dispatch(getPublicTemplates());
    if (!sessionStorage.getItem('initialLoginTime')) {
      const timeoutID: any = setTimeout(async () => {
        const response: any = await asyncErrorHandler(validateTokenAPI());
        if (authState.isAuthenticated && response && response.status === 200) {
          setUserIsAuthenticated(true);
        } else {
          logoutHandler();
          setUserIsAuthenticated(false);
          window.location.href = `${SharedCommomRouteName.validateRoute}?autoLogoutReason=403`;
        }
      }, 700);
      sessionStorage.setItem('initialLoginTime', `${new Date()}`);
      return () => clearTimeout(timeoutID);
    }
  }, [authState]);

  /// Get Logo URL.
  useEffect(() => {
    const getLogoURL = async () => {
      if (LocalStorage.getLogoImageURL() === null) {
        await asyncErrorHandler(dispatch(getLogoAction()));
        setLogoLoaded(true);
      } else {
        setLogoURL(LocalStorage.getLogoImageURL() || '');
        setLogoLoaded(true);
      }
    };
    if (authState.isAuthenticated) {
      getLogoURL();
    } else {
      setLogoLoaded(true);
    }
  }, [LocalStorage.getLogoImageURL()]);

  const getPageContent = () => {
    return (
      <>
        <MenuWrapper
          menuItems={menuItems}
          menu={menu}
          fnLogout={properties.fnLogout}
          setMenuItems={setMenuItems}
          history={history}
          isAdmin={false}
          didChangeTheme={properties.didChangeTheme}
          themeMode={properties.themeMode}
          logoURL={logoURL}
          languageSettingsMode={properties.languageSettingsMode}
          didChangeLanguage={properties.didChangeLanguage}
        />
        <Col className="main-body" id="main-body">
          <ClientContent />
        </Col>
      </>
    );
  };

  useEffect(() => {
    if (templates && templates.publicTemplates && templates.publicTemplates.template) {
      setFullLoaderTemplate(
        templates.publicTemplates.template.find(
          (template: any) => template.type === 'splashLoader'
        ) || null
      );
    }
  }, [templates.publicTemplates]);

  // If user is not autheticated, redirect to /validate.
  if (!authState.isAuthenticated || !isTokenValid(LocalStorage.getXAuthToken())) {
    const parameters: URLSearchParams = new URLSearchParams({
      logout: 'true',
      themeMode: LocalStorage.getThemeMode(),
      languageSettingsMode: LocalStorage.getLanguageSettingsMode()
    }); /**< Query parameters */
    // If the path contains /client/technical-support/, redirect to /validate with redirectURL
    const locationPath: string = window.location.pathname;
    if (locationPath.indexOf(RouteName.clientReportsRoute) > -1) {
      const parametersWithRedirectURL: URLSearchParams = new URLSearchParams({
        ...Object.fromEntries(parameters),
        redirect_url: locationPath
      });
      return (
        <Redirect
          to={`${SharedCommomRouteName.validateRoute}?${parametersWithRedirectURL.toString()}`}
        />
      );
    } else {
      return <Redirect to={`${SharedCommomRouteName.validateRoute}?${parameters.toString()}`} />;
    }
  }

  // If user is admin, redirect to admin route.
  if (authState.isAuthenticated && authState.isAdmin) {
    const locationPath: string = window.location.pathname;

    // If the path contains /client/technical-support/, redirect to /admin/technical-support/
    return (
      <Redirect
        to={
          locationPath.indexOf(RouteName.clientReportsRoute) > -1
            ? locationPath.replace(RouteName.clientReportsRoute, RouteName.adminReportsRoute) // Replace the exact client report route with admin report route with reportID
            : SharedMainRouteName.adminRoute
        }
      />
    );
  }

  if (!userIsAuthenticated && authState.isAuthenticated && fullLoaderTemplate) {
    return <FullPageLoader template={(fullLoaderTemplate && fullLoaderTemplate.html) || ''} />;
  }

  /// Populate menuItems when language changes
  useEffect(() => {
    getConditionSidebarMenus();
  }, [properties.languageSettingsMode]);

  // NOTE: Get admin menu items when localstorage changes
  window.addEventListener('storage', () => {
    getConditionSidebarMenus();
  });

  return (
    <>
      {isLogoLoaded && menuItems.length ? (
        <main className="d-flex flex-column h-100">
          <Container fluid={true}>
            <Row>{getPageContent()}</Row>
          </Container>
        </main>
      ) : (
        <></>
      )}
    </>
  );
};

export default withErrorBoundary(ClientPanel, createLogApi, false);
