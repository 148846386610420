/*
 * NewsletterUnSubscriptionPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterUnSubscriptionPage.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Col, Container, Row, Alert } from 'react-bootstrap';
import { Card } from 'primereact/card';
import './NewsletterUnSubscriptionPage.css';
import { unsubscribeNewsletterByEmail } from '../../../Store/Newsletter';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { INewsletterStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { translate } from '../../../Utils/Translate';
import Loader from '@abstract/abstractwebcommon-client/Loader';

/**
 * Newsletter UnSubscription page component.
 */
const NewsletterUnSubscriptionByEmailPage: React.FC<any> = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const search: string = useLocation().search;
  const parsedString: any = queryString.parse(search); /**< Query Parameters. */
  const email: string = parsedString['email']; /**< Customer email. */
  const newsletterState: INewsletterStateSelector = useSelector(
    (state: IStateSelectors) => state.newsletter
  ); /**< Newsletter state */
  const [isShowMessage, setShowMessage] = useState<boolean>(false);

  useEffect(() => {
    if (email) {
      dispatch(unsubscribeNewsletterByEmail(email)); /**< Unsubscribe user from newsletter */
      setShowMessage(true);
    }
  }, []);

  const getMesssage = () => {
    if (isShowMessage && !newsletterState.isLoading) {
      return (
        <Alert variant={newsletterState.isUnsubscribed ? 'success' : 'danger'}>
          {newsletterState.isUnsubscribed
            ? translate(`${newsletterState.unsubscribedMessage}`)
            : `${
                newsletterState.unsubscribeNewsletterError
                  ? newsletterState.unsubscribeNewsletterError
                  : translate('I18N.admin.page.newsletter.newsletterUnsubscription.error')
              }`}
        </Alert>
      );
    }
    return <Loader />;
  };

  return (
    <div className="unsubscription-page">
      <Container className="unsubscription-Container">
        <Row>
          <Col xs={12} className="mt-3">
            <Card className="rounded-corners-to-card-component keep-original-card-style keep-original-card-title-style">
              {getMesssage()}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewsletterUnSubscriptionByEmailPage;
