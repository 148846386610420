/*
 * Avatar.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file Avatar.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from 'react';
import "./index.css";
import { getInitials } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import UserDetailsPopup from '../UserDetailsPopup';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * @interface AvatarPropertiesInterface
 */
 interface AvatarPropertiesInterface {
    imageUrl?: string /**< image url */
    user?: IUser /**< user object. */
    users?: IUser[] /**< user array of object */
    copyToClipboardSuccessHandler?: (text?: string) => void;
    count?: number /**<< number of users */
    className?: string /**< CSS class name */
    isShowTooltipForDeletedUser?: boolean /**< To show tooltip for deleted user */
  }
  
/**
 * Avatar component
 */
const Avatar = (properties: AvatarPropertiesInterface): ReactElement => {
  let firstNameInitial: string = ''; /**< firstNameInitial */
  let lastNameInitial: string = ''; /**< lastNameInitial */
  let userInitials: string = ''; /**< userInitials */

  /// UserInitials
  if(properties.user && properties.user.username) {
    if(!isStringEmptyOrNullOrUndefined(properties?.user.firstName)) {
      firstNameInitial = properties?.user.firstName.charAt(0);
    }
    if(!isStringEmptyOrNullOrUndefined(properties?.user.lastName)) {
      lastNameInitial = properties?.user.lastName.charAt(0);
    }
    userInitials =  getInitials(`${firstNameInitial} ${lastNameInitial}`);
  } else if(properties.user && properties.user.email) {
    userInitials = properties.user.email.substring(0, 2).toUpperCase();
  } else {
    userInitials = getInitials('Deleted User');
  }

  let imageContainerClassName: string = '';

  if(!properties.isShowTooltipForDeletedUser && !properties.user.email) {
    imageContainerClassName = 'disabled-div';
  }

  return (
  <div className={properties.className}>
   {properties.count && (
    <div className="image-container image-container-border-color rounded-circle">
          <span className="count-placeholder">+{properties.count}</span>
      </div>
   )}
   {properties.count === undefined && (
    <div className={`image-container rounded-circle ${imageContainerClassName}`}>
       {properties.imageUrl !== undefined && properties.imageUrl ? (
           <img src={properties.imageUrl} className="rounded-circle img-thumbnail" alt="" />
          ) : (
            <div
            data-initials={userInitials}>
            </div>
       )}
       {properties.users && (
        <div className="d-none d-sm-block pop-up-text">
        <UserDetailsPopup
          users={properties.users}
          userUUID={properties?.user.userUUID}
          user={!properties.user.username ? properties.user : null}
          className="avatar"
          isShowTooltipForDeletedUser={properties.isShowTooltipForDeletedUser}
        />
      </div>
       )}
    </div>
   )}
   </div>
  );
};

export default Avatar;
