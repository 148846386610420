/**
 * Dashboard.ts (InstaLOD GmbH) *
 * Copyright © 2021 InstaMaterial GmbH - All Rights Reserved. *
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all it's contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 * @file Dashboard.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2021 InstaMaterial GmbH. All rights reserved.
 * @section License
 */

import {
  IDashboardActionsProperties,
  DashboardActions
} from '@abstract/abstractwebcommon-client/store/DashboardSlice';
import { BASE_API_URL } from '../config';
import { createLogApi } from '../Services/Log';

const dashboardActionsProperties: IDashboardActionsProperties = {
  baseApiUrl: BASE_API_URL,
  createLogApi: createLogApi,
  fetchStatisticsUrl: '/system/statistics',
  fetchVersionUrl: '/system/version',
  fetchFeedUrl: '/system/feed',
  fetchSystemWarningsUrl: '/system/warnings'
}; /**< Dashboard Actions properties. */

const dashboardActions = new DashboardActions(dashboardActionsProperties);

export const fetchDashboardStatisticsAction = dashboardActions.fetchDashboardStatisticsAction;
export const fetchDashboardVersionAction = dashboardActions.fetchDashboardVersionAction;
export const fetchFeedAction = dashboardActions.fetchFeedAction;
export const fetchSystemWarningsAction = dashboardActions.fetchSystemWarningsAction;
