/*
 * Clients.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Cslients.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DATA_OPERATOR } from '@abstract/abstractwebcommon-shared/enum/pagination';
import {
  IFilter,
  IPaginationResponse,
  IPaginationSort
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import {
  createOrUpdate,
  fetchClientsWithUUID,
  fetchClientsWithUUIDs,
  getAll,
  getTotalClientCount,
  read,
  readNonLicenseClients,
  remove
} from '../Services/Client';
import { formatTableSortOrder } from '../Utils/Formatter';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IReducerAction,
  PaginationRequestAction,
  PaginationResponseAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IAPIEntityResponse,
  IAPIErrorData,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const CLIENTS_FEATURE_KEY = 'clients';
/**
 * Interface client State
 */
interface IClientState {
  list: any;
  listIsFetching: boolean;
  client: any;
  clientIsFetching: boolean;
  clientIsChanging: boolean;
  clientDialogOpened: boolean;
  clientList: any;
  clientListIsFetching: boolean;
  skip: number;
  limit: number;
  sort: IPaginationSort<IUser>;
  filter: {
    username: null;
    firstName: null;
    lastName: null;
    email: null;
  };
  totalRecords: number;
  clientCount: number | null;
  fetchError: IAPIErrorData | null;
  nonLicenseClientList: IPaginationResponse<IUser> | null /**< Non license client list */;
}

/**
 * Interface client form
 */
export interface IClientFormValues {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  userUUID?: string;
}

/**
 * Client Intial state
 */
const INITIAL_STATE: IClientState = {
  list: [],
  listIsFetching: false,
  clientList: null,
  clientListIsFetching: false,
  client: null,
  clientIsFetching: false,
  clientIsChanging: false,
  clientDialogOpened: false,
  skip: 0,
  limit: defaultTableLimit,
  sort: {
    sortField: 'created',
    sortOrder: -1
  },
  filter: {
    username: null,
    firstName: null,
    lastName: null,
    email: null
  },
  totalRecords: 0,
  clientCount: null,
  fetchError: null,
  nonLicenseClientList: null
};

export const clientSlice = createSlice({
  name: CLIENTS_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    getClientsRequest(state: IClientState, action: PaginationRequestAction<IUser>) {
      const skip: number = action.payload.skip;
      const limit: number = action.payload.limit;
      const sort: IPaginationSort<IUser> = action.payload.sort as IPaginationSort<IUser>;
      const filter: any = action.payload.filter;
      if (sort) {
        state.sort = sort;
      }
      state.skip = skip;
      state.limit = limit;
      state.filter = filter;
      state.listIsFetching = true;
    },
    getClientsSuccess(state: IClientState, action: PaginationResponseAction<IUser>) {
      state.list = action.payload && action.payload.records;
      state.totalRecords = action.payload && action.payload.totalRecords;
      state.listIsFetching = false;
    },
    getClientsFailure(state: IClientState) {
      state.listIsFetching = false;
    },
    getAllClientsRequest(state: IClientState) {
      state.listIsFetching = true;
    },
    getAllClientsSuccess(state: IClientState, action: PaginationResponseAction<IUser>) {
      state.list = action.payload;
      state.listIsFetching = false;
    },
    getAllClientsFailure(state: IClientState) {
      state.listIsFetching = false;
    },
    getClientsWithUUIDRequest(state: IClientState) {
      state.clientListIsFetching = true;
    },
    getClientsWithUUIDSuccess(state: IClientState, action: IReducerAction<IUser[]>) {
      state.clientList = action.payload;
      state.clientListIsFetching = false;
    },
    getClientsWithUUIDFailure(state: IClientState) {
      state.clientListIsFetching = false;
    },
    getOneClientRequest(state: IClientState) {
      state.clientIsFetching = true;
    },
    getOneClientSuccess(state: IClientState, action: IReducerAction<IUser[]>) {
      state.client = action.payload;
      state.clientIsFetching = false;
    },
    getOneClientFailure(state: IClientState) {
      state.clientIsFetching = false;
    },
    addClientRequest(state: IClientState) {
      state.clientIsChanging = true;
    },
    addClientSuccess(state: IClientState) {
      state.clientIsChanging = false;
    },
    addClientFailure(state: IClientState) {
      state.clientIsChanging = false;
    },
    updateClientRequest(state: IClientState) {
      state.clientIsChanging = true;
    },
    updateClientSuccess(state: IClientState) {
      state.clientIsChanging = false;
    },
    updateClientFailure(state: IClientState) {
      state.clientIsChanging = false;
    },
    deleteClientRequest(state: IClientState) {
      state.clientIsChanging = true;
    },
    deleteClientSuccess(state: IClientState) {
      state.clientIsChanging = false;
    },
    deleteClientFailure(state: IClientState) {
      state.clientIsChanging = false;
    },
    getAllNonLicenseClientsRequest(state: IClientState) {
      state.listIsFetching = true;
    },
    getAllNonLicenseClientsSuccess(state: IClientState, action: PaginationResponseAction<IUser>) {
      state.nonLicenseClientList = action.payload;
      state.listIsFetching = false;
    },
    getAllNonLicenseClientsFailure(state: IClientState) {
      state.listIsFetching = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTotalClientCountAction.pending, (state: IClientState) => {
        state.listIsFetching = true;
        state.fetchError = null;
      })
      .addCase(
        getTotalClientCountAction.fulfilled,
        (state: IClientState, action: IReducerAction<IAPIEntityResponse<IUser>>) => {
          if (action.payload.data) {
            state.clientCount = action.payload.data.clientCount;
            state.listIsFetching = false;
          }

          if (action.payload.error) {
            state.listIsFetching = false;
            state.clientCount = null;
            state.fetchError = action.payload.error;
          }
        }
      );
  }
});

export const clientReducer = clientSlice.reducer;
export const clientActions = clientSlice.actions;

/**
 * Get all clients Action.
 * @param payload
 */
export const getAllClients = createAsyncThunk('client/all', async (payload: any, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getClientsRequest, getClientsSuccess, getClientsFailure } = clientActions;

  try {
    const skip: number = payload.skip;
    const limit: number = payload.limit;
    const sortField: string = payload.sort?.sortField; /**< Sort field. */
    const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
    const filter: any = payload.filter;
    const searchTerm: string = payload.searchTerm;

    dispatch(getClientsRequest({ skip, limit, sort: { sortField, sortOrder }, filter }));

    const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';

    const formattedFilter: IFilter[] = [];
    if (filter) {
      const keys: string[] = Object.keys(filter);
      if (keys && keys.length !== 0) {
        for (let i = 0; i < keys.length; i++) {
          if (filter[keys[i]] && filter[keys[i]].value) {
            formattedFilter.push({
              column: keys[i],
              operator: DATA_OPERATOR.LIKE,
              value: filter[keys[i]].value
            });
          }
        }
      }
    }

    const response: PaginatedAPIEntityResponse<IUser> = await asyncErrorHandler(
      read(skip, limit, sortField, formattedSortOrder, formattedFilter, searchTerm)
    );
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getClientsFailure());
    } else {
      dispatch(getClientsSuccess(response.data));
      const clients: IUser[] = response.data.records || [];
      //sort clientUUIDs and dispatch action to fetch
      const clientUUIDs: string[] = Array.from(
        new Set(clients.map((client: IUser) => client.userUUID))
      );
      if (clientUUIDs && clientUUIDs.length > 0) {
        dispatch(getClientsWithUUID({ clientUUIDs }));
      }
    }
  } catch (error: any) {
    dispatch(getClientsFailure());
    handleError({ message: error.message });
  }
});

/**
 * Get clients By UUID action.
 * @param payload
 */
export const getClientsWithUUID = createAsyncThunk(
  'client/find',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const getClientsWithUUIDRequest: any = clientActions.getClientsWithUUIDRequest;
    const getClientsWithUUIDSuccess: any = clientActions.getClientsWithUUIDSuccess;
    const getClientsWithUUIDFailure: any = clientActions.getClientsWithUUIDFailure;

    try {
      const clientUUIDs: string[] = payload.clientUUIDs;
      dispatch(getClientsWithUUIDRequest());

      const response: IAPIEntityResponse<IUser[]> = await asyncErrorHandler(
        fetchClientsWithUUIDs(clientUUIDs)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getClientsWithUUIDFailure());
      } else {
        dispatch(getClientsWithUUIDSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getClientsWithUUIDFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Get all clients Action without pagination
 * @param payload
 */
export const getAllClientsWithoutPagination = createAsyncThunk(
  'client/all',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getAllClientsRequest, getAllClientsSuccess, getAllClientsFailure } = clientActions;

    try {
      dispatch(getAllClientsRequest());
      const response: PaginatedAPIEntityResponse<IUser> = await asyncErrorHandler(getAll(payload));
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getAllClientsFailure());
      } else {
        dispatch(getAllClientsSuccess(response.data));
        return response;
      }
    } catch (error: any) {
      dispatch(getAllClientsFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Get client By UUID action.
 * @param payload
 */
export const getClientWithUUID = createAsyncThunk(
  'client/get/one',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const getOneClientRequest: any = clientActions.getOneClientRequest;
    const getOneClientSuccess: any = clientActions.getOneClientSuccess;
    const getOneClientFailure: any = clientActions.getOneClientFailure;

    try {
      const clientUUID: string = payload.clientUUID;
      dispatch(getOneClientRequest());

      const response: IAPIEntityResponse<IUser[]> = await asyncErrorHandler(
        fetchClientsWithUUID(clientUUID)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getOneClientFailure());
      } else {
        dispatch(getOneClientSuccess(response.client.data));
      }
    } catch (error: any) {
      dispatch(getOneClientFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Create client Action.
 * @param payload
 */
export const createClient = createAsyncThunk(
  'client/create',
  async (payload: IClientFormValues, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const { addClientRequest, addClientSuccess, addClientFailure } = clientActions;
    try {
      dispatch(addClientRequest());
      const result: IAPIEntityResponse<IUser> = await asyncErrorHandler(createOrUpdate(payload));
      if (result.error) {
        dispatch(addClientFailure());
        handleError({ message: result.error.message });
        return {
          result: false
        };
      } else {
        dispatch(addClientSuccess());
        showSuccessToast(result.message);
        dispatch(
          getAllClients({
            skip: store.clients.skip,
            limit: store.clients.limit,
            sort: {
              sortField: store.clients.sort ? store.clients.sort.sortField : '',
              sortOrder: store.clients.sort ? store.clients.sort.sortOrder : ''
            }
          })
        );

        return {
          result: true,
          userUUID: result.data.userUUID
        };
      }
    } catch (error: any) {
      dispatch(addClientFailure());
      handleError({ message: error.message });
      return {
        result: false
      };
    }
  }
);

/**
 * Update client Action.
 * @param payload
 */
export const updateClient = createAsyncThunk(
  'client/update',
  async (payload: IClientFormValues, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const { updateClientRequest, updateClientSuccess, updateClientFailure } = clientActions;
    try {
      dispatch(updateClientRequest());
      const result: IAPIEntityResponse<IUser> = await asyncErrorHandler(createOrUpdate(payload));
      if (result.error) {
        dispatch(updateClientFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateClientSuccess());
        showSuccessToast(result.message);
        dispatch(
          getAllClients({
            skip: store.clients.skip,
            limit: store.clients.limit,
            sort: {
              sortField: store.clients.sort.sortField,
              sortOrder: store.clients.sort.sortOrder
            }
          })
        );
      }
    } catch (error: any) {
      dispatch(updateClientFailure());
      handleError({ message: error.error.message });
    }
  }
);

/**
 * Delete client Action.
 * @param payload
 */
export const deleteClient = createAsyncThunk('client/delete', async (payload: any, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const store: any = getState();
  const { deleteClientRequest, deleteClientSuccess, deleteClientFailure } = clientActions;

  try {
    dispatch(deleteClientRequest());
    const { id } = payload;
    const result: IAPIEntityResponse<IUser> = await asyncErrorHandler(remove(id));
    if (result.error) {
      dispatch(deleteClientFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(deleteClientSuccess());
      showSuccessToast(result.message);
      dispatch(
        getAllClients({
          skip: store.clients.skip,
          limit: store.clients.limit,
          sort: {
            sortField: store.clients.sort ? store.clients.sort.sortField : '',
            sortOrder: store.clients.sort ? store.clients.sort.sortOrder : ''
          }
        })
      );
    }
  } catch (error: any) {
    dispatch(deleteClientFailure());
    handleError({ message: error.error ? error.error.message : 'Failed to delete!' });
  }
});

/**
 * Fetches total clients count.
 */
export const getTotalClientCountAction = createAsyncThunk('clients/count', async () => {
  return getTotalClientCount();
});

/**
 * Get all non license clients
 * @param payload
 */
export const getAllNonLicenseClients = createAsyncThunk(
  'client/all/nonLicense',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getAllNonLicenseClientsRequest,
      getAllNonLicenseClientsSuccess,
      getAllNonLicenseClientsFailure
    } = clientActions;

    try {
      dispatch(getAllNonLicenseClientsRequest());
      const response: PaginatedAPIEntityResponse<IUser> = await asyncErrorHandler(
        readNonLicenseClients(payload)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getAllNonLicenseClientsFailure());
      } else {
        dispatch(getAllNonLicenseClientsSuccess(response.data));
        return response;
      }
    } catch (error: any) {
      dispatch(getAllNonLicenseClientsFailure());
      handleError({ message: error.message });
    }
  }
);
