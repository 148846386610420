/*
 * FileRecordForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file FileRecordForm.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { InstaTextArea } from '@abstract/abstractwebcommon-client/FormControl/InstaTextArea';
import i18n from '../../../Services/I18n';
import { IFileRecord } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecord';
import {
  IFileRecordOtherProperties,
  IFileRecordMyFormProperties,
  IWarningMessage
} from '../../../Interfaces/FileRecord';
import { validators } from '@abstract/abstractwebcommon-shared/validators';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import InstaImageUpload, {
  FILE_UPLOAD_ERROR
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { Button } from 'react-bootstrap';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';

const FileRecordForm = (
  properties: IFileRecordOtherProperties & FormikProps<IFileRecord>
): JSX.Element => {
  const {
    handleSubmit,
    handleChange,
    handleDeleteFileRecord,
    hideDialog,
    handleBlur,
    touched,
    values,
    setFieldValue /**< To update field value */,
    errors,
    isLoading,
    fileRecord
  } = properties;

  const { t } = useTranslation();
  const onEditId: number = fileRecord && fileRecord.id;
  const [selectedIconFile, setSelectedIconFile] = useState<any>(null);
  const [isFileChanged, setIsFileChanged] = useState<boolean>(false); /**< To Check file change */
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [isShowingConfirmation, setIsShowingConfirmation] = useState<boolean>(false);
  const [isEntitlementDialog, toggleEntitlementDialog] = useState<boolean>(false);
  const [warningMessage, setWarningMessage] = useState<IWarningMessage[]>(); /**< Warning Message */

  /**
   * On file change
   * Detect filename and update value
   * @param event
   * @param string key: #file | #icon
   * @returns void
   */
  const onChangeFile = (event: any, key: string): void => {
    const file: any = document.querySelector(key);
    let filename = '';
    if (file && file.files[0] && file.files[0].name) {
      filename = file.files[0].name;
    }

    if (key === '#file') {
      // file
      values.originalFilename = filename;
    }

    handleChange(event);
  };

  /// Triggers on image upload. Sets the image with url
  const onUpload = (files: File[]) => {
    try {
      const file: File = files ? files[0] : null;

      setIsFileChanged(true);
      setFieldValue('croppedIconImage', file);
      setSelectedIconFile(file);
    } catch (error) {
      console.log(error);
    }
  };

  /// Dispatches deleteAPI to delete the icon
  const deleteImage = (): void => {
    setFieldValue('croppedIconImage', '');
    setFieldValue('icon', null);
  };

  const errorHandler = (error: any): void => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({ severity: 'error', summary: t('I18N.settings.no_image_uploaded') });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({ severity: 'error', summary: t('I18N.settings.upload_valid_image') });
    }
  };

  /// Gets the fileUpload component.
  const getFileUpload = (): JSX.Element => {
    return (
      <div className="bg-dark text-center px-4 py-2 primary-border-radius">
        <InstaImageUpload
          showLegend={false}
          imageUrl={(fileRecord && fileRecord.icon) || ''}
          showDelete={true}
          deleteHandler={deleteImage}
          imgContainerClass={'px-3'}
          onChange={onUpload}
          croppedImage={croppedImage}
          showUploadBtn={false}
          errorHandler={errorHandler}
          imgClass={'imageUrl rounded'}
          altText={t('I18N.settings.logo_alt')}
          isPlainBtn={true}
          plainBtnLabel={t('admin.page.files.form.choose_file_icon')}
        />
      </div>
    );
  };

  /// show delete popup
  const deleteButtonClick = (event: any) => {
    setIsShowingConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete file on Accept
  const onAccept = async (): Promise<void> => {
    const message: IWarningMessage[] = [];
    [fileRecord].forEach((eachFile: IFileRecord) => {
      if (eachFile.linkedEntitlements && eachFile.linkedEntitlements.length) {
        const entitlements: string[] = []; /**< Linked Entitlements */
        eachFile.linkedEntitlements.forEach((eachEntitlement: IEntitlement) => {
          entitlements.push(eachEntitlement.name);
        });
        message.push({
          id: eachFile.id,
          fileName: eachFile.name,
          linkedEntitlements: entitlements
        });
      }
    }); /**< Warning Message. */
    setWarningMessage(message);
    if (message && message.length > 0) {
      toggleEntitlementDialog(true);
    } else {
      onWarningAccepted();
    }
    setIsShowingConfirmation(false);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setIsShowingConfirmation(false);
    toggleEntitlementDialog(false);
  };

  const renderEntitlementWarningDialogFooter = (): JSX.Element => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => onReject()}
          autoFocus
          variant="secondary"
          className="d-flex align-items-center">
          <i className="bi bi-x-circle btn-icon"></i>
          {t('/confirm_messages.cancel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => onWarningAccepted()}
          autoFocus
          className="d-flex align-items-center m-0">
          <i className="bi bi-check2-circle btn-icon"></i>
          {t('admin.page.files.delete.confirm')}
        </Button>
      </div>
    );
  };

  // Dispatch delete action on warning accepted
  const onWarningAccepted = async () => {
    handleDeleteFileRecord([fileRecord.fileRecordUUID]);
    setIsShowingConfirmation(false);
    toggleEntitlementDialog(false);
    setWarningMessage([]);
    hideDialog();
  };

  return (
    <>
      <FormWrapper
        controlButtonLabel={fileRecord && Object.keys(fileRecord).length > 0}
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonClick(event)
        }
        handleSubmitButton={() => handleSubmit(values)}>
        <Row>
          {/* To browse and upload image */}
          <Col xs={12}>{getFileUpload()}</Col>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.files.form.name')}
              labelClassName="required"
              name="name"
              id={'name'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.name}
              errors={errors.name}
              value={values.name}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
          <InstaTextArea
            sizing={{ sm: 12, md: 12 }}
            label={t('admin.page.files.form.description')}
            labelClassName="required"
            fieldName="description"
            isLoading={isLoading}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.description}
            errors={errors.description}
            value={values.description}
            onKeyPress={(event: any) => {
              if (event.key === 'Enter') {
                handleSubmit(values);
              }
            }}
          />
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.files.form.website')}
              name="website"
              id={'website'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.website}
              errors={errors.website}
              value={values.website}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>

          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.files.form.group')}
              name="group"
              id="group"
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.group}
              errors={errors.group}
              value={values.group}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>

          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.files.form.platform')}
              name="platform"
              id={'platform'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.platform}
              errors={errors.platform}
              value={values.platform}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>

          {!onEditId && (
            <>
              <Form.Group as={Col} className="mb-3">
                <Form.Label className="required">{t('admin.page.files.form.mainFile')}</Form.Label>
                <Form.File
                  id="file"
                  name="file"
                  label={
                    values.originalFilename
                      ? values.originalFilename
                      : t('admin.page.files.form.choose_file')
                  }
                  className={touched.originalFilename && errors.originalFilename ? 'p-invalid' : ''}
                  onChange={(event: any) => onChangeFile(event, `#file`)}
                  custom
                  title=""
                />
                {touched.originalFilename && errors.originalFilename ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {t(errors.originalFilename)}
                  </small>
                ) : null}
              </Form.Group>
            </>
          )}

          {/* Crop image section */}
          <CropDialog
            isVisible={isFileChanged}
            setVisible={setIsFileChanged}
            file={selectedIconFile}
            onImageCropComplete={(image: any) => {
              setFieldValue('croppedIconImage', image);
              setCroppedImage(image);
              setSelectedIconFile(image);
            }}
            isIcon
          />
        </Row>

        <ConfirmationPopup
          target={confirmPopupTarget}
          isShow={isShowingConfirmation}
          title={t('/confirm_messages.delete_records')}
          onAccept={onAccept}
          onReject={onReject}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
          popupPosition="bottom"
        />
      </FormWrapper>

      <DialogWrapper
        isCustomClassName
        headerTitle={t('admin.page.files.delete.header')}
        isDialogVisible={isEntitlementDialog}
        footer={renderEntitlementWarningDialogFooter()}
        onHide={() => toggleEntitlementDialog(false)}>
        <Col className="mb-2 px-0">{t('admin.page.files.delete.delete_header')}</Col>
        <Col className="mb-2 px-0">
          {warningMessage && warningMessage.length > 0 && (
            <Col className="mb-2 px-0">
              <table className="w-100">
                <tr>
                  <th>File </th>
                  <th>{t('admin.page.files.delete.linkedEntitlements')}</th>
                </tr>
                {warningMessage.map((message: IWarningMessage) => (
                  <tr key={message.id}>
                    <td>{message.fileName}</td>
                    <td>
                      {message.linkedEntitlements
                        .map((entitlementName: string) => `${entitlementName}`)
                        .join(' ,')}
                    </td>
                  </tr>
                ))}
              </table>
            </Col>
          )}
        </Col>
        <Col className="px-0">{t('admin.page.files.delete.warningMessage')}</Col>
      </DialogWrapper>
    </>
  );
};

const FileRecord = withFormik<IFileRecordMyFormProperties, IFileRecord>({
  mapPropsToValues: (properties) => {
    const { fileRecord } = properties;
    if (fileRecord) {
      return {
        name: fileRecord.name,
        description: fileRecord.description,
        website: fileRecord.website,
        group: fileRecord.group,
        platform: fileRecord.platform,
        originalFilename: fileRecord.originalFilename,
        icon: fileRecord.icon
      };
    }
    return {
      name: properties.initialName || '',
      description: properties.initialDescription || '',
      website: properties.initialWebsite || '',
      group: properties.initialGroup || '',
      platform: properties.initialPlatform || '',
      originalFilename: properties.initialOriginalFilename || '',
      icon: properties.initialIconFilename || ''
    };
  },
  validationSchema: (properties: any) => {
    const isOnEdit = properties.fileRecord && properties.fileRecord.id ? true : false;
    const validationSpec = {
      name: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(100, i18n.t('validation.max', { field: '100' }))
        .required(i18n.t('validation.required', { field: i18n.t('admin.page.files.form.name') })),
      description: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(1000, i18n.t('validation.max', { field: '1000' }))
        .required(
          i18n.t('validation.required', { field: i18n.t('admin.page.files.form.description') })
        ),
      website: Yup.string()
        .matches(validators.WEBSITE, i18n.t('validation.url'))
        .max(500, i18n.t('validation.max', { field: '500' }))
        .notRequired()
        .nullable(),
      group: Yup.string()
        .max(255, i18n.t('validation.max', { field: '255' }))
        .nullable(),
      platform: Yup.string()
        .max(255, i18n.t('validation.max', { field: '255' }))
        .nullable(),
      originalFilename: isOnEdit
        ? Yup.string()
        : Yup.string().required(
            i18n.t('validation.required', { field: i18n.t('admin.page.files.form.mainFile') })
          )
    };

    return Yup.object(validationSpec);
  },
  handleSubmit: (values, bag) => {
    const iconFile: any = document.querySelector('#iconFile');
    if (bag.props.fileRecord) {
      let updatedFieldsCount: number = 0;
      Object.keys(values).forEach((key: string) => {
        if (
          values[key as keyof typeof bag.props.fileRecord] !==
          bag.props.fileRecord[key as keyof typeof bag.props.fileRecord]
        ) {
          updatedFieldsCount++;
        }
      });

      if (updatedFieldsCount > 0) {
        return bag.props.handleSubmit(
          {
            id: bag.props.fileRecord.id,
            fileRecordUUID: bag.props.fileRecord.fileRecordUUID,
            ...values
          },
          bag.resetForm
        );
      }
      return;
    }

    const file: any = document.querySelector('#file');
    let iconFileData: HTMLImageElement | File;

    if (values.croppedIconImage) {
      iconFileData = values.croppedIconImage;
    } else if (iconFile) {
      iconFileData = iconFile.files[0];
    }

    return bag.props.handleSubmit(
      {
        ...values,
        file: file.files[0],
        iconFile: iconFileData
      },
      bag.resetForm
    );
  }
})(FileRecordForm);

export default FileRecord;
