/*
 * NewsletterForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterForm.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { translate } from '../../../Utils/Translate';
import { useHistory, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewsletter,
  fetchAllNewsletterCustomLists,
  fetchNewsletter,
  updateNewsletter
} from '../../../Store/Newsletter';
import {
  ILicenseStateSelector,
  INewsletterStateSelector,
  ISettingsStateSelector,
  IStateSelectors,
  ITemplateStateSelector
} from '../../../Interfaces/Selectors';
import {
  INewsletter,
  INewsletterPlaceholder,
  NewsletterPlaceholderList
} from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import * as Yup from 'yup';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { InstaTextArea } from '@abstract/abstractwebcommon-client/FormControl/InstaTextArea';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/theme/dracula.css';
import 'codemirror/mode/xml/xml';
import './NewsletterForm.css';
import { NewsletterLicenseType } from '@abstract/abstractwebcommon-shared/enum/license/Newsletter';
import { Dropdown } from 'primereact/dropdown';
import { RouteName } from '../../../Utils/routesNames';
import TemplatePreview from '@abstract/abstractwebcommon-client/TemplateEditor/TemplatePreview';
import { getTemplates } from '../../../Store/Templates';
import { getSafeSettings } from '../../../Store/Settings';
import { templateTypes } from '@abstract/abstractwebcommon-shared/enum/license/template';
import PlaceholderComponent from '@abstract/abstractwebcommon-client/TemplateEditor/PlaceholderComponent';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const licenseBackendCSS = require('!css-loader!resolve-url-loader!sass-loader!../../../Scss/App.scss');

/**
 * @interface INewsletterFormProperties
 */
interface INewsletterFormProperties {
  newsletterUUID: string /**< newsletter UUID. */;
}

/**
 * NewsletterForm component.
 */
const NewsletterForm = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const newsletterState: INewsletterStateSelector = useSelector(
    (state: IStateSelectors) => state.newsletter
  ); /**< Newsletter state */
  const parameters: INewsletterFormProperties = useParams<INewsletterFormProperties>();
  const [newsletter, setNewsletter] = useState<INewsletter>(); /**< Newsletter */
  const history: any = useHistory();
  const codeMirrorOptions = {
    mode: 'text/html',
    theme: 'dracula',
    lineNumbers: true,
    lineWrapping: true
  }; /**< Code Mirror Options*/
  const licenseType: string[] = [
    NewsletterLicenseType.all,
    NewsletterLicenseType.active,
    NewsletterLicenseType.custom
  ]; /**< License type */
  const isLoading: boolean = newsletterState.isLoading;
  const isCreateSuccessful: boolean = newsletterState.isNewsletterCreatedSuccess;
  const isUpdateSuccessful: boolean = newsletterState.isNewsletterUpdatedSuccess;
  const isDeleteSuccessful: boolean = newsletterState.isNewsletterDeletedSuccess;
  const settings: ISettingsStateSelector = useSelector(
    (state: IStateSelectors) => state.settings
  ); /**< Setting state */
  const templates: ITemplateStateSelector = useSelector(
    (state: IStateSelectors) => state.templates
  ); /**< Template state */
  const [masterTemplate, setMasterTemplate] = useState<string>(''); /**< Master template */
  const [placeholders, setPlaceholders] =
    useState<INewsletterPlaceholder[]>(NewsletterPlaceholderList); /**< Newsletter Placeholders */
  const [isLayoutChanged, setLayoutChanged] = useState<any>(true); /**< Layout changes */
  const licenseState: ILicenseStateSelector = useSelector(
    (state: IStateSelectors) => state.licenses
  ); /**< License state. */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: newsletter?.title || '',
      description: newsletter?.description || '',
      newsletter: newsletter?.newsletter || '',
      licenseType: newsletter?.licenseType,
      customLicenseList: newsletter?.customLicenseList || null
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(2, translate('validation.min', { field: '2' }))
        .max(100, translate('validation.max', { field: '100' }))
        .required(
          translate('validation.required', {
            field: translate('I18N.admin.page.newsletter.form.title')
          })
        ),
      description: Yup.string()
        .min(2, translate('validation.min', { field: '2' }))
        .max(500, translate('validation.max', { field: '500' }))
        .required(
          translate('validation.required', {
            field: translate('I18N.admin.page.newsletter.form.description')
          })
        ),
      newsletter: Yup.string().required(
        translate('validation.required', {
          field: translate('I18N.admin.page.newsletter.form.newsletter')
        })
      ),
      licenseType: Yup.string().required(
        translate('validation.required', {
          field: translate('I18N.admin.page.newsletter.form.licenseType')
        })
      ),
      customLicenseList: Yup.object().when('licenseType', {
        is: (type) => type === NewsletterLicenseType.custom,
        then: Yup.object()
          .nullable()
          .required(
            translate('validation.required', {
              field: translate('I18N.admin.page.newsletter.form.customList')
            })
          ),
        otherwise: Yup.object().nullable()
      })
    }),
    onSubmit: (data: INewsletter) => {
      const payload: INewsletter = {};

      if (newsletter) {
        Object.keys(data).forEach((key: string, index: number) => {
          if (key === 'customLicenseList') {
            if (
              data[key]?.customListUUID !== formik.initialValues?.customLicenseList?.customListUUID
            ) {
              payload.customLicenseList = data[key];
            }
          } else if (
            data[key as keyof typeof formik.initialValues] !==
            formik.initialValues[key as keyof typeof formik.initialValues]
          ) {
            payload[key] = data[key as keyof typeof formik.initialValues];
          }
          if (index === Object.keys(data).length - 1) {
            if (payload && Object.keys(payload).length > 0) {
              handleSubmit({
                id: newsletter.id,
                newsletterUUID: newsletter.newsletterUUID,
                ...payload
              });
            }
          }
        });
      } else {
        handleSubmit(data);
      }
    }
  });

  /// Handle submit handler
  const handleSubmit = async (payload: INewsletter) => {
    if (payload.newsletterUUID) {
      await asyncErrorHandler(dispatch(updateNewsletter(payload)));
    } else {
      await asyncErrorHandler(dispatch(createNewsletter(payload)));
    }
  };

  /// Newsletter preview component
  const previewComponent = (): JSX.Element => {
    return (
      <div className="p-d-block overflow-hidden h-100 primary-border-radius">
        <Form.Label htmlFor="preview">
          {translate('I18N.admin.page.newsletter.form.preview')}
        </Form.Label>
        <TemplatePreview
          html={formik.values.newsletter}
          masterTemplate={masterTemplate}
          masterBodyPlaceholder={'{{message}}'}
          applicationCSS={licenseBackendCSS || ''}
          customCSSLink={
            (settings && settings.safeSettings && settings.safeSettings['customCssLink']) || ''
          }
          placeholders={placeholders}
          isEmail={true}
          className={`newsletter-preview`}
        />
      </div>
    );
  };

  /// Newsletter code mirror component
  const codeMirrorComponent = (): JSX.Element => {
    return (
      <div className="p-d-block overflow-hidden h-100 primary-border-radius">
        <Form.Label htmlFor="newsletter" className="required">
          {translate('I18N.admin.page.newsletter.form.newsletter')}
        </Form.Label>
        <CodeMirror
          value={formik.values.newsletter}
          options={codeMirrorOptions}
          onBlur={onCodeEditorBlurHandler}
          onBeforeChange={(editor: any, data: any, htmlOnChange: string) => {
            formik.setFieldValue('newsletter', htmlOnChange);
          }}
          className={`${
            formik.touched.newsletter && formik.errors.newsletter ? 'custom-html-invalid-field' : ''
          }`}
        />
      </div>
    );
  };

  /// Splitter Component
  const splitterComponent = (): JSX.Element => {
    return (
      <Col xs={12} className="pl-0 templates">
        <Splitter
          className={`${isLayoutChanged ? '' : 'vertical-splitter'}`}
          layout={`${isLayoutChanged ? 'horizontal' : 'vertical'}`}>
          <SplitterPanel
            className={`codemirror-splitterPanel ${
              isLayoutChanged ? '' : 'vertical-splitter-panel'
            }`}
            minSize={20}>
            {codeMirrorComponent()}
          </SplitterPanel>
          <SplitterPanel
            className={`${isLayoutChanged ? 'horizontal-splitter-panel' : ''}`}
            minSize={10}>
            {previewComponent()}
          </SplitterPanel>
        </Splitter>
        {formik.touched.newsletter && formik.errors.newsletter ? (
          <small id="html-invalid" className="p-invalid error-text">
            {formik.errors.newsletter}
          </small>
        ) : null}
      </Col>
    );
  };

  useEffect(() => {
    if (parameters.newsletterUUID) {
      dispatch(fetchNewsletter(parameters.newsletterUUID));
      localStorage.setItem(
        'edit_newsletterID',
        parameters.newsletterUUID
      ); /**< Add newsletterID to localstorage used to highlight sidebar menu */
      window.dispatchEvent(new Event('storage')); // Dispatch an event when changes to local storage.
    }
    dispatch(
      fetchAllNewsletterCustomLists({
        limit: 0,
        skip: 0,
        sort: {
          sortField: 'created',
          sortOrder: -1
        },
        searchTerm: ''
      })
    ); // To fetch all custom lists.
  }, []);

  useEffect(() => {
    if (parameters.newsletterUUID) {
      setNewsletter(newsletterState.newsletter);
    }
  }, [newsletterState.newsletter]);

  useEffect(() => {
    if (isCreateSuccessful || isUpdateSuccessful || isDeleteSuccessful) {
      history.push({ pathname: RouteName.adminNewsletterRoute });
    }
  }, [isCreateSuccessful, isUpdateSuccessful, isDeleteSuccessful]);

  const onCodeEditorBlurHandler = () => {
    formik.setTouched({ ...formik.touched, ['newsletter']: true });
  };

  useEffect(() => {
    if (!templates || !templates.list || !templates.list.template) {
      dispatch(getTemplates());
    }

    if (!settings || !settings.safeSettings) {
      dispatch(getSafeSettings());
    }
  }, [dispatch]);

  ///Set master template
  useEffect(() => {
    if (templates && templates.list && templates.list.template && templates.list.template.length) {
      setMasterTemplate(
        templates.list.template.find(
          (template: any) => template.type === templateTypes.masterTemplate
        )?.html
      );
    }
  }, [templates.list.template]);

  /// To update placeholder value.
  const toUpdatePlaceholder = (key: INewsletterPlaceholder, placeholder: string) => {
    const duplicatePlaceholders: INewsletterPlaceholder[] = [...placeholders];
    const placeholderIndex: number = duplicatePlaceholders.findIndex(
      (item: INewsletterPlaceholder) => item.placeholderKey === key.placeholderKey
    );
    duplicatePlaceholders[placeholderIndex] = {
      ...duplicatePlaceholders[placeholderIndex],
      placeholderValue: placeholder
    };
    setPlaceholders(duplicatePlaceholders);
  };

  /// Copies a placeholder.
  const toCopyPlaceholder = (item: string) => {
    navigator.clipboard.writeText(`{{${item}}}`);
    showSuccessToast(translate('I18N.admin.template_editor.copy_success'));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Form.Group as={Col} xs={12} className="pl-0">
            <InstaInputText
              label={translate('I18N.admin.page.newsletter.form.title')}
              labelClassName="required"
              name="title"
              id={'title'}
              isLoading={isLoading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.title}
              errors={formik.errors.title}
              value={formik.values.title}
            />
          </Form.Group>
          <InstaTextArea
            sizing={{ xs: 12 }}
            label={translate('I18N.admin.page.newsletter.form.description')}
            labelClassName="required"
            fieldName="description"
            name="description"
            id={'description'}
            isLoading={isLoading}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.description}
            errors={formik.errors.description}
            value={formik.values.description}
            rows={1}
            formClassName="pl-0"
          />
          <Col xs={12} className="pl-0">
            <div className="p-d-block">
              <Form.Label htmlFor="licenseType" className="required">
                {translate('I18N.admin.page.newsletter.form.licenseType')}
              </Form.Label>
              <div className="d-flex mb-2 align-items-center">
                <Dropdown
                  value={formik.values.licenseType}
                  name="licenseType"
                  options={licenseType}
                  placeholder={translate('I18N.admin.page.newsletter.form.licensetypePlaceholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required={true}
                  className={`newsletter-dropdown ${
                    formik.touched.licenseType && formik.errors.licenseType ? 'p-invalid' : ''
                  }`}
                  disabled={isLoading}
                />
              </div>
              {formik.touched.licenseType && formik.errors.licenseType ? (
                <small id="html-invalid" className="p-invalid error-text">
                  {formik.errors.licenseType}
                </small>
              ) : null}
            </div>
          </Col>
          {formik.values.licenseType === NewsletterLicenseType.custom ? (
            <Col xs={12} className="pl-0">
              <div className="p-d-block">
                <Form.Label htmlFor="customLicenseList" className="required">
                  {translate('I18N.admin.page.newsletter.form.customList')}
                </Form.Label>
                <div className="d-flex mb-2 align-items-center">
                  <Dropdown
                    value={formik.values.customLicenseList}
                    name="customLicenseList"
                    dataKey="id"
                    optionLabel="customListName"
                    options={
                      newsletterState.newsletterCustomList &&
                      newsletterState.newsletterCustomList.records
                    }
                    placeholder={translate('I18N.admin.page.newsletter.form.customListPlaceholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required={true}
                    className={`newsletter-dropdown ${
                      formik.touched.customLicenseList && formik.errors.customLicenseList
                        ? 'p-invalid'
                        : ''
                    }`}
                    disabled={isLoading || !newsletterState.newsletterCustomList}
                  />
                </div>
                {formik.values.customLicenseList ? (
                  <small>
                    {translate('I18N.admin.page.newsletter.form.contact_count', {
                      customListName: `${formik.values.customLicenseList.customListName}`,
                      customLicensesCount: `${formik.values.customLicenseList.customLicensesCount}`,
                      licenseCount: `${licenseState.licenseCount}`
                    })}
                  </small>
                ) : null}
                {formik.touched.customLicenseList && formik.errors.customLicenseList ? (
                  <small id="html-invalid" className="p-invalid error-text">
                    {formik.errors.customLicenseList}
                  </small>
                ) : null}
              </div>
            </Col>
          ) : (
            <></>
          )}
          <Col xs={12} className="pl-0 d-flex">
            <Button
              className="mr-2 custom-button-height text-center p-button p-button-primary"
              onClick={() => setLayoutChanged(!isLayoutChanged)}>
              {translate('awc:/.templateEditor.template.changeLayout')}
            </Button>
            <Button
              className="custom-button-height p-button p-button-primary"
              onClick={() => formik.handleSubmit()}>
              <i className="bi bi-check2-circle mr-2"></i>
              {newsletter && Object.keys(newsletter).length > 0
                ? translate('awc:/.button.update')
                : translate('awc:/.button.save')}
            </Button>
          </Col>
          {splitterComponent()}
          <Col xs={12} className="pl-0">
            <div className="p-d-block">
              <PlaceholderComponent
                placeholders={placeholders}
                templateType={formik.values.title}
                updatePlaceholderHandler={toUpdatePlaceholder}
                copyPlaceholderHandler={toCopyPlaceholder}
              />
            </div>
          </Col>
        </>
      )}
    </>
  );
};

export default NewsletterForm;
