/*
 * LimitRatio.tsx
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file LimitRatio.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement } from 'react';

/**
 * @interface ILimitRatio
 */
interface ILimitRatio {
  id: string; /**< Element Id */
  allowedLimit: number | string; /**< Allowed Limit */
  className?: string; /**< Classname */
}

const LimitRatio = (properties: ILimitRatio): ReactElement => {
  return(
    <span className="float-right">
      <small
        className = {properties.className}
        id={properties.id}>
        0
      </small>
      <small
       className = {properties.className}>
        /{properties.allowedLimit}
      </small>
    </span>
  )
}

export default LimitRatio;