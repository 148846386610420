/**
* CommentForm.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file CommentForm.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { Checkbox } from 'primereact/checkbox';
import { IReport, IComment } from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import { translate } from '../../../Utils/Translate';
import { FileWithPath } from 'react-dropzone';
import { ReportStatus } from '@abstract/abstractwebcommon-shared/enum/license/report';
import Avatar from '@abstract/abstractwebcommon-client/Avatar';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import FileUpload, { removeSVGFile } from '@abstract/abstractwebcommon-client/FileUpload';
import { maximumFileSize } from '../../../config';
import { ISettingsStateSelector } from '../../../Interfaces/Selectors';
import { removeDuplicatesOnArrayOfObject } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import MDEditor from '@uiw/react-md-editor';
import './CommentForm.css';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';

/**
 * @interface ICommentFormProperties
 */
interface ICommentFormProperties {
  report: IReport /**< report object. */;
  isLoading: boolean /**< True if loading and false otherwise.*/;
  isCreationSuccessful: boolean /**< True if created successfully and false otherwise.*/;
  handleSubmit: (payload: IReport) => void /**< handle comment submit function*/;
  auth: IUser /** authenticated user information */;
  isSupportRole: boolean /** True if use have support role and false otherwise.*/;
  settings: ISettingsStateSelector /** Application system settings */;
  isCreationLoading: boolean /**< True if created loding and false otherwise.*/;
}

/**
 * Comment form component.
 */
const CommentForm = (properties: ICommentFormProperties): JSX.Element => {
  const report: IReport = properties.report;
  const themeMode: string = LocalStorage.getThemeMode() || ThemeMode.lightMode; /**< Theme Mode */

  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      comment: '',
      isInternalComment: false
    },
    validationSchema: () => {
      const shapes = {};

      if (uploadedFiles.length) {
        // No comment is required when uploading the file
        shapes['comment'] = Yup.string();
      } else {
        // Comment is required if the file is not uploaded
        shapes['comment'] = Yup.string()
          .min(1, translate('validation.min', { field: '1' }))
          .required(
            translate('validation.required', {
              field: translate('client.page.reports.table.comment')
            })
          );
      }
      shapes['isInternalComment'] = Yup.boolean();

      return Yup.object().shape(shapes);
    },
    onSubmit: (data: IComment) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          payload.report = { id: report.id };
          if (uploadedFiles.length) {
            payload.report.files = uploadedFiles;
          }
          if (payload.status === ReportStatus.done) {
            payload.report.status = payload.status;
          }
          properties.handleSubmit(payload);
        }
      });
    }
  });

  const handleClick = (event) => {
    if (event.currentTarget.name === 'saveAndDone') {
      formik.setFieldValue('status', ReportStatus.done);
    }
    formik.handleSubmit();
  };

  const removeFiles = () => {
    uploadedFiles.length = 0;
    uploadedFiles.splice(0, uploadedFiles.length);
  };

  const getUploadedFiles = (file: FileWithPath[]) => {
    setUploadedFiles(file);
  };

  /// Drag & dropped files.
  const getDroppedFiles = (files: FileWithPath[]) => {
    files = removeSVGFile(files); // To remove svg file
    const formattedFiles: FileWithPath[] = files.map((eachFile: FileWithPath) => {
      Object.assign(eachFile, {
        path: eachFile.name
      });
      return eachFile;
    }); /**< Add path into files.*/

    const mergedFileArray: FileWithPath[] = removeDuplicatesOnArrayOfObject(
      [...uploadedFiles, ...formattedFiles],
      'name'
    );
    setUploadedFiles(mergedFileArray);
  };

  useEffect(() => {
    if (properties.isCreationSuccessful) {
      removeFiles();
      formik.resetForm();
    }
  }, [properties.isCreationSuccessful]);

  /// Gets the submit button or loader.
  const getSubmitButton = (): JSX.Element => {
    if (properties.isCreationLoading) {
      return <Loader />;
    }
    return (
      <div className="justify-content-between d-sm-flex">
        <Button
          name="save"
          type="submit"
          onClick={() => formik.handleSubmit()}
          disabled={properties.isCreationLoading}
          className="d-flex align-items-center mr-2 mr-sm-0 mt-2 mt-sm-0 justify-content-center custom-comment-form-button">
          {translate('client.page.reports.form.send')}
        </Button>
        {(properties.isSupportRole || properties.auth.isAdmin) && (
          <Button
            name="saveAndDone"
            type="button"
            onClick={(event) => handleClick(event)}
            disabled={properties.isCreationLoading}
            className="d-flex align-items-center ml-sm-2 p-button-secondary mr-2 mt-2 mt-sm-0 justify-content-center custom-comment-form-button">
            {translate('client.page.reports.form.sendAndDone')}
          </Button>
        )}
      </div>
    );
  };

  return (
    report !== null && (
      <div>
        <div className="d-flex justify-content-start">
          {properties.auth && (
            <Avatar
              user={properties.auth}
              imageUrl={properties.auth.imageUrl}
              isShowTooltipForDeletedUser
            />
          )}
          <div className="ml-3 custom-comment-box-field-container w-100">
            <div data-color-mode={themeMode === ThemeMode.lightMode ? 'light' : 'dark'}>
              <MDEditor
                id="comment"
                height={200}
                value={formik.values.comment}
                onChange={(value: string) => formik.setFieldValue('comment', value)}
                onBlur={formik.handleBlur}
                onDrop={(event) => {
                  const files: File[] = Array.from(event.dataTransfer.files);
                  getDroppedFiles(files);
                }}
                className={
                  formik.touched.comment && formik.errors.comment && !uploadedFiles.length
                    ? 'p-invalid'
                    : ''
                }
                textareaProps={{
                  placeholder: translate('client.page.reports.form.addComment')
                }}
                visibleDragbar={true}
                commandsFilter={(command) =>
                  command && /(image|title|checked-list|table|hr)/.test(command.name)
                    ? false
                    : command
                }
              />
              {formik.touched.comment && formik.errors.comment && !uploadedFiles.length ? (
                <small id="comment-invalid" className="p-invalid">
                  {formik.errors.comment}
                </small>
              ) : null}
            </div>
            {(properties.isSupportRole || properties.auth.isAdmin) && (
              <div className="mt-2 d-flex align-items-center">
                <Checkbox
                  inputId="IsInternalComment"
                  value={formik.values.isInternalComment}
                  checked={formik.values.isInternalComment}
                  onChange={(event: any) =>
                    formik.setFieldValue('isInternalComment', event.checked)
                  }
                />
                <label htmlFor="IsInternalComment" className="mb-0 pl-2">
                  {translate('client.page.reports.form.internalComment')}
                </label>
              </div>
            )}
            <div className="mt-2 comment-button">
              <div>
                <FileUpload
                  maximumTotalUploadSize={maximumFileSize}
                  getUploadedFiles={getUploadedFiles}
                  droppedFiles={uploadedFiles}
                  getSubmitButton={getSubmitButton()}
                  isFileSizeLimitVisible={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex">
          {formik.status && formik.status.message !== undefined ? (
            <small className="p-invalid">{formik.status.message}</small>
          ) : null}
        </div>
      </div>
    )
  );
};

export default CommentForm;
