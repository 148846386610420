/**
 * Pagination.ts (InstaLOD GmbH)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file Pagination.ts
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * Map sorting fields to object with sort key and direction
 *
 * mapSortingFields
 * @param string sort
 * @param string sortOrder
 * @returns string sorting remapped and stringified for get apis
 */
export const mapSortingFields = (sort: string, sortOrder: string): string => {
  let sortAsObject: any = {};
  if (sort) {
    sortAsObject = {
      [sort]: sortOrder
    };
  }

  return encodeURIComponent(JSON.stringify(sortAsObject));
};

/**
 * Remap filters to standard LIKE mapping
 * Special case for in fields
 *
 * mapFilterFields
 * @param object originalFilters
 * @param string[] specialInFields
 * @returns string reformatted filter object
 */
export const mapFilterFields = (originalFilters: any, specialInFields: string[]): string => {
  const filter: any[] = [];
  const originalFiltersKeys: string[] = Object.keys(originalFilters);
  originalFiltersKeys.forEach((eachFilter: any) => {
    filter.push({
      column: eachFilter,
      operator: specialInFields && specialInFields.indexOf(eachFilter) !== -1 ? 'IN' : 'LIKE',
      value: originalFilters[eachFilter]
    });
  });

  return encodeURIComponent(JSON.stringify(filter));
};
