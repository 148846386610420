/*
 * NewsletterTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterTable.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { Column } from 'primereact/column';
import React, { ReactElement, useRef, useState } from 'react';
import {
  IPageEvent,
  IPaginationResponse,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { INewsletter } from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { translate } from '../../../Utils/Translate';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { Button } from 'react-bootstrap';
import { NewsletterSendStatus } from '@abstract/abstractwebcommon-shared/enum/license/Newsletter';
import './NewsletterForm.css';

/**
 * @interface INewsletterTableProperties
 */
interface INewsletterTableProperties {
  onSort: (event: ISortEvent) => void /**< Handle sort update. */;
  onPage: (event: IPageEvent) => void /**< Handle page update. */;
  onFilter: (searchTerm: string) => void /**< Handle filter update. */;
  tablePayload: ITablePayload /**  Table payload. */;
  newsletterList: IPaginationResponse<INewsletter> | null /**< newsletter list. */;
  isLoading: boolean /**< True if loading and false otherwise.*/;
  handleAddOrEditNewsletter: (newsletterUUID?: string) => void /**< Add or Edit newsletter. */;
  handleDeleteNewsletter: (newsletterUUIDs: string[]) => void /**< Delete newsletter(s). */;
  handleCopyNewsletter: (newsletterUUIDs: string[]) => void /**< Copy newsletter(s). */;
  handleSendNewsletter: (newsletter: INewsletter) => void /**< Send Newsletter to license users. */;
  handleSendNewsletterPreview: (
    newsletterUUID: string
  ) => void /**< Send Newsletter preview to loggedin user. */;
  isNewsletterSending: boolean /**<  True if sending and false otherwise. */;
}

/**
 * Newsletter Table component.
 */
const NewsletterTable = (properties: INewsletterTableProperties): ReactElement => {
  const newsletterList: IPaginationResponse<INewsletter> = properties.newsletterList;
  const tablePayload: ITablePayload = properties.tablePayload;
  const [expandedRows, setExpandedRows] = useState<INewsletter>({});
  const [selectedNewsletters, setSelectedNewsletters] =
    useState<INewsletter[]>(null); /**< Selected Newsletters. */
  const deleteButtonReference: any = useRef(null); /**< Delete Button Reference. */
  const [isShowDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false); /**< Show delete Confirmation Popup. */
  const [deleteConfirmPopupTarget, setDeleteConfirmPopupTarget] =
    useState<any>(null); /**< Delete ConfirmationPopup Target. */
  const [sendConfirmPopupTarget, setSendConfirmPopupTarget] =
    useState<any>(null); /**< Send ConfirmationPopup Target. */
  const [isShowSendConfirmation, setShowSendConfirmation] =
    useState<boolean>(false); /**< Show send Confirmation Popup. */
  const [selectedNewsletter, setSelectedNewsletter] =
    useState<INewsletter>(null); /**< Selected Newsletter. */
  const [selectedNewsletterTitle, setSelectedNewsletterTitle] =
    useState<string>(null); /**< Selected Newsletter title. */

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.newsletterUUID]: true });
    }
  };

  /// Handles selection change event
  const onSelectionChange = (event: any) => {
    const rows: INewsletter[] = event.value;
    if (Array.isArray(rows)) {
      const selectedRows: INewsletter[] = rows.map((row: INewsletter) => {
        return row;
      });
      setSelectedNewsletters(selectedRows);
    }
  };

  /// Show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowDeleteConfirmation(true);
    setDeleteConfirmPopupTarget(event.target);
  };

  /// Copy button clicked handler
  const copyButtonClicked = () => {
    const newsletterUUIDs: string[] = selectedNewsletters?.map(
      (eachNewsletter: INewsletter) => eachNewsletter.newsletterUUID
    ); /**< newsletterUUIDs */
    properties.handleCopyNewsletter(newsletterUUIDs);
    setSelectedNewsletters(null);
  };

  /// Delete Newsletter(s) on Accept
  const onDeleteAccept = async () => {
    const newsletterUUIDs: string[] = selectedNewsletters?.map(
      (eachNewsletter: INewsletter) => eachNewsletter.newsletterUUID
    ); /**< newsletterUUIDs */
    properties.handleDeleteNewsletter(newsletterUUIDs);
    setShowDeleteConfirmation(false);
    setSelectedNewsletters(null);
  };

  /// Hide confirmation on reject
  const onDeleteReject = () => {
    setShowDeleteConfirmation(false);
  };

  /// Confirmation Popup
  const getConfirmationPopup = () => {
    return (
      <ConfirmationPopup
        target={deleteConfirmPopupTarget}
        isShow={isShowDeleteConfirmation}
        title={translate('/confirm_messages.delete_records')}
        onAccept={onDeleteAccept}
        onReject={onDeleteReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translate('/confirm_messages.no')}
        acceptLabel={translate('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };

  const isButtonDisabled: boolean =
    ((selectedNewsletters && Object.keys(selectedNewsletters).length === 0) ||
      !selectedNewsletters) ??
    false;

  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center row-direction wrap-header">
      <div className="headerTableContainer margin-bottom-on-wrap">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={isButtonDisabled}
          buttonReference={deleteButtonReference}
        />{' '}
        {/* To delete Newsletters. */}
        <ActionButton onClick={() => properties.handleAddOrEditNewsletter()} />{' '}
        {/* To Add Newsletter. */}
        <Button
          onClick={copyButtonClicked}
          disabled={isButtonDisabled}
          className="d-none d-sm-flex align-items-center mr-2 button-without-icon">
          {translate('I18N.admin.page.newsletter.table.copy')}
        </Button>
      </div>
      <div className="d-flex">
        <Button
          onClick={copyButtonClicked}
          disabled={isButtonDisabled}
          className="d-flex d-sm-none align-items-center mr-2 button-without-icon">
          {translate('I18N.admin.page.newsletter.table.copy')}
        </Button>
        <SearchBar onSearchTermChanged={(data: string) => properties.onFilter(data)} />
      </div>
    </div>
  );

  const NewsletterRowExpansionTemplate = (values: { rowData: INewsletter }): JSX.Element => {
    return (
      <>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.title')}</th>
          <td>{values.rowData['title']}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.description')}</th>
          <td>{values.rowData['description']}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.licenseType')}</th>
          <td>{values.rowData['licenseType']}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.customList')}</th>
          <td>{values.rowData?.customLicenseList?.customListName}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.isSent')}</th>
          <td>{<ShowCheckOrUncheckIcon value={values.rowData['isSent']} />}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.sendStatus')}</th>
          <td>{values.rowData['sendStatus']}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.createdBy')}</th>
          <td>{values.rowData['createdBy']}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.updatedBy')}</th>
          <td>{values.rowData['updatedBy']}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.created')}</th>
          <td>{values.rowData.created ? formatDate(values.rowData.created) : ''}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.updated')}</th>
          <td>{values.rowData.updated ? formatDate(values.rowData.updated) : ''}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.sentDate')}</th>
          <td>{values.rowData.sentDate ? formatDate(values.rowData.sentDate) : ''}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.totalCount')}</th>
          <td>{values.rowData.totalLicensesCount}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.sentCount')}</th>
          <td>{values.rowData['sentCount']}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletter.table.openedCount')}</th>
          <td>{values.rowData['openedCount']}</td>
        </tr>
      </>
    );
  };

  /// Show send popup
  const sendButtonClicked = (event: any) => {
    setShowSendConfirmation(true);
    setSendConfirmPopupTarget(event.target);
  };

  /// Send Newsletter on Accept
  const onSendAccept = async () => {
    properties.handleSendNewsletter(selectedNewsletter);
    setShowSendConfirmation(false);
  };

  /// Hide confirmation on reject
  const onSendReject = () => {
    setShowSendConfirmation(false);
  };

  /// To render expansion rows
  const renderExpansionRows = (rowData: INewsletter) => (
    <>
      <ExpansionRow>
        <NewsletterRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <NewsletterRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Get send confirmation popup
  const getSendConfirmationPopup = () => {
    return (
      <ConfirmationPopup
        target={sendConfirmPopupTarget}
        isShow={isShowSendConfirmation}
        title={translate('/confirm_messages.send_newsletter', {
          field: selectedNewsletterTitle
        })}
        onAccept={onSendAccept}
        onReject={onSendReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translate('/confirm_messages.no')}
        acceptLabel={translate('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  const getDataTable = () => {
    return (
      <BaseDatatable
        value={newsletterList && newsletterList.records}
        totalRecords={newsletterList && newsletterList.totalRecords}
        isLoading={!newsletterList}
        header={header}
        parentClass="newslettersDataTable" /**< ClassName for div Component.*/
        first={tablePayload.skip}
        emptyMessage={translate('I18N.admin.page.newsletter.table.empty_msg')}
        rows={tablePayload.limit}
        onPage={properties.onPage}
        sortField={tablePayload.sort.sortField}
        onSort={properties.onSort}
        sortOrder={tablePayload.sort.sortOrder}
        onFilter={properties.onFilter}
        rowExpansionTemplate={renderExpansionRows}
        bodyStyle={'text-center'}
        responsive
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        expandedRows={expandedRows}
        dataKey="newsletterUUID"
        selection={selectedNewsletters}
        onSelectionChange={(event: any) => onSelectionChange(event)}>
        <Column expander className="p-0 col-width-3" headerClassName="p-0 col-width-3" />
        <Column selectionMode="multiple" className="col-width-3" />
        <Column
          sortable
          field="title"
          body={(rowData: INewsletter) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletter.table.title')}
              data={rowData.title}
            />
          )}
          header={translate('I18N.admin.page.newsletter.table.title')}
        />
        <Column
          sortable
          field="description"
          body={(rowData: INewsletter) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletter.table.description')}
              data={rowData.description}
            />
          )}
          header={translate('I18N.admin.page.newsletter.table.description')}
          className="d-table-cell d-sm-none d-xxl-table-cell"
          headerClassName="d-table-cell d-sm-none d-xxl-table-cell"
        />
        <Column
          sortable
          field="licenseType"
          body={(rowData: INewsletter) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletter.table.licenseType')}
              data={rowData.licenseType}
            />
          )}
          header={translate('I18N.admin.page.newsletter.table.licenseType')}
          className="d-table-cell d-sm-none d-md-table-cell col-width-7"
        />
        <Column
          sortable
          field="customListName"
          body={(rowData: INewsletter) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletter.table.customList')}
              data={rowData?.customLicenseList?.customListName}
            />
          )}
          header={translate('I18N.admin.page.newsletter.table.customList')}
          className="d-table-cell d-sm-none d-xl-table-cell col-width-10"
        />
        <Column
          field="totalLicensesCount"
          sortable
          body={(rowData: INewsletter) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletter.table.totalCount')}
              data={rowData.totalLicensesCount}
            />
          )}
          header={translate('I18N.admin.page.newsletter.table.totalCount')}
          className="d-table-cell d-sm-none d-xl-table-cell createdDateCol"
        />
        <Column
          field="createdBy"
          body={(rowData: INewsletter) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletter.table.createdBy')}
              data={rowData.createdBy}
            />
          )}
          header={translate('I18N.admin.page.newsletter.table.createdBy')}
          className="d-table-cell d-sm-none d-xxl-table-cell col-width-12"
        />
        <Column
          sortable
          field="created"
          body={(rowData: INewsletter) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletter.table.created')}
              data={formatDate(rowData.created)}
            />
          )}
          header={translate('I18N.admin.page.newsletter.table.created')}
          className="d-table-cell d-sm-none newsletter-created-date-column createdDateCol"
          headerClassName="d-table-cell d-sm-none newsletter-created-date-column createdDateCol"
        />
        <Column
          field="preview"
          className="newsletter-button-head-container"
          headerClassName="newsletter-button-head-container"
          body={(rowData: INewsletter) => {
            return (
              <div className="d-flex justify-content-center preview-button-container">
                <Button
                  onClick={() => {
                    properties.handleSendNewsletterPreview(rowData.newsletterUUID);
                  }}
                  className="d-none d-sm-block">
                  {translate('I18N.admin.page.newsletter.table.preview')}
                </Button>

                <Button
                  onClick={() => {
                    properties.handleSendNewsletterPreview(rowData.newsletterUUID);
                  }}
                  variant="outline"
                  className="custom-action-column-action-position d-block d-sm-none position-absolute-mr-60">
                  <i className="bi bi-binoculars editIcon fa-lg text-primary"></i>
                </Button>
              </div>
            );
          }}
        />
        <Column
          field="send"
          className="newsletter-button-head-container"
          headerClassName="newsletter-button-head-container"
          body={(rowData: INewsletter) => {
            return (
              <div className="d-flex justify-content-center send-button-container">
                <Button
                  onClick={(event: any) => {
                    setSelectedNewsletter(rowData);
                    setSelectedNewsletterTitle(rowData.title);
                    sendButtonClicked(event);
                  }}
                  disabled={
                    rowData['sendStatus'] === NewsletterSendStatus.inProgress ||
                    rowData['sendStatus'] === NewsletterSendStatus.done
                      ? true
                      : false
                  }
                  className={`d-none d-sm-block ${
                    rowData['sendStatus'] === NewsletterSendStatus.inProgress ||
                    rowData['sendStatus'] === NewsletterSendStatus.done
                      ? 'custom-disabled-button'
                      : ''
                  }`}>
                  {rowData['sendStatus'] === NewsletterSendStatus.inProgress
                    ? translate('I18N.admin.page.newsletter.table.sending')
                    : rowData['sendStatus'] === NewsletterSendStatus.done
                    ? translate('I18N.admin.page.newsletter.table.sent')
                    : translate('I18N.admin.page.newsletter.table.send')}
                </Button>
                <Button
                  onClick={(event: any) => {
                    setSelectedNewsletter(rowData);
                    setSelectedNewsletterTitle(rowData.title);
                    sendButtonClicked(event);
                  }}
                  variant="outline"
                  disabled={
                    rowData['sendStatus'] === NewsletterSendStatus.inProgress ||
                    rowData['sendStatus'] === NewsletterSendStatus.done
                      ? true
                      : false
                  }
                  className="custom-action-column-action-position d-block d-sm-none position-absolute-mr-30">
                  <i
                    className={`${
                      rowData['sendStatus'] === NewsletterSendStatus.inProgress ||
                      rowData['sendStatus'] === NewsletterSendStatus.done
                        ? 'bi bi-send editIcon fa-lg custom-disabled-icon'
                        : 'bi bi-send editIcon fa-lg'
                    }`}></i>
                </Button>
              </div>
            );
          }}
        />
        <Column
          field="edit"
          body={(rowData: INewsletter) => {
            return (
              <Button
                className="custom-action-column-action-position"
                onClick={() => properties.handleAddOrEditNewsletter(rowData.newsletterUUID)}
                variant="outline">
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Button>
            );
          }}
          className="p-0 col-width-45 absolute-position-responsive-screen"
        />
      </BaseDatatable>
    );
  };

  return (
    <>
      {getDataTable()}
      {getSendConfirmationPopup()}
      {getConfirmationPopup()}
    </>
  );
};

export default NewsletterTable;
