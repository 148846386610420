/**
* ClientDashboardSkeletonDesktopView.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained Rafael Rodrigues K, 2022 
* @file ClientDashboardSkeletonDesktopView.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React from 'react'

import SkeletonWrapper from '../../SkeletonWrapper'

/**
 * Render a skeleton for Dashboard component when data is loading for it for desktop screen.
 * 
 * @returns JSX.Element
 */
const ClientDashboardSkeletonDesktopView = (): JSX.Element => {
  return (
    <div className="w-100">
      <SkeletonWrapper height="50px" className="mb-5" />

      <div className="border rounded mb-5 w-100 mr-4 p-4">
        <SkeletonWrapper height="40px" width="150px" className="mb-4" />
        <SkeletonWrapper height="50px" className="mb-2 w-100" />
      </div>

      <div className="border rounded mb-5 w-100 mr-4 p-4">
        <SkeletonWrapper height="40px" width="150px" className="mb-4" />
        <SkeletonWrapper height="50px" className="mb-2 w-100" />
      </div>

      <div className="border rounded mb-5 w-100 mb-2 p-4">
        <h1><SkeletonWrapper /></h1>
        <h1><SkeletonWrapper /></h1>
        <h1><SkeletonWrapper /></h1>
      </div>

      <div className="border rounded mb-5 w-100 mr-4 p-4">
        <SkeletonWrapper height="40px" width="150px" className="mb-4" />

        <div className="border rounded p-3 w-100">
          <SkeletonWrapper height="40px" className="mb-2 w-100" />
          <SkeletonWrapper height="80px" className="w-100" />
        </div>

        <div className="border rounded p-3 w-100 mt-3">
          <SkeletonWrapper height="40px" className="mb-2 w-100" />
          <SkeletonWrapper height="80px" className="w-100" />
        </div>
      </div>
    </div>
  )
}

export default ClientDashboardSkeletonDesktopView