/*
 * SettingForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file SettingForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import SettingSMTPForm from './SettingSMTPForm';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import SettingApplicationForm from './SettingApplicationForm';
import { getUsersFromUserAPIAction, processNewPurchases } from '../../../Store/Settings';
import { ILicenseStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import EnvironmentVariablePopup from '@abstract/abstractwebcommon-client/EnvironmentVariablePopup';
import { IPListForm } from '@abstract/abstractwebcommon-client/IPList/IPListForm';
import StaticLinkForm from '@abstract/abstractwebcommon-client/StaticLinks/StaticLinksForm';
import { IStaticLink } from '@abstract/abstractwebcommon-shared/interfaces/staticLinks';
import './SettingForm.css';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import Button from 'react-bootstrap/Button';
import { ImageUploadAction } from './SettingPage';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import Spinner from 'react-bootstrap/Spinner';

interface ISettingOtherProperties {
  handleSubmit: (data: any) => void /**< Handle submit settings changes */;
  handleFavouriteIconUpload: (file: any) => void /**< Handle upload favicon image */;
  handleFavouriteIconDelete: (event: any) => void /**< Handle delete favicon image */;
  displayCroppedFavouriteIcon: any /**< Display favicon cropped image */;
  handleLogoUpload: (file: any) => void /**< Handle upload logo image */;
  handleLogoDelete: (event: any) => void /**< Handle delete logo image */;
  displayCroppedLogo: any /**< Display logo cropped image */;
  fetchLicenseDropdown: (filter: string) => void /**< Handle fetch license dropdown */;
  isLoading?: boolean /**< True if loading, false otherwise */;
  settingsState?: any /**< Settings state */;
  staticLinksState?: any /**< Static Links state */;
  CSSTemplates?: any /**< CSS templates */;
  handleTestSmtp?: any /**< Handle test smtp */;
  handleStaticLinkUpdate: (
    staticLink: any,
    staticLinkUUID: string | null
  ) => Promise<void> /**< Handle static link update */;
  handleStaticLinkDelete: (
    staticLinkUUIDs: IStaticLink[]
  ) => Promise<void> /**< Handle static link delete */;
  uploadStaticLinkIcon: (event: any) => Promise<void> /**< Handle upload static link icon */;
  deleteStaticLinkIcon: (event: any) => void /**< Handle delete static link icon */;
  displayCroppedStaticLinkIcon: any /**< Handle display cropped static link icon */;
  setCroppedStaticLinkIcon: React.Dispatch<
    React.SetStateAction<any>
  > /**< Set cropped staticlink icon. */;
  refreshSaticLinksList: (updatedCriteria?: ITablePayload) => void;
  isLoadingTestSMTP?: boolean /**< Loading state for test smtp */;
  logoUploadStatus?: ImageUploadAction /**< Logo upload Status */;
  favouriteIconUploadStatus?: ImageUploadAction /**< Favourite icon upload Status */;
}

const SettingForm = (properties: ISettingOtherProperties): JSX.Element => {
  const {
    handleSubmit,
    handleFavouriteIconUpload,
    handleFavouriteIconDelete,
    displayCroppedLogo,
    displayCroppedFavouriteIcon,
    handleLogoUpload,
    handleLogoDelete,
    isLoading,
    CSSTemplates,
    handleTestSmtp,
    settingsState,
    staticLinksState,
    fetchLicenseDropdown,
    handleStaticLinkUpdate,
    handleStaticLinkDelete,
    uploadStaticLinkIcon,
    deleteStaticLinkIcon,
    displayCroppedStaticLinkIcon,
    setCroppedStaticLinkIcon,
    refreshSaticLinksList,
    isLoadingTestSMTP,
    logoUploadStatus,
    favouriteIconUploadStatus
  } = properties;
  const { t } = useTranslation();
  const [isListenerAdded, setIsListenerAdded] = useState<boolean>(false);
  const [showEnvironmentVariable, setShowEnvironmentVariable] = useState<boolean>(false);
  const [showGetUsersFromUserAPIPopup, setGetUsersFromUserAPIPopup] = useState<boolean>(false);
  const licenseState: ILicenseStateSelector = useSelector(
    (state: IStateSelectors) => state.licenses
  );
  const allLicenseTemplates: any[] = licenseState.templates;
  const dispatch: any = useDispatch();
  const [isShowConfirmationPopup, setShowConfirmationPopup] =
    useState<boolean>(false); /**< To show Confirmation Popup. */
  const [ConfirmPopupTarget, setConfirmPopupTarget] =
    useState<any>(null); /**< ConfirmationPopup Target. */

  useEffect(() => {
    const licenseFilter: any = document.getElementById('licenseDropdown');
    if (licenseFilter && !isListenerAdded && allLicenseTemplates?.length > 0) {
      licenseFilter.addEventListener('keyup', (e: any) => {
        e.preventDefault();
        const licenseFilterInArray: any = document.getElementsByClassName(
          'p-dropdown-filter p-inputtext p-component'
        );

        if (licenseFilterInArray && licenseFilterInArray[0]) {
          const licenseFilterIn = licenseFilterInArray[0];
          const filter = licenseFilterIn.value;
          setIsListenerAdded(true);
          fetchLicenseDropdown(filter);
        }
      });
    }
  }, [allLicenseTemplates, fetchLicenseDropdown, isListenerAdded]);

  const hideEnvironmentVariablesDialog = (): void => {
    setShowEnvironmentVariable(false);
  };

  const getApplicationEnvironmentVariables = (): JSX.Element => {
    return (
      <EnvironmentVariablePopup
        environmentVariables={settingsState.environmentVariables}
        onClose={hideEnvironmentVariablesDialog}
      />
    );
  };

  const handleStaticLinkSubmit = (payload: any, staticLinkUUID: string | null): void => {
    handleStaticLinkUpdate(payload, staticLinkUUID);
  };

  /// On Accept handler
  const onAccept = async () => {
    setShowConfirmationPopup(false);
    await asyncErrorHandler(dispatch(processNewPurchases()));
  };

  /// On Reject handler
  const onReject = async () => {
    setShowConfirmationPopup(false);
    setGetUsersFromUserAPIPopup(false);
  };

  /// Confirmation Popup
  const getConfirmationPopup = () => {
    return (
      <ConfirmationPopup
        target={ConfirmPopupTarget}
        isShow={isShowConfirmationPopup}
        title={t('/confirm_messages.new_purchases')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  /// On Accept handler
  const onAcceptGetUsersFromUserAPI = async () => {
    setGetUsersFromUserAPIPopup(false);
    await asyncErrorHandler(dispatch(getUsersFromUserAPIAction()));
  };

  // Get Users From User API button Popup.
  const getUsersFromUserAPIPopup = () => {
    return (
      <ConfirmationPopup
        target={ConfirmPopupTarget}
        isShow={showGetUsersFromUserAPIPopup}
        title={t('/confirm_messages.getUserFromUserAPIPopup')}
        onAccept={onAcceptGetUsersFromUserAPI}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  return (
    <Form>
      <Row className="d-flex justify-content-center">
        <Col xs={12} lg={6}>
          <SettingApplicationForm
            handleSubmit={handleSubmit}
            handleFavouriteIconUpload={handleFavouriteIconUpload}
            handleFavouriteIconDelete={handleFavouriteIconDelete}
            handleLogoUpload={handleLogoUpload}
            handleLogoDelete={handleLogoDelete}
            displayCroppedFavouriteIcon={displayCroppedFavouriteIcon}
            displayCroppedLogo={displayCroppedLogo}
            CSSTemplates={CSSTemplates}
            isLoading={isLoading}
            settingsState={settingsState}
            fetchLicenseDropdown={fetchLicenseDropdown}
            logoUploadStatus={logoUploadStatus}
            favouriteIconUploadStatus={favouriteIconUploadStatus}
          />
        </Col>
        <Col xs={12} lg={6} className="pb-0">
          <SettingSMTPForm
            handleSubmit={handleSubmit}
            handleTestSmtp={handleTestSmtp}
            settingsState={settingsState}
            isLoading={!settingsState.setting}
            isLoadingTestSMTP={isLoadingTestSMTP}
            translation={t}
          />
          <IPListForm
            isLoading={!settingsState.setting}
            saveButtonClick={(event: any, selectedIPs: string[]) =>
              handleSubmit({ whitelistedIPs: selectedIPs })
            }
            ipRanges={settingsState.setting && settingsState.setting.whitelistedIPs}
            isWhitelistedIPs={true}
          />
          <IPListForm
            isLoading={!settingsState.setting}
            saveButtonClick={(event: any, selectedIPs: string[]) =>
              handleSubmit({ blacklistedIPs: selectedIPs })
            }
            ipRanges={settingsState.setting && settingsState.setting.blacklistedIPs}
            isWhitelistedIPs={false}
          />
          <StaticLinkForm
            staticLinksState={staticLinksState}
            tableData={staticLinksState.staticLinks}
            refreshSaticLinksList={refreshSaticLinksList}
            isLoading={staticLinksState.isStaticLinksLoading}
            deleteLinks={handleStaticLinkDelete}
            uploadStaticLinkIcon={uploadStaticLinkIcon}
            deleteStaticLinkIcon={deleteStaticLinkIcon}
            displayCroppedStaticLinkIcon={displayCroppedStaticLinkIcon}
            handleSubmit={handleStaticLinkSubmit}
            setCroppedStaticLinkIcon={setCroppedStaticLinkIcon}
          />
        </Col>
        <Col xs="12" className="py-0">
          <Button
            className="settingsForm ml-3 float-right"
            onClick={() => {
              setShowEnvironmentVariable(!showEnvironmentVariable);
            }}>
            {showEnvironmentVariable ? (
              <span className="d-flex align-items-center">
                <i className="pi pi-eye-slash" style={{ fontSize: '1em' }} />
                <span className="flex-grow-1">
                  {t('admin.page.settings.buttons.hideEnvironmentVariables')}
                </span>
              </span>
            ) : (
              <span className="d-flex align-items-center">
                <i className="pi pi-eye" style={{ fontSize: '1em' }} />
                <span className="flex-grow-1">
                  {t('admin.page.settings.buttons.showEnvironmentVariables')}
                </span>
              </span>
            )}
          </Button>
          <Button
            className="settingsForm float-right"
            onClick={(event: any) => {
              setShowConfirmationPopup(true);
              setConfirmPopupTarget(event.target);
            }}
            disabled={settingsState.isCheckingNewPurchases}>
            {
              <span className="d-flex align-items-center">
                <i className="pi pi-shopping-cart" style={{ fontSize: '1em' }} />
                <span className="flex-grow-1">
                  {t('admin.page.settings.buttons.checkForNewPurchases')}
                </span>
              </span>
            }
          </Button>
          <Button
            className="settingsForm mr-3 float-right"
            onClick={(event: any) => {
              setGetUsersFromUserAPIPopup(true);
              setConfirmPopupTarget(event.target);
            }}
            disabled={settingsState.isCheckingUsersFromUserAPI}>
            <span className="d-flex align-items-center">
              <i className="pi pi-user-plus" style={{ fontSize: '1em' }} />
              <span className="flex-grow-1">
                {t('admin.page.settings.buttons.getUsersFromUserAPI')}
              </span>

              {settingsState.isCheckingUsersFromUserAPI ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                <></>
              )}
            </span>
          </Button>
        </Col>
      </Row>
      <DialogWrapper
        isDialogVisible={showEnvironmentVariable}
        onHide={() => hideEnvironmentVariablesDialog()}
        headerTitle={t('admin.page.settings.category.enviromentVariables')}>
        {getApplicationEnvironmentVariables()}
      </DialogWrapper>
      {getConfirmationPopup()}
      {getUsersFromUserAPIPopup()}
    </Form>
  );
};

export default SettingForm;
