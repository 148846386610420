/*
 * EntitlementList.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file EntitlementList.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import { PageSectionType } from './ProductsForm';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ISettingsStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import { ILicense } from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import { ProductCardClassName } from './DashboardPage';

interface IEntitlementList {
  entitlements: IEntitlement[] /** Entitlement data to be rendered. */;
  pageSectionType: PageSectionType /** ENUM with which section should be rendered. */;
  selectProductHandler?: (
    selectedProductIdentifier: number,
    productUUID: string
  ) => void /** Function to trigger and display the entitlement files in Your Files table. */;
  isRenderingOnlyEntitlementList?: boolean /** If the Entitlements of the Your Products should be only rendered with no actions. */;
}

const EntitlementList: React.FC<IEntitlementList> = (properties) => {
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);

  /** The description of the cards into Your Products should have elision style applied after the number of characters defined here. */
  const limitDescriptionToApplyElisionStyle: number = 100;

  const translation = useTranslation().t;

  const [productIndexArraySelected, setProductIndexArraySelected] = useState<number | null>(null);

  const selectProductButtonHandler = (index: number, entitlementUUID: string) => {
    properties.selectProductHandler(index, entitlementUUID);
    if (index + 1 === productIndexArraySelected) {
      setProductIndexArraySelected(null);
    } else {
      setProductIndexArraySelected(index + 1);
    }
  };

  const getCardBadgeStatusLabel = (license: ILicense): string => {
    if (license.isPaused) {
      return translation('I18N.client.dashboard.productPaused');
    }

    return new Date(license.licenseEndDate) > new Date()
      ? translation('I18N.client.dashboard.productActive')
      : translation('I18N.client.dashboard.productExpired');
  };

  const getCardBadgeStatusClassname = (license: ILicense): string => {
    if (license.isPaused) {
      return ProductCardClassName.Paused;
    }

    return new Date(license.licenseEndDate) > new Date()
      ? ProductCardClassName.Active
      : ProductCardClassName.Expired;
  };

  return (
    <>
      {properties.entitlements !== null &&
        properties.entitlements?.map(function (entitlement: any, index: number) {
          let entitlementDisplayName: string = '';
          let entitlementUUID: string = '';
          let projectName: string = '';
          let imageURL: string = '';
          let description: string = '';
          let filesCount: number; /**< Files count */

          if (entitlement) {
            // if not template
            entitlementDisplayName = entitlement.displayName;
            entitlementUUID = entitlement.entitlementUUID;
            projectName =
              properties.pageSectionType === PageSectionType.MyProducts
                ? entitlement.license.projectName
                : '';
            imageURL = entitlement.imageURL;
            description = entitlement.description;
            filesCount = entitlement?.files ? entitlement.files.length : 0;
          }

          //Note: Should display only products with a defined icon/logo in the Unlicensed Products section and if there are bound files to it
          if (
            isStringEmptyOrNullOrUndefined(entitlement?.imageURL) &&
            properties.pageSectionType === PageSectionType.UnlicensedProducts
          ) {
            return;
          }

          if (properties.pageSectionType === PageSectionType.MyProducts && filesCount !== 0) {
            return (
              <Col lg={2} className="my-products-and-unlicensed-sections-container" key={index}>
                <div
                  className={`card custom-card-height d-flex align-items-center my-products-and-unlicensed-sections-container-low-breakpoints ${
                    !properties.isRenderingOnlyEntitlementList ? 'productCard' : ''
                  } ${
                    productIndexArraySelected && productIndexArraySelected === index + 1
                      ? 'selectedProduct'
                      : ''
                  }`}
                  onClick={() => {
                    !properties.isRenderingOnlyEntitlementList &&
                      selectProductButtonHandler(index, entitlementUUID);
                  }}>
                  <div className="w-100 d-flex align-items-center flex-column">
                    <img
                      src={
                        imageURL || imageURL !== ''
                          ? imageURL
                          : settings?.safeSettings?.favouriteIconImageURL
                      }
                      className="small-images mt-4 convert-image-filter"
                      alt=""
                    />
                    <div className={`card-body expand-as-needed`}>
                      <div className="d-flex">
                        <p
                          className={`card-text product-text card-status-container mr-2 ${getCardBadgeStatusClassname(
                            entitlement.license
                          )}`}>
                          {getCardBadgeStatusLabel(entitlement.license)}
                        </p>

                        <p className={`card-text product-text card-filesCount-container`}>
                          {filesCount}{' '}
                          {filesCount === 1
                            ? translation('I18N.client.dashboard.fileLabel')
                            : translation('I18N.client.dashboard.filesLabel')}
                        </p>
                      </div>
                      {description.length < limitDescriptionToApplyElisionStyle ? (
                        <p className="card-text product-text m-0">{description}</p>
                      ) : (
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">{description}</Tooltip>}>
                          <p className="card-text product-text your-products-description-elision">
                            {description}
                          </p>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            );
          }

          if (
            properties.pageSectionType === PageSectionType.UnlicensedProducts &&
            !properties.isRenderingOnlyEntitlementList
          ) {
            return (
              <Col lg={2} className="my-products-and-unlicensed-sections-container" key={index}>
                <div
                  className={`card custom-card-height d-flex align-items-center my-products-and-unlicensed-sections-container-low-breakpoints`}>
                  <div className="w-100 d-flex align-items-center flex-column">
                    <img
                      src={
                        imageURL || imageURL !== ''
                          ? imageURL
                          : settings?.safeSettings?.favouriteIconImageURL
                      }
                      className="small-images mt-4 convert-image-filter"
                      alt=""
                    />
                    <div className={`card-body expand-as-needed`}>
                      <p className="card-text product-text text-center card-entitlement-margin">
                        {entitlementDisplayName}
                      </p>
                      <p className="card-text product-text text-center card-project-name-label">
                        {projectName}
                      </p>
                      <p className="card-text product-text text-center">{description}</p>
                    </div>
                  </div>
                </div>
              </Col>
            );
          }
        })}
    </>
  );
};

export default EntitlementList;
