/*
 * NewsletterPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterPage.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NewsletterTable from './NewsletterTable';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  copyNewsletter,
  deleteNewsletter,
  fetchAllNewsletters,
  sendNewsletter,
  sendNewsletterPreview
} from '../../../Store/Newsletter';
import { INewsletterStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { useHistory } from 'react-router-dom';
import { NewsletterAPI } from '../../../Services/Newsletter';
import { INewsletter } from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import { NewsletterSendStatus } from '@abstract/abstractwebcommon-shared/enum/license/Newsletter';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

/**
 * NewsletterPage component
 */
const NewsletterPage: React.FC<any> = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const newsletterState: INewsletterStateSelector = useSelector(
    (state: IStateSelectors) => state.newsletter
  ); /**< Newsletter state */
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'created',
      sortOrder: -1
    },
    searchTerm: ''
  }); /**< Default Payload */
  const history: any = useHistory();

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(fetchAllNewsletters(updatedPayload));
  };

  /// Handle page update event
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(fetchAllNewsletters(updatedPayload));
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(fetchAllNewsletters(updatedPayload));
  };

  /// Handle add or edit newsletter event
  const handleAddOrEditNewsletter = async (newsletterUUID?: string): Promise<void> => {
    if (newsletterUUID) {
      history.push({
        pathname: `newsletter/${newsletterUUID}`
      });
    } else {
      history.push({ pathname: `newsletter/new` });
    }
  };

  /// Handle delete newsletter event
  const handleDeleteNewsletter = async (newsletterUUIDs: string[]): Promise<void> => {
    await asyncErrorHandler(dispatch(deleteNewsletter(newsletterUUIDs)));
    await asyncErrorHandler(dispatch(fetchAllNewsletters(payload)));
  };

  /// Handle copy newsletter event
  const handleCopyNewsletter = async (newsletterUUIDs: string[]): Promise<void> => {
    await asyncErrorHandler(dispatch(copyNewsletter(newsletterUUIDs)));
    await asyncErrorHandler(dispatch(fetchAllNewsletters(payload)));
  };

  /// Handle send newsletter event
  const handleSendNewsletter = async (newsletter: INewsletter): Promise<void> => {
    /// Initial state of sending newsletter
    const intialNewsletterSendStatus: INewsletter = {
      newsletterUUID: newsletter.newsletterUUID,
      sendStatus: NewsletterSendStatus.inProgress,
      isSent: false,
      sentDate: null,
      sentCount: 0,
      openedCount: 0,
      pendingUsersCount: newsletter.totalLicensesCount
    };
    /// Update the newsletter with current status
    await asyncErrorHandler(NewsletterAPI.createOrUpdate(intialNewsletterSendStatus));
    await asyncErrorHandler(dispatch(fetchAllNewsletters(payload)));

    /// Send newsletter to license users
    await asyncErrorHandler(dispatch(sendNewsletter(newsletter.newsletterUUID)));
  };

  /// Handle send newsletter preview event
  const handleSendNewsletterPreview = async (newsletterUUID: string): Promise<void> => {
    /// Send newsletter to loggedin user for preview
    await asyncErrorHandler(dispatch(sendNewsletterPreview(newsletterUUID)));
  };

  useEffect(() => {
    dispatch(fetchAllNewsletters(payload));
  }, []);

  return (
    <>
      <Row>
        <Col>
          <NewsletterTable
            onSort={handleSortUpdate}
            onPage={handlePageUpdate}
            onFilter={handleFilterUpdate}
            tablePayload={payload}
            newsletterList={newsletterState.newsletterList}
            isLoading={newsletterState.isLoading}
            handleAddOrEditNewsletter={handleAddOrEditNewsletter}
            handleDeleteNewsletter={handleDeleteNewsletter}
            handleCopyNewsletter={handleCopyNewsletter}
            handleSendNewsletter={handleSendNewsletter}
            handleSendNewsletterPreview={handleSendNewsletterPreview}
            isNewsletterSending={
              newsletterState.isNewsletterChanging || newsletterState.isNewsletterSending
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default NewsletterPage;
