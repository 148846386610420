/**
 * FileRecord.ts (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file FileRecord.ts
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IFileRecordVersion,
  IMappedFileRecordVersion
} from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecordVersion';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { IImageUploadResponse } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import { IFileRecord } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecord';
import { mapSortingFields } from '../Utils/Pagination';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

interface IErrorResponse {
  error: {
    message: string;
  };
}

/**
 * Create or update a file record
 * @param fileRecord Payload required for the API request
 * @returns JSON Object of the API response
 */
export const createOrUpdateAPI = async (
  fileRecord: any
): Promise<IAPIEntityResponse<IFileRecord>> => {
  const url: string = `/fileRecord`;
  if (fileRecord.fileRecordUUID) {
    return httpClient.post<IFileRecord>(`${url}/${fileRecord.fileRecordUUID}/update`, fileRecord);
  }
  return httpClient.post<IFileRecord>(`${url}/create`, fileRecord);
};

/**
 * Gets signed URL to upload a file
 * @param payload Payload required for the API request
 * @returns signed URL as a string
 */
export const getSignedUploadURLAPI = async (
  payload: any
): Promise<IAPIEntityResponse<{ signedUploadURL: string }>> => {
  return httpClient.post<{ signedUploadURL: string }>(
    `/fileRecord/${payload.fileRecordUUID}/signedUploadURL`,
    {
      originalname: payload.file.name
    }
  );
};

/**
 * Gets signed URL to download a file
 * @param payload Payload required for the API request
 * @returns signed URL as a string
 */
export const getSignedDownloadURLAPI = async (
  payload: any
): Promise<IAPIEntityResponse<{ signedDownloadURL: string }>> => {
  const url: string = `/fileRecord/${payload.fileRecordUUID}/signedDownloadURL${
    payload.versionID ? `?versionID=${payload.versionID}` : ''
  }${payload.userUUID ? `?userUUID=${payload.userUUID}` : ''}`;
  return httpClient.get<{ signedDownloadURL: string }>(url);
};

/**
 * Upload icon imageIAPIEntityResponse
 * @param payload Payload required for the API request
 * @returns JSON Object of the API response
 */
export const uploadIconAPI = async (
  payload: any
): Promise<IAPIEntityResponse<IImageUploadResponse>> => {
  const formData: FormData = new FormData();

  formData.append('file', payload.file, payload.file.name);
  return httpClient.imageUpload<IImageUploadResponse>('/fileRecord/upload/icon', formData);
};

/**
 * Creates a new fileRecordVersion
 * @param payload Payload required for the API request
 * @returns JSON Object of the API response
 */
export const createFileRecordVersion = async (payload: any): Promise<IAPIEntityResponse<void>> => {
  const url: string = `/fileRecord/create/version?fileRecordUUID=${payload.fileRecordUUID}`;
  return httpClient.post<void>(url, { originalname: payload.file.name, size: payload.file.size });
};

/**
 * Fetches the versions of a fileRecord with pagination
 * @param skip Pagination parameter
 * @param limit Pagination parameter
 * @param sort Pagination parameter
 * @param sortOrder Pagination parameter
 * @param searchTerm Search term
 * @param fileRecordUUID UUID of the fileRecord
 * @returns fileRecordVersions with the requested pagination options
 */
export const fetchVersionsAPI = async (
  skip = 0,
  limit = defaultTableLimit,
  sort = 'isActive', /// By default,the activated version should be at the top, followed by newly created versions.
  sortOrder = 'DESC',
  searchTerm: string,
  fileRecordUUID: string
): Promise<PaginatedAPIEntityResponse<IMappedFileRecordVersion>> => {
  const url: string = `/fileRecord/${fileRecordUUID}/versions`;
  sort = sort || 'isActive'; /// By default,the activated version should be at the top, followed by newly created versions.
  const mappedSort: string = mapSortingFields(
    sort,
    sortOrder
  ); /**< To map sortFields and sortOrder.*/

  return httpClient.get<IMappedFileRecordVersion>(
    `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&searchTerm=${encodeURIComponent(
      searchTerm ? searchTerm : ''
    )}`
  );
};

/**
 * Fetches all fileRecords
 * @param skip Pagination parameter
 * @param limit Pagination parameter
 * @param sort Pagination parameter
 * @param sortOrder Pagination parameter
 * @param searchTerm Search term
 * @returns fileRecords with the requested pagination options
 */
export const readAPI = async (
  skip = 0,
  limit = defaultTableLimit,
  sort = 'updated',
  sortOrder = 'DESC',
  searchTerm: string
): Promise<PaginatedAPIEntityResponse<IFileRecord>> => {
  const url: string = `/fileRecords/all`;
  sort = sort || 'updated';
  const mappedSort: string = mapSortingFields(
    sort,
    sortOrder
  ); /**< To map sortFields and sortOrder.*/

  return httpClient.get<IFileRecord>(
    `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&searchTerm=${encodeURIComponent(
      searchTerm ? searchTerm : ''
    )}`
  );
};

/**
 * Deletes FileRecord(s) by fileRecordUUIDs
 * @param payload fileRecordUUIDs
 * @returns Promise
 */
export const removeAPI = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post(`/fileRecords/delete`, { fileRecordUUIDs: payload });
};

/**
 * Deletes fileRecordVersion(s)
 * @param fileRecordUUID UUID of the fileRecord
 * @param versionIDs VersionIDs
 * @returns JSON Object of the API response
 */
export const removeVersionAPI = (
  fileRecordUUID: string,
  versionIDs: string[]
): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post(`/fileRecord/${fileRecordUUID}/versions/delete`, {
    versionIDs: versionIDs
  });
};

/**
 * Sets a fileRecordVersion as the active version
 * @param fileRecordUUID UUID of the fileRecord
 * @param versionID ID of the file version
 * @returns JSON Object of the API response
 */
export const setActiveVersionAPI = (
  fileRecordUUID: string,
  versionID?: string
): Promise<IAPIEntityResponse<void>> => {
  return httpClient.get<void>(
    `/fileRecord/${fileRecordUUID}/setActiveVersion?versionID=${versionID}`
  );
};

/**
 * Updates a fileRecordVersion
 * @param data data required for the API request
 * @returns JSON Object of the API response
 */
export const updateFileRecordVersionAPI = (
  data: any
): Promise<IAPIEntityResponse<IFileRecordVersion>> => {
  const url: string = `/fileRecord/${data.fileRecordUUID}/version/${data.versionID}/update`;
  delete data.id;

  return httpClient.post<IFileRecordVersion>(url, data);
};

/**
 * Uploads a file to S3 using signedURL
 * @param url Signed URL for the upload
 * @param data Data of the file to be uploaded
 * @param onUploadProgress Callback function for upload progress percentage
 * @returns JSON Object of the API response
 */
export const uploadToS3 = async (
  url: string,
  data: any,
  onUploadProgress: any,
  cancelTokenSource: CancelTokenSource
): Promise<AxiosResponse<unknown, any> | IErrorResponse> => {
  return await asyncErrorHandler(
    axios
      .request({
        method: 'PUT',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        url,
        data,
        onUploadProgress,
        cancelToken: cancelTokenSource.token
      })
      .catch((error) => {
        console.log('error uploadToS3', error);
        console.log('######');
        console.log('error uploadToS3 url', url);
        console.log('######');
        console.log('error uploadToS3 data', data);
        console.log('######');
        console.log('error uploadToS3 onUploadProgress', onUploadProgress);
        console.log('######');
        console.log('error uploadToS3 cancelTokenSource', cancelTokenSource);
        if (axios.isCancel(error)) {
          return { error: { message: 'Request cancelled.' } };
        }
        return { error: { message: 'Error while uploading file' } };
      })
  );
};

/**
 * Fetches all paginated Client fileRecords
 * @param skip Pagination parameter
 * @param limit Pagination parameter
 * @param sort Pagination parameter
 * @param sortOrder Pagination parameter
 * @param searchTerm Search term
 * @param entitlementUUID Entitlement UUID
 * @returns client fileRecords with the requested pagination options
 */
export const getAllClientFiles = async (
  skip = 0,
  limit = defaultTableLimit,
  sort = 'updated',
  sortOrder = 'DESC',
  searchTerm: string,
  entitlementUUID: string,
  userUUID: string
): Promise<PaginatedAPIEntityResponse<IFileRecord>> => {
  const url: string = `/fileRecords/allClientFiles`;
  sort = sort || 'updated';
  const mappedSort: string = mapSortingFields(
    sort,
    sortOrder
  ); /**< To map sortFields and sortOrder.*/

  return httpClient.get<IFileRecord>(
    `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&searchTerm=${encodeURIComponent(
      searchTerm ? searchTerm : ''
    )}&entitlementUUID=${entitlementUUID ? entitlementUUID : ''}&userUUID=${userUUID}`
  );
};
