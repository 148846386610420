/*
 * VersionForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file VersionForm.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { IFileRecordVersion } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecordVersion';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';
import i18n from '../../../Services/I18n';

/**
 * IVersionFormProperties
 */
interface IVersionFormProperties {
  isLoading: boolean /**< Loading State. */;
  filerecordVersion: IFileRecordVersion /**< FileRecord Version. */;
  handleSubmit: (
    payload: IFileRecordVersion,
    id: string
  ) => void /**< Handlesubmit event handler. */;
  isEditDescription: boolean /**< To edit description or not */;
}

const VersionForm = (properties: IVersionFormProperties): JSX.Element => {
  const filerecordVersion: IFileRecordVersion =
    properties.filerecordVersion; /**< FileRecord Version data. */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: filerecordVersion.description || '',
      versionName: filerecordVersion.versionName || ''
    },
    validationSchema: Yup.object({
      versionName: Yup.string().max(200, i18n.t('validation.max', { field: '200' }))
    }),
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          if (Object.keys(payload).length) {
            properties.handleSubmit(payload, filerecordVersion.versionID);
          }
        }
      });
    }
  });

  return (
    <>
      <FormWrapper
        handleSubmitButton={() => formik.handleSubmit()}
        controlButtonLabel={false}
        isLoading={properties.isLoading}>
        <div className="mb-3">
          {properties.isEditDescription ? (
            <>
              <InputTextarea
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                className={
                  formik.touched.description && formik.errors.description ? 'p-invalid' : ''
                }
                onBlur={formik.handleBlur}
                rows={5}
                autoResize
              />
              {formik.touched.description && formik.errors.description ? (
                <small id="description-invalid" className="p-invalid">
                  {formik.errors.description}
                </small>
              ) : null}
            </>
          ) : (
            <>
              <InputTextarea
                id="versionName"
                value={formik.values.versionName}
                onChange={formik.handleChange}
                className={
                  formik.touched.versionName && formik.errors.versionName ? 'p-invalid' : ''
                }
                onBlur={formik.handleBlur}
                rows={5}
                autoResize
              />
              {formik.touched.versionName && formik.errors.versionName ? (
                <small id="versionName-invalid" className="p-invalid">
                  {formik.errors.versionName}
                </small>
              ) : null}
            </>
          )}
        </div>
      </FormWrapper>
    </>
  );
};

export default VersionForm;
