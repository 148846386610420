/*
 * FileRecordTablePage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file FileRecordTablePage.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import FileRecordTable from './FileRecordTable';
import {
  cancelS3Upload,
  createFileRecord,
  deleteFileRecord,
  deleteFileVersion,
  downloadFileRecord,
  fetchVersions,
  getAllFileRecords,
  setActive,
  updateFileRecord,
  updateFileRecordVersion,
  uploadFileVersion
} from '../../../Store/FileRecords';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { updateEntitlement } from '../../../Store/Entitlements';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const FileRecordTablePage: React.FC<any> = () => {
  const dispatch: Dispatch<any> = useDispatch();

  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'updated',
      sortOrder: -1
    }
  }); /**< Default Payload */

  const handleUploadFileVersion = async (values: any): Promise<void> => {
    await asyncErrorHandler(
      dispatch(
        uploadFileVersion({
          values: values /**< File version data to upload. */,
          resetForm: refresh /**< Refresh grid. */
        })
      )
    );
  };

  useEffect(() => {
    const updatedPayload = {
      skip: 0,
      limit: defaultTableLimit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      searchTerm: payload.searchTerm
    };

    setPayload(updatedPayload);
    dispatch(getAllFileRecords(updatedPayload));
  }, [dispatch, payload.sort.sortOrder, payload.sort.sortField]);

  /**
   * handleUpdate
   * @param fileRecord
   */
  const handleUpdate = (fileRecord: any) => {
    dispatch(updateFileRecord(fileRecord));
  };

  /**
   * handleCreateFileRecord
   * @param fileRecord
   */
  const handleCreateFileRecord = (values: any, resetForm: any) => {
    dispatch(
      createFileRecord({
        values,
        resetForm
      })
    );
  };

  const handleDeleteFileRecord = (fileRecordUUIDs: string[]) => {
    return dispatch(deleteFileRecord(fileRecordUUIDs));
  };

  const handleDownload = async (data: any) => {
    dispatch(
      downloadFileRecord({
        fileRecordUUID: data.fileRecordUUID,
        versionID: data.versionID
      })
    );
  };

  const handleDeleteVersion = async (
    data: any,
    setShowVersionPage: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    return dispatch(
      deleteFileVersion({
        values: data /**< File version data to delete.  */,
        resetForm: refresh /**< Refresh grid. */,
        setShowVersionPage: setShowVersionPage /**< To show version table or not */
      })
    );
  };

  const handleUpdateFileRecordVersion = async (fileRecordVersion: any) => {
    dispatch(
      updateFileRecordVersion({
        values: fileRecordVersion /**< File Version data to update. */,
        resetForm: refresh /**< Refresh grid. */
      })
    );
  };

  const handleSubmitFileEntitlement = async (data: any): Promise<any> => {
    return dispatch(
      updateEntitlement({
        entitlementUUID: data.entitlement,
        files: data.files
      })
    );
  };

  const handleFetchVersions = async (fileRecord: any) => {
    const fetchVersionsResponse = await asyncErrorHandler(
      dispatch(
        fetchVersions({
          fileRecordUUID: fileRecord.fileRecordUUID
        })
      )
    );
    return fetchVersionsResponse;
  };
  const handleSetActive = async (data: any) => {
    dispatch(
      setActive({
        values: data /**File Version data to activate. */,
        resetForm: refresh /**< Refresh grid. */
      })
    );
  };

  const handleSortUpdate = (state: any) => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: state.sortField,
        sortOrder: state.sortOrder
      },
      searchTerm: payload.searchTerm
    };

    setPayload(updatedPayload);
    dispatch(getAllFileRecords(updatedPayload));
  };

  const handlePageUpdate = (e: any) => {
    const { first, rows } = e;
    const updatedPayload = {
      skip: first,
      limit: rows,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllFileRecords(updatedPayload));
  };

  const handleOnFilter = (event: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: event.filters,
      searchTerm: event
    };

    setPayload(updatedPayload);
    dispatch(getAllFileRecords(updatedPayload));
  };

  /// Clear files pagination handler
  const clearPagination = () => {
    const updatedPayload = {
      skip: 0,
      limit: defaultTableLimit,
      sort: {
        sortField: '',
        sortOrder: -1
      },
      filters: {},
      searchTerm: ''
    };
    setPayload(updatedPayload);
    dispatch(getAllFileRecords(updatedPayload));
  };

  /// Refresh grid
  const refresh = () => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      searchTerm: payload.searchTerm
    };

    dispatch(getAllFileRecords(updatedPayload));
  };

  const handleCancelUpload = () => {
    dispatch(cancelS3Upload({}));
  };

  return (
    <FileRecordTable
      handlePageUpdate={handlePageUpdate}
      handleSortUpdate={handleSortUpdate}
      handleDeleteFileRecord={handleDeleteFileRecord}
      handleDownload={handleDownload}
      handleCreateFileRecord={handleCreateFileRecord}
      handleUpdate={handleUpdate}
      handleSetActive={handleSetActive}
      handleFetchVersions={handleFetchVersions}
      handleUploadFileVersion={handleUploadFileVersion}
      handleDeleteVersion={handleDeleteVersion}
      handleUpdateFileRecordVersion={handleUpdateFileRecordVersion}
      handleSubmitFileEntitlement={handleSubmitFileEntitlement}
      clearPagination={clearPagination}
      refresh={refresh}
      handleCancelUpload={handleCancelUpload}
      payload={payload}
      handleOnFilter={handleOnFilter}
    />
  );
};

export default FileRecordTablePage;
