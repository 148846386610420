/*
 * CssEditor.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file CssEditor.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { Dispatch, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCss, updateCssTemplate } from '../../../Store/Templates';
import CSSEditorComponent from '@abstract/abstractwebcommon-client/TemplateEditor/CSSEditorComponent';
import { IStateSelectors, ITemplateStateSelector } from '../../../Interfaces/Selectors';

/**
 * Template Editor component.
 * @name CssEditor
 */
const CssEditor = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const templates: ITemplateStateSelector = useSelector(
    (state: IStateSelectors) => state.templates
  );

  const codeMirrorOptions = {
    theme: 'dracula',
    lineNumbers: true,
    lineWrapping: true
  };

  /// onSubmit action
  const onSubmitHandler = (updatedPayload: string) => {
    dispatch(updateCssTemplate(updatedPayload));
  };

  /// Fetch css template on Load.
  useEffect(() => {
    dispatch(fetchCss());
  }, [dispatch]);

  return (
    <CSSEditorComponent
      isLoading={(templates && templates.cssIsFetching) || (templates && templates.cssIsChanging)}
      codeMirrorOptions={{
        mode: 'css',
        ...codeMirrorOptions
      }}
      submitHandler={onSubmitHandler}
      submitLabel={t('admin.page.templates.card.editor.save')}
      submitClasses={'p-button p-button-primary'}
      confirmationMessage={t('/confirm_messages.save_record')}
      confirmationNo={t('/confirm_messages.no')}
      confirmationYes={t('/confirm_messages.yes')}
      CSSTemplate={templates.css}
    />
  );
};

export default CssEditor;
