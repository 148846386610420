import ActivationPage from '../Components/Client/Activations/ActivationPage';
import { DashboardPage } from '../Components/Client/Dashboard/DashboardPage';
import ReportsPage from '../Components/Client/Reports/RecordsPage';
import ReportPage from '../Components/Admin/Report/ReportPage';
import NotFoundRedirect from '@abstract/abstractwebcommon-client/NotFound/NotFoundRedirect';
import AdminReportPage from '../Components/Admin/Reports/ReportsPage';
import { SharedMainRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { RouteName } from '../Utils/routesNames';
import i18n from '../Services/I18n';
import LicenseTable from '../Components/Admin/License/LicenseTable';

/**
 * Get client routes
 * @returns clientRoutes
 */
export const getClientRoutes = () => {
  const clientRoutes = [
    {
      path: SharedMainRouteName.clientRoute,
      Component: DashboardPage,
      isAuthenticated: true
    },
    {
      path: RouteName.clientHomeRoute,
      Component: DashboardPage,
      isAuthenticated: true
    },
    {
      path: RouteName.clientActivationsRoute,
      Component: ActivationPage,
      isAuthenticated: true,
      headingKey: i18n.t('admin.menu.activation')
    },
    {
      path: RouteName.clientLicenseRoute,
      Component: LicenseTable,
      authenticated: true,
      headingKey: i18n.t('admin.page.licenses.page_title')
    },
    {
      path: RouteName.clientReportsRoute,
      Component: ReportsPage,
      isAuthenticated: true,
      headingKey: i18n.t('I18N.admin.page.reports.page_title')
    },
    {
      path: `${RouteName.clientReportsRoute}/:reportUUID`,
      Component: ReportPage,
      isAuthenticated: true,
      headingKey: i18n.t('I18N.admin.page.reports.page_title'),
      isHeaderLabelHide: false
    },
    {
      Component: NotFoundRedirect,
      isAuthenticated: true
    }
  ];

  return clientRoutes;
};

/**
 * Get support routes
 * @returns supportRoutes
 */
export const getSupportRoutes = () => {
  const supportRoutes = {
    path: RouteName.clientReportsRoute,
    Component: AdminReportPage,
    isAuthenticated: true,
    headingKey: i18n.t('I18N.admin.page.reports.page_title')
  };

  return supportRoutes;
};
