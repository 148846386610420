/**
 * Auth.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Auth.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import jwtDecode from 'jwt-decode';
import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import {
  IMappedRole,
  IUserInformationToken
} from '@abstract/abstractwebcommon-shared/utils/UserAuthenticationToken';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Checks whether a token is valid or not
 * @param token Token to validate
 * @returns Boolean
 */
export const isTokenValid = (token: string | null): boolean => {
  try {
    if (!token) return false;

    const decodedToken: IUserInformationToken = jwtDecode(token);
    const currentDate: Date = new Date();
    let isValid: boolean;

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  } catch (error: any) {
    return false;
  }
};

/**
 * Checks if a specified user is an admin
 * @param roles Roles of the user
 * @param adminRoleUUID adminRoleUUID
 * @returns
 */
export const checkIsAdmin = (roles: IMappedRole[], adminRoleUUID: string): boolean => {
  return roles.find((role: IMappedRole) => role.roleUUID === adminRoleUUID) ? true : false;
};

/**
 * Checks if user has an application
 * @param applicationsHaystack Applications to search in
 * @param applicationUUID UUID of requested application
 * @returns
 */
export const hasApplication = (applicationsHaystack: any[], applicationUUID: string): boolean => {
  return applicationsHaystack.some((application: IApplications) => application === applicationUUID);
};

/**
 * Token backend validation
 * @returns Promise
 */
export const validateTokenAPI = (): Promise<IAPIEntityResponse<void>> => {
  return httpClient.get<void>(`/system/validate`);
};

/**
 * Get authicated user profile
 */
export const getUserProfile = (): Promise<IAPIEntityResponse<IUser>> => {
  return httpClient.get<void>(`/auth/profile`);
};
