import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NotFound from './NotFound/NotFound';
import LoginPage from './Login/LoginPage';
import { reconnectAction, getAuthenticatedUserProfile } from '../Store/Auth';
import i18n from '../Services/I18n';
import { getFavouriteIconAction, getSafeSettings } from '../Store/Settings';
import { loadCss } from '../Services/Template';
import { BASE_API_URL } from '../config';
import AuthenticationVerificationPage from './Login/AuthenticationVerificationPage';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import {
  IAuthStateSelector,
  ISettingsStateSelector,
  IStateSelectors
} from '../Interfaces/Selectors';
import { ErrorHandler } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import ScrollToTop from '@abstract/abstractwebcommon-client/ScrollToTop';
import ClientPanel from './Client/ClientPanel';
import AdminPanel from './Admin/AdminPanel';
import { AlertToast, showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import ConfigurationSettingsPage from './Login/ConfigurationSettingsPage';
import { changeUserTheme } from '@abstract/abstractwebcommon-client/utils/themeModeUtils';
import {
  IUserAPIState,
  getUserAPIState
} from '@abstract/abstractwebcommon-client/store/UserAPISlice';
import { customLocalStorageClearUtil } from '../Utils/localStorageUtils';
import {
  updateUserThemeModeStateAction,
  updateUserLanguageSettingsModeStateAction
} from '../Store/UserAPI';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import {
  SharedCommomRouteName,
  SharedMainRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { RouteName } from '../Utils/routesNames';
import NewsletterUnSubscriptionPage from './Admin/Newsletter/NewsletterUnSubscriptionPage';
import NewsletterUnSubscriptionByEmailPage from './Admin/Newsletter/NewsletterUnSubscriptionByEmailPage';
import { changeUserLanguage } from '@abstract/abstractwebcommon-client/utils/LanguageSettingsModeUtils';
import EulaPermissionPage from './Login/EulaPermissionPage';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import TopBar from '@abstract/abstractwebcommon-client/TopBar';
import SLAPermissionPage from './Login/SLAPermissionPage';

function Main(): ReactElement {
  const dispatch = useDispatch();
  const translation: TFunction = useTranslation().t;
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const authState: IAuthStateSelector = useSelector((state: IStateSelectors) => state.auth);
  const userAPIState: IUserAPIState = useSelector(getUserAPIState);

  const [isAppReconnecting, setIsAppReconnecting] = useState<boolean>(true);
  const [favouriteIconX180, setFavouriteIconX180] = useState<string>('');
  const [favouriteIconX32, setFavouriteIconX32] = useState<string>('');
  const [favouriteIconX16, setFavouriteIconX16] = useState<string>('');
  const [themeMode, setThemeMode] = useState<string>(LocalStorage.getThemeMode());
  const [languageSettingsMode, setLanguageSettingsMode] = useState<string>(
    LocalStorage.getLanguageSettingsMode()
  ); /**< Language settings mode */

  const didChangeTheme = async (theme: ThemeMode) => {
    LocalStorage.setThemeMode(theme);
    setThemeMode(theme);

    if (!window.location.pathname.includes(SharedCommomRouteName.loginRoute)) {
      if (
        LocalStorage.getXAuthToken() &&
        !isStringEmptyOrNullOrUndefined(LocalStorage.getXUserUUID())
      ) {
        dispatch(
          updateUserThemeModeStateAction({
            userUUID: LocalStorage.getXUserUUID(),
            themeMode: theme
          })
        ); /** Update user theme mode preference in database */
      }
    }
  };

  /**
   * Change user language preference
   * @param language
   */
  const didChangeLanguage = async (language: string): Promise<void> => {
    LocalStorage.setLanguageSettingsMode(language);
    setLanguageSettingsMode(language);
    if (!window.location.pathname.includes(SharedCommomRouteName.loginRoute)) {
      if (
        LocalStorage.getXAuthToken() &&
        !isStringEmptyOrNullOrUndefined(LocalStorage.getXUserUUID())
      ) {
        dispatch(
          updateUserLanguageSettingsModeStateAction({
            userUUID: LocalStorage.getXUserUUID(),
            languageSettingsMode: language
          })
        ); /** Update user language settings mode preference in database */
      }
    }
  };

  /// handle error when updating user theme mode state
  useEffect(() => {
    if (userAPIState.updateThemeModeStateError) {
      showToast({
        severity: 'error',
        summary: translation('userAPI.update_theme_mode_failed'),
        detail: translation('I18N.error_messages.update_user_theme_mode_state')
      });
    }
  }, [userAPIState.updateThemeModeStateError]);

  /// handle error when updating user language settings mode state
  useEffect(() => {
    if (userAPIState.updateLanguageSettingsModeStateError) {
      showToast({
        severity: 'error',
        summary: translation('userAPI.update_language_settings_mode_failed'),
        detail: translation('I18N.error_messages.update_user_language_settings_mode_state')
      });
    }
  }, [userAPIState.updateLanguageSettingsModeStateError]);

  useEffect(() => {
    dispatch(getSafeSettings());
    dispatch(reconnectAction());
    dispatch(getFavouriteIconAction());
  }, [dispatch]);

  useEffect(() => {
    setIsAppReconnecting(authState.isReconnecting);
  }, [authState.isReconnecting]);

  useEffect(() => {
    // We should update the received URL token.
    LocalStorage.setTokenFromURL();

    if (authState.isAuthenticated && !authState.isProfileLoaded) {
      dispatch(getAuthenticatedUserProfile());
    }
  }, [authState]);

  useEffect(() => {
    const getImage = async () => {
      setFavouriteIconX180(settings.resizedFavouriteIconImageURLs.favouriteIconImageURLLarge);
      setFavouriteIconX32(settings.resizedFavouriteIconImageURLs.favouriteIconImageURLMedium);
      setFavouriteIconX16(settings.resizedFavouriteIconImageURLs.favouriteIconImageURLSmall);
    };
    getImage();
  }, [settings.resizedFavouriteIconImageURLs]);

  /// load custom css to application
  useEffect(() => {
    const customCssLink: string = settings?.safeSettings?.customCssLink;
    if (customCssLink && !settings.settingsIsFetching) {
      loadCss(BASE_API_URL + customCssLink, 'customCss');
    }
  }, [settings.safeSettings, settings.settingsIsFetching]);

  const fnLogout = () => {
    customLocalStorageClearUtil();
    const parameters: URLSearchParams = new URLSearchParams({
      logout: 'true',
      themeMode: LocalStorage.getThemeMode(),
      languageSettingsMode: LocalStorage.getLanguageSettingsMode()
    }); /**< Query parameters */
    window.location.href = `${SharedCommomRouteName.validateRoute}?${parameters.toString()}`;
  };

  const renderRootPath = () => {
    // we can check on auState.isAuthenticated
    if (!LocalStorage.getXAuthToken()) {
      return <Redirect to={SharedCommomRouteName.validateRoute} />;
    }

    if (!isAppReconnecting) {
      if (authState.isAdmin) {
        return <Redirect to={SharedMainRouteName.adminRoute} />;
      } else if (!authState.isAdmin) {
        return <Redirect to={SharedMainRouteName.clientRoute} />;
      }
    } else {
      return <Loader />;
    }
  };

  useEffect(() => {
    if (themeMode) {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => e.matches && didChangeTheme(ThemeMode.darkMode));

      window
        .matchMedia('(prefers-color-scheme: light)')
        .addEventListener('change', (e) => e.matches && didChangeTheme(ThemeMode.lightMode));

      changeUserTheme(LocalStorage.getThemeMode());
    }
  }, [themeMode]);

  useEffect(() => {
    if (languageSettingsMode) {
      changeUserLanguage(languageSettingsMode, i18n);
    }
  }, [languageSettingsMode]);

  // Listen to changes in localStorage.
  // HttpClient.ts file in AWC will fire a storage event when token is rewened to update theme-mode and language-settings-mode.
  // AuthenticationVerificationPage.tsx file will fire a storage event when redirect logic is applied to get theme mode and language settings mode from another application.
  window.addEventListener('storage', (event: StorageEvent) => {
    // Only triggers here when token is refresh in AWC/Client/HttpClient.ts
    if (event.key && event.key === LocalStorage.themeModeKey) {
      setThemeMode((prevState: string) => {
        prevState = event.newValue;
        return prevState;
      });
      LocalStorage.setThemeMode(event.newValue);
    } else if (event.key && event.key === LocalStorage.languageSettingsModeKey) {
      setLanguageSettingsMode((prevState: string) => {
        prevState = event.newValue;
        return prevState;
      });
      LocalStorage.setLanguageSettingsMode(event.newValue);
    } else {
      setThemeMode((prevState: string) => {
        prevState = LocalStorage.getThemeMode();
        return prevState;
      });
      setLanguageSettingsMode((prevState: string) => {
        prevState = LocalStorage.getLanguageSettingsMode();
        return prevState;
      });
    }
  });

  /**
   * Get TopBar wrapper element
   */
  const getTopBarWrapper = (): JSX.Element => {
    return (
      <TopBar
        languageSettingsMode={languageSettingsMode}
        didChangeLanguage={didChangeLanguage}
        i18nService={i18n}
        isThemeModeVisible={false}
      />
    );
  };

  return (
    <>
      {settings && settings.safeSettings && (
        <main className="d-flex flex-column h-100">
          <Helmet>
            <title>{settings.safeSettings.applicationTitle}</title>
            <link rel="apple-touch-icon" sizes="180x180" href={favouriteIconX180} />
            <link rel="icon" type="image/png" sizes="32x32" href={favouriteIconX32} />
            <link rel="icon" type="image/png" sizes="16x16" href={favouriteIconX16} />
          </Helmet>
          <AlertToast />
          <ErrorHandler />
          <React.Suspense fallback={<Loader />}>
            <Router>
              <ScrollToTop />
              <Switch>
                <Route exact path="/" render={() => renderRootPath()} />
                <Route path={SharedCommomRouteName.loginRoute} component={LoginPage} />
                <Route
                  path={SharedCommomRouteName.validateRoute}
                  component={AuthenticationVerificationPage}
                />
                <Route path={SharedCommomRouteName.configurationSettingsRoute}>
                  <>
                    {getTopBarWrapper()}
                    <ConfigurationSettingsPage />
                  </>
                </Route>
                <Route path={RouteName.newsletterUnsubscribeRoute}>
                  <NewsletterUnSubscriptionPage />
                </Route>
                <Route path={RouteName.newsletterUnsubscribeByEmailRoute}>
                  <NewsletterUnSubscriptionByEmailPage />
                </Route>
                <Route path={SharedCommomRouteName.code404Route}>
                  <NotFound />
                </Route>
                <Route path={SharedMainRouteName.adminRoute}>
                  <AdminPanel
                    fnLogout={fnLogout}
                    themeMode={themeMode}
                    didChangeTheme={didChangeTheme}
                    languageSettingsMode={languageSettingsMode}
                    didChangeLanguage={didChangeLanguage}
                  />
                </Route>
                <Route path={SharedMainRouteName.clientRoute}>
                  <ClientPanel
                    fnLogout={fnLogout}
                    themeMode={themeMode}
                    didChangeTheme={didChangeTheme}
                    languageSettingsMode={languageSettingsMode}
                    didChangeLanguage={didChangeLanguage}
                  />
                </Route>
                <Route path={RouteName.eulaPermissionRoute}>
                  <div className="text-dark login-container login-pages-global-container">
                    {getTopBarWrapper()}
                    <EulaPermissionPage />
                  </div>
                </Route>
                <Route path={RouteName.slaPermissionRoute}>
                  <div className="text-dark login-container login-pages-global-container">
                    {getTopBarWrapper()}
                    <SLAPermissionPage />
                  </div>
                </Route>
                <Route>
                  <Redirect to={{ pathname: SharedCommomRouteName.code404Route }} />
                </Route>
              </Switch>
            </Router>
          </React.Suspense>
        </main>
      )}
    </>
  );
}

export default Main;
