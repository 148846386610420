/*
 * Templates.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Templates.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { IAPIEntityResponse } from '@abstract/abstractwebcommon-shared/interfaces/api';
import { AnyAction, createAsyncThunk, createSlice, ThunkDispatch } from '@reduxjs/toolkit';
import { IReducerAction } from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  createOrUpdate,
  fetchPublicTemplate,
  getAllCSSTemplates,
  getCSS,
  read,
  updateCss
} from '../Services/Template';
import { getSafeSettings } from './Settings';
import { ITemplate } from '@abstract/abstractwebcommon-shared/interfaces/license/template';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const TEMPLATE_FEATURE_KEY = 'templates';
/**
 * Interface templateState
 */
interface ITemplateState {
  list?: any;
  templatesIsFetching?: boolean;
  publicTemplates?: any;
  publicTemplatesIsFetching?: boolean;
  templateIsChanging?: boolean;
  template?: any;
  css?: string;
  allCSSTemplates?: any[];
  cssIsFetching?: boolean;
  cssIsChanging?: boolean;
  placeholders?: any;
  placeholderIsFetching?: boolean;
  placeholderIsChanging?: boolean;
}

export const INITIAL_STATE: ITemplateState = {
  list: {
    template: null
  },
  templatesIsFetching: false,
  publicTemplates: {
    template: []
  },
  publicTemplatesIsFetching: false,
  templateIsChanging: false,
  template: null,
  css: '',
  allCSSTemplates: [],
  cssIsFetching: false,
  cssIsChanging: false,
  placeholders: {},
  placeholderIsFetching: false,
  placeholderIsChanging: false
};

/**
 * Creates Slice - All User related state will be stored here
 */
export const templateSlice = createSlice({
  name: TEMPLATE_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    getTemplatesRequest(state: ITemplateState) {
      state.templatesIsFetching = true;
    },
    getTemplatesSuccess(state: ITemplateState, action: IReducerAction<ITemplate[]>) {
      state.templatesIsFetching = false;
      state.list.template = action.payload;
    },
    getTemplatesFailure(state: ITemplateState) {
      state.templatesIsFetching = false;
    },
    getPublicTemplatesRequest(state: ITemplateState) {
      state.publicTemplatesIsFetching = true;
    },
    getPublicTemplatesSuccess(state: ITemplateState, action: IReducerAction<ITemplate[]>) {
      state.publicTemplatesIsFetching = false;
      state.publicTemplates = action.payload;
    },
    getPublicTemplatesFailure(state: ITemplateState) {
      state.publicTemplatesIsFetching = false;
    },
    updatePlaceholderRequest(state: ITemplateState) {
      state.placeholderIsChanging = true;
    },
    updatePlaceholderSuccess(state: ITemplateState, action: IReducerAction<any>) {
      state.placeholderIsChanging = false;
      state.placeholders = action.payload;
    },
    updatePlaceholderFailure(state: ITemplateState) {
      state.placeholderIsChanging = false;
    },
    getCssRequest(state: ITemplateState) {
      state.cssIsFetching = true;
    },
    getCssSuccess(state: ITemplateState, action: IReducerAction<ITemplate>) {
      state.cssIsFetching = false;
      state.css = action.payload.html;
    },
    getCssFailure(state: ITemplateState) {
      state.cssIsFetching = false;
    },
    getAllCSSRequest(state: ITemplateState) {
      state.cssIsFetching = true;
    },
    getAllCSSSuccess(state: ITemplateState, action: IReducerAction<ITemplate[]>) {
      state.cssIsFetching = false;
      state.allCSSTemplates = action.payload;
    },
    getAllCSSFailure(state: ITemplateState) {
      state.cssIsFetching = false;
    },
    updateCssRequest(state: ITemplateState) {
      state.cssIsChanging = true;
    },
    updateCssSuccess(state: ITemplateState) {
      state.cssIsChanging = false;
    },
    updateCssFailure(state: ITemplateState) {
      state.cssIsChanging = false;
    },
    updateTemplatesRequest(state: ITemplateState) {
      state.templatesIsFetching = true;
    },
    updateTemplatesSuccess(state: ITemplateState) {
      state.templatesIsFetching = false;
    },
    updateTemplatesFailure(state: ITemplateState) {
      state.templatesIsFetching = false;
    }
  },
  extraReducers: {}
});

export const templateReducer = templateSlice.reducer;
export const templateActions = templateSlice.actions;

/**
 * Get all templates Action.
 */
export const getTemplates = createAsyncThunk('template/all', async (payload: void, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getTemplatesSuccess, getTemplatesRequest, getTemplatesFailure } = templateActions;

  try {
    dispatch(getTemplatesRequest());
    const response: IAPIEntityResponse<ITemplate[]> = await asyncErrorHandler(read());
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getTemplatesFailure());
    } else {
      dispatch(getTemplatesSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getTemplatesFailure());
    handleError({ message: error.message });
  }
});

/**
 * Get all templates Action.
 */
export const getPublicTemplates = createAsyncThunk(
  'template/public',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getPublicTemplatesSuccess, getPublicTemplatesRequest, getPublicTemplatesFailure } =
      templateActions;

    try {
      dispatch(getPublicTemplatesRequest());
      const response: IAPIEntityResponse<ITemplate[]> = await asyncErrorHandler(
        fetchPublicTemplate()
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getPublicTemplatesFailure());
      } else {
        dispatch(getPublicTemplatesSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getPublicTemplatesFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Update templates Action.
 * @param payload
 */
export const updateTemplate = createAsyncThunk(
  'template/update',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { updateTemplatesRequest, updateTemplatesSuccess, updateTemplatesFailure } =
      templateActions;

    try {
      dispatch(updateTemplatesRequest());
      const response = await asyncErrorHandler(createOrUpdate(payload));
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(updateTemplatesFailure());
      } else {
        dispatch(updateTemplatesSuccess());
        showSuccessToast(response.message);
        dispatch(getTemplates());
      }
    } catch (error: any) {
      dispatch(updateTemplatesFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Update placeholder Action.
 * @param payload
 */
export const updatePlaceholder = createAsyncThunk(
  'template/placeholder/update',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const state: any = getState();
    const { updatePlaceholderRequest, updatePlaceholderSuccess, updatePlaceholderFailure } =
      templateActions;

    try {
      dispatch(updatePlaceholderRequest());
      const { key, value, type } = payload;
      const newPlaceholderForType = { ...state.templates.placeholders[type], [key]: value };
      const newPlaceholder = { ...state.templates.placeholders, [type]: newPlaceholderForType };
      dispatch(updatePlaceholderSuccess(newPlaceholder));
    } catch (error: any) {
      dispatch(updatePlaceholderFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Get css Action.
 */
export const fetchCss = createAsyncThunk('template/css/get', async (payload: void, thunkAPI) => {
  const dispatch: ThunkDispatch<unknown, unknown, AnyAction> = thunkAPI.dispatch;
  const getState: any = thunkAPI.getState;
  const { getCssSuccess, getCssRequest, getCssFailure } = templateActions;

  try {
    dispatch(getCssRequest());
    dispatch(getSafeSettings()).then(async () => {
      const state: any = getState();
      if (!state.settings.safeSettings.selectedCSSTemplate) {
        dispatch(getCssFailure());
      } else {
        const response: IAPIEntityResponse<ITemplate> = await asyncErrorHandler(
          getCSS(state.settings.safeSettings.selectedCSSTemplate.templateUUID)
        );
        if (response.error) {
          handleError({ message: response.error.message });
          dispatch(getCssFailure());
        } else {
          dispatch(getCssSuccess(response.data));
        }
      }
    });
  } catch (error: any) {
    dispatch(getCssFailure());
    handleError({ message: error.message });
  }
});

/**
 * Get all css template Action.
 */
export const fetchAllCSSTemplate = createAsyncThunk(
  'template/css/get/all',
  async (payload: void, thunkAPI) => {
    const dispatch: ThunkDispatch<unknown, unknown, AnyAction> = thunkAPI.dispatch;
    const { getAllCSSSuccess, getAllCSSRequest, getAllCSSFailure } = templateActions;

    try {
      dispatch(getAllCSSRequest());
      const response: IAPIEntityResponse<ITemplate[]> = await asyncErrorHandler(
        getAllCSSTemplates()
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getAllCSSFailure());
      } else {
        dispatch(getAllCSSSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getAllCSSFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Update css Action.
 */
export const updateCssTemplate = createAsyncThunk(
  'template/css/get',
  async (payload: string, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { updateCssSuccess, updateCssRequest, updateCssFailure } = templateActions;

    try {
      dispatch(updateCssRequest());
      const response: IAPIEntityResponse<ITemplate> = await asyncErrorHandler(updateCss(payload));
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(updateCssFailure());
      } else {
        dispatch(updateCssSuccess());
        showSuccessToast(response.message);
        dispatch(fetchCss());
      }
    } catch (error: any) {
      dispatch(updateCssFailure());
      handleError({ message: error.message });
    }
  }
);
