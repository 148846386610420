import React, { useState, useCallback, ReactElement, useEffect } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import Button from 'react-bootstrap/Button';
import {
    bytesToSize,
    maximumFileSize,
    removeDuplicatesOnArrayOfObject
  } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import "./index.css";
import LimitRatio from '../FormControl/LimitRatio';
import { useTranslation, TFunction } from "react-i18next";

/**
 * @interface IFileUploadProperties
 */
 interface IFileUploadProperties {
    maximumTotalUploadSize: number /**< Maximum Total file upload size. */;
    getUploadedFiles: (file: Array<FileWithPath>) => void /** Array of files to upload. */;
    isFileSizeLimitVisible: boolean; /**< Is file size limit is visible or not. */
    droppedFiles?: FileWithPath[] /**< Drag & dropped files. */;
    [x:string]: any /**< Other properties. */;
    getSubmitButton?: JSX.Element; /**< Submit button. */
  }

/**
 * To remove svg files
 * @param attachedFiles 
 * @returns 
 */
export const removeSVGFile = (attachedFiles: FileWithPath[]): FileWithPath[] => {
  const invalidFileExtension: string = 'svg'; /**< Invalid image file extension. */
  // Check an image file is invalid or not based on file extensions.
  attachedFiles.forEach((attachedFile: FileWithPath) => {
    if (attachedFile.type.match('image/' + invalidFileExtension)) {
      attachedFiles = attachedFiles.filter(
        (file: FileWithPath) => file.name !== attachedFile.name
      ); // Remove the svg file.
    }
  });

  return attachedFiles;
};

const FileUpload = (properties: IFileUploadProperties): ReactElement => {
    const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
    const [isFileTooBig, setFileTooBig] = useState<boolean>(false);
    
    const translate: TFunction = useTranslation().t;

    const { maximumTotalUploadSize, getUploadedFiles, getSubmitButton, ...rest } = properties

    /// Get Total File size
    const getTotalFileSize = (files: FileWithPath[]) : number => {
      return files.reduce(
        (previousValue, currentValue) => previousValue + currentValue.size,
        0
      );
    }

    /// To update total file size in span
    const updateTotalFileSizeSpan = (files: FileWithPath[]) => {
      const totalFileSize: number = getTotalFileSize(files); // Total file size
      if (document.getElementById('file-size')) {
        document.getElementById('file-size').innerHTML = `${
          bytesToSize(totalFileSize)
        }`;
      }
    }

    /// Handle uploaded files.
    const onUploadedFilesHandler = (files: FileWithPath[]) => {
      updateTotalFileSizeSpan(files); // To update total size in span
      setFileTooBig(false);
      if (maximumFileSize(files, maximumTotalUploadSize)) {
        setFileTooBig(true);
        return;
      }
      setUploadedFiles(files);
      getUploadedFiles(files);
    }

    const onDrop = useCallback(
        (acceptedFiles: FileWithPath[]) => {
          acceptedFiles = removeSVGFile(acceptedFiles); // To remove svg files.

          const files = removeDuplicatesOnArrayOfObject([...uploadedFiles, ...acceptedFiles], 'name');
          onUploadedFilesHandler(files);
        },
        [uploadedFiles]
      );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop
      });

    const removeFile = (file: FileWithPath) => {
        const newFiles: FileWithPath[] = [...uploadedFiles];
        newFiles.splice(uploadedFiles.indexOf(file), 1);
        updateTotalFileSizeSpan(newFiles); // To update total size in span
        setUploadedFiles(newFiles);
        getUploadedFiles(newFiles)
    };
    
    // Get File details in table
    const getFiles = () => {
      return (
        <table className="w-100 m-2">
          {uploadedFiles.map((file: FileWithPath) => (
            <tr key={file.path}>
              <td>
                <span>{file.path}</span>
              </td>
              <td>
                <div className='d-flex justify-content-between align-items-center float-right'>
                  <span>{bytesToSize(file.size)}</span>
                  <span>
                    <i className="pi pi-times-circle ml-2 icon-danger" onClick={() => removeFile(file)}></i>
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </table>
      );
    };

    /// Set uploadedFiles when dragged files changes
    useEffect(() => {
      if (properties.droppedFiles && properties.droppedFiles.length) {
        onUploadedFilesHandler(properties.droppedFiles);
      }
    }, [properties.droppedFiles]);

    useEffect(() => {
      if(document.getElementById('file-size')) {
        document.getElementById('file-size').innerHTML = `${
          bytesToSize(0)
        }`;
      }
    },[]);

    return (
      <div>
        <div className='d-sm-flex justify-content-between'>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button
              {...rest}
              type="button"
              className="d-flex align-items-center justify-content-center mr-2 w-100 text-center">
                {translate('awc:/.image.attachFile')}
            </Button>
          </div>
          {getSubmitButton}
        </div>
        <div>
          {getFiles()}
          <div className="d-flex justify-content-between">
            <div>
              {isFileTooBig ? (
                <span>
                  <small id="file-invalid" className="p-invalid">
                    {translate('awc:/.image.totalFileSize')}
                  </small>
                </span>
              ) : null}
            </div>
            {properties.isFileSizeLimitVisible && (
              <LimitRatio
              id="file-size"
              allowedLimit={bytesToSize(maximumTotalUploadSize)}
              className={isFileTooBig ? 'p-invalid' : ''}
            />
            )}
          </div>
          
        </div>
      </div>
      );
}

export default FileUpload;