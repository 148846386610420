/**
 * UserAPI.ts (abstractuser)
 * Copyright © 2023 InstaMaterial GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all it's contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 * @file UserAPI.ts
 * @author Rafael Rodrigues
 * @copyright 2023 InstaMaterial GmbH. All rights reserved.
 * @section License
 */

import {
  IUserAPIActionsProperties,
  UserAPIActions
} from '@abstract/abstractwebcommon-client/store/UserAPISlice';
import { createLogApi } from '../Services/Log';
import { BASE_API_URL } from '../config';

const userAPIActionsProperties: IUserAPIActionsProperties = {
  baseAPIURL: BASE_API_URL,
  onCreateErrorLog: createLogApi
};

const userAPIActions = new UserAPIActions(userAPIActionsProperties);

export const updateUserThemeModeStateAction = userAPIActions.updateUserThemeModeStateAction;
export const updateUserLanguageSettingsModeStateAction =
  userAPIActions.updateUserLanguageSettingsModeStateAction;
