/*
 * Licenses.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Licenses.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  allLicenseTemplates,
  createOrUpdate,
  getLicenseByUUID,
  getTotalLicenseCount,
  read,
  remove,
  removeEulaEntitlementFromUserLicense,
  removeSLAEntitlementFromUserLicense
} from '../Services/License';
import { formatTableSortOrder } from '../Utils/Formatter';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IReducerAction,
  PaginationRequestAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IAPIEntityResponse,
  IAPIErrorData,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ILicense } from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import { IPaginationSort } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const LICENSE_FEATURE_KEY = 'licenses';

/**
 * Interface license State
 */
export interface ILicenseState {
  list: [];
  listIsFetching: boolean;
  license: any;
  licenseIsFetching: boolean;
  licenseIsChanging: boolean;
  filter: any;
  sort: IPaginationSort<ILicense>;
  skip: number;
  limit: number;
  templates: [];
  licenseCount: number | null;
  fetchError: IAPIErrorData | null;
  userData: IUser[] | null /**< UserData */;
  isEulaEntitlementRemoved: boolean /**< Eula Entitlement removed */;
  isSLAEntitlementRemoved: boolean /**< Eula Entitlement removed */;
  removeEntitlementStatusCode: number /**< Status code return */;
  searchTerm?: string /* Search component value **/;
}

/**
 * License Intial state
 */
const INITIAL_STATE: ILicenseState = {
  list: null,
  listIsFetching: false,
  license: null,
  licenseIsFetching: false,
  licenseIsChanging: false,
  filter: {},
  sort: {
    sortField: 'created',
    sortOrder: -1
  },
  skip: 0,
  limit: defaultTableLimit,
  templates: [],
  licenseCount: null,
  fetchError: null,
  userData: null,
  isEulaEntitlementRemoved: false,
  isSLAEntitlementRemoved: false,
  removeEntitlementStatusCode: null,
  searchTerm: ''
};

interface IFilterLicenseObject {
  projectName?: any;
  licenseMaxCount?: any;
  isActive?: boolean /**< License status(active/ inactive) based on the licenseEndDate. */;
  id?: number[] /**< License IDs */;
  licenseType?: string[] /**< License Type */;
}

/**
 * Interface license form values
 */
interface ILicenseFormValues {
  license_start_date: any;
  license_end_date: any;
  projectName: string;
  licenseMaxCount: number;
  entitlements: Array<string>;
  userUUID: string;
}

interface IFormAndRefresh {
  license: ILicenseFormValues;
  refresh: () => void;
}

export const licenseSlice = createSlice({
  name: LICENSE_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    resetMyLicensesStates(state: ILicenseState) {
      state.license = null;
    },
    getLicensesRequest(state: ILicenseState, action: PaginationRequestAction<ILicense>) {
      const { skip, limit, filter, sort, searchTerm } = action.payload;
      if (filter) {
        state.filter = filter;
      }
      if (sort) {
        state.sort = sort as IPaginationSort<ILicense>;
      }
      state.skip = skip;
      state.limit = limit;
      state.listIsFetching = true;
      state.searchTerm = searchTerm;
    },
    getLicensesSuccess(state: ILicenseState, action: IReducerAction<Record<string, any>>) {
      state.list = action.payload.licenses;
      state.userData = action.payload.users;
      state.listIsFetching = false;
    },
    getLicensesFailure(state: ILicenseState) {
      state.listIsFetching = false;
    },
    getLicenseByUUIDRequest(state: ILicenseState) {
      state.licenseIsFetching = true;
    },
    getLicenseByUUIDSuccess(state: ILicenseState, action: IReducerAction<Record<string, any>>) {
      state.license = action.payload;
      state.licenseIsFetching = false;
    },
    getLicenseByUUIDFailure(state: ILicenseState) {
      state.licenseIsFetching = false;
      state.license = null;
    },
    addLicenseRequest(state: ILicenseState) {
      state.licenseIsChanging = true;
    },
    addLicenseSuccess(state: ILicenseState) {
      state.licenseIsChanging = false;
    },
    addLicenseFailure(state: ILicenseState) {
      state.licenseIsChanging = false;
    },
    updateLicenseRequest(state: ILicenseState) {
      state.licenseIsChanging = true;
    },
    updateLicenseSuccess(state: ILicenseState) {
      state.licenseIsChanging = false;
    },
    updateLicenseFailure(state: ILicenseState) {
      state.licenseIsChanging = false;
    },
    deleteLicenseRequest(state: ILicenseState) {
      state.licenseIsChanging = true;
    },
    deleteLicenseSuccess(state: ILicenseState) {
      state.licenseIsChanging = false;
    },
    deleteLicenseFailure(state: ILicenseState) {
      state.licenseIsChanging = false;
    },
    fetchLicenseTemplatesRequest(state: ILicenseState) {
      state.templates = [];
    },
    fetchLicenseTemplatesSuccess(state: ILicenseState, action: IReducerAction<ILicense[]>) {
      state.templates = action.payload as any;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTotalLicenseCountAction.pending, (state: ILicenseState) => {
        state.listIsFetching = true;
        state.fetchError = null;
      })
      .addCase(
        getTotalLicenseCountAction.fulfilled,
        (state: ILicenseState, action: IReducerAction<IAPIEntityResponse<ILicense>>) => {
          if (action.payload.data) {
            state.licenseCount = action.payload.data.licenseCount;
          }

          if (action.payload.error) {
            state.listIsFetching = false;
            state.licenseCount = null;
            state.fetchError = action.payload.error;
          }
        }
      )
      .addCase(removeEulaEntitlementFromUserLicensesAction.pending, (state: ILicenseState) => {
        state.licenseIsChanging = true;
        state.removeEntitlementStatusCode = null;
      })
      .addCase(
        removeEulaEntitlementFromUserLicensesAction.fulfilled,
        (state: ILicenseState, action: IReducerAction<IAPIEntityResponse<void>>) => {
          if (action.payload.status === 200) {
            state.isEulaEntitlementRemoved = true;
          }

          if (action.payload.status !== 200) {
            state.removeEntitlementStatusCode = action.payload.status;
          }

          state.licenseIsChanging = false;
        }
      )

      .addCase(removeSLAEntitlementFromUserLicensesAction.pending, (state: ILicenseState) => {
        state.licenseIsChanging = true;
        state.removeEntitlementStatusCode = null;
      })
      .addCase(
        removeSLAEntitlementFromUserLicensesAction.fulfilled,
        (state: ILicenseState, action: IReducerAction<IAPIEntityResponse<void>>) => {
          if (action.payload.status === 200) {
            state.isSLAEntitlementRemoved = true;
          }

          if (action.payload.status !== 200) {
            state.removeEntitlementStatusCode = action.payload.status;
          }

          state.licenseIsChanging = false;
        }
      );
  }
});

export const licenseReducer = licenseSlice.reducer;
export const licenseActions = licenseSlice.actions;

/**
 * Get all licenses Action.
 * @param payload
 */
export const getAllLicenses = createAsyncThunk('license/all', async (payload: any, thunkAPI) => {
  const { dispatch } = thunkAPI;
  const { getLicensesRequest, getLicensesSuccess, getLicensesFailure } = licenseActions;

  try {
    const { skip, limit, filter } = payload;
    const sortField: string = payload.sort?.sortField; /**< Sort field. */
    const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
    const searchTerm: string = payload?.searchTerm; /**< Search term to find. */

    dispatch(
      getLicensesRequest({ skip, limit, filter, sort: { sortField, sortOrder }, searchTerm })
    );

    const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';
    const formattedFilters: IFilterLicenseObject = {};

    if (filter && filter.projectName) {
      formattedFilters.projectName = filter.projectName.value;
    }
    if (filter && filter.licenseMaxCount) {
      formattedFilters.licenseMaxCount = filter.licenseMaxCount.value;
    }
    if (filter && filter.active) {
      formattedFilters.isActive =
        filter.active.value === 'Active'
          ? true
          : false; /** Formatting Active column's filter value  */
    }
    if (filter && filter.id) {
      formattedFilters.id = filter.id.value;
    } /**< Custom license filter */
    if (filter && filter.licenseType) {
      formattedFilters.licenseType = filter.licenseType.value;
    } /**< License Type filter */

    const response: PaginatedAPIEntityResponse<ILicense> = await asyncErrorHandler(
      read(searchTerm, null, skip, limit, sortField, formattedSortOrder, formattedFilters)
    );
    if (response.error) {
      handleError({ message: response.error.message });
      dispatch(getLicensesFailure());
    } else {
      dispatch(getLicensesSuccess(response.data));
    }
  } catch (error: any) {
    dispatch(getLicensesFailure());
    handleError({ message: error.message });
  }
});

/**
 * Create license Action.
 * @param payload
 */
export const createLicense = createAsyncThunk(
  'license/create',
  async (payload: IFormAndRefresh, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addLicenseRequest, addLicenseSuccess, addLicenseFailure } = licenseActions;
    const { license, refresh } = payload;

    try {
      dispatch(addLicenseRequest());
      const result: IAPIEntityResponse<ILicense> = await asyncErrorHandler(createOrUpdate(license));
      if (result.error) {
        dispatch(addLicenseFailure());
        handleError({ message: result.error.message });
        return false;
      } else {
        dispatch(addLicenseSuccess());
        showSuccessToast(result.message);
        if (result.isEmailSent) {
          showSuccessToast(result.emailMessage);
        }

        refresh();
        return true;
      }
    } catch (error: any) {
      dispatch(addLicenseFailure());
      handleError({ message: error.message });
      return false;
    }
  }
);

/**
 * Update licenses Action.
 * @param payload
 */
export const updateLicense = createAsyncThunk(
  'license/update',
  async (payload: IFormAndRefresh, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const { updateLicenseRequest, updateLicenseSuccess, updateLicenseFailure } = licenseActions;
    const { license, refresh } = payload;
    try {
      dispatch(updateLicenseRequest());
      const result: IAPIEntityResponse<ILicense> = await asyncErrorHandler(createOrUpdate(license));
      if (result.error) {
        dispatch(updateLicenseFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateLicenseSuccess());
        showSuccessToast(result.message);

        refresh(); // refresh settings
        dispatch(
          getAllLicenses({
            skip: store.licenses.skip,
            limit: store.licenses.limit,
            sort: {
              sortField: store.licenses.sort.sortField,
              sortOrder: store.licenses.sort.sortOrder
            },
            filter: store.licenses.filter,
            searchTerm: store.licenses.searchTerm
          })
        );
      }
    } catch (error: any) {
      dispatch(updateLicenseFailure());
      handleError({ message: error.error.message });
    }
  }
);

/**
 * Delete License Action.
 * @param payload
 */
export const deleteLicense = createAsyncThunk('license/delete', async (payload: any, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const store: any = getState();
  const { deleteLicenseRequest, deleteLicenseSuccess, deleteLicenseFailure } = licenseActions;

  try {
    dispatch(deleteLicenseRequest());
    const result: IAPIEntityResponse<void> = await asyncErrorHandler(remove(payload));
    if (result.error) {
      dispatch(deleteLicenseFailure());
      handleError({ message: result.error.message });
    } else {
      dispatch(deleteLicenseSuccess());
      showSuccessToast(result.message);
      dispatch(
        getAllLicenses({
          skip: store.licenses.skip,
          limit: store.licenses.limit,
          sort: {
            sortField: store.licenses.sort.sortField,
            sortOrder: store.licenses.sort.sortOrder
          },
          searchTerm: store.licenses.searchTerm
        })
      );
    }
  } catch (error: any) {
    dispatch(deleteLicenseFailure());
    handleError({ message: error?.error?.message || '' });
  }
});

// license templates
/**
 * Get one license Action.
 * @param payload
 */
export const fetchLicenseTemplates = createAsyncThunk(
  'license/fetchTemplates',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { fetchLicenseTemplatesRequest, fetchLicenseTemplatesSuccess } = licenseActions;

    try {
      const { filter } = payload;

      dispatch(fetchLicenseTemplatesRequest());
      const result: IAPIEntityResponse<ILicense[]> = await asyncErrorHandler(
        allLicenseTemplates(filter)
      );
      if (result.error) {
        handleError({ message: result.error.message });
      } else {
        dispatch(fetchLicenseTemplatesSuccess(result.data));
      }
    } catch (error: any) {
      handleError({ message: error.message });
    }
  }
);

/**
 * Fetches total licenses count.
 */
export const getTotalLicenseCountAction = createAsyncThunk('licenses/count', async () => {
  return getTotalLicenseCount();
});

/**
 * Remove Eula entitlement from user license Action.
 * @param userUUID userUUID
 */
export const removeEulaEntitlementFromUserLicensesAction = createAsyncThunk(
  `/removeEulaEntitlementFromUserLicense`,
  async () => {
    const response: IAPIEntityResponse<void> = await asyncErrorHandler(
      removeEulaEntitlementFromUserLicense()
    );
    return response;
  }
);

/**
 * Remove SLA entitlement from user license Action.
 * @param userUUID userUUID
 */
export const removeSLAEntitlementFromUserLicensesAction = createAsyncThunk(
  `/removeSLAEntitlementFromUserLicense`,
  async () => {
    const response: IAPIEntityResponse<void> = await removeSLAEntitlementFromUserLicense();
    return response;
  }
);

/**
 * Get License information by license UUID.
 * @param payload
 */
export const getLicenseByUUIDAction = createAsyncThunk(
  'license/licenseUUID',
  async (payload: string, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getLicenseByUUIDRequest, getLicenseByUUIDSuccess, getLicenseByUUIDFailure } =
      licenseActions;

    try {
      dispatch(getLicenseByUUIDRequest());
      const result: IAPIEntityResponse<ILicense> = await asyncErrorHandler(
        getLicenseByUUID(payload)
      );
      if (result.error) {
        dispatch(getLicenseByUUIDFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(getLicenseByUUIDSuccess(result.license));
      }
    } catch (error: any) {
      dispatch(getLicenseByUUIDFailure());
      handleError({ message: error.message });
    }
  }
);
