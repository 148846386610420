/*
 * EntitlementTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file EntitlementTable.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, useEffect, ReactElement, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import ImageRow from '@abstract/abstractwebcommon-client/Table/ImageRow/ImageRow';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import EntitlementForm from './EntitlementForm';
import { getAllFileRecords } from '../../../Store/FileRecords';
import {
  getAllEntitlements,
  updateEntitlement,
  deleteEntitlement,
  setAreAllImagesLoaded
} from '../../../Store/Entitlements';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IEntitlementStateSelector,
  IFileRecordStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { IFileRecord } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecord';

const EntitlementTable = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entitlements: IEntitlementStateSelector = useSelector(
    (state: IStateSelectors) => state.entitlements
  );
  const fileRecords: IFileRecordStateSelector = useSelector(
    (state: IStateSelectors) => state.fileRecords
  );
  const entitlementList = entitlements.list;
  const isUpdateSuccessful: boolean = useSelector(
    (state: IStateSelectors) => state.entitlements.entitlementUpdateSuccess
  );
  let filesOptions: Array<any> = [];
  if (fileRecords && fileRecords.list && fileRecords.list.records) {
    filesOptions = fileRecords.list.records.map((eachFile: any) => {
      const temporaryFile = { ...eachFile };
      delete temporaryFile.linkedEntitlements;
      return temporaryFile;
    });
  }

  const [entitlement, setEntitlement] = useState<any>();
  const [isEditPage, toggleIsEditPage] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [selectedEntitlements, setSelectedEntitlements] =
    useState<IEntitlement[]>(null); /**< Selected Entitlements. */
  const deleteButtonReference: any = useRef(null); /**< Delete Button Reference. */
  const [expandedRows, setExpandedRows] = useState<IEntitlement>({});
  const [isImageLoading, setImageLoading] = useState<boolean>(true);
  const [imageCount, setImageCount] = useState<number>(1);

  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'created',
      sortOrder: -1
    }
  }); /**< Default Payload */

  const handleEditDialog = (data: any) => {
    setEntitlement(data);
    toggleIsEditPage(true);
  };

  /// update entitlement
  const handleUpdate = (entitlementData: any) => {
    dispatch(updateEntitlement(entitlementData));
  };

  /// Action to trigger license addition process
  const handleAdd = async () => {
    setEntitlement(null);
    toggleIsEditPage(true);
  };

  const handleSortUpdate = (e: any) => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      },
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllEntitlements(updatedPayload));
  };

  const handlePageUpdate = (e: any) => {
    const { first, rows } = e;
    const updatedPayload = {
      skip: first,
      limit: rows,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllEntitlements(updatedPayload));
  };

  const hideDialog = () => toggleIsEditPage(false);

  const onFilter = (event: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: event.filters,
      searchTerm: event
    };
    setPayload(updatedPayload);
    dispatch(getAllEntitlements(updatedPayload));
  };

  useEffect(() => {
    if (isUpdateSuccessful) {
      toggleIsEditPage(false);
    }
  }, [isUpdateSuccessful]);

  useEffect(() => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      }
    };
    setPayload(updatedPayload);
    dispatch(getAllEntitlements(updatedPayload));
  }, [dispatch, payload.sort.sortOrder, payload.sort.sortField]);

  useEffect(() => {
    const fileRecordPayload = {
      skip: 0,
      limit: 100 // TODO: fix limit similar to client drop down
    };
    dispatch(getAllFileRecords(fileRecordPayload));
  }, [dispatch]);

  const onAccept = async () => {
    const entitlementUUIDs: string[] = selectedEntitlements?.map(
      (eachEntitlement: IEntitlement) => eachEntitlement.entitlementUUID
    ); /**< entitlementUUIDs */
    dispatch(deleteEntitlement(entitlementUUIDs));
    setShowConfirmation(false);
    setSelectedEntitlements(null);
  };

  const onReject = () => {
    setShowConfirmation(false);
  };

  const getConfirmPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={t('/confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };

  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={
            ((selectedEntitlements && Object.keys(selectedEntitlements).length === 0) ||
              !selectedEntitlements) ??
            false
          }
          buttonReference={deleteButtonReference}
        />{' '}
        {/* To delete Entitlements. */}
        <ActionButton onClick={handleAdd} />
      </div>

      <SearchBar onSearchTermChanged={(data: string) => onFilter(data)} />
    </div>
  );

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Handles selection change event
  const onSelectionChange = (event: any) => {
    const rows: IEntitlement[] = event.value;
    if (Array.isArray(rows)) {
      const selectedRows: IEntitlement[] = rows.map((row: IEntitlement) => {
        return row;
      });
      setSelectedEntitlements(selectedRows);
    }
  };

  const GetRowExpansionTemplate = (values: { rowData: IEntitlement }) => (
    <>
      <tr>
        <th>{t('admin.page.entitlement.form.entitlementUUID')}</th>
        <td>{values.rowData['entitlementUUID']}</td>
      </tr>
      <tr>
        <th>{t('admin.page.entitlement.form.entitlementName')}</th>
        <td>{values.rowData['name']}</td>
      </tr>
      <tr>
        <th>{t('admin.page.entitlement.form.displayName')}</th>
        <td>{values.rowData['displayName']}</td>
      </tr>
      <tr>
        <th>{t('admin.page.entitlement.form.entitlementDescription')}</th>
        <td>{values.rowData['description']}</td>
      </tr>
      <tr>
        <th>{t('admin.page.entitlement.form.live')}</th>
        <td>{<ShowCheckOrUncheckIcon value={values.rowData.live} />}</td>
      </tr>
      <tr>
        <th>{t('admin.page.entitlement.form.isDeactivatable')}</th>
        <td>{<ShowCheckOrUncheckIcon value={values.rowData.isDeactivatable} />}</td>
      </tr>
      <tr>
        <th>{t('admin.page.entitlement.form.use24HoursTimeout')}</th>
        <td>{<ShowCheckOrUncheckIcon value={values.rowData.use24HoursTimeout} />}</td>
      </tr>
      <tr>
        <th>{t('admin.page.entitlement.form.files')}</th>
        <td>
          {values.rowData.files.map((file: IFileRecord) => {
            return (
              <div key={file.fileRecordUUID} className="d-flex">
                <span>{file.name}</span>
              </div>
            );
          })}
        </td>
      </tr>
      <tr>
        <th>{t('admin.page.entitlement.form.created')}</th>
        <td>{values.rowData.created ? formatDate(values.rowData.created) : ''}</td>
      </tr>
    </>
  );

  const renderExpansionRows = (rowData: IEntitlement) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.entitlementUUID]: true });
    }
  };

  useEffect(() => {
    if (!isImageLoading || (entitlementList?.records && entitlementList?.records.length === 0)) {
      dispatch(setAreAllImagesLoaded());
    }
  }, [isImageLoading, entitlementList?.records]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <BaseDatatable
            value={entitlementList && entitlementList.records}
            totalRecords={entitlementList && entitlementList.totalRecords}
            isLoading={!entitlementList || entitlements.isLoadingImages}
            expandedRows={expandedRows}
            dataKey="entitlementUUID"
            onRowExpand={expandRow}
            onRowCollapse={() => setExpandedRows({})}
            rowExpansionTemplate={renderExpansionRows}
            header={header}
            parentClass="entitlementDataTable" /**< ClassName for div Component.*/
            emptyMessage={t('I18N.admin.entitlements.empty_msg')}
            sortField={payload.sort.sortField}
            onSort={handleSortUpdate}
            sortOrder={payload.sort.sortOrder}
            first={payload.skip}
            rows={payload.limit}
            bodyStyle={'text-center'}
            onPage={handlePageUpdate}
            responsive /**< Datatable responsive layout.*/
            selection={selectedEntitlements}
            onSelectionChange={(event: any) => onSelectionChange(event)}>
            <Column expander className="p-0 col-width-3" headerClassName="p-0 col-width-3" />
            <Column selectionMode="multiple" className="col-width-3" />
            <Column
              field="imageUrl"
              body={(rows: any) => (
                <ImageRow
                  setImageLoading={setImageLoading}
                  imageURL={rows.imageURL}
                  totalImagesCount={
                    entitlementList &&
                    entitlementList.records.filter(
                      (entitlementData: IEntitlement) => entitlementData.imageURL
                    ).length
                  }
                  setImageCount={setImageCount}
                  imageCount={imageCount}
                  classNames="convert-image-filter"
                />
              )}
              header={t('admin.page.entitlement.form.image')}
              className="icon-column-style d-none d-sm-table-cell col-width-5"
              headerClassName="icon-column-style d-none d-sm-table-cell col-width-5"
            />
            <Column
              field="name"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.entitlement.form.entitlementName')}
                  data={rows.name}
                />
              )}
              header={t('admin.page.entitlement.form.entitlementName')}
              sortable
              className="d-table-cell d-sm-none d-xl-table-cell col-width-15"
              headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-15"
            />
            <Column
              field="displayName"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.entitlement.form.displayName')}
                  data={rows.displayName}
                />
              )}
              header={t('admin.page.entitlement.form.displayName')}
              sortable
              className="col-width-15"
            />
            <Column
              sortable
              field="description"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.entitlement.form.entitlementDescription')}
                  data={rows.description}
                />
              )}
              header={t('admin.page.entitlement.form.entitlementDescription')}
              className="d-table-cell d-sm-none d-md-table-cell col-width-25"
              headerClassName="d-table-cell d-sm-none d-md-table-cell col-width-25"
            />
            <Column
              sortable
              field="live"
              header={t('admin.page.entitlement.form.live')}
              className="col-width-5"
              body={(row: any) => (
                <DatatableColumn
                  title={t('admin.page.entitlement.form.live')}
                  data={<ShowCheckOrUncheckIcon value={row.live} />}
                />
              )}
            />
            <Column
              sortable
              field="isDeactivatable"
              header={t('admin.page.entitlement.form.isDeactivatable')}
              className="custom-header-min-width-allowed d-table-cell d-sm-none d-xl-table-cell"
              headerClassName="custom-header-min-width-allowed d-table-cell d-sm-none d-xl-table-cell"
              body={(row: any) => (
                <DatatableColumn
                  title={t('admin.page.entitlement.form.isDeactivatable')}
                  data={<ShowCheckOrUncheckIcon value={row.isDeactivatable} />}
                />
              )}
            />

            <Column
              field="created"
              header={t('admin.page.entitlement.form.created')}
              sortable
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.entitlement.form.created')}
                  data={formatDate(rows.created)}
                />
              )}
              className="createdDateCol d-table-cell d-sm-none d-xxl-table-cell"
              headerClassName="createdDateCol d-table-cell d-sm-none d-xxl-table-cell"
            />
            <Column
              field="edit"
              body={(rowData: any) => {
                return (
                  <Button
                    className="custom-action-column-action-position"
                    onClick={() => handleEditDialog(rowData)}
                    variant="outline">
                    <i className="bi bi-pencil-square editIcon fa-lg"></i>
                  </Button>
                );
              }}
              className="p-0 col-width-3 absolute-position-responsive-screen"
            />
          </BaseDatatable>
        </Col>
      </Row>

      <DialogWrapper
        isDialogVisible={isEditPage}
        headerTitle={
          entitlement
            ? t('admin.page.entitlement.form.header.edit_entitlement_dialog')
            : t('admin.page.entitlement.form.header.add_entitlement_dialog')
        }
        onHide={() => {
          toggleIsEditPage(false);
        }}>
        <EntitlementForm
          isLoading={entitlements.entitlementIsChanging}
          handleSubmit={handleUpdate}
          entitlement={entitlement}
          filesOptions={filesOptions}
          hideDialog={hideDialog}
        />
      </DialogWrapper>
      {getConfirmPopup()}
    </>
  );
};

export default EntitlementTable;
