/*
 * ActivationTotalCount.tsx (License)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file ActivationTotalCount.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { useSelector } from 'react-redux';
import MenuBadgeCount from '@abstract/abstractwebcommon-client/Sidebar/MenuBadgeCount/MenuBadgeCount';

import { IActivationStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { getTotalActivationCountAction } from '../../../Store/Activations';

/**
 * Display the total of activations registered
 * @returns JSX.Element
 */
const ActivationTotalCount = (): JSX.Element => {
  const activations: IActivationStateSelector = useSelector(
    (state: IStateSelectors) => state.activations
  );

  return (
    <MenuBadgeCount
      getTotalItemCount={getTotalActivationCountAction}
      itemCount={activations.activationCount}
      fetchError={activations.fetchError}
      itemName="activations"
    />
  );
};

export default ActivationTotalCount;
