/*
 * RecordsPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file RecordsPage.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { translate } from '../../../Utils/Translate';
import ReportsTable from './ReportsTable';
import ReportForm from '../../Admin/Reports/ReportForm';
import { createReport, fetchUserReport } from '../../../Store/Report';
import {
  IReportStateSelector,
  IStateSelectors,
  ISettingsStateSelector
} from '../../../Interfaces/Selectors';
import { IReport } from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';

/**
 * Report component
 */
const RecordsPage: React.FC<any> = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const report: IReportStateSelector = useSelector((state: IStateSelectors) => state.report);
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const [isEditingPage, setEditingPage] = useState<boolean>(false);
  const [tablePayload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      created: 'DESC'
    },
    searchTerm: ''
  });
  let defaultSortField, defaultSortOrder: string; // Default sort criteria from tableData.
  Object.keys(tablePayload.sort).forEach((key) => {
    defaultSortField = key; // Sortfield
    defaultSortOrder = tablePayload.sort[key]; //Sortorder
  });
  const [multiSortMeta, setMultiSortMeta] = useState<Record<string, number>[]>([
    { field: defaultSortField, order: defaultSortOrder === 'ASC' ? 1 : -1 }
  ]);

  /// Action to trigger files addition process
  const handleAdd = async (): Promise<void> => {
    setEditingPage(true);
  };

  /// Handles Report form submission
  const handleReportSubmit = (payload: IReport) => {
    dispatch(createReport(payload));
  };
  /// Triggers on sort btn click. This sends a request to the backend with the specific column information along with the ASC|DESC indicator
  const onSort = (event: any) => {
    const updatedPayload: ITablePayload = tablePayload;
    updatedPayload.sort = {};
    for (let i = 0; i < event.multiSortMeta.length; i++) {
      updatedPayload.sort[event.multiSortMeta[i].field] =
        event.multiSortMeta[i].order === 1 ? 'ASC' : 'DESC';
    }
    setPayload(updatedPayload);
    setMultiSortMeta(event.multiSortMeta);
    dispatch(fetchUserReport(updatedPayload));
  };

  const onFilter = (termToFind: string) => {
    Object.assign(tablePayload, { searchTerm: termToFind });
    dispatch(fetchUserReport(tablePayload));
  };

  const handlePageUpdate = (event: any) => {
    const updatedPayload = {
      skip: event.first,
      limit: event.rows,
      sort: {
        sortField: tablePayload.sort.sortField,
        sortOrder: tablePayload.sort.sortOrder
      },
      searchTerm: tablePayload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(fetchUserReport(updatedPayload));
  };

  useEffect(() => {
    if (report.isCreatedSuccess) {
      dispatch(fetchUserReport(tablePayload));
      setEditingPage(false);
    }
  }, [report.isCreatedSuccess]);

  useEffect(() => {
    dispatch(fetchUserReport(tablePayload));
  }, []);

  return (
    <>
      <Row>
        <Col>
          <ReportsTable
            onSort={onSort}
            multiSortMeta={multiSortMeta}
            reports={report.reports}
            handleAdd={handleAdd}
            isLoading={report.isLoading}
            onFilter={onFilter}
            handlePageUpdate={handlePageUpdate}
            tablePayload={tablePayload}
            totalRecords={report.totalRecords}
          />
        </Col>
      </Row>
      <DialogWrapper
        className="add-participant-dialog"
        isDialogVisible={isEditingPage}
        headerTitle={translate('client.page.reports.table.reportAnIssue')}
        onHide={() => setEditingPage(false)}>
        <ReportForm
          handleSubmit={handleReportSubmit}
          isLoading={report.isCreatedLoading}
          settings={settings}
        />
      </DialogWrapper>
    </>
  );
};

export default RecordsPage;
