/*
 * EntitlementModal.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file EntitlementModal.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { Column } from 'primereact/column';

import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import {
  formatDate,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import {
  IPageEvent,
  IPaginationResponse,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { IActivation } from '@abstract/abstractwebcommon-shared/interfaces/license/activation';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import { ILicense } from '@abstract/abstractwebcommon-shared/interfaces/license/license';

import {
  IActivationStateSelector,
  ILicenseStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import { getLicenseByUUIDAction, licenseActions } from '../../../Store/Licenses';
import { fetchLicenseActivations, activationActions } from '../../../Store/Activations';

import './EntitlementModal.scss';

interface IEntitlementModal {
  isShowEntitlementModal: boolean /** If the modal should be rendered. */;
  setShowEntitlementModal: React.Dispatch<
    React.SetStateAction<boolean>
  > /** State to manage the modal visualization. */;
  selectedLicenseUUID: string /** License UUID to fetch information. */;
  setSelectedLicenseUUID: React.Dispatch<
    React.SetStateAction<string>
  > /** State to manage the selected license. */;
}

const EntitlementModal: React.FC<IEntitlementModal> = (properties) => {
  const translation: TFunction = useTranslation().t;

  const dispatch = useDispatch();

  const licenses: ILicenseStateSelector = useSelector((state: IStateSelectors) => state.licenses);

  const activationState: IActivationStateSelector = useSelector(
    (state: IStateSelectors) => state.activations
  ); /**< Activation State */
  const licenseActivations: IPaginationResponse<IActivation> =
    activationState.licenseActivations; /**< License Activations. */

  const [activationPayload, setActivationPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'activationDate',
      sortOrder: -1
    },
    searchTerm: ''
  }); /**< Default Payload */

  // Handle sort update event in the Activations table
  const handleActivationsSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = activationPayload; /**< Updated Payload. */

    Object.assign(updatedPayload, {
      sort: event
    });

    setActivationPayload(updatedPayload);

    dispatch(
      fetchLicenseActivations({
        ...updatedPayload,
        licenseUUID: properties.selectedLicenseUUID
      })
    );
  };

  // Handle page update event in the Activations table
  const handleActivationsPageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = activationPayload; /**< Updated Payload. */

    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });

    setActivationPayload(updatedPayload);

    dispatch(
      fetchLicenseActivations({
        ...updatedPayload,
        licenseUUID: properties.selectedLicenseUUID
      })
    );
  };

  const headerTable = (headerTitle: string | TFunction): JSX.Element => (
    <div className="text-center">{headerTitle}</div>
  );

  useEffect(() => {
    if (!isStringEmptyOrNullOrUndefined(properties.selectedLicenseUUID)) {
      dispatch(getLicenseByUUIDAction(properties.selectedLicenseUUID));

      dispatch(
        fetchLicenseActivations({
          ...activationPayload,
          licenseUUID: properties.selectedLicenseUUID
        })
      );
    }
  }, [properties.selectedLicenseUUID]);

  const LicenseTable = (): JSX.Element => (
    <BaseDatatable
      value={[licenses.license]}
      parentClass="licenseDataTable your-license-section nestedDatatable nestedDatatable-withoutPagination" /**< ClassName for div Component.*/
      header={headerTable(licenses.license.projectName)}
      lazy
      paginator={false}
      emptyMessage={translation('I18N.admin.license.empty_msg')}>
      <Column className="d-none" />
      <Column selectionMode="multiple" className="d-none" />
      <Column
        field="name"
        header={translation('admin.page.licenses.form.licenseEntitlement')}
        className="d-table-cell"
        headerClassName="d-table-cell"
        body={(rows: ILicense) => {
          const entitlementName = rows.entitlements.map(
            (entitlement: IEntitlement, index: number) => {
              return (
                <span key={index + 1}>
                  <span className="badge badge-secondary mr-2">{entitlement.displayName}</span>
                </span>
              );
            }
          );

          return (
            <DatatableColumn
              title={translation('admin.page.licenses.form.licenseEntitlement')}
              data={entitlementName}
            />
          );
        }}
      />
      <Column
        field="licenseMaxCount"
        className="d-table-cell col-width-7"
        headerClassName="d-table-cell col-width-7"
        header={translation('admin.page.licenses.form.licenseMaxCount')}
        body={(rows: ILicense) => (
          <DatatableColumn
            title={translation('admin.page.licenses.form.licenseMaxCount')}
            data={rows.licenseMaxCount}
          />
        )}
      />
      <Column
        field="active"
        className="d-table-cell col-width-5"
        headerClassName="d-table-cell col-width-5"
        header={translation('admin.page.licenses.form.active')}
        body={(rows: ILicense) => (
          <DatatableColumn
            title={translation('admin.page.licenses.form.active')}
            data={<ShowCheckOrUncheckIcon value={new Date(rows.licenseEndDate) > new Date()} />}
          />
        )}
      />
      <Column
        field="isPaused"
        className="d-table-cell col-width-5"
        headerClassName="d-table-cell col-width-5"
        header={translation('admin.page.licenses.form.isPaused')}
        body={(rows: ILicense) => (
          <DatatableColumn
            title={translation('admin.page.licenses.form.isPaused')}
            data={<ShowCheckOrUncheckIcon value={rows.isPaused} />}
          />
        )}
      />
      <Column
        field="licenseStartDate"
        header={translation('admin.page.licenses.form.startDate')}
        body={(rows: ILicense) => (
          <DatatableColumn
            title={translation('admin.page.licenses.form.startDate')}
            data={formatDate(rows.licenseStartDate)}
            isEllipsisVisible={false}
          />
        )}
        className="d-table-cell createdDateCol"
        headerClassName="d-table-cell createdDateCol"
      />
      <Column
        field="licenseEndDate"
        header={translation('admin.page.licenses.form.endDate')}
        className="d-table-cell d-sm-none d-lg-table-cell createdDateCol"
        headerClassName="d-table-cell d-sm-none d-lg-table-cell createdDateCol"
        body={(rows: ILicense) => (
          <DatatableColumn
            isEllipsisVisible={false}
            title={translation('admin.page.licenses.form.endDate')}
            data={formatDate(rows.licenseEndDate)}
          />
        )}
      />
    </BaseDatatable>
  );

  // If a license object exists in one of the activation objects, then display the project name of that license.
  const isProjectNameColumnVisible: boolean = licenseActivations?.records?.find(
    (activation: IActivation) =>
      activation.license !== undefined && Object.keys(activation.license).length
  )
    ? true
    : false;

  const ActivationsTable = (): JSX.Element => (
    <BaseDatatable
      header={headerTable(translation('admin.page.activations.activationsLabel'))}
      value={licenseActivations?.records}
      totalRecords={licenseActivations?.totalRecords ?? 0}
      parentClass="mt-2" /**< ClassName for div Component.*/
      responsive /**< Datatable responsive layout.*/
      sortField={activationPayload.sort.sortField}
      onSort={handleActivationsSortUpdate}
      sortOrder={activationPayload.sort.sortOrder}
      first={activationPayload.skip}
      rows={activationPayload.limit}
      onPage={handleActivationsPageUpdate}
      lazy
      emptyMessage={translation('I18N.admin.license.no_activations')}>
      <Column selectionMode="multiple" className="d-none" />
      <Column
        sortable
        field="machine"
        className="col-width-12"
        header={translation('admin.page.activations.form.machine')}
        body={(rows: IActivation) => (
          <DatatableColumn
            title={translation('admin.page.activations.form.machine')}
            data={rows.machine}
          />
        )}
      />
      <Column
        sortable
        field="machineIP"
        className="col-width-12 d-table-cell"
        header={translation('admin.page.activations.form.ip')}
        body={(rows: IActivation) => (
          <DatatableColumn
            title={translation('admin.page.activations.form.ip')}
            data={rows.machineIP}
          />
        )}
      />
      {isProjectNameColumnVisible && (
        <Column
          field="license.projectName"
          header={translation('admin.page.licenses.form.licenseProjectName')}
          body={(rows: IActivation) => (
            <DatatableColumn
              title={translation('admin.page.licenses.form.licenseProjectName')}
              data={rows?.license?.projectName}
            />
          )}
          className="pl-0 d-table-cell d-sm-none d-md-table-cell col-width-15"
          sortable
        />
      )}
      <Column
        sortable
        field="activationDate"
        header={translation('admin.page.activations.form.activationDate')}
        body={(rows: IActivation) => (
          <DatatableColumn
            title={translation('admin.page.activations.form.activationDate')}
            data={formatDate(rows.activationDate)}
          />
        )}
        className="col-width-12"
      />
      <Column
        sortable
        field="deactivationDate"
        header={translation('admin.page.activations.form.deactivationDate')}
        className="col-width-12 d-table-cell"
        body={(rows: IActivation) => (
          <DatatableColumn
            title={translation('admin.page.activations.form.deactivationDate')}
            data={formatDate(rows.deactivationDate)}
          />
        )}
      />
    </BaseDatatable>
  );

  return (
    <DialogWrapper
      isDialogVisible={properties.isShowEntitlementModal}
      headerTitle={translation('client.page.dashboard.myLicensesSection.entitlementModalTitle', {
        projectName: licenses?.license?.projectName
      })}
      onHide={() => {
        properties.setShowEntitlementModal(false);
        properties.setSelectedLicenseUUID(null);

        dispatch(licenseActions.resetMyLicensesStates());
        dispatch(activationActions.resetMyLicensesActivationsStates());
      }}
      className="table-width-992"
      blockScroll={false}>
      <Row>
        <Col xs={12} className="custom-entitlement-modal-md-breakpoint">
          {licenses?.license ? <LicenseTable /> : <></>}
          <ActivationsTable />
        </Col>
      </Row>
    </DialogWrapper>
  );
};

export default EntitlementModal;
