/*
 * LicenseTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file LicenseTable.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import './LicenseTableExpansion.css';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { IActivation } from '@abstract/abstractwebcommon-shared/interfaces/license/activation';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ITablePayload,
  ISortEvent,
  IPageEvent,
  IPaginationResponse
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { fetchLicenseActivations } from '../../../Store/Activations';
import { IActivationStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import ShowCheckOrUncheckIcon from '@abstract/abstractwebcommon-client/Table/ShowCheckOrUncheckIcon';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';

/**
 * @interface ILicenseTableExpansionProperties
 */
interface ILicenseTableExpansionProperties {
  licenseUUID: string /**< License UUID. */;
  clientList: IUser[] /**< Client List. */;
  clientListIsFetching: boolean /**< Client list is fetching or not. */;
  userUUID: string /**< User UUID. */;
  rowData: any /**< Row data. */;
}

const LicenseTableExpansion = (properties: ILicenseTableExpansionProperties): ReactElement => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const activationState: IActivationStateSelector = useSelector(
    (state: IStateSelectors) => state.activations
  ); /**< Activation State */
  const licenseActivations: IPaginationResponse<IActivation> =
    activationState.licenseActivations; /**< License Activations. */
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'activationDate',
      sortOrder: -1
    },
    searchTerm: ''
  }); /**< Default Payload */

  /// Fetch License Activations
  useEffect(() => {
    dispatch(
      fetchLicenseActivations({
        ...payload,
        licenseUUID: properties.licenseUUID
      })
    );
  }, []);

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(
      fetchLicenseActivations({
        ...updatedPayload,
        licenseUUID: properties.licenseUUID
      })
    );
  };

  /// Handle page update event
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(
      fetchLicenseActivations({
        ...updatedPayload,
        licenseUUID: properties.licenseUUID
      })
    );
  };

  // Client details for row expansion template.
  const getClientDetails = (): JSX.Element => {
    const client: IUser = properties.rowData.user;

    return (
      <>
        <tr>
          <th>{t('admin.page.clients.form.userId')}</th>
          <td>{client?.userUUID}</td>
        </tr>
        <tr>
          <th>{t('admin.page.clients.form.username')}</th>
          <td>{client?.username}</td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.licenseProjectName')}</th>
          <td>{properties.rowData.projectName}</td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.licenseEntitlement')}</th>
          <td>
            {properties.rowData.entitlements.map((entitlement: any, index: number) => {
              return (
                <span key={index + 1}>
                  <span className="badge badge-secondary mr-2">{entitlement.name}</span>
                </span>
              );
            })}
          </td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.licenseMaxCount')}</th>
          <td>{properties.rowData.licenseMaxCount}</td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.active')}</th>
          <td>
            {
              <ShowCheckOrUncheckIcon
                value={new Date(properties.rowData.licenseEndDate) > new Date()}
              />
            }
          </td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.isPaused')}</th>
          <td>{<ShowCheckOrUncheckIcon value={properties.rowData.isPaused} />}</td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.startDate')}</th>
          <td>{formatDate(properties.rowData.licenseStartDate)}</td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.endDate')}</th>
          <td>{formatDate(properties.rowData.licenseEndDate)}</td>
        </tr>
        <tr>
          <th>{t('admin.page.clients.form.firstname')}</th>
          <td>{client?.firstName}</td>
        </tr>
        <tr>
          <th>{t('admin.page.clients.form.lastname')}</th>
          <td>{client?.lastName}</td>
        </tr>
        <tr>
          <th>{t('admin.page.clients.form.email')}</th>
          <td>{client?.email}</td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.created')}</th>
          <td>{formatDate(properties.rowData.created)}</td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.isSublicense')}</th>
          <td>
            <ShowCheckOrUncheckIcon
              value={properties.rowData.isSublicense || properties.rowData.isParentLicense}
            />
          </td>
        </tr>
        <tr>
          <th>{t('admin.page.licenses.form.remainingMaxCount')}</th>
          <td>{properties.rowData.remainingSeats}</td>
        </tr>
      </>
    );
  };

  const header: JSX.Element = (
    <div className="text-center">{t('I18N.admin.license.activations')}</div>
  );
  // If a license object exists in one of the activation objects, then display the project name of that license.
  const isProjectNameColumnVisible: boolean = licenseActivations?.records?.find(
    (activation: IActivation) =>
      activation.license !== undefined && Object.keys(activation.license).length
  )
    ? true
    : false;

  return (
    <Container fluid={true} className={'px-0'}>
      <Row>
        <Col md={12}>
          <ExpansionRow>{getClientDetails()}</ExpansionRow>

          <ExpansionRow isSmallBreakpoint={true}>{getClientDetails()}</ExpansionRow>
        </Col>
        <Col md={12}>
          <Row>
            {properties.clientListIsFetching || activationState.licenseActivationsIsFetching ? (
              <Loader />
            ) : (
              <Col>
                <BaseDatatable
                  header={header}
                  value={licenseActivations?.records}
                  totalRecords={licenseActivations?.totalRecords}
                  parentClass="licenseActivationDataTable nestedDatatable" /**< ClassName for div Component.*/
                  responsive /**< Datatable responsive layout.*/
                  sortField={payload.sort.sortField}
                  onSort={handleSortUpdate}
                  sortOrder={payload.sort.sortOrder}
                  first={payload.skip}
                  rows={payload.limit}
                  onPage={handlePageUpdate}
                  lazy
                  emptyMessage={t('I18N.admin.license.no_activations')}>
                  <Column selectionMode="multiple" className="d-none" />
                  <Column
                    field="username"
                    className="col-width-12"
                    header={t('admin.page.activations.form.username')}
                    body={(rows: IActivation) => (
                      <DatatableColumn
                        title={t('admin.page.activations.form.username')}
                        data={rows.user?.username}
                      />
                    )}
                  />
                  <Column
                    sortable
                    field="machine"
                    className="col-width-12"
                    header={t('admin.page.activations.form.machine')}
                    body={(rows: any) => (
                      <DatatableColumn
                        title={t('admin.page.activations.form.machine')}
                        data={rows.machine}
                      />
                    )}
                  />
                  <Column
                    sortable
                    field="machineIP"
                    className="col-width-12"
                    header={t('admin.page.activations.form.ip')}
                    body={(rows: any) => (
                      <DatatableColumn
                        title={t('admin.page.activations.form.ip')}
                        data={rows.machineIP}
                      />
                    )}
                  />
                  {isProjectNameColumnVisible && (
                    <Column
                      field="license.projectName"
                      header={t('admin.page.licenses.form.licenseProjectName')}
                      body={(rows: IActivation) => (
                        <DatatableColumn
                          title={t('admin.page.licenses.form.licenseProjectName')}
                          data={rows?.license?.projectName}
                        />
                      )}
                      className="d-table-cell d-sm-none d-md-table-cell col-width-15"
                      sortable
                    />
                  )}
                  <Column
                    sortable
                    field="activationDate"
                    header={t('admin.page.activations.form.activationDate')}
                    body={(rows: any) => (
                      <DatatableColumn
                        title={t('admin.page.activations.form.activationDate')}
                        data={formatDate(rows.activationDate)}
                      />
                    )}
                    className="col-width-12 d-table-cell d-sm-none d-xl-table-cell"
                  />
                  <Column
                    sortable
                    field="deactivationDate"
                    header={t('admin.page.activations.form.deactivationDate')}
                    className="col-width-12 d-table-cell d-sm-none d-xl-table-cell"
                    body={(rows: any) => (
                      <DatatableColumn
                        title={t('admin.page.activations.form.deactivationDate')}
                        data={formatDate(rows.deactivationDate)}
                      />
                    )}
                  />
                </BaseDatatable>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LicenseTableExpansion;
