/**
 * License.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file License.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import { mapSortingFields, mapFilterFields } from '../Utils/Pagination';
import { ILicense } from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Create or update a license
 * @param license License information
 * @returns Promise
 */
export const createOrUpdate = async (license: any): Promise<IAPIEntityResponse<ILicense>> => {
  // Send only entitlementUUID(s) in request payload
  const requestPayload: ILicense = { ...license };
  if (license.entitlements) {
    requestPayload.entitlements = license.entitlements.map((entitlement: IEntitlement) => ({
      entitlementUUID: entitlement.entitlementUUID
    }));
  }

  if (license.licenseUUID) {
    return httpClient.post<ILicense>(`/license/${license.licenseUUID}/update`, requestPayload);
  }

  return httpClient.post<ILicense>(`/license/create`, requestPayload);
};

/**
 * Gets licenses
 * @param id license id
 * @param skip Skip
 * @param limit Limit
 * @param sort Sort
 * @param sortOrder Sort order
 * @param data Data of filter values
 * @param searchTerm Search term to find
 * @returns Promise
 */
export const read = async (
  searchTerm: string,
  id?: string | null,
  skip = 0,
  limit = 10,
  sort = 'created',
  sortOrder = 'DESC',
  data = {}
): Promise<PaginatedAPIEntityResponse<ILicense>> => {
  const url: string = `/licenses/all`;
  const clientURL: string = `/licenses/all/client/${LocalStorage.getXUserUUID()}`; /**< Client license route */
  const isAdmin: boolean = LocalStorage.getAdmin();

  if (id) {
    return httpClient.get<ILicense>(`${url}/${id}`);
  } else {
    const mappedSort: string = mapSortingFields(sort, sortOrder);
    const mappedFilter: string = mapFilterFields(data, [
      'active',
      'id',
      'licenseType'
    ]); /** Remap operator of active filter as 'IN' */

    return httpClient.get<ILicense>(
      `${
        isAdmin ? url : clientURL
      }?filter=${mappedFilter}&skip=${skip}&limit=${limit}&sort=${mappedSort}&searchTerm=${encodeURIComponent(
        searchTerm ? searchTerm : ''
      )}`
    );
  }
};

/**
 * Deletes license(s) by licenseUUIDs
 * @param payload licenseUUIDs
 * @returns Promise
 */
export const remove = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post(`/licenses/delete`, { licenseUUIDs: payload });
};

/**
 * Fetches all license templates
 * @param filter Filter
 * @returns Promise
 */
export const allLicenseTemplates = (filter: string): Promise<IAPIEntityResponse<ILicense[]>> => {
  return httpClient.get<ILicense[]>(`/license/templates?filter=${filter || ''}`);
};

/**
 * Fetches total licenses count
 * @returns Promise<ILicense>
 */
export const getTotalLicenseCount = (): Promise<IAPIEntityResponse<ILicense>> => {
  const isAdmin: boolean = LocalStorage.getAdmin();
  const endpointTypeName: string = isAdmin ? 'admin' : 'client';
  return httpClient.get<ILicense>(`/licenses/${endpointTypeName}/get/count`);
};

/**
 * Remove Eula entitlement from user license
 * @returns Promise
 */
export const removeEulaEntitlementFromUserLicense = (): Promise<IAPIEntityResponse<void>> => {
  return httpClient.get<void>(`/license/eula/accept`);
};

/**
 * Remove SLA entitlement from user license
 * @returns Promise
 */
export const removeSLAEntitlementFromUserLicense = (): Promise<IAPIEntityResponse<void>> => {
  return httpClient.get<void>(`/license/sla/accept`);
};

/**
 * Get License information by license UUID
 * @param licenseUUID License UUID
 * @returns Promise
 */
export const getLicenseByUUID = (licenseUUID: string): Promise<IAPIEntityResponse<ILicense>> => {
  return httpClient.get<ILicense>(`/license/${licenseUUID}`);
};
