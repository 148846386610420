/*
 * LicenseTemplates.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file LicenseTemplates.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { copyLicenseTemplate, createOrUpdate, read, remove } from '../Services/LicenseTemplate';
import { formatTableSortOrder } from '../Utils/Formatter';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IReducerAction,
  PaginationRequestAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ILicenseTemplate } from '@abstract/abstractwebcommon-shared/interfaces/license/licenseTemplate';
import { IPaginationSort } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const LICENSE_TEMPLATE_FEATURE_KEY = 'licenseTemplates';

/**
 * Interface licenseTemplate State
 */
interface ILicenseTemplateState {
  list: [];
  listIsFetching: boolean;
  licenseTemplate: any;
  licenseTemplateIsFetching: boolean;
  licenseTemplateIsChanging: boolean;
  filter: any;
  sort: IPaginationSort<ILicenseTemplate>;
  skip: number;
  limit: number;
  searchTerm: string /* Search component value **/;
  templates: [];
}

/**
 * LicenseTemplate Intial state
 */
const INITIAL_STATE: ILicenseTemplateState = {
  list: null,
  listIsFetching: false,
  licenseTemplate: null,
  licenseTemplateIsFetching: false,
  licenseTemplateIsChanging: false,
  filter: {},
  sort: {
    sortField: 'created',
    sortOrder: -1
  },
  skip: 0,
  limit: defaultTableLimit,
  templates: [],
  searchTerm: ''
};

interface IFilterLicenseTemplateObject {
  projectName?: any;
}

/**
 * Interface licenseTemplate form values
 */
interface ILicenseTemplateFormValues {
  duration: string;
  projectName: string;
  entitlements: Array<string>;
  sku: string;
}

interface IFormAndRefresh {
  licenseTemplate: ILicenseTemplateFormValues;
  refresh: () => void;
}

export const licenseTemplateSlice = createSlice({
  name: LICENSE_TEMPLATE_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    getLicenseTemplatesRequest(
      state: ILicenseTemplateState,
      action: PaginationRequestAction<ILicenseTemplate>
    ) {
      const { skip, limit, filter, sort, searchTerm } = action.payload;
      if (filter) {
        state.filter = filter;
      }
      if (sort) {
        state.sort = sort as IPaginationSort<ILicenseTemplate>;
      }
      state.skip = skip;
      state.limit = limit;
      state.listIsFetching = true;
      state.searchTerm = searchTerm;
    },
    getLicenseTemplatesSuccess(
      state: ILicenseTemplateState,
      action: IReducerAction<PaginatedAPIEntityResponse<ILicenseTemplate>>
    ) {
      state.list = action.payload as any;
      state.listIsFetching = false;
    },
    getLicenseTemplatesFailure(state: ILicenseTemplateState) {
      state.listIsFetching = false;
    },
    addLicenseTemplateRequest(state: ILicenseTemplateState) {
      state.licenseTemplateIsChanging = true;
    },
    addLicenseTemplateSuccess(state: ILicenseTemplateState) {
      state.licenseTemplateIsChanging = false;
    },
    addLicenseTemplateFailure(state: ILicenseTemplateState) {
      state.licenseTemplateIsChanging = false;
    },
    updateLicenseTemplateRequest(state: ILicenseTemplateState) {
      state.licenseTemplateIsChanging = true;
    },
    updateLicenseTemplateSuccess(state: ILicenseTemplateState) {
      state.licenseTemplateIsChanging = false;
    },
    updateLicenseTemplateFailure(state: ILicenseTemplateState) {
      state.licenseTemplateIsChanging = false;
    },
    deleteLicenseTemplateRequest(state: ILicenseTemplateState) {
      state.licenseTemplateIsChanging = true;
    },
    deleteLicenseTemplateSuccess(state: ILicenseTemplateState) {
      state.licenseTemplateIsChanging = false;
    },
    deleteLicenseTemplateFailure(state: ILicenseTemplateState) {
      state.licenseTemplateIsChanging = false;
    }
  },
  extraReducers: {}
});

export const licenseTemplateReducer = licenseTemplateSlice.reducer;
export const licenseTemplateActions = licenseTemplateSlice.actions;

/**
 * Get all licenseTemplates Action.
 * @param payload
 */
export const getAllLicenseTemplates = createAsyncThunk(
  'licenseTemplate/all',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getLicenseTemplatesRequest, getLicenseTemplatesSuccess, getLicenseTemplatesFailure } =
      licenseTemplateActions;

    try {
      const skip: number = payload.skip;
      const limit: number = payload.limit;
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const filter: any = payload.filter;
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */

      dispatch(
        getLicenseTemplatesRequest({
          skip,
          limit,
          filter,
          sort: { sortField, sortOrder },
          searchTerm
        })
      );

      const formattedSortOrder: any = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';
      const formattedFilters: IFilterLicenseTemplateObject = {};

      if (filter && filter.projectName) formattedFilters.projectName = filter.projectName.value;

      const response: PaginatedAPIEntityResponse<ILicenseTemplate> = await asyncErrorHandler(
        read(searchTerm, null, skip, limit, sortField, formattedSortOrder, formattedFilters)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getLicenseTemplatesFailure());
      } else {
        dispatch(getLicenseTemplatesSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getLicenseTemplatesFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Create licenseTemplate Action.
 * @param payload
 */
export const createLicenseTemplate = createAsyncThunk(
  'licenseTemplate/create',
  async (payload: IFormAndRefresh, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addLicenseTemplateRequest, addLicenseTemplateSuccess, addLicenseTemplateFailure } =
      licenseTemplateActions;
    const { licenseTemplate, refresh } = payload;

    try {
      dispatch(addLicenseTemplateRequest());
      const result: IAPIEntityResponse<ILicenseTemplate> = await asyncErrorHandler(
        createOrUpdate(licenseTemplate)
      );
      if (result.error) {
        dispatch(addLicenseTemplateFailure());
        handleError({ message: result.error.message });
        return false;
      } else {
        dispatch(addLicenseTemplateSuccess());
        showSuccessToast(result.message);
        if (result.isEmailSent) {
          showSuccessToast(result.emailMessage);
        }

        refresh();
        return true;
      }
    } catch (exception: any) {
      dispatch(addLicenseTemplateFailure());
      handleError({ message: exception.message });
      return false;
    }
  }
);

/**
 * Update licenseTemplates Action.
 * @param payload
 */
export const updateLicenseTemplate = createAsyncThunk(
  'licenseTemplate/update',
  async (payload: IFormAndRefresh, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const {
      updateLicenseTemplateRequest,
      updateLicenseTemplateSuccess,
      updateLicenseTemplateFailure
    } = licenseTemplateActions;
    const { licenseTemplate, refresh } = payload;
    try {
      dispatch(updateLicenseTemplateRequest());
      const result: IAPIEntityResponse<ILicenseTemplate> = await asyncErrorHandler(
        createOrUpdate(licenseTemplate)
      );
      if (result.error) {
        dispatch(updateLicenseTemplateFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateLicenseTemplateSuccess());
        showSuccessToast(result.message);
        refresh(); // refresh settings
        dispatch(
          getAllLicenseTemplates({
            skip: store.licenseTemplates.skip,
            limit: store.licenseTemplates.limit,
            sort: {
              sortField: store.licenseTemplates.sort.sortField,
              sortOrder: store.licenseTemplates.sort.sortOrder
            },
            filter: store.licenseTemplates.filter,
            searchTerm: store.licenseTemplates.searchTerm
          })
        );
      }
    } catch (exception: any) {
      dispatch(updateLicenseTemplateFailure());
      handleError({ message: exception && exception.error ? exception.error.message : '' });
    }
  }
);

/**
 * Delete LicenseTemplate Action.
 * @param payload
 */
export const deleteLicenseTemplate = createAsyncThunk(
  'licenseTemplate/delete',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const {
      deleteLicenseTemplateRequest,
      deleteLicenseTemplateSuccess,
      deleteLicenseTemplateFailure
    } = licenseTemplateActions;

    try {
      dispatch(deleteLicenseTemplateRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(remove(payload));
      if (result.error) {
        dispatch(deleteLicenseTemplateFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deleteLicenseTemplateSuccess());
        showSuccessToast(result.message);
        dispatch(
          getAllLicenseTemplates({
            skip: store.licenseTemplates.skip,
            limit: store.licenseTemplates.limit,
            sort: {
              sortField: store.licenseTemplates.sort.sortField,
              sortOrder: store.licenseTemplates.sort.sortOrder
            },
            filter: store.licenseTemplates.filter,
            searchTerm: store.licenseTemplates.searchTerm
          })
        );
      }
    } catch (exception: any) {
      dispatch(deleteLicenseTemplateFailure());
      handleError({ message: exception && exception.error ? exception.error.message : '' });
    }
  }
);

/**
 * Copy license template(s) Action.
 * @param payload
 */
export const copyLicenseTemplateAction = createAsyncThunk(
  'licenseTemplate/copy',
  async (payload: string[], thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addLicenseTemplateRequest, addLicenseTemplateSuccess, addLicenseTemplateFailure } =
      licenseTemplateActions;

    try {
      dispatch(addLicenseTemplateRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        copyLicenseTemplate(payload)
      );
      if (result.error) {
        dispatch(addLicenseTemplateFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(addLicenseTemplateSuccess());
        showSuccessToast(result.message);
      }
    } catch (error: any) {
      dispatch(addLicenseTemplateFailure());
      handleError({ message: error.message });
    }
  }
);
