/*
 * DeactivationPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file DeactivationPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { deactivateAllActivations, getUserActivations } from '../../../Store/Activations';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { getAllClientsWithoutPagination } from '../../../Store/Clients';
import { AutoComplete } from 'primereact/autocomplete';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import {
  IStateSelectors,
  IActivationStateSelector,
  IClientStateSelector
} from '../../../Interfaces/Selectors';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { translate } from '../../../Utils/Translate';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { IActivation } from '@abstract/abstractwebcommon-shared/interfaces/license/activation';
import { useTranslation } from 'react-i18next';

import './DeactivationPage.css';

/**
 * Interface for event from handlers.
 */
interface IEventValue<Entity> {
  value: Entity /**< Event value */;
}

/**
 * Autocomplete user details
 */
export interface IAutoCompleteUserDetails extends IUser {
  fullDetails: string /**< Full user details with username, email, firstname, lastname */;
}

const DeactivationPage = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activations: IActivationStateSelector = useSelector(
    (state: IStateSelectors) => state.activations
  );
  const clients: IClientStateSelector = useSelector((state: IStateSelectors) => state.clients);
  const [selectedClient, setSelectedClient] = useState<string | null>(null);
  const [searchedClient, setSearchedClient] = useState<string | IAutoCompleteUserDetails>('');
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const ref: any = useRef(null);
  const [isShowingConfirmation, setIsShowingConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [isListenerAdded, setIsListenerAdded] = useState<boolean>(false);
  const [expandedRows, setExpandedRows] = useState<IActivation>({});

  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'activationDate',
      sortOrder: -1
    },
    filter: {}
  }); /**< Default Payload */

  /// Delete roles on Accept
  const onAccept = (): void => {
    dispatch(deactivateAllActivations({ userUUID: selectedUserId }));
    setIsShowingConfirmation(false);
  };

  /// Hide confirmation on reject
  const onReject = (): void => {
    setIsShowingConfirmation(false);
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = (): JSX.Element => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isShowingConfirmation}
        title={translate('/confirm_messages.deactivate_activation')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translate('/confirm_messages.no')}
        acceptLabel={translate('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
      />
    );
  };

  /// show delete popup
  const deactivateAllButtonClick = (event: any): void => {
    setIsShowingConfirmation(true);
    setConfirmPopupTarget(event !== undefined && event.target);
  };

  const handleSortUpdate = (event: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: event.sortField,
        sortOrder: event.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getUserActivations({ data: updatedPayload, userUUID: selectedUserId }));
  };

  const handlePageUpdate = (event: any): void => {
    const { first, rows } = event;
    const updatedPayload = {
      skip: first,
      limit: rows,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getUserActivations({ data: updatedPayload, userUUID: selectedUserId }));
  };

  const handleClientChange = (event: IEventValue<string | object>): void => {
    if (typeof event.value === 'string') {
      setSearchedClient(event.value);
    } else if (typeof event.value === 'object') {
      const user: IUser = event.value;
      if (user) {
        setSearchedClient({
          ...user,
          fullDetails: `${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`
        });
      }
    }
  };
  const handleSelectedUser = (event: IEventValue<IUser>): void => {
    const userUUID: string = event.value.userUUID;
    const email: string = event.value.email;
    const firstName: string = event.value.firstName;
    const lastName: string = event.value.lastName;
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setSelectedUserId(userUUID);
    setSelectedClient(`${firstName} ${lastName} - ${email}`);
    dispatch(getUserActivations({ data: updatedPayload, userUUID: userUUID }));
  };
  const handleClientSearch = (event: any): void => {
    dispatch(getAllClientsWithoutPagination({ filter: event?.query.trim() || '' }));
  };

  const itemTemplate = (user: IUser): JSX.Element => {
    return <div>{`${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`}</div>;
  };

  const onFilter = (event: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: event.filters,
      searchTerm: event
    };
    setPayload(updatedPayload);
    dispatch(getUserActivations({ data: updatedPayload, userUUID: selectedUserId }));
  };

  /// Div containing header action buttons
  const getHeader = (): JSX.Element => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div className="headerTableContainer">
          <Button
            onClick={(event: any) => deactivateAllButtonClick(event)}
            ref={ref}
            className="mr-2 d-flex align-items-center deactivation-button-container"
            variant="danger"
            disabled={activations.userActivations.length === 0}>
            <i className="bi bi-x btn-icon"></i>
            {translate('admin.page.activations.deactivateAll')}
          </Button>
        </div>

        <SearchBar onSearchTermChanged={(data: string) => onFilter(data)} />
      </div>
    );
  };

  useEffect(() => {
    const clientFilter: any = document.getElementById('clientDropdown');
    if (clientFilter && !isListenerAdded) {
      clientFilter.addEventListener('keyup', () => {
        const clientFilterInArray: any = document.getElementsByClassName(
          'p-dropdown-filter p-inputtext p-component'
        );

        if (clientFilterInArray && clientFilterInArray[0]) {
          const clientFilterIn = clientFilterInArray[0];
          const filter = clientFilterIn.value;
          setIsListenerAdded(true);
          dispatch(getAllClientsWithoutPagination({ filter }));
        }
      });
    }
  }, [dispatch, isListenerAdded, clients.list?.records]);

  const GetRowExpansionTemplate = (values: { rowData: IActivation }) => (
    <>
      <tr>
        <th>{t('admin.page.activations.form.machine')}</th>
        <td>{values.rowData['machine']}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.ip')}</th>
        <td>{values.rowData['machineIP']}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.licenseProject')}</th>
        <td>{`${values.rowData.license ? values.rowData.license.projectName : '-'}`}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.notes')}</th>
        <td>{values.rowData.notes}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.activationDate')}</th>
        <td>{values.rowData.activationDate ? formatDate(values.rowData.activationDate) : ''}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.deactivationDate')}</th>
        <td>
          {values.rowData.deactivationDate ? formatDate(values.rowData.deactivationDate) : ''}
        </td>
      </tr>
    </>
  );

  const renderExpansionRows = (rowData: IActivation) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.activationUUID]: true });
    }
  };

  return (
    <Row>
      <Col xs={12}>
        <Card
          subTitle={translate('admin.page.activations.searchTitle')}
          className="p-mt-5 p-col-12">
          <Col className="pl-0">
            <AutoComplete
              value={searchedClient}
              suggestions={clients.list && clients.list?.records}
              completeMethod={handleClientSearch}
              field="fullDetails"
              onChange={handleClientChange}
              size={40}
              dropdown
              onSelect={handleSelectedUser}
              itemTemplate={itemTemplate}
              appendTo="self"
              className="custom-auto-complete-deactivation-page custom-autocomplete-padding"
            />
          </Col>
        </Card>
      </Col>
      {activations.userActivations && selectedClient ? (
        <Col xs={12}>
          <BaseDatatable
            value={activations.userActivations}
            totalRecords={activations.userTotalActivations}
            parentClass="activationDataTable" /**< ClassName for div Component.*/
            isLoading={!activations.userActivations}
            expandedRows={expandedRows}
            dataKey="activationUUID"
            onRowExpand={expandRow}
            onRowCollapse={() => setExpandedRows({})}
            rowExpansionTemplate={renderExpansionRows}
            header={getHeader()}
            onPage={handlePageUpdate}
            filters={payload.filter}
            sortField={payload.sort.sortField}
            onSort={handleSortUpdate}
            emptyMessage={translate('I18N.admin.activations.empty_msg')}
            sortOrder={payload.sort.sortOrder}
            first={payload.skip}
            rows={payload.limit}>
            <Column selectionMode="multiple" className="d-none" />
            <Column expander className="p-0 col-width-3" />
            <Column
              field="machine"
              header={translate('admin.page.activations.form.machine')}
              sortable
              body={(rows: any) => (
                <DatatableColumn
                  className="mt-2 mb-sm-0"
                  title={translate('admin.page.activations.form.machine')}
                  data={rows.machine}
                />
              )}
              className="col-width-25"
            />
            <Column
              sortable
              field="machineIP"
              header={translate('admin.page.activations.form.ip')}
              className="d-table-cell d-sm-none d-xl-table-cell col-width-10"
              headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-10"
              body={(rows: any) => (
                <DatatableColumn
                  title={translate('admin.page.activations.form.ip')}
                  data={rows.machineIP}
                />
              )}
            />
            <Column
              field="licenseProject"
              header={translate('admin.page.activations.form.licenseProject')}
              className="d-table-cell d-sm-none d-xl-table-cell col-width-15"
              headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-15"
              sortable
              body={(rows: any) => (
                <DatatableColumn
                  title={translate('admin.page.activations.form.licenseProject')}
                  data={rows.license ? rows.license.projectName : '-'}
                />
              )}
            />
            <Column
              sortable
              field="notes"
              header={translate('admin.page.activations.form.notes')}
              className="d-table-cell d-sm-none d-xl-table-cell col-width-25"
              headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-25"
              body={(rows: any) => (
                <DatatableColumn
                  title={translate('admin.page.activations.form.notes')}
                  data={rows.notes ? rows.notes : '-'}
                />
              )}
            />
            <Column
              field="activationDate"
              header={translate('admin.page.activations.form.activationDate')}
              sortable
              body={(rows: any) => (
                <DatatableColumn
                  title={translate('admin.page.activations.form.activationDate')}
                  data={formatDate(rows.activationDate)}
                />
              )}
              className="createdDateCol"
            />
            <Column
              field="deactivationDate"
              header={translate('admin.page.activations.form.deactivationDate')}
              sortable
              className="createdDateCol d-table-cell d-sm-none d-md-table-cell"
              headerClassName="createdDateCol d-table-cell d-sm-none d-md-table-cell"
              body={(rows: any) => (
                <DatatableColumn
                  title={translate('admin.page.activations.form.deactivationDate')}
                  data={formatDate(rows.deactivationDate)}
                />
              )}
            />
          </BaseDatatable>
        </Col>
      ) : (
        <Col xs={12}>
          <h3>{translate('admin.page.activations.selectUserFeedback')}</h3>
        </Col>
      )}
      {getConfirmPopup()}
    </Row>
  );
};

export default DeactivationPage;
