/*
 * LoginPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file LoginPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { Redirect } from 'react-router-dom';
import '@abstract/abstractwebcommon-client/LoginForm';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';

const LoginPage: React.FC<any> = () => {
  return <Redirect to={SharedCommomRouteName.validateRoute} />;
};

export default LoginPage;
