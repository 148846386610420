/*
 * InstaInputMultiselect.js (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file InstaInputMultiselect.js
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { Form } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

export const InstaInputMultiselect = ({
  label,
  labelClassName = '',
  labelAfter = '',
  fieldName,
  isLoading,
  handleChange,
  handleBlur,
  errors,
  touched,
  required = true,
  value,
  placeholder,
  optionLabel,
  options,
  sizing = { sm: 12, md: 6 },
  className,
  ...properties
}) => {
  const { t } = useTranslation();

  return (
    <Form.Group as={Col} {...sizing}>
      <Form.Label className={labelClassName}>{label}</Form.Label>
      <MultiSelect
        value={value}
        name={fieldName}
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isLoading}
        className={classnames(className, 'w-100', {
          'p-invalid': touched && errors
        })}
        required={required}
        filter
        optionLabel={optionLabel}
        placeholder={placeholder}
        {...properties}
      />
      {touched && errors ? 
        (
          <small id="email-invalid" className="p-invalid error-text">
            {t(errors)}
          </small>
        ) : 
        null
      }
    </Form.Group>
  );
};
