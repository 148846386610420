/*
 * DashboardForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file DashboardForm.tsx
 * @author Etienne Daher
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import FeedCard from '@abstract/abstractwebcommon-client/Dashboard/FeedCard';
import ClientFileRecordTable from './ClientFileRecordTable';
import ProductsForm, { PageSectionType } from './ProductsForm';
import './Style.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  IFileRecordStateSelector,
  ISettingsStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import { Dispatch } from 'redux';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { getAllPaginatedClientFiles } from '../../../Store/FileRecords';
import ClientDashboardSkeleton from '@abstract/abstractwebcommon-client/SkeletonLoader/DashboardSkeleton/ClientDashboard/ClientDashboardSkeleton';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import { IMyLicensesSection } from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

interface IDashboardFormPropsInterface {
  handleDownloadClick: (file: any) => void;
  isLoading: boolean /**< Is dashboard loading */;
  clientData: {
    myProducts: [];
    unlicensedProducts: [];
    myFiles: [];
    myLicensesSection: IMyLicensesSection[];
  };
  getClientEntitlement: () => void;
  feedsData: any;
  userUUID: string /**< UserUUID. */;
}

const DashboardForm: React.FC<any> = (props: IDashboardFormPropsInterface) => {
  const { handleDownloadClick, clientData, feedsData } = props;
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [selectedProductUUID, setSelectedProductUUID] =
    useState<string>(); /**< Selected ProductUUID. */
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const welcomeMessage: string = settings.safeSettings.applicationTitle
    ? t('admin.page.dashboard.welcome', {
        applicationTitle: settings.safeSettings.applicationTitle
      })
    : t('client.page.dashboard.welcome');
  const dispatch: Dispatch<any> = useDispatch();
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'updated',
      sortOrder: -1
    },
    searchTerm: ''
  }); /**< Default Payload */
  const fileRecords: IFileRecordStateSelector = useSelector(
    (state: IStateSelectors) => state.fileRecords
  ); /**< File Records */

  const selectProductHandler = async (
    selectedProductIdentifier: number,
    productUUID: string
  ): Promise<void> => {
    const updatedPayload: ITablePayload = {
      limit: defaultTableLimit,
      skip: 0,
      sort: {
        sortField: 'updated',
        sortOrder: -1
      },
      searchTerm: ''
    }; /**< Updated Payload. */
    setPayload(updatedPayload);

    if (selectedProductIdentifier === selectedProduct) {
      setSelectedProduct(null);
      setSelectedProductUUID(null);
      await asyncErrorHandler(
        dispatch(
          getAllPaginatedClientFiles({
            ...updatedPayload,
            selectedProductUUID: '',
            userUUID: props.userUUID
          })
        )
      ); /**< Fetch all product files. */
    } else {
      setSelectedProduct(selectedProductIdentifier);
      setSelectedProductUUID(productUUID);
      await asyncErrorHandler(
        dispatch(
          getAllPaginatedClientFiles({
            ...updatedPayload,
            selectedProductUUID: productUUID,
            userUUID: props.userUUID
          })
        )
      ); /**< Fetch selected product files. */
    }
  };

  useEffect(() => {
    dispatch(
      getAllPaginatedClientFiles({
        ...payload,
        selectedProductUUID,
        userUUID: props.userUUID
      })
    );
  }, []);

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(
      getAllPaginatedClientFiles({
        ...updatedPayload,
        selectedProductUUID,
        userUUID: props.userUUID
      })
    );
  };

  /// Handle page update event
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(
      getAllPaginatedClientFiles({
        ...updatedPayload,
        selectedProductUUID,
        userUUID: props.userUUID
      })
    );
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(
      getAllPaginatedClientFiles({
        ...updatedPayload,
        selectedProductUUID,
        userUUID: props.userUUID
      })
    );
  };

  if (
    !clientData.myProducts ||
    !clientData.unlicensedProducts ||
    !clientData.myLicensesSection ||
    !feedsData ||
    !fileRecords.clientFileRecordsList
  ) {
    return <ClientDashboardSkeleton />;
  }

  return (
    <Row>
      <Col sm={12}>
        <h3 className="text-left font-weight-bold">{welcomeMessage}</h3>
      </Col>

      {clientData && clientData.myLicensesSection && clientData.myLicensesSection.length > 0 ? (
        <Col sm={12} id="dashboardProduct">
          <Card title={t('client.page.dashboard.myLicenses')}>
            <ProductsForm
              myLicensesSectionData={clientData && clientData.myLicensesSection}
              pageSectionType={PageSectionType.MyLicenses}
              products={clientData && clientData.myProducts}
            />
          </Card>
        </Col>
      ) : (
        <></>
      )}

      <Col sm={12} id="dashboardProduct">
        <Card title={t('client.page.dashboard.product')}>
          <ProductsForm
            products={clientData && clientData.myProducts}
            pageSectionType={PageSectionType.MyProducts}
            selectProductHandler={selectProductHandler}
          />
        </Card>
      </Col>

      {clientData.unlicensedProducts.length > 0 &&
      !(
        clientData.unlicensedProducts.length > 0 &&
        clientData.unlicensedProducts.every((product: IEntitlement) =>
          isStringEmptyOrNullOrUndefined(product.imageURL)
        )
      ) ? (
        <>
          <Col sm={12} id="dashboardProduct">
            <Card title={t('client.page.dashboard.unlicensedProducts')}>
              <ProductsForm
                products={clientData.unlicensedProducts}
                pageSectionType={PageSectionType.UnlicensedProducts}
              />
            </Card>
          </Col>
        </>
      ) : (
        <></>
      )}

      <Col sm={12} id="dashboardProduct">
        <Card title={t('client.page.dashboard.files')}>
          <ClientFileRecordTable
            handleSortUpdate={handleSortUpdate}
            handlePageUpdate={handlePageUpdate}
            handleFilterUpdate={handleFilterUpdate}
            payload={payload}
            handleDownloadClick={handleDownloadClick}
          />
        </Card>
      </Col>

      <Col sm={12}>
        <FeedCard feedsData={feedsData} />
      </Col>
    </Row>
  );
};

export default DashboardForm;
