import React from 'react';
import { ProgressBar } from 'primereact/progressbar';
import DialogWrapper from '../DialogWrapper/DialogWrapper';
import Button from 'react-bootstrap/Button';
import { translate } from '../utils/translate';

import './ProgressbarDialog.css'

/** IProgressDialogProperties */
interface IProgressDialogProperties {
  isLoading: boolean; /**< True if loading and false otherwise. */
  value: number; /**< The progress bar with value 1 to 100. */
  onHide: () => void; /**< Function to be called to hide dialog. */
  showCancelDialog: () => void; /**< Show cancel dialog function. */
  uploadingLabel?: string; /**< Uploading label. */
}

/**
 * Progress Bar Dialog component.
 */
const ProgressBarDialog = ({
  isLoading, 
  value, 
  onHide, 
  showCancelDialog,
  uploadingLabel=''
} :IProgressDialogProperties) => {

  const renderFooter = () => {
    return (
      <div>
        <Button
          onClick={showCancelDialog}
          >
          {translate('awc:/.button.cancel')}
        </Button>
      </div>
    )
  }
  return (
    <div id='progress-bar' >
      <DialogWrapper 
        className="custom-dialog-container-with-out-header" 
        isDialogVisible={isLoading}
        showHeader={false} 
        onHide={onHide} 
        footer={renderFooter()}
      >
        {uploadingLabel && (
          <p>{uploadingLabel}</p>
        )}
        <ProgressBar value={value}/>
      </DialogWrapper>
    </div>
  );
}

export default ProgressBarDialog;