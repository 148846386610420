/*
 * ReportTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file ReportTable.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GalleriaWrapper from '@abstract/abstractwebcommon-client/Galleria/GalleriaWrapper';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import {
  IReport,
  IReportFile
} from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import { translate } from '../../../Utils/Translate';
import { IAuthStateSelector, ISettingsStateSelector } from '../../../Interfaces/Selectors';
import Avatar from '@abstract/abstractwebcommon-client/Avatar';
import Loader from '@abstract/abstractwebcommon-client/Loader';
import { InputTextarea } from 'primereact/inputtextarea';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';

/**
 * @interface IReportsTableProperties
 */
interface IReportsTableProperties {
  report: IReport /**< report object. */;
  isLoading: boolean /**< True if loading and false otherwise.*/;
  isDownloadingAttachedFile: boolean /** True if downloading file and false otherwise. */;
  auth: IAuthStateSelector /** authenticated user information */;
  isSupportRole: boolean /** True if use have support role and false otherwise.*/;
  handleUserListOpen: () => void /**< Open user list dialog. */;
  copyToClipboardSuccessHandler: (text: string) => void;
  handleDownloadFile: (
    fileUUID: string,
    reportUUID: string
  ) => void /**< Triggers download file. */;
  handleFileDelete: (payload: IReportFile) => void /**< Triggers download file. */;
  isDeletedLoading: boolean /** True if delete request is pending and false otherwise. */;
  settings: ISettingsStateSelector /** Application system settings */;
  handleReportUpdate: (payload: IReport) => void /**< handle file report update function*/;
  setReportFilesLoading: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Report files loading state */;
}

/**
 * Report Table component
 */
const ReportTable = (properties: IReportsTableProperties): ReactElement => {
  const report: IReport = properties.report;

  // Note: It was requested to just commented out the code instead of removing it. We may want to add it back.
  // const handleDescriptionUpdate = (): void => {
  //   if (formik.initialValues.description !== formik.values.description) {
  //     formik.handleSubmit();
  //   }
  // };

  /// Handle Report Title update
  const handleTitleUpdate = (): void => {
    if (formik.initialValues.title !== formik.values.title) {
      formik.handleSubmit();
    }
  };

  if (properties.isLoading) {
    return <Loader />;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: report.title || '',
      description: report.description || ''
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(2, translate('validation.min', { field: '2' }))
        .max(100, translate('validation.max', { field: '100' }))
        .required(
          translate('validation.required', {
            field: translate('I18N.admin.page.reports.table.title')
          })
        ),
      description: Yup.string()
        .min(2, translate('validation.min', { field: '2' }))
        .required(
          translate('validation.required', {
            field: translate('I18N.admin.page.reports.table.description')
          })
        )
    }),
    onSubmit: (data: IReport) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          properties.handleReportUpdate(payload);
        }
      });
    }
  });

  //Adjust description field height based in your content
  useEffect(() => {
    const textarea = document.querySelector('textarea');
    textarea.style.height = `${textarea.scrollHeight}px`;
    ///Note: If no report file(s) exist, set reportfilesloading to false.
    if ((report.files && report.files.length === 0) || !report.files) {
      properties.setReportFilesLoading && properties.setReportFilesLoading(false);
    }
  }, []);

  return (
    <>
      <Col className="pb-0">
        <InputTextarea
          id="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={handleTitleUpdate}
          className={`report-title report-textarea keep-original-input-style ${
            formik.touched.title && formik.errors.title ? 'p-invalid' : ''
          }`}
          disabled={!(properties.isSupportRole || properties.auth.isAdmin)}
          autoResize
        />
        {formik.touched.title && formik.errors.title ? (
          <small id="title-invalid" className="p-invalid">
            {formik.errors.title}
          </small>
        ) : null}
      </Col>
      <Col>
        <Row className="report-display">
          <Col md={12} className="mb-4 pb-0">
            <p>{translate('I18N.admin.page.reports.table.participant')}</p>
            <div className="d-flex flex-row flex-wrap">
              {report.participants &&
                report.participants.map((user, index) => {
                  return (
                    <Avatar
                      imageUrl={user.imageUrl}
                      key={index}
                      user={user}
                      users={report.participants}
                      className="mb-2"
                      isShowTooltipForDeletedUser={false}
                    />
                  );
                })}
              {properties.isSupportRole || properties.auth.isAdmin ? (
                <Button
                  className="d-flex align-items-center mt-md-0 mb-2 manage-users-button"
                  onClick={properties.handleUserListOpen}>
                  {translate('table_buttons.manageUsers')}
                </Button>
              ) : (
                <ActionButton
                  onClick={properties.handleUserListOpen}
                  className="mr-2 d-flex align-items-center manage-users-button"
                />
              )}
            </div>{' '}
          </Col>
          <Col>
            <div className="mb-4">
              <p>{translate('I18N.admin.page.reports.table.status')}</p>
              {properties.auth && (
                <div className="d-flex mb-2">
                  <span>{report.status}</span>
                </div>
              )}
            </div>

            <div className="mb-4">
              <p>{translate('I18N.admin.page.reports.table.created')}</p>
              <h6 className="mb-4">
                {formatDate(report.created, {
                  isTime: true,
                  isTwentyFour: true,
                  isSecondsHide: true
                })}
              </h6>
            </div>

            <div className="mb-4">
              <p>{translate('I18N.admin.page.reports.table.description')}</p>
              <span>{formik.values.description}</span>

              {/* Note: It was requested to just commented out the code instead of removing it. We may want to add it back. */}
              {/* <InputTextarea
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={handleDescriptionUpdate}
                className={`report-description report-textarea keep-original-input-style ${
                  formik.touched.description && formik.errors.description ? 'p-invalid' : ''
                }`}
                disabled={!(properties.isSupportRole || properties.auth.isAdmin)}
                autoResize
              /> */}
              {formik.touched.description && formik.errors.description ? (
                <small id="description-invalid" className="p-invalid">
                  {formik.errors.description}
                </small>
              ) : null}
            </div>
            {report.files && report.files.length ? (
              <div>
                <p>
                  {translate('I18N.admin.page.reports.table.attachment')} ({report.files.length})
                </p>
                <GalleriaWrapper
                  report={report}
                  isSupportRole={properties.isSupportRole}
                  isAdmin={properties.auth.isAdmin}
                  handleDownloadFile={properties.handleDownloadFile}
                  handleFileDelete={properties.handleFileDelete}
                  displayDownloadAction
                  displayDeleteAction
                  isReportObjectChanged={true}
                  setReportFilesLoading={properties.setReportFilesLoading}
                />
              </div>
            ) : (
              <></>
            )}
          </Col>
          <hr style={{ width: '100%' }} />
        </Row>
      </Col>
    </>
  );
};

export default ReportTable;
