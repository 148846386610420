/*
 * AccountPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file AccountPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { Col, Row, Form } from 'react-bootstrap/';
import { SlideMenu } from 'primereact/slidemenu';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';

const AccountPage: React.FC<any> = () => {
  const { t } = useTranslation();
  const items = [
    { label: t('admin.page.accountPage.profile'), className: 'active' },
    { label: t('admin.page.accountPage.logout') }
  ];
  const header = (
    <Col md={12} lg={12} sm={12} id="profile-header">
      <Col className="d-flex justify-content-center" md={12} lg={12} sm={12}>
        <Col md={2} sm={4} lg={2} className="p-md-4 text-center">
          <img
            src="https://www.placecage.com/640/260"
            className="rounded-circle img-thumbnail profile-image"
            alt={t('I18N.account.profile_alt')}></img>
        </Col>
      </Col>
      <Col md={12} sm={12} lg={12} className="text-center">
        <h5>John Doe</h5>
      </Col>
    </Col>
  );

  const profileCardHeader = (
    <Row id="profileActiveCardHeader">
      <Col md={2} sm={4} lg={2} className="p-md-4">
        <strong>{t('admin.page.accountPage.profile')}</strong>
      </Col>
      <Col md={2} sm={4} lg={2} className="p-md-4 pr-md-5 offset-md-8 text-right">
        <a href="#todo"> {t('admin.page.accountPage.edit')} </a>
      </Col>
    </Row>
  );

  return (
    <Col md={12} sm={12} lg={12}>
      <Card header={header}>
        <Row>
          <Col md={4} lg={4} sm={4}>
            <SlideMenu
              model={items}
              viewportHeight={250}
              menuWidth={485}
              className="w-100"></SlideMenu>
          </Col>
          <Col md={8} lg={8} sm={8}>
            <Card header={profileCardHeader}>
              <Row>
                <Form.Group as={Col} md={6} sm={12}>
                  <Form.Label>{t('admin.page.accountPage.form.firstname')}</Form.Label>
                  <InputText type={'text'} disabled={true} value={'John'} />
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12}>
                  <Form.Label>{t('admin.page.accountPage.form.lastname')}</Form.Label>
                  <InputText type={'text'} disabled={true} value={'Doe'} />
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12}>
                  <Form.Label>{t('admin.page.accountPage.form.email')}</Form.Label>
                  <InputText type={'text'} disabled={true} value={'john@doe.com'} />
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12}>
                  <Form.Label>{t('admin.page.accountPage.form.username')}</Form.Label>
                  <InputText type={'text'} disabled={true} value={'JohnDoe'} />
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12}>
                  <Form.Label>{t('admin.page.accountPage.form.role')}</Form.Label>
                  <InputText type={'text'} disabled={true} value={'Admin'} />
                </Form.Group>
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default AccountPage;
