/*
 * EntitlementForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file EntitlementForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { InstaTextArea } from '@abstract/abstractwebcommon-client/FormControl/InstaTextArea';
import { InstaButton } from '@abstract/abstractwebcommon-client/FormControl/InstaButton';
import { Checkbox } from 'primereact/checkbox';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import i18n from '../../../Services/I18n';
import { InstaInputMultiselect } from '@abstract/abstractwebcommon-client/FormControl/InstaInputMultiselect';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import { IFileRecord } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecord';
import InstaImageUpload, {
  FILE_UPLOAD_ERROR
} from '@abstract/abstractwebcommon-client/InstaImageUpload';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { deleteEntitlement } from '../../../Store/Entitlements';
import { useDispatch } from 'react-redux';
import { validators } from '@abstract/abstractwebcommon-shared/validators';

interface IEntitlementOtherProperties {
  handleSubmit: any /**< Handle form submit */;
  isLoading?: boolean /**< True if state is loading, false otherwise */;
  entitlement?: any /**< Entitlement */;
  filesOptions?: any /**< Files option */;
  hideDialog: () => void /**< Hide the dialog/form component. */;
}

interface IEntitlementFormValues {
  name: string /**< Internal name of entitlement */;
  displayName: string /**< Display name of entitlement */;
  description: string /**< Description */;
  productUrl: string /**< Product website url */;
  live: boolean /**< True if live, false otherwise */;
  file: string /**< File */;
  files: Array<any> /**< Files */;
  isDeactivatable: boolean /**< True if entitlement is deactivatable */;
  use24HoursTimeout: boolean /**< True if entitlement has 24 hours timeout */;
  imageURL?: string /**< Image URL (S3 Bucket) */;
  imageName?: string /**< Image file name */;
}

interface IEntitlementMyFormProperties {
  initialName?: string /**< Internal name of entitlement (Initial form value) */;
  initialDisplayName?: string /**< Display name of entitlement (Initial form value) */;
  initialDescription?: string /**< Description (Initial form value) */;
  initialLive?: boolean /**< True if live, false otherwise (Initial form value) */;
  initialFile?: string /**< File (Initial form value) */;
  initialFiles?: Array<any> /**< Files (Initial form value) */;
  initialProductUrl?: string /**< Product website url (Initial form value) */;
  initialIsDeactivatable?: boolean /**< True if entitlement is deactivatable (Initial form value) */;
  initialUse24HoursTimeout?: boolean /**< True if entitlement has 24 hours timeout (Initial form value) */;
  license?: Array<any> /**< Linked licenses (Initial form value) */;
  handleSubmit: any /**< Submit handler (Initial form value) */;
  filesOptions?: any /**< File options (Initial form value) */;
  isLoading?: boolean /**< True if is loading, false otherwise (Initial form value) */;
  entitlement?: any /**< Entitlement (Initial form value) */;
  imageURL?: string /**< Image URL (S3 Bucket) (Initial form value) */;
  imageName?: string /** Image File name (Initial form value) */;
  hideDialog: () => void /**< Hide the dialog/form component. */;
}

const EntitlementForm = (
  properties: IEntitlementOtherProperties & FormikProps<IEntitlementFormValues>
): JSX.Element => {
  const {
    handleSubmit,
    filesOptions,
    handleChange,
    handleBlur,
    touched,
    values,
    setFieldValue,
    errors,
    isLoading,
    entitlement,
    hideDialog
  } = properties;
  const [activationConfirmation, setActivationConfirmation] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any | null>(null);
  const [isFileChanged, setIsFileChanged] = useState<boolean>(false);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [isShowingConfirmation, setIsShowingConfirmation] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  /// Triggers on file upload. Sets the file with url
  const onUpload = (files: File[]) => {
    try {
      const file: File = files ? files[0] : null;
      setIsFileChanged(true);
      setFieldValue('file', file);
      setSelectedImage(file);
    } catch (error: any) {
      console.log(error);
    }
  };

  /// Dispatches deleteAPI to delete the profile picture
  const deleteImage = (): void => {
    setFieldValue('file', '');
    setFieldValue('imageURL', '');
    setFieldValue('imageName', null);
  };

  // Adds spacing between selected files
  const selectedItemTemplate = (option: IFileRecord): string | void => {
    if (option) {
      // No comma for first element
      if (
        values.files.length === 1 ||
        (values.files[0] && (values.files[0] as IFileRecord).name === option.name)
      ) {
        return `${option.name}`;
      } else {
        return `, ${option.name}`;
      }
    }
  };

  const errorHandler = (error: any): void => {
    if (error === FILE_UPLOAD_ERROR.NO_FILE_UPLOADED) {
      showToast({ severity: 'error', summary: t('I18N.settings.no_image_uploaded') });
    }
    if (error === FILE_UPLOAD_ERROR.NOT_AN_IMAGE) {
      showToast({ severity: 'error', summary: t('I18N.settings.upload_valid_image') });
    }
  };

  /// Gets the fileUpload component.
  const getFileUpload = (): JSX.Element => {
    return (
      <div className="bg-dark text-center px-4 py-2 primary-border-radius">
        <InstaImageUpload
          showLegend={false}
          imageUrl={(entitlement && entitlement.imageURL) || null}
          showDelete={true}
          deleteHandler={deleteImage}
          imgContainerClass={'px-3'}
          onChange={onUpload}
          croppedImage={croppedImage}
          showUploadBtn={false}
          errorHandler={errorHandler}
          imgClass={'imageUrl rounded'}
          altText={t('admin.page.settings.alt.logo')}
          isPlainBtn={true}
          plainBtnLabel={t('admin.page.entitlement.form.choose_entitlement_icon')}
        />
      </div>
    );
  };

  /// show delete popup
  const deleteButtonClick = (event: any) => {
    setIsShowingConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Delete entitlement on Accept
  const onAccept = async (): Promise<void> => {
    dispatch(deleteEntitlement([entitlement.entitlementUUID]));
    setIsShowingConfirmation(false);
    hideDialog();
  };

  return (
    <>
      <FormWrapper
        controlButtonLabel={entitlement && Object.keys(entitlement).length > 0}
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonClick(event)
        }
        handleSubmitButton={() => handleSubmit(values)}>
        <Row>
          {/* To browse and upload image */}
          <Col xs={12}>{getFileUpload()}</Col>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.entitlement.form.entitlementName')}
              labelClassName="required"
              name="name"
              id={'name'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.name}
              errors={errors.name}
              value={values.name}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.entitlement.form.displayName')}
              labelClassName="required"
              name="displayName"
              id={'displayName'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.displayName}
              errors={errors.displayName}
              value={values.displayName}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
          <InstaTextArea
            sizing={{ sm: 12, md: 12 }}
            label={t('admin.page.entitlement.form.entitlementDescription')}
            labelClassName="required"
            fieldName="description"
            name="description"
            id={'description'}
            isLoading={isLoading}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.description}
            errors={errors.description}
            value={values.description}
            onKeyPress={(event: any) => {
              if (event.key === 'Enter') {
                handleSubmit(values);
              }
            }}
          />
          <Form.Group as={Col} sm="12" md="12">
            <InstaInputText
              label={t('admin.page.entitlement.form.entitlementUrl')}
              name="productUrl"
              id={'productUrl'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.productUrl}
              errors={errors.productUrl}
              value={values.productUrl}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
          <InstaInputMultiselect
            sizing={{ sm: 6, md: 6 }}
            value={values.files}
            label={t('admin.page.entitlement.form.files')}
            fieldName="files"
            dataKey="id"
            isLoading={isLoading}
            selectedItemTemplate={selectedItemTemplate}
            options={filesOptions}
            handleChange={handleChange}
            handleBlur={handleBlur}
            optionLabel="name"
            placeholder={t('admin.page.entitlement.form.files_placeholder')}
            errors={errors.files}
            disabled={false}
          />
          <Form.Group as={Col} sm={12} className="pb-0">
            <Row>
              <Col md={4}>
                <Checkbox
                  inputId="live"
                  onChange={(event: any) => {
                    entitlement && entitlement.live
                      ? setActivationConfirmation(true)
                      : handleChange(event);
                  }}
                  name="live"
                  checked={values.live}
                />
                &nbsp;&nbsp;
                <Form.Label htmlFor="live" className="d-inline">
                  {t('admin.page.entitlement.form.live')}
                </Form.Label>
              </Col>

              <Col md={4}>
                <Checkbox
                  inputId="isDeactivatable"
                  onChange={handleChange}
                  name="isDeactivatable"
                  checked={values.isDeactivatable}
                />
                &nbsp;&nbsp;
                <Form.Label htmlFor="isDeactivatable" className="d-inline">
                  {t('admin.page.entitlement.form.isDeactivatable')}
                </Form.Label>
              </Col>

              <Col md={4}>
                <Checkbox
                  inputId="use24HoursTimeout"
                  onChange={handleChange}
                  name="use24HoursTimeout"
                  checked={values.use24HoursTimeout}
                />
                &nbsp;&nbsp;
                <Form.Label htmlFor="use24HoursTimeout" className="d-inline">
                  {t('admin.page.entitlement.form.use24HoursTimeout')}
                </Form.Label>
              </Col>
            </Row>
          </Form.Group>
        </Row>

        <ConfirmationPopup
          target={confirmPopupTarget}
          isShow={isShowingConfirmation}
          title={t('/confirm_messages.delete_records')}
          onAccept={onAccept}
          onReject={() => setIsShowingConfirmation(false)}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
          popupPosition="top"
        />
      </FormWrapper>

      {entitlement && entitlement.live && (
        <DialogWrapper
          isCustomClassName
          isDialogVisible={activationConfirmation}
          onHide={() => setActivationConfirmation(false)}
          headerTitle={`Do you want to deactivate this entitlement? ${entitlement.licensesCount} license linked!`}>
          <InstaButton
            type="button"
            label="Confirm"
            onClick={() => {
              values.live = !values.live;
              handleSubmit(values);
              setActivationConfirmation(false);
            }}
          />
        </DialogWrapper>
      )}
      <CropDialog
        isVisible={isFileChanged}
        setVisible={setIsFileChanged}
        file={selectedImage}
        isIcon
        onImageCropComplete={(image: any) => {
          setFieldValue('file', image);
          setCroppedImage(image);
          setSelectedImage(image);
        }}
      />
    </>
  );
};

const Entitlement = withFormik<IEntitlementMyFormProperties, IEntitlementFormValues>({
  mapPropsToValues: (properties) => {
    const { entitlement } = properties;
    if (entitlement) {
      return {
        name: entitlement.name,
        displayName: entitlement.displayName,
        description: entitlement.description,
        live: entitlement.live,
        isDeactivatable: entitlement.isDeactivatable,
        use24HoursTimeout: entitlement.use24HoursTimeout,
        file: entitlement.file,
        files: entitlement.files,
        productUrl: entitlement.productUrl,
        license: entitlement.license,
        imageURL: entitlement.imageURL,
        imageName: entitlement.imageName
      };
    }
    return {
      name: properties.initialName || '',
      displayName: properties.initialDisplayName || '',
      description: properties.initialDescription || '',
      live: properties.initialLive || false,
      isDeactivatable: properties.initialIsDeactivatable || true,
      use24HoursTimeout: properties.initialUse24HoursTimeout || true,
      file: properties.initialFile || '',
      files: properties.initialFiles || [],
      productUrl: properties.initialProductUrl || '',
      license: properties.license || [],
      imageURL: properties.imageURL || '',
      imageName: properties.imageName
    };
  },
  validationSchema: () => {
    // validation schema
    const validationSpec = {
      name: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(100, i18n.t('validation.max', { field: '100' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.entitlement.form.entitlementName')
          })
        ),
      displayName: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(100, i18n.t('validation.max', { field: '100' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.entitlement.form.displayName')
          })
        ),
      description: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(300, i18n.t('validation.max', { field: '300' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.entitlement.form.entitlementDescription')
          })
        ),
      productUrl: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(300, i18n.t('validation.max', { field: '300' }))
        .matches(validators.WEBSITE, i18n.t('validation.url')),
      file: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .nullable(),
      isDeactivatable: Yup.boolean(),
      live: Yup.boolean()
    };

    return Yup.object(validationSpec);
  },
  handleSubmit: (values, bag) => {
    if (bag.props.entitlement) {
      let updatedFieldsCount: number = 0;
      Object.keys(values).forEach((key: string) => {
        if (key == 'files') {
          const newFilesUuidsString: string = JSON.stringify(
            values[key].map((fileRecord: IFileRecord) => fileRecord.fileRecordUUID).sort()
          );
          const initialFilesUuidsString: string = JSON.stringify(
            bag.props.entitlement[key]
              .map((fileRecord: IFileRecord) => fileRecord.fileRecordUUID)
              .sort()
          );
          if (newFilesUuidsString !== initialFilesUuidsString) {
            updatedFieldsCount++;
          }
        } else if (
          values[key as keyof typeof bag.props.entitlement] !==
          bag.props.entitlement[key as keyof typeof bag.props.entitlement]
        ) {
          updatedFieldsCount++;
        }
      });

      if (updatedFieldsCount > 0) {
        return bag.props.handleSubmit(
          {
            id: bag.props.entitlement.id,
            entitlementUUID: bag.props.entitlement.entitlementUUID,
            ...values
          },
          bag.resetForm
        );
      }
      return;
    }
    return bag.props.handleSubmit(
      {
        ...values
      },
      bag.resetForm
    );
  }
})(EntitlementForm);

export default Entitlement;
