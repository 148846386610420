/*
 * NewsletterCustomListTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterCustomListTable.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  ILicenseStateSelector,
  INewsletterStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import {
  IPageEvent,
  IPaginationResponse,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  copyNewsletterCustomList,
  createNewsletterCustomList,
  deleteNewsletterCustomList,
  fetchAllNewsletterCustomLists,
  fetchCustomLicensesFilter,
  getNewslettersByCustomlistID,
  updateNewsletterCustomList
} from '../../../Store/Newsletter';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import { INewsletterCustomList } from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import { translate } from '../../../Utils/Translate';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { Button, Col } from 'react-bootstrap';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { Column } from 'primereact/column';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import NewsletterCustomListForm from './NewsletterCustomListForm';
import { Dialog } from 'primereact/dialog';
import './NewsletterCustomListTable.css';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { getAllLicenses } from '../../../Store/Licenses';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

/**
 * Newsletter custom list Table component.
 */
const NewsletterCustomListTable = (): ReactElement => {
  const dispatch: Dispatch<any> = useDispatch();
  const newsletterState: INewsletterStateSelector = useSelector(
    (state: IStateSelectors) => state.newsletter
  ); /**< Newsletter state */
  const newsletterCustomList: IPaginationResponse<INewsletterCustomList> =
    newsletterState.newsletterCustomList;
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'created',
      sortOrder: -1
    },
    searchTerm: ''
  }); /**< Default Payload */
  const [expandedRows, setExpandedRows] = useState<INewsletterCustomList>({});
  const [selectedCustomLists, setSelectedCustomLists] =
    useState<INewsletterCustomList[]>(null); /**< Selected Custom lists. */
  const deleteButtonReference: any = useRef(null); /**< Delete Button Reference. */
  const [isShowDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false); /**< Show delete Confirmation Popup. */
  const [deleteConfirmPopupTarget, setDeleteConfirmPopupTarget] =
    useState<any>(null); /**< Delete ConfirmationPopup Target. */
  const [isShowCustomListForm, setShowCustomListForm] =
    useState<boolean>(false); /**< To show customList form to add or edit. */
  const [customList, setCustomList] = useState<INewsletterCustomList>(); /**< Custom list */
  const isCreateSuccessful: boolean =
    newsletterState.isNewsletterCustomListCreatedSuccess; /**< Is custom list create successful. */
  const isUpdateSuccessful: boolean =
    newsletterState.isNewsletterCustomListUpdatedSuccess; /**< Is custom list update successful. */
  const isDeleteSuccessful: boolean =
    newsletterState.isNewsletterCustomListDeletedSuccess; /**< Is custom list delete successful. */
  const [isShowDeleteWarningDialog, setShowDeleteWarningDialog] =
    useState<boolean>(false); /**< To show the warning dialog. */
  const linkedNewsletters: string[] = newsletterState.linkedNewsletters; /**< Linked newsletters. */
  const defaultLicenseTablePayload: ITablePayload = {
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'created',
      sortOrder: -1
    },
    searchTerm: '',
    filter: {}
  }; /**< Default License table payload */
  const [licenseTablePayload, setLicenseTablePayload] = useState<ITablePayload>(
    defaultLicenseTablePayload
  ); /**< License table payload */
  const licenseState: ILicenseStateSelector = useSelector(
    (state: IStateSelectors) => state.licenses
  ); /**< License state */
  const licenses: Record<string, any> = licenseState.list; /**< License list */
  const userData: IUser[] = licenseState.userData; /**< User data */

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.customListUUID]: true });
    }
  };

  /// Handles selection change event
  const onSelectionChange = (event: any) => {
    const rows: INewsletterCustomList[] = event.value;
    if (Array.isArray(rows)) {
      const selectedRows: INewsletterCustomList[] = rows.map((row: INewsletterCustomList) => {
        return row;
      });
      setSelectedCustomLists(selectedRows);
    }
  };

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(fetchAllNewsletterCustomLists(updatedPayload));
  };

  /// Handle page update event
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(fetchAllNewsletterCustomLists(updatedPayload));
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(fetchAllNewsletterCustomLists(updatedPayload));
  };

  useEffect(() => {
    dispatch(fetchAllNewsletterCustomLists(payload));
    dispatch(fetchCustomLicensesFilter()); // To fetch custom licenses filter
  }, []);

  useEffect(() => {
    if (isCreateSuccessful || isUpdateSuccessful || isDeleteSuccessful) {
      setShowCustomListForm(false);
    }
  }, [isCreateSuccessful, isUpdateSuccessful, isDeleteSuccessful]);

  /// Delete Newsletter custom list(s)
  const handleDeleteCustomList = async (customListUUIDs: string[]) => {
    await asyncErrorHandler(dispatch(deleteNewsletterCustomList(customListUUIDs)));
    await asyncErrorHandler(dispatch(fetchAllNewsletterCustomLists(payload)));
  };

  /// Show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowDeleteConfirmation(true);
    setDeleteConfirmPopupTarget(event.target);
  };

  /// Delete Newsletter custom list(s) on Accept
  const onDeleteAccept = async () => {
    setShowDeleteConfirmation(false);
    await asyncErrorHandler(deleteCustomLists(selectedCustomLists, false));
    setShowDeleteWarningDialog(true);
  };

  /// Hide confirmation on reject
  const onDeleteReject = () => {
    setShowDeleteConfirmation(false);
    setShowDeleteWarningDialog(false);
  };

  const onConfirmDelete = async (): Promise<void> => {
    await asyncErrorHandler(deleteCustomLists(selectedCustomLists, true));
    setShowDeleteWarningDialog(false);
    setSelectedCustomLists(null);
  };

  /// Dispatches action to delete selected rows
  const deleteCustomLists = async (
    customLists: INewsletterCustomList[],
    isConfirmedDelete: boolean
  ) => {
    const customListUUIDs: string[] = customLists.map(
      (eachCustomList: INewsletterCustomList) => eachCustomList.customListUUID
    ); /**< customListUUIDs */
    isConfirmedDelete
      ? await asyncErrorHandler(handleDeleteCustomList(customListUUIDs))
      : await asyncErrorHandler(
          dispatch(
            getNewslettersByCustomlistID({
              customListUUIDs: customListUUIDs,
              tablePayload: payload
            })
          )
        );
  };

  const renderRowDeleteDialogFooter = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => onDeleteReject()}
          autoFocus
          variant="secondary"
          className="d-flex align-items-center">
          <i className="bi bi-x-circle btn-icon"></i>
          {translate(
            'I18N.admin.page.newsletterCustomList.delete_customlist_dialog.confirmDeleteCustomlistsNo'
          )}
        </Button>
        <Button
          onClick={() => onConfirmDelete()}
          autoFocus
          variant="danger"
          className="d-flex align-items-center m-0">
          <i className="bi bi-trash btn-icon"></i>
          {translate(
            'I18N.admin.page.newsletterCustomList.delete_customlist_dialog.confirmDeleteCustomlistsYes'
          )}
        </Button>
      </div>
    );
  };

  /// Confirmation Popup
  const getConfirmationPopup = () => {
    return (
      <>
        <ConfirmationPopup
          target={deleteConfirmPopupTarget}
          isShow={isShowDeleteConfirmation}
          title={translate('/confirm_messages.delete_records')}
          onAccept={onDeleteAccept}
          onReject={onDeleteReject}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={translate('/confirm_messages.no')}
          acceptLabel={translate('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
          popupPosition="bottom"
        />

        <Dialog
          header={translate(
            'I18N.admin.page.newsletterCustomList.delete_customlist_dialog.confirmDeleteCustomlistsHeader'
          )}
          visible={isShowDeleteWarningDialog && linkedNewsletters.length > 0}
          className="overflow-auto custom-dialog-container"
          footer={renderRowDeleteDialogFooter()}
          onHide={() => setShowDeleteWarningDialog(false)}
          draggable={false}>
          <Col className="mb-2 px-0">
            {translate(
              'I18N.admin.page.newsletterCustomList.delete_customlist_dialog.confirmDeleteCustomlists'
            )}
          </Col>
          {linkedNewsletters.length > 0 && (
            <Col className="mb-2 px-0">
              Total Affected Newsletters: <b>{linkedNewsletters.length}</b> - [
              {linkedNewsletters.join(', ')}]
            </Col>
          )}
        </Dialog>
      </>
    );
  };

  const isButtonDisabled: boolean =
    ((selectedCustomLists && Object.keys(selectedCustomLists).length === 0) ||
      !selectedCustomLists) ??
    false;

  /// Handle add or edit custom list event
  const handleAddOrEditCustomList = async (
    customListPayload: INewsletterCustomList
  ): Promise<void> => {
    if (customListPayload.customListUUID) {
      await asyncErrorHandler(dispatch(updateNewsletterCustomList(customListPayload)));
    } else {
      await asyncErrorHandler(dispatch(createNewsletterCustomList(customListPayload)));
    }
    await asyncErrorHandler(dispatch(fetchAllNewsletterCustomLists(payload)));
  };

  /// Copy button clicked handler
  const copyButtonClicked = async () => {
    const customListUUIDs: string[] = selectedCustomLists?.map(
      (eachCustomList: INewsletterCustomList) => eachCustomList.customListUUID
    );
    /**< customListUUIDs */
    await asyncErrorHandler(dispatch(copyNewsletterCustomList(customListUUIDs)));
    await asyncErrorHandler(dispatch(fetchAllNewsletterCustomLists(payload)));
    setSelectedCustomLists(null);
  };

  /// Handle Add Custom list event
  const handleAdd = async () => {
    const updatedPayload: ITablePayload = defaultLicenseTablePayload;
    /**< Updated payload */
    await asyncErrorHandler(dispatch(getAllLicenses(updatedPayload))); // To fetch all licenses
    setLicenseTablePayload(updatedPayload);
    setCustomList(null);
    setShowCustomListForm(true);
  };

  /// Handle Edit dialog
  const handleEditDialog = async (rowData: INewsletterCustomList) => {
    const updatedPayload: ITablePayload = defaultLicenseTablePayload;
    /**< Updated payload */
    await asyncErrorHandler(dispatch(getAllLicenses(updatedPayload))); // To fetch all licenses
    setLicenseTablePayload(updatedPayload);
    setCustomList(rowData);
    setShowCustomListForm(true);
  };

  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center row-direction wrap-header">
      <div className="headerTableContainer margin-bottom-on-wrap">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={isButtonDisabled}
          buttonReference={deleteButtonReference}
        />{' '}
        {/* To delete CustomLists. */}
        <ActionButton onClick={handleAdd} /> {/* To Add CustomList. */}
        <Button
          onClick={copyButtonClicked}
          disabled={isButtonDisabled}
          className="d-none d-sm-flex align-items-center mr-2 button-without-icon">
          {translate('I18N.admin.page.newsletter.table.copy')}
        </Button>
      </div>
      <div className="d-flex">
        <Button
          onClick={copyButtonClicked}
          disabled={isButtonDisabled}
          className="d-flex d-sm-none align-items-center mr-2 button-without-icon">
          {translate('I18N.admin.page.newsletter.table.copy')}
        </Button>
        <SearchBar onSearchTermChanged={(data: string) => handleFilterUpdate(data)} />
      </div>
    </div>
  );

  const CustomListRowExpansionTemplate = (values: {
    rowData: INewsletterCustomList;
  }): JSX.Element => {
    return (
      <>
        <tr>
          <th>{translate('I18N.admin.page.newsletterCustomList.table.name')}</th>
          <td>{values.rowData['customListName']}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletterCustomList.table.licenseCount')}</th>
          <td>{values.rowData['customLicensesCount']}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletterCustomList.table.created')}</th>
          <td>{values.rowData.created ? formatDate(values.rowData.created) : ''}</td>
        </tr>
        <tr>
          <th>{translate('I18N.admin.page.newsletterCustomList.table.updated')}</th>
          <td>{values.rowData.updated ? formatDate(values.rowData.updated) : ''}</td>
        </tr>
      </>
    );
  };

  /// To render expansion rows
  const renderExpansionRows = (rowData: INewsletterCustomList) => (
    <>
      <ExpansionRow>
        <CustomListRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <CustomListRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Handle license table sort update event
  const handleLicenseTableSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = licenseTablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setLicenseTablePayload(updatedPayload);
    dispatch(getAllLicenses(updatedPayload));
  };

  /// Handle license table page update event
  const handleLicenseTablePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = licenseTablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setLicenseTablePayload(updatedPayload);
    dispatch(getAllLicenses(updatedPayload));
  };

  /// Handle license table filter update event
  const handleLicenseTableFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = licenseTablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setLicenseTablePayload(updatedPayload);
    dispatch(getAllLicenses(updatedPayload));
  };

  /// Handle license table filter change event
  const handleLicenseTableFilterChange = (filterValue: Record<string, any>): void => {
    const updatedPayload: ITablePayload = licenseTablePayload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      filter: filterValue
    });
    setLicenseTablePayload(updatedPayload);
    dispatch(getAllLicenses(updatedPayload));
  };

  const getDataTable = () => {
    return (
      <BaseDatatable
        value={newsletterCustomList && newsletterCustomList.records}
        totalRecords={newsletterCustomList && newsletterCustomList.totalRecords}
        isLoading={!newsletterCustomList}
        header={header}
        parentClass="customListDataTable" /**< ClassName for div Component.*/
        first={payload.skip}
        emptyMessage={translate('I18N.admin.page.newsletterCustomList.table.empty_msg')}
        rows={payload.limit}
        onPage={handlePageUpdate}
        sortField={payload.sort.sortField}
        onSort={handleSortUpdate}
        sortOrder={payload.sort.sortOrder}
        onFilter={handleFilterUpdate}
        rowExpansionTemplate={renderExpansionRows}
        bodyStyle={'text-center'}
        responsive
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows({})}
        expandedRows={expandedRows}
        dataKey="customListUUID"
        selection={selectedCustomLists}
        onSelectionChange={(event: any) => onSelectionChange(event)}>
        <Column expander className="p-0 col-width-45" headerClassName="p-0 col-width-45" />
        <Column selectionMode="multiple" className="col-width-45" />
        <Column
          sortable
          field="customListName"
          body={(rowData: INewsletterCustomList) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletterCustomList.table.name')}
              data={rowData.customListName}
            />
          )}
          header={translate('I18N.admin.page.newsletterCustomList.table.name')}
        />
        <Column
          field="customLicensesCount"
          sortable
          body={(rowData: INewsletterCustomList) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletterCustomList.table.licenseCount')}
              data={rowData.customLicensesCount}
            />
          )}
          header={translate('I18N.admin.page.newsletterCustomList.table.licenseCount')}
          className="createdDateCol"
        />
        <Column
          sortable
          field="created"
          body={(rowData: INewsletterCustomList) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletterCustomList.table.created')}
              data={formatDate(rowData.created)}
            />
          )}
          header={translate('I18N.admin.page.newsletterCustomList.table.created')}
          className="d-table-cell d-sm-none d-md-table-cell createdDateCol"
          headerClassName="d-table-cell d-sm-none d-md-table-cell createdDateCol"
        />
        <Column
          sortable
          field="updated"
          body={(rowData: INewsletterCustomList) => (
            <DatatableColumn
              title={translate('I18N.admin.page.newsletterCustomList.table.updated')}
              data={formatDate(rowData.updated)}
            />
          )}
          header={translate('I18N.admin.page.newsletterCustomList.table.updated')}
          className="d-table-cell d-sm-none d-md-table-cell createdDateCol"
          headerClassName="d-table-cell d-sm-none d-md-table-cell createdDateCol"
        />
        <Column
          field="edit"
          body={(rowData: any) => {
            return (
              <Button
                className="custom-action-column-action-position"
                onClick={() => handleEditDialog(rowData)}
                variant="outline">
                <i className="bi bi-pencil-square editIcon fa-lg"></i>
              </Button>
            );
          }}
          className="p-0 col-width-3 absolute-position-responsive-screen"
        />
      </BaseDatatable>
    );
  };

  const hideDialog = () => setShowCustomListForm(false);

  const getDialogWrapper = () => {
    return (
      <DialogWrapper
        isDialogVisible={isShowCustomListForm}
        className="table-width-992"
        headerTitle={
          customList
            ? translate('I18N.admin.page.newsletterCustomList.form.header.edit_customlist_dialog')
            : translate('I18N.admin.page.newsletterCustomList.form.header.add_customlist_dialog')
        }
        onHide={() => {
          setShowCustomListForm(false);
        }}>
        <NewsletterCustomListForm
          isLoading={newsletterState.isNewsletterCustomListChanging}
          handleAddOrEditCustomList={handleAddOrEditCustomList}
          handleDeleteCustomList={deleteCustomLists}
          customList={customList}
          hideDialog={hideDialog}
          linkedNewsletters={linkedNewsletters}
          licenses={licenses}
          userData={userData}
          licenseTablePayload={licenseTablePayload}
          handleLicenseTableFilterUpdate={handleLicenseTableFilterUpdate}
          handleLicenseTableSortUpdate={handleLicenseTableSortUpdate}
          handleLicenseTablePageUpdate={handleLicenseTablePageUpdate}
          handleLicenseTableFilterChange={handleLicenseTableFilterChange}
        />
      </DialogWrapper>
    );
  };
  return (
    <>
      {getDataTable()}
      {getDialogWrapper()}
      {getConfirmationPopup()}
    </>
  );
};

export default NewsletterCustomListTable;
