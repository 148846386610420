import { startOfWeek, endOfDay, addWeeks, addMonths, addYears, startOfDay } from "date-fns";

/**
 * durationDate.ts (InstaLOD GmbH)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file durationDate.ts
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */
export enum durations {
  ONE_DAY = 'One day',
  ONE_WEEK = 'One week',
  TWO_WEEKS = 'Two weeks',
  ONE_MONTH = 'One month',
  ONE_YEAR = 'One year',
  TWO_YEARS = 'Two years',
  THREE_YEARS = 'Three years',
  NINETYNINE_YEARS = 'Ninety-nine years'
}

export const definedDates = () => {
  return {
    startOfToday: startOfDay(new Date()),
    ONE_DAY: endOfDay(new Date()),
    ONE_WEEK: endOfDay(addWeeks(new Date(), 1)),
    TWO_WEEKS: endOfDay(addWeeks(new Date(), 2)),
    ONE_MONTH: endOfDay(addMonths(new Date(), 1)),
    ONE_YEAR: endOfDay(addYears(new Date(), 1)),
    TWO_YEARS: endOfDay(addYears(new Date(), 2)),
    THREE_YEARS: endOfDay(addYears(new Date(), 3)),
    NINETYNINE_YEARS: endOfDay(addYears(new Date(), 99))
  }
}