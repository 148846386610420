/*
 * MenuWrapper (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file MenuWrapper
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REACT_APP_USER_FRONTEND_BASE_URL } from '../../config';
import { useTranslation } from 'react-i18next';
import SidebarPage, { IMenuItem } from '@abstract/abstractwebcommon-client/Sidebar/SidebarPage';
import { menuItemClick } from '@abstract/abstractwebcommon-client/Sidebar/menuItemUtils';
import { useLocation } from 'react-router-dom';
import { getUserApplicationsAction } from '../../Store/Settings';
import {
  IAuthStateSelector,
  ISettingsStateSelector,
  IStateSelectors
} from '../../Interfaces/Selectors';
import { Helmet } from 'react-helmet';
import {
  linkedApplicationsSidebarItems,
  staticLinkSidebarItems
} from '@abstract/abstractwebcommon-client/Sidebar/sharedMenus';
import { getAllStaticLinksAction } from '../../Store/StaticLinks';
import {
  getStaticLinksState,
  IStaticLinksState
} from '@abstract/abstractwebcommon-client/store/StaticLinksSlice';
import { userAuthenticationVerificationUrlPath } from '@abstract/abstractwebcommon-client/Constants';
import { IApplications } from '@abstract/abstractwebcommon-shared/interfaces/user/applications';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import {
  SharedMainRouteName,
  SharedCommomRouteName
} from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { RouteName } from '../../Utils/routesNames';
import i18n from '../../Services/I18n';

const MenuWrapper: React.FC<any> = (properties: any) => {
  const {
    menuItems,
    menu,
    fnLogout,
    isAdmin,
    history,
    setMenuItems,
    didChangeTheme,
    themeMode,
    didChangeLanguage,
    languageSettingsMode
  } = properties;
  const user: IAuthStateSelector = useSelector((state: IStateSelectors) => state.auth);
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const staticLinks: IStaticLinksState = useSelector(getStaticLinksState);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [menuItemsToSendThroughSidebar, setMenuItemsToSendThroughSidebar] =
    useState<IMenuItem[]>(menuItems);
  const noImageContainer = (
    <h4 className="text-light">{settings.safeSettings.applicationTitle || t('/.page_title')}</h4>
  );
  const dispatch: Dispatch<any> = useDispatch();
  const [extraMenuStyles, setExtraMenuStyles] = useState<string>('');
  const token: string | null = LocalStorage.getXAuthToken(); /**< User token. */
  const userFrontendURL: string =
    settings.safeSettings.userFrontendBaseURL ||
    REACT_APP_USER_FRONTEND_BASE_URL; /**< User Frontend URL. */

  useEffect(() => {
    if (menuItems) {
      const splitParam = isAdmin
        ? `${SharedMainRouteName.adminRoute}/`
        : `${SharedMainRouteName.clientRoute}/`;
      const id = pathname.split(splitParam)[1]
        ? pathname.split(splitParam)[1].split('/')[0]
        : RouteName.dashboardRoute;
      menuItemClick(
        menu,
        setMenuItems,
        history,
        pathname,
        id === '' ? RouteName.dashboardRoute : id,
        false
      );
    }
    if (LocalStorage.getXAuthToken() && staticLinks.allStaticLinks.length <= 0) {
      dispatch(getAllStaticLinksAction());
    }
    if (settings.userApplications.length <= 0) {
      dispatch(getUserApplicationsAction());
    }
  }, []);

  /// Added static links and linked applications to menu items of Sidebar component
  useEffect(() => {
    setMenuItemsToSendThroughSidebar(() => {
      return [
        ...properties.menuItems,
        ...linkedApplicationsSidebarItems(
          settings.userApplications,
          `${userFrontendURL}${userAuthenticationVerificationUrlPath}`
        ),
        ...staticLinkSidebarItems([...staticLinks.allStaticLinks])
      ];
    });
  }, [staticLinks.allStaticLinks, settings.userApplications, properties.menuItems]);

  const regenerateMenuItems = () => {
    const editNewsletterID: string = localStorage.getItem('edit_newsletterID');
    const analyticsNewsletterID: string = localStorage.getItem('analytics_newsletterID');
    /// Add edit-newsletter pathname with ID to highlight the newsletter submenu
    if (editNewsletterID) {
      if (menuItems && menuItems.length) {
        menuItems.forEach((menuItem: IMenuItem) => {
          if (menuItem.items && menuItem.items.length) {
            menuItem.items.forEach((subItem: IMenuItem) => {
              if (subItem.id === 'newsletterPage') {
                subItem.pathToHighlight = [
                  RouteName.adminNewsletterRoute,
                  `${RouteName.adminNewsletterRoute}/new`,
                  `${RouteName.adminNewsletterRoute}/${localStorage.getItem('edit_newsletterID')}`
                ];
              }
            });
          }
        });
      }
    }
    /// Add newsletter-analytics pathname with ID to highlight the analytics submenu
    if (analyticsNewsletterID) {
      if (menuItems && menuItems.length) {
        menuItems.forEach((menuItem: IMenuItem) => {
          if (menuItem.items && menuItem.items.length) {
            menuItem.items.forEach((subItem: IMenuItem) => {
              if (subItem.id === 'analytics') {
                subItem.pathToHighlight = [
                  RouteName.adminNewsletterAnalyticsRoute,
                  `${RouteName.adminNewsletterAnalyticsRoute}/${localStorage.getItem(
                    'analytics_newsletterID'
                  )}`
                ];
              }
            });
          }
        });
      }
    }
    setMenuItemsToSendThroughSidebar(() => {
      return [
        ...menuItems,
        ...linkedApplicationsSidebarItems(
          settings.userApplications,
          `${userFrontendURL}${userAuthenticationVerificationUrlPath}`
        ),
        ...staticLinkSidebarItems([...staticLinks.allStaticLinks])
      ];
    });
  };

  useEffect(() => {
    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener('storage', regenerateMenuItems);
    };
  }, []);

  // Hook up the event handler
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.xAuthTokenKey) {
      return;
    }

    regenerateMenuItems();
  });

  const handleUserMenuItemsPopulated = (styles: string): void => {
    setExtraMenuStyles(styles);
  };

  /// Checks if the user is authenticated, else redirect to /validate
  useEffect(() => {
    if (!LocalStorage.getXAuthToken() && !user.isAuthenticated) {
      window.location.href = SharedCommomRouteName.validateRoute;
    }
  }, [user]);

  /// Logout all applications
  const logoutAllApplications = () => {
    const userApplications: IApplications[] = settings.userApplications
      .filter(
        (eachApplication: IApplications) =>
          eachApplication.isUserPermissionGranted === true &&
          eachApplication.verificationURL !== userFrontendURL
      )
      .map((eachApplication) => {
        return {
          applicationUUID: eachApplication.applicationUUID,
          verificationURL: eachApplication.verificationURL
        };
      }); /**< UserApplications. */

    const parameters: URLSearchParams = new URLSearchParams({
      logoutAll: 'true',
      userApplications: JSON.stringify(userApplications)
    }); /**< Query parameters */
    window.location.href = `${SharedCommomRouteName.validateRoute}?${parameters.toString()}`;
  };

  return (
    <>
      {user.isAuthenticated && (
        <>
          <Helmet>
            <style type="text/css">{`
        ${extraMenuStyles}
      `}</style>
          </Helmet>

          <SidebarPage
            menu={menu}
            menuItems={menuItemsToSendThroughSidebar}
            logoAlt={t('I18N.sidebar.logo_alt')}
            logoNoImageContainer={noImageContainer}
            logoUrl={properties.logoURL}
            onAccount={() => {
              // Redirect to AbstractUser Profile page.
              if (token) {
                window.open(
                  `${userFrontendURL}${
                    SharedCommomRouteName.loginRoute
                  }?token=${token}&themeMode=${LocalStorage.getThemeMode()}&languageSettingsMode=${LocalStorage.getLanguageSettingsMode()}`,
                  '_blank'
                );
              }
            }}
            onLogout={() => fnLogout()}
            userInformation={user}
            handleUserMenuItemsPopulated={handleUserMenuItemsPopulated}
            didChangeTheme={didChangeTheme}
            themeMode={themeMode}
            logoutAllText={t('admin.menu.logout_all')}
            onLogoutAll={logoutAllApplications}
            languageSettingsMode={languageSettingsMode}
            didChangeLanguage={didChangeLanguage}
            i18nService={i18n}
          />
        </>
      )}
    </>
  );
};

export default React.memo(MenuWrapper);
