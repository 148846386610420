/*
 * Entitlements.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Entitlements.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  createOrUpdate,
  getAll,
  getClientData,
  read,
  remove,
  uploadImage
} from '../Services/Entitlement';
import { formatTableSortOrder } from '../Utils/Formatter';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IReducerAction,
  PaginationRequestAction,
  PaginationResponseAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import { IPaginationSort } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IImageUploadResponse } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import { IGetClientEntitlementsResponse } from '@abstract/abstractwebcommon-shared/interfaces/license/api';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { IMyLicensesSection } from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const ENTITLEMENTS_FEATURE_KEY = 'entitlements';

/**
 * Interface entitlement State
 */
interface IEntitlementState {
  list: [];
  entitlements: [];
  clientEntitlements: [];
  clientData: {
    myProducts: any[];
    unlicensedProducts: any[];
    myFiles: any[];
    myLicensesSection: IMyLicensesSection[];
  };
  entitlementsIsFetching: boolean;
  clientEntitlementsIsFetching: boolean;
  listIsFetching: boolean;
  entitlement: any;
  entitlementIsFetching: boolean;
  entitlementIsChanging: boolean;
  entitlementUpdateSuccess: boolean;
  sort: IPaginationSort<IEntitlement>;
  skip: number;
  limit: number;
  isLoadingImages: boolean /** Control when all images are loading and when are loaded */;
  searchTerm: string /* Search component value **/;
}

/**
 * Entitlement Intial state
 */
const INITIAL_STATE: IEntitlementState = {
  list: null,
  entitlements: null,
  clientEntitlements: [],
  clientData: {
    myProducts: [],
    unlicensedProducts: [],
    myFiles: [],
    myLicensesSection: []
  },
  entitlementsIsFetching: false,
  clientEntitlementsIsFetching: true,
  listIsFetching: false,
  entitlement: null,
  entitlementIsFetching: false,
  entitlementIsChanging: false,
  entitlementUpdateSuccess: false,
  sort: {
    sortField: 'created',
    sortOrder: -1
  },
  skip: 0,
  limit: defaultTableLimit,
  isLoadingImages: true,
  searchTerm: ''
};

export interface IEntitlementFormValues {
  name?: string;
  description?: string;
  live?: boolean;
  file?: any;
  productUrl?: any;
  isDeactivatable?: boolean;
  imageName?: string;
  entitlementId?: string;
  entitlementUUID?: string;
  files?: any[];
}

export const entitlementSlice = createSlice({
  name: ENTITLEMENTS_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    getEntitlementsRequest(
      state: IEntitlementState,
      action: PaginationRequestAction<IEntitlement>
    ) {
      const { skip, limit, sort, searchTerm } = action.payload;
      if (sort) {
        state.sort = sort as IPaginationSort<IEntitlement>;
      }
      state.skip = skip;
      state.limit = limit;
      state.listIsFetching = true;
      state.searchTerm = searchTerm;
    },
    getEntitlementsSuccess(
      state: IEntitlementState,
      action: PaginationResponseAction<IEntitlement>
    ) {
      state.list = action.payload as any;
      state.listIsFetching = false;
    },
    getEntitlementsFailure(state: IEntitlementState) {
      state.list = [];
      state.listIsFetching = false;
    },
    getAllEntitlementsRequest(state: IEntitlementState) {
      state.entitlementsIsFetching = true;
    },
    getAllEntitlementsSuccess(
      state: IEntitlementState,
      action: PaginationResponseAction<IEntitlement>
    ) {
      state.entitlements = action.payload as any;
      state.entitlementsIsFetching = false;
    },
    getAllEntitlementsFailure(state: IEntitlementState) {
      state.entitlementsIsFetching = false;
    },
    getClientEntitlementsRequest(state: IEntitlementState) {
      state.clientEntitlementsIsFetching = true;
    },
    getClientEntitlementsSuccess(
      state: IEntitlementState,
      action: IReducerAction<IGetClientEntitlementsResponse>
    ) {
      state.clientData = action.payload;
      state.clientEntitlementsIsFetching = false;
    },
    getClientEntitlementsFailure(state: IEntitlementState) {
      state.clientEntitlementsIsFetching = false;
    },
    addEntitlementRequest(state: IEntitlementState) {
      state.entitlementIsChanging = true;
    },
    addEntitlementSuccess(state: IEntitlementState) {
      state.entitlementIsChanging = false;
    },
    addEntitlementFailure(state: IEntitlementState) {
      state.entitlementIsChanging = false;
    },
    updateEntitlementRequest(state: IEntitlementState) {
      state.entitlementIsChanging = true;
      state.entitlementUpdateSuccess = false;
    },
    updateEntitlementSuccess(state: IEntitlementState) {
      state.entitlementIsChanging = false;
      state.entitlementUpdateSuccess = true;
    },
    updateEntitlementFailure(state: IEntitlementState) {
      state.entitlementIsChanging = false;
    },
    deleteEntitlementRequest(state: IEntitlementState) {
      state.entitlementIsChanging = true;
    },
    deleteEntitlementSuccess(state: IEntitlementState) {
      state.entitlementIsChanging = false;
    },
    deleteEntitlementFailure(state: IEntitlementState) {
      state.entitlementIsChanging = false;
    },
    areAllImagesLoadedSuccess(state: IEntitlementState) {
      state.isLoadingImages = false;
    }
  },
  extraReducers: {}
});

export const entitlementReducer = entitlementSlice.reducer;
export const entitlementActions = entitlementSlice.actions;

/**
 * Set isLoadingImages state to false when all images are loaded.
 * @param payload
 */
export const setAreAllImagesLoaded = createAsyncThunk(
  'entitlement/get/image/all/load/success',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { areAllImagesLoadedSuccess } = entitlementActions;

    try {
      dispatch(areAllImagesLoadedSuccess());
    } catch (error: any) {
      handleError({ message: error.message });
    }
  }
);

/**
 * Get all entitlements with pagniation Action.
 * @param payload
 */
export const getAllEntitlements = createAsyncThunk(
  'entitlement/all',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getEntitlementsRequest, getEntitlementsSuccess, getEntitlementsFailure } =
      entitlementActions;

    try {
      const { skip, limit } = payload;
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */

      dispatch(getEntitlementsRequest({ skip, limit, sort: { sortField, sortOrder }, searchTerm }));

      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';

      const response: PaginatedAPIEntityResponse<IEntitlement> = await asyncErrorHandler(
        read(searchTerm, null, skip, limit, sortField, formattedSortOrder)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getEntitlementsFailure());
      } else {
        dispatch(getEntitlementsSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getEntitlementsFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Get all entitlements Action.
 * @param payload
 */
export const getAllEntitlementsWithoutPagination = createAsyncThunk(
  'entitlement/get/all',
  async (payload: void, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getAllEntitlementsRequest, getAllEntitlementsSuccess, getAllEntitlementsFailure } =
      entitlementActions;

    try {
      dispatch(getAllEntitlementsRequest());
      const response: PaginatedAPIEntityResponse<IEntitlement> = await asyncErrorHandler(getAll());
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getAllEntitlementsFailure());
      } else {
        dispatch(getAllEntitlementsSuccess(response.data.records || []));
      }
    } catch (error: any) {
      dispatch(getAllEntitlementsFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Get users entitlements Action.
 * @param payload
 */
export const getClientEntitlement = createAsyncThunk(
  'entitlement/get/client',
  async (payload: string, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getClientEntitlementsRequest,
      getClientEntitlementsSuccess,
      getClientEntitlementsFailure
    } = entitlementActions;

    try {
      dispatch(getClientEntitlementsRequest());
      const userUUID: string = payload; /**< User UUID. */
      const response: IAPIEntityResponse<IGetClientEntitlementsResponse> = await asyncErrorHandler(
        getClientData(userUUID)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getClientEntitlementsFailure());
      } else {
        dispatch(getClientEntitlementsSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getClientEntitlementsFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Create entitlement Action.
 * @param payload
 */
export const createEntitlement = createAsyncThunk(
  'entitlement/create',
  async (payload: IEntitlementFormValues, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const { addEntitlementRequest, addEntitlementSuccess, addEntitlementFailure } =
      entitlementActions;
    try {
      dispatch(addEntitlementRequest());

      if (payload && payload.file) {
        const result: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadImage(payload.file)
        );

        if (result.status === 200) {
          payload.imageName = result.data.imageName;
        }
      }
      const result: IAPIEntityResponse<IEntitlement> = await asyncErrorHandler(
        createOrUpdate(payload)
      );
      if (result.error) {
        dispatch(addEntitlementFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(addEntitlementSuccess());
        showSuccessToast(result.message);
        dispatch(
          getAllEntitlements({
            skip: store.entitlements.skip,
            limit: store.entitlements.limit,
            sort: {
              sortField: store.entitlements.sort.sortField,
              sortOrder: store.entitlements.sort.sortOrder
            },
            searchTerm: store.entitlements.searchTerm
          })
        );
      }
    } catch (error: any) {
      dispatch(addEntitlementFailure());
      handleError({ message: error.message });
    }
  }
);

/**
 * Update entitlement Action.
 * @param payload
 */
export const updateEntitlement = createAsyncThunk(
  'entitlement/update',
  async (payload: IEntitlementFormValues, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const { updateEntitlementRequest, updateEntitlementSuccess, updateEntitlementFailure } =
      entitlementActions;
    try {
      dispatch(updateEntitlementRequest());
      if (payload && payload.file) {
        const result: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadImage(payload.file)
        );

        if (result.status === 200) {
          payload.imageName = result.data.imageName;
        } else if (result.error) {
          dispatch(updateEntitlementFailure());
          handleError({ message: result.error.message });
          return;
        }
      }
      const result: IAPIEntityResponse<IEntitlement> = await asyncErrorHandler(
        createOrUpdate(payload)
      );
      if (result.error) {
        dispatch(updateEntitlementFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateEntitlementSuccess());
        showSuccessToast(result.message);
        dispatch(
          getAllEntitlements({
            skip: store.entitlements.skip,
            limit: store.entitlements.limit,
            sort: {
              sortField: store.entitlements.sort.sortField,
              sortOrder: store.entitlements.sort.sortOrder
            },
            searchTerm: store.entitlements.searchTerm
          })
        );
      }
      return result;
    } catch (error: any) {
      dispatch(updateEntitlementFailure());
      handleError({ message: error.error.message });
    }
  }
);

/**
 * Delete entitlement Action.
 * @param payload
 */
export const deleteEntitlement = createAsyncThunk(
  'entitlement/delete',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const { deleteEntitlementRequest, deleteEntitlementSuccess, deleteEntitlementFailure } =
      entitlementActions;

    try {
      dispatch(deleteEntitlementRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(remove(payload));
      if (result.error) {
        dispatch(deleteEntitlementFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deleteEntitlementSuccess());
        showSuccessToast(result.message);
        dispatch(
          getAllEntitlements({
            skip: store.entitlements.skip,
            limit: store.entitlements.limit,
            sort: {
              sortField: store.entitlements.sort.sortField,
              sortOrder: store.entitlements.sort.sortOrder
            },
            searchTerm: store.entitlements.searchTerm
          })
        );
      }
    } catch (error: any) {
      dispatch(deleteEntitlementFailure());
      handleError({ message: (error && error.error && error.error.message) || '' });
    }
  }
);
