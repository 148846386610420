/*
 * SettingPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file SettingPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SettingForm from './SettingForm';
import {
  testSmtpAction,
  updateSettings,
  getSettings,
  getApplicationEnvironmentVariables
} from '../../../Store/Settings';
import { fetchLicenseTemplates } from '../../../Store/Licenses';
import { fetchAllCSSTemplate } from '../../../Store/Templates';
import CropDialog from '@abstract/abstractwebcommon-client/CropDialog/CropDialog';
import {
  ISettingsStateSelector,
  IStateSelectors,
  ITemplateStateSelector
} from '../../../Interfaces/Selectors';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  getStaticLinksState,
  IStaticLinksState,
  staticLinksActions
} from '@abstract/abstractwebcommon-client/store/StaticLinksSlice';
import {
  createStaticLinkAction,
  deleteStaticLinkAction,
  getStaticLinksAction,
  updateStaticLinkAction
} from '../../../Store/StaticLinks';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';

export enum ImageUploadAction {
  Delete = 'delete',
  Add = 'add',
  Unset = 'unset'
}

const SettingPage: React.FC<any> = (): JSX.Element => {
  const t: TFunction = useTranslation().t;
  const dispatch: Dispatch<any> = useDispatch();
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const templates: ITemplateStateSelector = useSelector(
    (state: IStateSelectors) => state.templates
  );
  const staticLinks: IStaticLinksState = useSelector(getStaticLinksState);
  const [uploadedLogoFile, setUploadedLogoFile] = useState<any>(null);
  const [statusLogoUpload, setStatusLogoUpload] = useState<ImageUploadAction>(
    ImageUploadAction.Unset
  );
  const [uploadedFavouriteIconFile, setUploadedFavouriteIconFile] = useState<any>(null);
  const [statusFavouriteIconUpload, setStatusFavouriteIconUpload] = useState<ImageUploadAction>(
    ImageUploadAction.Unset
  );
  const [isLogoFileChanged, setIsLogoFileChanged] = useState<boolean>(false);
  const [isFavouriteIconFileChanged, setFavouriteIconFileChanged] = useState<boolean>(false);
  const [croppedLogo, setCroppedLogo] = useState<any>(null);
  const [croppedFavouriteIcon, setCroppedFavouriteIcon] = useState<any>(null);

  const [croppedStaticLinkIcon, setCroppedStaticLinkIcon] = useState<any>(null);
  const [isStaticLinkIconChanged, setStaticLinkIconChanged] = useState<boolean>(false);
  const [initiateStaticLinkIconUpload, setInitiateStaticLinkIconUpload] = useState<any>(null);
  const [statusStaticLinkIconUpload, setStatusStaticLinkIconUpload] = useState<string>('');
  const [tablePayload, setTablePayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      created: 'ASC'
    }
  });

  /**
   * Trigger setting update
   * @param values Values to update
   */
  const handleSettingSubmit = async (values: any): Promise<void> => {
    const payload: any = {
      values
    };

    if (statusLogoUpload === ImageUploadAction.Add) {
      payload['uploadLogoImage'] = { file: uploadedLogoFile };
    } else if (statusLogoUpload === ImageUploadAction.Delete) {
      payload['deleteLogoImage'] = true;
      payload['values']['logoImageName'] = '';
    }

    if (statusFavouriteIconUpload === ImageUploadAction.Add) {
      payload['uploadFavouriteIconImage'] = { file: uploadedFavouriteIconFile };
    } else if (statusFavouriteIconUpload === ImageUploadAction.Delete) {
      payload['deleteFavouriteIconImage'] = true;
      payload['values']['favouriteIconImageName'] = '';
    }

    dispatch(updateSettings(payload));

    //set defaults
    setUploadedFavouriteIconFile(null);
    setStatusFavouriteIconUpload(ImageUploadAction.Unset);
    setIsLogoFileChanged(false);
    setFavouriteIconFileChanged(false);
    setCroppedFavouriteIcon(null);
    setCroppedLogo(null);
    setUploadedLogoFile(null);
    setStatusLogoUpload(ImageUploadAction.Unset);
  };

  /**
   * Trigger setting test smtp
   * @param values Values to test
   */
  const handleTestSmtp = async (values: any): Promise<void> => {
    dispatch(testSmtpAction(values));
  };

  /**
   * Uploads favouriteIcon
   * @param files Favicon file to upload
   */
  const handleFavouriteIconUpload = (files: any): void => {
    const file: any = files ? files[0] : null;
    setStatusFavouriteIconUpload(ImageUploadAction.Add);
    setFavouriteIconFileChanged(true);
    setUploadedFavouriteIconFile(file);
  };

  /**
   * Deletes favouriteIcon
   */
  const handleFavouriteIconDelete = (): void => {
    setStatusFavouriteIconUpload(ImageUploadAction.Delete);
  };

  /**
   * Uploads logo
   * @param files Logo file to upload
   */
  const handleLogoUpload = (files: any): void => {
    const file: any = files ? files[0] : null;
    setStatusLogoUpload(ImageUploadAction.Add);
    setIsLogoFileChanged(true);
    setUploadedLogoFile(file);
  };

  /**
   * Deletes logo
   */
  const handleLogoDelete = (): void => {
    setStatusLogoUpload(ImageUploadAction.Delete);
  };

  // fetch license templates
  /**
   * Fetch license template
   * @param filter filter
   */
  const fetchLicenseDropdown = (filter: string): void => {
    dispatch(
      fetchLicenseTemplates({
        filter
      })
    );
  };

  const handleLogoCrop = (image: any): void => {
    if (!image) {
      setStatusLogoUpload(ImageUploadAction.Unset);
    }
    setCroppedLogo(image);
    setUploadedLogoFile(image);
  };
  const handleFavouriteIconCrop = (image: any): void => {
    if (!image) {
      setStatusFavouriteIconUpload(ImageUploadAction.Unset);
    }
    setCroppedFavouriteIcon(image);
    setUploadedFavouriteIconFile(image);
  };

  /**
   * extract uploaded static icon file
   * @param files Static link file to upload
   */
  const uploadStaticLinkIcon = async (files: any[]): Promise<void> => {
    const file: any = files ? files[0] : null;
    if (file.type.indexOf('image/') !== -1) {
      setInitiateStaticLinkIconUpload(file);
      setStaticLinkIconChanged(true);
      setStatusStaticLinkIconUpload('add');
    } else {
      showToast({
        severity: 'error',
        summary: t('admin.page.settings.buttons.upload_valid_image')
      });
    }
  };

  /**
   * delete static links
   * @param selectedStaticLinks Selected links for deletion
   */
  const handleDeleteStaticLinks = async (selectedStaticLinks: any): Promise<void> => {
    const staticLinksUUIDs: any = {
      staticLinksUUIDs: selectedStaticLinks.map(
        (eachStaticLinks: any) => eachStaticLinks.staticLinkUUID
      )
    };
    dispatch(
      deleteStaticLinkAction({
        staticLinksUUIDs: staticLinksUUIDs.staticLinksUUIDs,
        tablePayload: tablePayload
      })
    );
  };

  /**
   * update static link
   * @param staticLink Static link to update
   * @param staticLinkUUID Static link uuid
   */
  const handleStaticLinkUpdate = async (
    staticLink: any,
    staticLinkUUID: string | null
  ): Promise<void> => {
    const payload: any = {
      staticLink,
      tablePayload
    };

    if (statusStaticLinkIconUpload === 'add') {
      payload['uploadStaticLinkIcon'] = { file: initiateStaticLinkIconUpload };
    } else if (statusStaticLinkIconUpload === 'delete') {
      payload['deleteStaticLinkIcon'] = true;
    }

    if (staticLinkUUID && staticLinkUUID !== '') {
      dispatch(updateStaticLinkAction({ data: payload, staticLinkUUID }));
    } else {
      dispatch(createStaticLinkAction(payload));
    }
  };

  /**
   * delete staticLinkIcon
   */
  const deleteStaticLinkIcon = (): void => {
    setStatusStaticLinkIconUpload('delete');
  };

  const handleStaticLinkIconCropComplete = (image: any): void => {
    setCroppedStaticLinkIcon(image);
    setInitiateStaticLinkIconUpload(image);
  };

  const refreshSaticLinksList = (updatedCriteria?: ITablePayload) => {
    setTablePayload(updatedCriteria);
    dispatch(getStaticLinksAction(updatedCriteria));
  };

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getStaticLinksAction(tablePayload));
    dispatch(getApplicationEnvironmentVariables());
    dispatch(fetchLicenseTemplates({}));
    dispatch(fetchAllCSSTemplate());

    // on unmount
    return () => {
      dispatch(staticLinksActions.reset()); // Reset staticLink state.
    };
  }, [dispatch]);

  /// listen to static links events
  useEffect(() => {
    if (staticLinks.staticLinksError) {
      showToast({
        severity: 'error',
        summary: staticLinks.staticLinksError?.message || staticLinks.staticLinksError
      });
    }
    if (staticLinks.isStaticLinkCreated) {
      showToast({ severity: 'success', summary: t('I18N.success_messages.static_link_created') });
    }
    if (staticLinks.isStaticLinkUpdated) {
      showToast({ severity: 'success', summary: t('I18N.success_messages.static_link_updated') });
    }
    if (staticLinks.isStaticLinksDeleted) {
      showToast({ severity: 'success', summary: t('I18N.success_messages.static_link_deleted') });
    }
  }, [staticLinks]);

  return (
    <Row>
      <Col xs={12} className="pb-0">
        <SettingForm
          handleSubmit={handleSettingSubmit}
          handleFavouriteIconUpload={handleFavouriteIconUpload}
          handleFavouriteIconDelete={handleFavouriteIconDelete}
          displayCroppedFavouriteIcon={croppedFavouriteIcon}
          handleLogoUpload={handleLogoUpload}
          handleLogoDelete={handleLogoDelete}
          handleTestSmtp={handleTestSmtp}
          displayCroppedLogo={croppedLogo}
          CSSTemplates={templates.allCSSTemplates}
          isLoading={settings.settingsIsFetching || settings.settingIsChanging}
          isLoadingTestSMTP={settings.isLoadingTestSMTP}
          settingsState={settings}
          staticLinksState={staticLinks}
          fetchLicenseDropdown={fetchLicenseDropdown}
          handleStaticLinkUpdate={handleStaticLinkUpdate}
          handleStaticLinkDelete={handleDeleteStaticLinks}
          refreshSaticLinksList={refreshSaticLinksList}
          uploadStaticLinkIcon={uploadStaticLinkIcon}
          deleteStaticLinkIcon={deleteStaticLinkIcon}
          displayCroppedStaticLinkIcon={croppedStaticLinkIcon}
          setCroppedStaticLinkIcon={setCroppedStaticLinkIcon}
          logoUploadStatus={statusLogoUpload}
          favouriteIconUploadStatus={statusFavouriteIconUpload}
        />
      </Col>
      <CropDialog
        isVisible={isLogoFileChanged}
        setVisible={setIsLogoFileChanged}
        file={uploadedLogoFile}
        onImageCropComplete={handleLogoCrop}
      />
      <CropDialog
        isVisible={isFavouriteIconFileChanged}
        setVisible={setFavouriteIconFileChanged}
        file={uploadedFavouriteIconFile}
        onImageCropComplete={handleFavouriteIconCrop}
        isIcon
      />
      <CropDialog
        isVisible={isStaticLinkIconChanged}
        setVisible={setStaticLinkIconChanged}
        file={initiateStaticLinkIconUpload}
        onImageCropComplete={handleStaticLinkIconCropComplete}
        isIcon
      />
    </Row>
  );
};

export default SettingPage;
