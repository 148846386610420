/*
 * AdminContent.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file AdminContent.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { IAuthStateSelector, IStateSelectors } from '../../Interfaces/Selectors';
import { getAdminRoutes } from '../../Routes/AdminRoutes';
import PageHeader from '@abstract/abstractwebcommon-client/PageHeader/PageHeader';
import Col from 'react-bootstrap/Col';
import { SharedCommomRouteName } from '@abstract/abstractwebcommon-client/utils/sharedRoutesNames';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { LanguageSettingsMode } from '@abstract/abstractwebcommon-shared/interfaces/Language';

const AdminContent = () => {
  const user: IAuthStateSelector = useSelector((state: IStateSelectors) => state.auth);
  const languageSettingsMode: string =
    LocalStorage.getLanguageSettingsMode() ||
    LanguageSettingsMode.english; /**< Language settings mode */
  const [routes, setRoutes] = useState<any>([]); /**< Routes */

  // Get admin routes when language changes
  useEffect(() => {
    const adminRoutes = getAdminRoutes();
    setRoutes([...adminRoutes]);
  }, [languageSettingsMode]);

  //Note: Get admin routes when localstorage changes
  window.addEventListener('storage', (event: StorageEvent) => {
    if (event.key === LocalStorage.languageSettingsModeKey) {
      const adminRoutes = getAdminRoutes();
      setRoutes([...adminRoutes]);
    }
  });

  /// Render specific component if isAuthenticated and if isAdmin, Else redirect to login.
  const renderComponent = routes.map(
    ({ authenticated, Component, path, headingKey, isHeaderLabelHide = true }, key) => {
      if (authenticated && !user.isAuthenticated && !user.isAdmin) {
        return <Redirect key={key} to={{ pathname: SharedCommomRouteName.validateRoute }} />;
      }

      return (
        <Route exact path={path} key={key}>
          {headingKey && isHeaderLabelHide ? <PageHeader title={headingKey} /> : null}
          <Col sm={12}>
            <Component />
          </Col>
        </Route>
      );
    }
  );

  return <Switch>{renderComponent}</Switch>;
};

export default AdminContent;
