/*
 * Activations.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Activations.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { ActionReducerMapBuilder, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deactivateAll,
  deactivateAllClientsActivations,
  deactivateSelected,
  fetchLicenseActivationsAPI,
  getAllUserActivations,
  getTotalActivationCount,
  read,
  readUserActivation,
  updateActivationAPI
} from '../Services/Activation';
import { formatTableSortOrder } from '../Utils/Formatter';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import {
  IReducerAction,
  PaginationRequestAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import {
  IAPIEntityResponse,
  IAPIErrorData,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import {
  IPaginationResponse,
  IPaginationSortField,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { IActivation } from '@abstract/abstractwebcommon-shared/interfaces/license/activation';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const ACTIVATION_FEATURE_KEY = 'activations';
interface IActivationState {
  list: [];
  records: IActivation[];
  totalRecords: number;
  listIsFetching: boolean;
  myActivationIsFetching: boolean;
  myActivations: [];
  userActivationsIsFetching: boolean;
  userActivations: IActivation[] | null /**< User activations */;
  userTotalActivations: number /**< User activations count */;
  activationIsFetching: boolean;
  activationIsChanging: boolean;
  filter: any;
  sort: IPaginationSortField<IActivation>;
  skip: number;
  limit: number;
  userActivationPagination: {
    filter: any;
    sort: IPaginationSortField<IActivation>;
    skip: number;
    limit: number;
  };
  activationCount: number | null;
  fetchError: IAPIErrorData | null;
  licenseActivations: [] /**< License Activations */;
  licenseActivationsIsFetching: boolean /**< License Activations is fetching. */;
  userData: IUser[] | null /**< UserData */;
  searchTerm?: string /* Search component value **/;
}

interface IFilterActivationObject {
  machine?: any;
  machineIP?: any;
  licenseProject?: any;
  activationDate?: any;
  deactivationDate?: any;
}

const INITIAL_STATE: IActivationState = {
  list: [],
  records: null,
  totalRecords: 0,
  listIsFetching: false,
  myActivationIsFetching: false,
  myActivations: [],
  userActivationsIsFetching: false,
  userActivations: null,
  userTotalActivations: 0,
  activationIsFetching: false,
  activationIsChanging: false,
  filter: {},
  sort: {},
  skip: 0,
  limit: defaultTableLimit,
  userActivationPagination: {
    filter: {},
    sort: {},
    skip: 0,
    limit: defaultTableLimit
  },
  activationCount: null,
  fetchError: null,
  licenseActivations: [],
  licenseActivationsIsFetching: false,
  userData: null,
  searchTerm: ''
};

export const activationSlice = createSlice({
  name: ACTIVATION_FEATURE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    resetMyLicensesActivationsStates(state: IActivationState) {
      state.licenseActivations = [];
    },
    getActivationsRequest(state: IActivationState, action: PaginationRequestAction<IActivation>) {
      const { skip, limit, filter, sort, searchTerm } = action.payload;
      if (filter) {
        state.filter = filter;
      }
      if (sort) {
        state.sort = sort;
      }
      state.skip = skip;
      state.limit = limit;
      state.listIsFetching = true;
      state.searchTerm = searchTerm;
    },
    getActivationSuccess(state: IActivationState, action: IReducerAction<Record<string, any>>) {
      state.listIsFetching = false;
      state.records = action.payload.activations.records;
      state.totalRecords = action.payload.activations.totalRecords;
      state.userData = action.payload.users;
    },
    getActivationsFailure(state: IActivationState) {
      state.listIsFetching = false;
      state.records = [];
    },
    getMyActivationsRequest(state: IActivationState, action: PaginationRequestAction<IActivation>) {
      const { skip, limit, filter, sort, searchTerm } = action.payload;
      if (filter) {
        state.filter = filter;
      }
      if (sort) {
        state.sort = sort;
      }
      state.skip = skip;
      state.limit = limit;
      state.myActivationIsFetching = true;
      state.searchTerm = searchTerm;
    },
    getMyActivationSuccess(
      state: IActivationState,
      action: IReducerAction<IPaginationResponse<IActivation>>
    ) {
      state.myActivationIsFetching = false;
      state.myActivations = action.payload as any;
    },
    getMyActivationsFailure(state: IActivationState) {
      state.myActivationIsFetching = false;
    },
    getUserActivationsRequest(
      state: IActivationState,
      action: PaginationRequestAction<IActivation>
    ) {
      const { skip, limit, filter, sort } = action.payload;
      if (filter) {
        state.userActivationPagination.filter = filter;
      }
      if (sort) {
        state.userActivationPagination.sort = sort;
      }
      state.userActivationPagination.skip = skip;
      state.userActivationPagination.limit = limit;
      state.userActivationsIsFetching = true;
    },
    getUserActivationSuccess(
      state: IActivationState,
      action: IReducerAction<PaginatedAPIEntityResponse<IActivation>>
    ) {
      state.userActivationsIsFetching = false;
      state.userActivations = action.payload.records;
      state.userTotalActivations = action.payload.totalRecords;
    },
    getUserActivationsFailure(state: IActivationState) {
      state.userActivationsIsFetching = false;
    },
    deactivateAllClientActivationsRequest(state: IActivationState) {
      state.activationIsChanging = true;
    },
    deactivateAllClientActivationsSuccess(state: IActivationState) {
      state.activationIsChanging = false;
    },
    deactivateAllClientActivationsFailure(state: IActivationState) {
      state.activationIsChanging = false;
    },
    deactivateAllActivationsRequest(state: IActivationState) {
      state.activationIsChanging = true;
    },
    deactivateAllActivationsSuccess(state: IActivationState) {
      state.activationIsChanging = false;
    },
    deactivateAllActivationsFailure(state: IActivationState) {
      state.activationIsChanging = false;
    },
    deactivateSelectedActivationsRequest(state: IActivationState) {
      state.activationIsChanging = true;
    },
    deactivateSelectedActivationsSuccess(state: IActivationState) {
      state.activationIsChanging = false;
    },
    deactivateSelectedActivationsFailure(state: IActivationState) {
      state.activationIsChanging = false;
    },
    getLicenseActivationsRequest(state: IActivationState) {
      state.licenseActivationsIsFetching = true;
    },
    getLicenseActivationsSuccess(
      state: IActivationState,
      action: IReducerAction<IPaginationResponse<IActivation>>
    ) {
      state.licenseActivationsIsFetching = false;
      state.licenseActivations = action.payload as any;
    },
    getLicenseActivationsFailure(state: IActivationState) {
      state.licenseActivationsIsFetching = false;
      state.licenseActivations.records = [];
      state.licenseActivations.totalRecords = 0;
    },
    updateActivationRequest(state: IActivationState) {
      state.activationIsChanging = true;
    },
    updateActivationSuccess(state: IActivationState) {
      state.activationIsChanging = false;
    },
    updateActivationFailure(state: IActivationState) {
      state.activationIsChanging = false;
    }
  },
  extraReducers: (builder: ActionReducerMapBuilder<IActivationState>) => {
    builder
      .addCase(getTotalActivationCountAction.pending, (state: IActivationState) => {
        state.listIsFetching = true;
        state.fetchError = null;
      })
      .addCase(
        getTotalActivationCountAction.fulfilled,
        (state: IActivationState, action: IReducerAction<IAPIEntityResponse<IActivation>>) => {
          if (action.payload.data) {
            state.activationCount = action.payload.data.activationCount;
          }
          if (action.payload.error) {
            state.activationCount = null;
            state.fetchError = action.payload.error;
          }
          state.listIsFetching = false;
        }
      );
  }
});

export const activationReducer = activationSlice.reducer;
export const activationActions = activationSlice.actions;

/**
 * Get all activations Action.
 * @param payload
 */
export const getAllActivations = createAsyncThunk(
  'activation/all',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getActivationSuccess, getActivationsRequest, getActivationsFailure } =
      activationActions;

    try {
      const { skip, limit, filter } = payload;
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */

      dispatch(
        getActivationsRequest({ skip, limit, sort: { sortField, sortOrder }, filter, searchTerm })
      );

      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';
      const formattedFilters: IFilterActivationObject = {};

      if (filter && filter.machine) formattedFilters.machine = filter.machine.value;
      if (filter && filter.machineIP) formattedFilters.machineIP = filter.machineIP.value;
      if (filter && filter.licenseProject)
        formattedFilters.licenseProject = filter.licenseProject.value;
      if (filter && filter.activationDate)
        formattedFilters.activationDate = filter.activationDate.value;
      if (filter && filter.deactivationDate)
        formattedFilters.deactivationDate = filter.deactivationDate.value;

      const response: PaginatedAPIEntityResponse<IActivation> = await asyncErrorHandler(
        read(skip, limit, sortField, formattedSortOrder, formattedFilters, searchTerm)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getActivationsFailure());
      } else {
        dispatch(getActivationSuccess(response.data));
      }
    } catch (exception: any) {
      dispatch(getActivationsFailure());
      handleError({ message: exception.message });
    }
  }
);

/**
 * Get my activations Action.
 * @param payload
 */
export const getMyActivations = createAsyncThunk(
  'activation/my',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getMyActivationsRequest, getMyActivationSuccess, getMyActivationsFailure } =
      activationActions;

    try {
      const { skip, limit, sortField, sortOrder, filter, searchTerm } = payload;

      dispatch(
        getMyActivationsRequest({ skip, limit, sort: { sortField, sortOrder }, filter, searchTerm })
      );

      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';
      const formattedFilters: IFilterActivationObject = {};

      if (filter && filter.machine) formattedFilters.machine = filter.machine.value;
      if (filter && filter.machineIP) formattedFilters.machineIP = filter.machineIP.value;
      if (filter && filter.licenseProject)
        formattedFilters.licenseProject = filter.licenseProject.value;
      if (filter && filter.activationDate)
        formattedFilters.activationDate = filter.activationDate.value;
      if (filter && filter.deactivationDate)
        formattedFilters.deactivationDate = filter.deactivationDate.value;

      const response: PaginatedAPIEntityResponse<IActivation> = await asyncErrorHandler(
        readUserActivation(skip, limit, sortField, formattedSortOrder, formattedFilters, searchTerm)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getMyActivationsFailure());
      } else {
        dispatch(getMyActivationSuccess(response.data));
      }
    } catch (exception: any) {
      dispatch(getMyActivationsFailure());
      handleError({ message: exception.message });
    }
  }
);

/**
 * Get a user activations Action.
 * @param payload
 */
export const getUserActivations = createAsyncThunk(
  'activation/user',
  async (payload: { data: ITablePayload; userUUID: string }, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getUserActivationsRequest, getUserActivationSuccess, getUserActivationsFailure } =
      activationActions;

    try {
      const { skip, limit, filter } = payload.data;
      const sortField: string = payload.data.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.data.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload.data?.searchTerm; /**< Sort order. */

      dispatch(getUserActivationsRequest({ skip, limit, sort: { sortField, sortOrder }, filter }));

      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';
      const formattedFilters: IFilterActivationObject = {};

      if (filter && filter.machine) formattedFilters.machine = filter.machine.value;
      if (filter && filter.machineIP) formattedFilters.machineIP = filter.machineIP.value;
      if (filter && filter.licenseProject)
        formattedFilters.licenseProject = filter.licenseProject.value;
      if (filter && filter.activationDate)
        formattedFilters.activationDate = filter.activationDate.value;
      if (filter && filter.deactivationDate)
        formattedFilters.deactivationDate = filter.deactivationDate.value;

      const response: PaginatedAPIEntityResponse<IActivation> = await asyncErrorHandler(
        getAllUserActivations(
          payload.userUUID,
          skip,
          limit,
          sortField,
          formattedSortOrder,
          formattedFilters,
          searchTerm
        )
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getUserActivationsFailure());
      } else {
        dispatch(getUserActivationSuccess(response.data));
      }
    } catch (exception: any) {
      dispatch(getUserActivationsFailure());
      handleError({ message: exception.message });
    }
  }
);

/**
 * Deactivate all activations Action (admin).
 * @param payload
 */
export const deactivateAllActivations = createAsyncThunk(
  'activation/deactivate/all',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const {
      deactivateAllActivationsRequest,
      deactivateAllActivationsSuccess,
      deactivateAllActivationsFailure
    } = activationActions;

    try {
      dispatch(deactivateAllActivationsRequest());
      const userUUID: string = payload.userUUID;
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(deactivateAll(userUUID));
      if (result.error) {
        dispatch(deactivateAllActivationsFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deactivateAllActivationsSuccess());
        showSuccessToast(result.message);
        dispatch(
          getUserActivations({
            data: {
              skip: store.activations.userActivationPagination.skip,
              limit: store.activations.userActivationPagination.limit,
              sort: {
                sortField: store.activations.userActivationPagination.sort.sortField,
                sortOrder: store.activations.userActivationPagination.sort.sortOrder
              },
              filter: store.activations.filter,
              searchTerm: store.activations.searchTerm
            },
            userUUID: userUUID
          })
        );
      }
    } catch (exception: any) {
      dispatch(deactivateAllActivationsFailure());
      handleError({ message: exception && exception.error ? exception.error.message : '' });
    }
  }
);

/**
 * Deactivate all activations Action (client). Not used anymore, can be delete (end all related code) in the future in case the app don't need the function
 * @param payload
 */
export const deactivateAllClientActivations = createAsyncThunk(
  'activation/deactivate/all',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const {
      deactivateAllClientActivationsRequest,
      deactivateAllClientActivationsSuccess,
      deactivateAllClientActivationsFailure
    } = activationActions;

    try {
      dispatch(deactivateAllClientActivationsRequest());
      const userUUID: string = LocalStorage.getXUserUUID() || '';
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        deactivateAllClientsActivations(userUUID)
      );
      if (result.error) {
        dispatch(deactivateAllClientActivationsFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deactivateAllClientActivationsSuccess());
        showSuccessToast(result.message);
        dispatch(
          getMyActivations({
            skip: store.activations.userActivationPagination.skip,
            limit: store.activations.userActivationPagination.limit,
            sortField: store.activations.userActivationPagination.sort.sortField,
            sortOrder: store.activations.userActivationPagination.sort.sortOrder,
            filter: store.activations.filter,
            searchTerm: store.activations.searchTerm
          })
        );
      }
    } catch (exception: any) {
      dispatch(deactivateAllClientActivationsFailure());
      handleError({ message: exception && exception.error ? exception.error.message : '' });
    }
  }
);

/**
 * Deactivate selected activations Action.
 * @param payload
 */
export const deactivateSelectedActivations = createAsyncThunk(
  'activation/deactivate/selected',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const {
      deactivateSelectedActivationsRequest,
      deactivateSelectedActivationsSuccess,
      deactivateSelectedActivationsFailure
    } = activationActions;

    try {
      dispatch(deactivateSelectedActivationsRequest());
      const { selectedActivations, client } = payload;
      const ids: string[] = selectedActivations.map((activation: any) => activation.activationUUID);
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(deactivateSelected(ids));
      if (result.error) {
        dispatch(deactivateSelectedActivationsFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deactivateSelectedActivationsSuccess());
        showSuccessToast(result.message);
        if (client) {
          dispatch(
            getMyActivations({
              skip: store.activations.skip,
              limit: store.activations.limit,
              sortField: store.activations.sort.sortField,
              sortOrder: store.activations.sort.sortOrder,
              filter: store.activations.filter,
              searchTerm: store.activations.searchTerm
            })
          );
        } else {
          dispatch(
            getAllActivations({
              skip: store.activations.skip,
              limit: store.activations.limit,
              sort: {
                sortField: store.activations.sort.sortField,
                sortOrder: store.activations.sort.sortOrder
              },
              filter: store.activations.filter,
              searchTerm: store.activations.searchTerm
            })
          );
        }
      }
    } catch (exception: any) {
      dispatch(deactivateSelectedActivationsFailure());
      handleError({ message: exception && exception.error ? exception.error.message : '' });
    }
  }
);

/**
 * Fetches total activations count.
 */
export const getTotalActivationCountAction = createAsyncThunk('activations/count', async () => {
  return getTotalActivationCount();
});

/**
 * Fetch License Activations with pagination Action.
 * @param payload
 */
export const fetchLicenseActivations = createAsyncThunk(
  'activation/licenseActivations',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getLicenseActivationsSuccess,
      getLicenseActivationsRequest,
      getLicenseActivationsFailure
    } = activationActions;
    try {
      const skip: number = payload?.skip; /**< Skip */
      const limit: number = payload?.limit; /**< Limit */
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const licenseUUID: string = payload.licenseUUID; /**< LicenseUUID. */

      dispatch(getLicenseActivationsRequest());
      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';

      const response: PaginatedAPIEntityResponse<IActivation> = await asyncErrorHandler(
        fetchLicenseActivationsAPI(skip, limit, sortField, formattedSortOrder, licenseUUID)
      );
      if (response.error) {
        dispatch(getLicenseActivationsFailure());
        handleError({ message: response.error.message });
        return false;
      } else {
        dispatch(getLicenseActivationsSuccess(response.data));
        return true;
      }
    } catch (exception: any) {
      dispatch(getLicenseActivationsFailure());
      handleError({ message: exception.message });
      return false;
    }
  }
);

/**
 * Update activation Action. Used to update notes so far only
 * @param payload
 */
export const updateActivation = createAsyncThunk(
  'activation/update',
  async (payload: IActivation, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { updateActivationSuccess, updateActivationRequest, updateActivationFailure } =
      activationActions;
    try {
      dispatch(updateActivationRequest());

      const response: IAPIEntityResponse<IActivation> = await asyncErrorHandler(
        updateActivationAPI(payload)
      );
      if (response.error) {
        dispatch(updateActivationFailure());
        handleError({ message: response.error.message });
      } else {
        dispatch(updateActivationSuccess());
        showSuccessToast(response.message);
      }
      return response;
    } catch (error: any) {
      dispatch(updateActivationFailure());
      handleError({ message: error.toString() });
    }
  }
);
