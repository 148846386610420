/*
 * SettingFavicon.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file SettingFavicon.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISettingsStateSelector } from '../../../Interfaces/Selectors';
import InstaImageUpload, {
  RemoveButtonStyle
} from '@abstract/abstractwebcommon-client/InstaImageUpload/index';
import '@abstract/abstractwebcommon-client/SettingsFormFaviconLogo.css';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';
interface IFavouriteIconProperties {
  handleFavouriteIconDelete: (event: any) => void;
  handleFavouriteIconUpload: (event: any) => void;
  displayCroppedFavouriteIcon: any;
  settingsState: ISettingsStateSelector;
  children: React.ReactNode /** Children elements */;
  changeInputClassName: () => void /** Change component class name based on settings information */;
}

const SettingFavicon = ({
  handleFavouriteIconDelete: handleFavouriteIconDelete,
  handleFavouriteIconUpload: handleFavouriteIconUpload,
  displayCroppedFavouriteIcon,
  settingsState,
  children,
  changeInputClassName
}: IFavouriteIconProperties): JSX.Element => {
  const { t } = useTranslation();
  const [toggleFullSizePreview, setToggleFullSizePreview] = useState<boolean>(false);
  return (
    <div id="favicon" className="logo-favicon basis-0 flex-grow-1 mt-2 input-fixed-height">
      <label htmlFor="favicon" className="logo-favicon flex-grow-1">
        {t('admin.page.settings.category.favicon')}
      </label>
      <PopupInformation
        id={'favicon'}
        popupText={t('admin.page.settings.category.favicon_popupText')}
      />
      <div className={`p-2 p-inputtext ${changeInputClassName(true)}`}>
        <InstaImageUpload
          showLegend={false}
          imageUrl={settingsState.setting?.favouriteIconImageURL}
          showDelete={true}
          removeButtonStyle={RemoveButtonStyle.SEPARATE}
          deleteHandler={handleFavouriteIconDelete}
          onChange={handleFavouriteIconUpload}
          imgClass={'imageUrl rounded'}
          showUploadBtn={false}
          isHorizontalLayout
          altText={t('admin.page.settings.alt.favicon')}
          plainBtnLabel={t('admin.page.settings.button_choose_file')}
          isPlainBtn={true}
          setToggleFullSizePreview={setToggleFullSizePreview}
          toggleFullSizePreview={toggleFullSizePreview}
          previewSizes={[
            { width: 180, height: 180 },
            { width: 32, height: 32 },
            { width: 16, height: 16 }
          ]}
          croppedImage={displayCroppedFavouriteIcon}
        />
      </div>
      {children}
    </div>
  );
};

export default SettingFavicon;
