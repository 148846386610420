/*
 * TemplateEditor.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file TemplateEditor.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/* eslint import/no-webpack-loader-syntax: off */
import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateTemplate, getTemplates } from '../../../Store/Templates';
import TemplateComponent from '@abstract/abstractwebcommon-client/TemplateEditor/TemplateComponent';
import { getSafeSettings } from '../../../Store/Settings';
import {
  ISettingsStateSelector,
  ITemplateStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { ITemplate } from '@abstract/abstractwebcommon-shared/interfaces/license/template';
const licenseBackendCSS = require('!css-loader!resolve-url-loader!sass-loader!../../../Scss/App.scss');

/**
 * Template Editor component.
 * @name TemplateEditor
 */
const TemplateEditor = (): ReactElement => {
  const { t } = useTranslation();
  const dispatch: Dispatch<any> = useDispatch();
  const templates: ITemplateStateSelector = useSelector(
    (state: IStateSelectors) => state.templates
  );
  const [masterTemplate, setMasterTemplate] = useState<string>('');
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);

  const codeMirrorOptions = {
    theme: 'dracula',
    lineNumbers: true,
    lineWrapping: true
  };

  /// Copies a placeholder
  const copyPlaceholder = (item: any) => {
    navigator.clipboard.writeText(`{{${item}}}`);
    showSuccessToast(t('I18N.admin.template_editor.copy_success'));
  };

  /// onSubmit action
  const onTemplateSubmit = (updatedTemplate: ITemplate) => {
    const updatedPayload = {
      templateUUID: updatedTemplate.templateUUID,
      name: updatedTemplate.name,
      subject: updatedTemplate.subject,
      type: updatedTemplate.type,
      html: updatedTemplate.html
    };
    dispatch(updateTemplate(updatedPayload));
  };

  /// Checks if the template is updated.
  useEffect(() => {
    dispatch(getTemplates());
    dispatch(getSafeSettings());
  }, [dispatch]);

  ///Set master template
  useEffect(() => {
    if (templates?.list?.template?.length > 0) {
      setMasterTemplate(
        templates?.list?.template?.find((template: any) => template.type === 'masterTemplate')?.html
      );
    }
  }, [templates.list.template]);

  return (
    <TemplateComponent
      codeMirrorOptions={{
        mode: 'text/html',
        ...codeMirrorOptions
      }}
      templates={templates?.list?.template}
      submitHandler={onTemplateSubmit}
      submitClasses={'p-button p-button-primary'}
      editorClasses={''}
      changeLayoutClasses={'p-button p-button-primary'}
      masterTemplate={masterTemplate}
      copyPlaceholderHandler={copyPlaceholder}
      applicationCSS={licenseBackendCSS || ''}
      customCSSLink={
        (settings && settings.safeSettings && settings.safeSettings['customCssLink']) || ''
      }
    />
  );
};

export default TemplateEditor;
