/*
 * ProductsForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file ProductsForm.tsx
 * @author Etienne Daher
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import { TFunction } from 'i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { IMyLicensesSection } from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import EntitlementList from './EntitlementList';
import EntitlementModal from './EntitlementModal';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';
import { ProductCardClassName } from './DashboardPage';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

interface IProductsFormPropertiesInterface {
  isLoading: boolean;
  products?: any[] /** Data to the Your Products and Unlicensed Products section. */;
  myLicensesSectionData?: IMyLicensesSection[] /** Data to the Your Licenses section. */;
  pageSectionType: PageSectionType;
  feedsData: any;
  selectProductHandler: (selectedProductIdentifier: number, productUUID: string) => void;
}

/**
 * @enum PageSectionType
 * Where the product form component is used.
 */
export enum PageSectionType {
  MyProducts = 'MY_PRODUCTS' /** Used in Your Products section. */,
  MyLicenses = 'MY_LICENSES' /** Used in Your Licenses section. */,
  UnlicensedProducts = 'UNLICENSED_PRODUCTS' /** Used in Unlicensed Products section. */
}

const ProductsForm: React.FC<any> = (properties: IProductsFormPropertiesInterface) => {
  const { isLoading, products, selectProductHandler, pageSectionType, myLicensesSectionData } =
    properties;
  const [isShowEntitlementModal, setShowEntitlementModal] =
    useState<boolean>(false); /**< To Show entitlement modal. */
  const [selectedLicenseUUID, setSelectedLicenseUUID] = useState<string | null>(
    null
  ); /**< Selected license to display in the entitlement modal. */

  const translation: TFunction = useTranslation().t;

  let entitlements = [...(products ?? [])];
  if (isLoading) {
    // render templates
    entitlements = [null, null, null];
  }

  const myLicensesSectionOnClick = (licenseUUID: string): void => {
    setSelectedLicenseUUID(licenseUUID);
    setShowEntitlementModal(true);
  };

  const getCardBadgeStatusLabel = (license: IMyLicensesSection): string => {
    if (license.isPaused) {
      return translation('I18N.client.dashboard.productPaused');
    }

    return new Date(license.licenseEndDate) > new Date()
      ? translation('I18N.client.dashboard.productActive')
      : translation('I18N.client.dashboard.productExpired');
  };

  const getCardBadgeStatusClassname = (license: IMyLicensesSection): string => {
    if (license.isPaused) {
      return ProductCardClassName.Paused;
    }

    return new Date(license.licenseEndDate) > new Date()
      ? ProductCardClassName.Active
      : ProductCardClassName.Expired;
  };

  return (
    <>
      <Row className="my-licenses-sections-grid-container">
        {myLicensesSectionData &&
          myLicensesSectionData.length > 0 &&
          myLicensesSectionData.map((license: IMyLicensesSection) => (
            <Col
              className="my-licenses-sections-flex-container-low-breakpoints"
              key={license.licenseUUID}>
              <div
                className={`card custom-card-height my-licenses-sections-grid-container-item productCard ${
                  selectedLicenseUUID === license.licenseUUID ? 'selectedProduct' : ''
                }`}
                onClick={() => myLicensesSectionOnClick(license.licenseUUID)}>
                <div className="w-100">
                  <div className="card-body expand-as-needed">
                    <div className="d-flex">
                      <p
                        className={`card-text product-text card-status-container ${getCardBadgeStatusClassname(
                          license
                        )}`}>
                        {getCardBadgeStatusLabel(license)}
                      </p>

                      <div>
                        {license.pastDueReason ? (
                          <PopupInformation
                            id={license.licenseUUID}
                            popupText={license.pastDueReason}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <p className="mt-n2 card-text product-text card-entitlement-margin custom-my-license-section-license-name">
                      {license.licenseName}
                    </p>

                    <p className="card-text product-text card-project-name-label">
                      {license.seats}{' '}
                      {license.seats === 1
                        ? translation('client.page.dashboard.myLicensesSection.seat')
                        : translation('client.page.dashboard.myLicensesSection.seats')}{' '}
                      ({license.remainingSeats}{' '}
                      {translation('client.page.dashboard.myLicensesSection.seatsRemaining')})
                    </p>

                    <p className="card-text product-text m-0 bg-red-500">
                      <label className="m-0 cursor-pointer">
                        {`${formatDate(license.licenseStartDate)} - ${formatDate(
                          license.licenseEndDate
                        )}`}
                      </label>
                    </p>

                    <p className="mt-2">
                      <span className="card-text product-text card-filesCount-container">
                        {license.entitlementCount}{' '}
                        {license.entitlementCount === '1'
                          ? translation('client.page.dashboard.myLicensesSection.entitlementLabel')
                          : translation(
                              'client.page.dashboard.myLicensesSection.entitlementsLabel'
                            )}
                      </span>
                      <span className="card-text product-text card-filesCount-container ml-2">
                        {translation('client.page.dashboard.myLicensesSection.renewable')}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
      </Row>

      <Row>
        {isShowEntitlementModal ? (
          <EntitlementModal
            isShowEntitlementModal={isShowEntitlementModal}
            setShowEntitlementModal={setShowEntitlementModal}
            selectedLicenseUUID={selectedLicenseUUID}
            setSelectedLicenseUUID={setSelectedLicenseUUID}
          />
        ) : (
          <></>
        )}

        <EntitlementList
          entitlements={entitlements}
          selectProductHandler={selectProductHandler}
          pageSectionType={pageSectionType}
          isRenderingOnlyEntitlementList={false}
        />

        {entitlements !== undefined && entitlements !== null && entitlements.length === 0 ? (
          <Col sm={12}>
            {pageSectionType === PageSectionType.MyProducts ? (
              <h6>{translation('I18N.client.dashboard.noPurchase')}</h6>
            ) : (
              <></>
            )}
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </>
  );
};

export default ProductsForm;
