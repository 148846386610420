/*
 * LicenseForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file LicenseForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useRef, useState } from 'react';
import { FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { addYears, endOfDay, startOfDay } from 'date-fns';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { Range as IDateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import { InstaInputMultiselect } from '@abstract/abstractwebcommon-client/FormControl/InstaInputMultiselect';
import i18n from '../../../Services/I18n';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import { ManualDateRangePicker } from '@abstract/abstractwebcommon-client/DateRangePicker/dateRangePicker';
import { showToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { AutoComplete } from 'primereact/autocomplete';
import {
  IAuthStateSelector,
  IClientStateSelector,
  IEntitlementStateSelector,
  ISettingsStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClientsWithoutPagination } from '../../../Store/Clients';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import SublicenseForm from './SublicenseForm';
import {
  ILicense,
  ISublicenseCreateDTO,
  ISublicense,
  ISublicenseUpdateDTO
} from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { ICachedUser } from '@abstract/abstractwebcommon-shared/interfaces/UserCache';

interface ILicenseOtherProperties {
  handleSubmit?: any;
  isLoading?: boolean;
  licenseEntitlements?: Array<any>;
  license?: any;
  clientID?: any;
  templateLicenseUUID?: any; // on new forms only (when triggered from page)
  deleteButtonHandler: (
    event: any,
    isDeleteTriggerFromLicenseForm: boolean
  ) => void /**< Handler for delete license(s). */;
  users: IUser[] /**< User data */;
}

interface ILicenseFormValues {
  licenseStartDate: any;
  licenseEndDate: any;
  projectName: string;
  licenseMaxCount: number;
  entitlements: Array<string>;
  userUUID: string;
  sublicenses?: ILicense[] /**< Sub licenses */;
  remainingSeats: number /**< License remaining/available seats. */;
  user: ICachedUser /**< License owner details. */;
}

interface ILicenseMyFormProperties {
  initialLicenseStartDate?: any;
  initialLicenseEndDate?: any;
  initialProjectName?: string;
  initialLicenseMaxCount?: number;
  initialEntitlements?: Array<string>;
  initialUserId?: string;
  handleSubmit?: any;
  isLoading?: boolean;
  licenseEntitlements?: Array<any>;
  license?: any;
  clientID?: any;
  templateLicenseUUID?: any;
  deleteButtonHandler: (
    event: any,
    isDeleteTriggerFromLicenseForm: boolean
  ) => void /**< Handler for delete license(s). */;
  initialSublicenses?: ISublicenseCreateDTO[];
}

/**
 * Interface for event from handlers.
 */
interface IEventValue<Entity> {
  value: Entity /**< Event value */;
}

const LicenseForm = (properties: ILicenseOtherProperties & FormikProps<ILicenseFormValues>) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    isLoading,
    licenseEntitlements = [],
    license,
    deleteButtonHandler,
    setFieldValue,
    setFieldError,
    users
  } = properties;
  const { t } = useTranslation();
  const node: any = useRef();
  const defaultStartDate: Date = new Date();
  const defaultEndDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  const defaultDate: IDateRange[] = [
    {
      startDate: license ? new Date(license.licenseStartDate) : startOfDay(new Date()),
      endDate: license ? new Date(license.licenseEndDate) : endOfDay(addYears(new Date(), 1)),
      key: 'selection'
    }
  ]; /**< License Date or initial date. */
  const [date, setDate] =
    useState<IDateRange[]>(defaultDate); /**< To update licenseStartDate & licenseEndDate. */
  const clientState: IClientStateSelector = useSelector(
    (state: IStateSelectors) => state.clients
  ); /**< Client state */
  const dispatch = useDispatch();
  const entitlements: IEntitlementStateSelector = useSelector(
    (state: IStateSelectors) => state.entitlements
  );

  const [searchedClient, setSearchedClient] = useState<any>(''); /**< Searched client */
  const [isSublicenseFormVisible, setSublicenseFormVisible] = useState<boolean>(
    license?.isParentLicense
  ); /**< To show sublicense form or not */
  const authState: IAuthStateSelector = useSelector(
    (state: IStateSelectors) => state.auth
  ); /**< Authstate */
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const isAdmin: boolean = authState.isAdmin; /**< Loggedin user is admin or not */
  const isClientUserOrSublicenseRecord: boolean = !isAdmin || license?.isSublicense;

  const showStartDateChangeWarning = (): void => {
    showToast({
      severity: 'warn',
      summary: 'Warn',
      detail: 'Start date of license has been changed'
    });
  };

  const handleDateChange = async (event: any): Promise<void> => {
    // If the start date has changed, display warning message.
    if (date[0].startDate.getTime() !== event.selection.startDate.getTime()) {
      showStartDateChangeWarning();
    }

    setDate([event.selection]);
    values.licenseStartDate = event.selection.startDate;
    values.licenseEndDate = event.selection.endDate;
  };

  const triggerSubmit = (event: any): void => {
    values.licenseStartDate = date[0].startDate;
    values.licenseEndDate = date[0].endDate;
    event.userFullName = searchedClient.fullName;
    handleSubmit(event);
  };

  // Adds spacing between selected entitlements
  const selectedItemTemplate = (option: IEntitlement): string | void => {
    if (option) {
      // No comma for first element
      if (
        values.entitlements.length === 1 ||
        (values.entitlements[0] && (values.entitlements[0] as IEntitlement).name === option.name)
      ) {
        return `${option.name}`;
      } else {
        return `, ${option.name}`;
      }
    }
  };

  // We should display the sublicense form if the sublicense entitlement is selected.
  const isSublicenseEntitlementSelected = (selectedEntitlements: IEntitlement[]): boolean =>
    selectedEntitlements.some(
      (entitlement) =>
        entitlement.entitlementUUID === settings?.safeSettings.sublicenseEntitlementUUID
    );

  useEffect(() => {
    // on init set default date values
    values.licenseStartDate = defaultStartDate;
    values.licenseEndDate = defaultEndDate;
  }, []);

  // Either user id is required before filling fields,
  // otherwise fields will be disabled
  const isDisabled: boolean = !values.userUUID || values.userUUID === '';

  /**
   * Handler for client search
   */
  const handleClientSearch = async (event: any): Promise<void> => {
    if (event?.query.length <= 2) return;

    setTimeout(async () => {
      timeoutFunction();
    }, 1500);

    const timeoutFunction = async (): Promise<void> => {
      if (license) {
        await asyncErrorHandler(
          dispatch(getAllClientsWithoutPagination({ filter: '', clientId: license.userUUID }))
        );
      } else {
        // If user is not an admin, fetch only that user details. (i.e) Don't allow to fetch all users.
        await asyncErrorHandler(
          dispatch(
            getAllClientsWithoutPagination(
              isAdmin
                ? { filter: event?.query.trim() || '' }
                : { filter: '', clientId: authState.userUUID }
            )
          )
        );
      }
    };
  };

  /**
   * Handler for client change
   */
  const handleClientChange = (event: IEventValue<IUser>): void => {
    if (typeof event.value === 'string') {
      setSearchedClient(event.value);
    } else if (typeof event.value === 'object') {
      const user: IUser = event.value;
      if (user) {
        setSearchedClient({
          ...user,
          fullDetails: `${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`
        });
      }
    }

    setFieldValue('userUUID', event?.value?.userUUID);
    setFieldValue('userID', event?.value?.id);
    setFieldValue('user', event?.value);
  };

  /**
   * Item template for Autocomplete
   */
  const itemTemplate = (user: IUser): JSX.Element => {
    return <div>{`${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`}</div>;
  };

  useEffect(() => {
    if (license) {
      const user: ICachedUser = license.user;

      setSearchedClient({
        ...user,
        fullDetails: `${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`
      }); /**< Set the license users details */
    }
  }, [license]);

  return (
    <>
      <FormWrapper
        controlButtonLabel={license && Object.keys(license).length > 0}
        isLoading={isLoading}
        handleDeleteButton={(event: React.MouseEvent<HTMLButtonElement>) =>
          deleteButtonHandler(event, true)
        }
        handleSubmitButton={() => triggerSubmit(values)}
        disableDeleteButton={!isAdmin}>
        <Row>
          <Form.Group as={Col} sm="12" md="12">
            <Form.Label
              htmlFor="userUUID"
              className={`${
                isLoading || license
                  ? true
                  : false || entitlements.entitlementsIsFetching
                  ? ''
                  : 'required'
              }`}>
              {t('admin.page.licenses.form.client')}
            </Form.Label>
            <AutoComplete
              id={'userUUID'}
              name={'userUUID'}
              value={searchedClient}
              suggestions={clientState.list && clientState.list?.records}
              completeMethod={handleClientSearch}
              field="fullDetails"
              placeholder={t('admin.page.licenses.placeholders.client')}
              onChange={handleClientChange}
              size={120}
              dropdown
              appendTo="self"
              itemTemplate={itemTemplate}
              disabled={isLoading || license ? true : false || entitlements.entitlementsIsFetching}
              className={`${touched.userUUID && errors.userUUID ? 'p-invalid' : ''} ${
                !isAdmin ? 'p-disabled' : ''
              }`}
              onClear={() => {
                setFieldValue('userUUID', null);
              }}
            />
            {touched.userUUID && errors.userUUID ? (
              <small id="email-invalid" className="p-invalid error-text">
                {t(errors.userUUID)}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} md={12} sm={12} lg={12} className="license-duration">
            <Form.Label>{t('admin.page.licenses.form.licenseStartAndEndDate')}</Form.Label>
            <ManualDateRangePicker
              node={node}
              date={date}
              setDate={setDate}
              defaultDate={defaultDate}
              isDisabled={isDisabled || isClientUserOrSublicenseRecord}
              isClearButtonVisible={false}
              handleDateChange={handleDateChange}
              showStartDateChangeWarning={showStartDateChangeWarning}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6" md="6">
            <InstaInputText
              label={t('admin.page.licenses.form.licenseProjectName')}
              labelClassName={`${isClientUserOrSublicenseRecord ? '' : 'required'}`}
              name="projectName"
              id={'projectName'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.projectName}
              errors={errors.projectName}
              value={values.projectName}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
              spanClassName={isClientUserOrSublicenseRecord ? 'p-disabled' : ''}
              isReadOnly={isClientUserOrSublicenseRecord}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6" md="6">
            <InstaInputText
              type="number"
              label={t('admin.page.licenses.form.licenseMaxActivationCount')}
              labelClassName={`${isClientUserOrSublicenseRecord ? '' : 'required'}`}
              name="licenseMaxCount"
              id={'licenseMaxCount'}
              isLoading={isLoading}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.licenseMaxCount}
              errors={errors.licenseMaxCount}
              value={values.licenseMaxCount}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
              spanClassName={isClientUserOrSublicenseRecord ? 'p-disabled' : ''}
              isReadOnly={isClientUserOrSublicenseRecord}
            />
          </Form.Group>
          <InstaInputMultiselect
            inputId={'entitlements'}
            sizing={{ sm: 6, md: 6 }}
            value={values.entitlements}
            label={t('admin.page.licenses.form.licenseEntitlement')}
            labelClassName={`${isDisabled || isClientUserOrSublicenseRecord ? '' : 'required'}`}
            fieldName="entitlements"
            name="entitlements"
            id={'entitlements'}
            isLoading={isLoading}
            selectedItemTemplate={selectedItemTemplate}
            options={licenseEntitlements}
            handleChange={(e) => {
              handleChange(e);
              setSublicenseFormVisible(isSublicenseEntitlementSelected(e.target.value));
            }}
            handleBlur={handleBlur}
            optionLabel="name"
            placeholder={t('admin.page.licenses.placeholders.licenseEntitlement')}
            errors={errors.entitlements}
            touched={touched.entitlements}
            disabled={isDisabled || isClientUserOrSublicenseRecord}
          />
          {/* We should display the remainingMaxCount field only while editing a license */}
          {license && Object.keys(license).length > 0 ? (
            <Form.Group as={Col} sm="6" md="6">
              <InstaInputText
                label={t('admin.page.licenses.form.remainingMaxCount')}
                name="remainingSeats"
                id={'remainingSeats'}
                value={values.remainingSeats}
                spanClassName="p-disabled"
                isReadOnly
              />
            </Form.Group>
          ) : (
            <></>
          )}
        </Row>

        {isSublicenseFormVisible ? (
          <Row>
            <Col sm={12}>
              <SublicenseForm
                parentLicenseOwner={values?.user}
                values={values?.sublicenses}
                touched={touched?.sublicenses}
                errors={errors?.sublicenses}
                isLoading={isLoading}
                handleChange={handleChange}
                handleBlur={handleBlur}
                licenseUserUUID={license?.userUUID}
                users={users}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
                selectedUserUUID={values?.userUUID}
              />
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </FormWrapper>
    </>
  );
};

const License = withFormik<ILicenseMyFormProperties, ILicenseFormValues>({
  mapPropsToValues: (properties) => {
    const { license, licenseEntitlements, templateLicenseUUID } = properties;

    let userUUID: string | null;
    if (license) {
      // filter none live [entitlements] and don't show null values
      let formattedEntitlements: any = license.entitlements.map((entitlement: any) => {
        const foundEntitlement = licenseEntitlements?.find(
          (originalEntitlement) => originalEntitlement?.name === entitlement?.name
        );
        return foundEntitlement;
      });

      // clear values if original entitlement not found
      formattedEntitlements = formattedEntitlements.filter(
        (entitlement: IEntitlement) => entitlement
      );

      return {
        licenseStartDate: license.licenseStartDate,
        licenseEndDate: license.licenseEndDate,
        projectName: license.projectName,
        licenseMaxCount: license.licenseMaxCount,
        remainingSeats: license.remainingSeats,
        entitlements: formattedEntitlements,
        userUUID: license.userUUID,
        user: license?.user,
        userID: license?.user.id,
        sublicenses: license.sublicenses.map((sublicense: ILicense) => ({
          userEmail: isStringEmptyOrNullOrUndefined(sublicense.userUUID)
            ? sublicense.userEmail
            : sublicense.user?.email,
          licenseMaxCount: sublicense.licenseMaxCount,
          licenseUUID: sublicense.licenseUUID,
          userUUID: sublicense.userUUID
        }))
      };
    }
    if (properties.clientID) {
      userUUID = properties.clientID;
    } else {
      userUUID = properties.initialUserId || null;
    }

    return {
      licenseStartDate: properties.initialLicenseStartDate || '',
      licenseEndDate: properties.initialLicenseEndDate || '',
      projectName: templateLicenseUUID?.projectName || properties.initialProjectName,
      licenseMaxCount: templateLicenseUUID?.licenseMaxCount || properties.initialLicenseMaxCount,
      entitlements: properties.initialEntitlements || [],
      userUUID: userUUID,
      sublicenses: properties.initialSublicenses || []
    };
  },
  validationSchema: () => {
    // checks if string contains only numbers
    const checkForJustNumber = (value: any) => !/^\d+$/.test(value);
    // validation schema
    const validationSpec = {
      projectName: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(100, i18n.t('validation.max', { field: '100' }))
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.licenses.form.licenseProjectName')
          })
        )
        .test('is-only-number', i18n.t('validation.cant_number'), checkForJustNumber),
      licenseMaxCount: Yup.number()
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.licenses.form.licenseMaxActivationCount')
          })
        )
        .positive('validation.positive_number'),
      entitlements: Yup.array()
        .min(
          1,
          i18n.t('validation.required', {
            field: i18n.t('admin.page.licenses.form.licenseEntitlement')
          })
        )
        .required(
          i18n.t('validation.required', {
            field: i18n.t('admin.page.licenses.form.licenseEntitlement')
          })
        ),
      userUUID: Yup.string()
        .required(i18n.t('validation.required', { field: i18n.t('I18N.admin.license.client') }))
        .nullable(),
      sublicenses: Yup.array().of(
        Yup.object().shape({
          userEmail: Yup.string()
            .email(i18n.t('validation.email'))
            .required(
              i18n.t('validation.required', {
                field: i18n.t('admin.page.licenses.sublicenses.form.userEmailRequired')
              })
            ),
          licenseMaxCount: Yup.number()
            .required(
              i18n.t('validation.required', {
                field: i18n.t('admin.page.licenses.form.licenseMaxActivationCount')
              })
            )
            .positive(i18n.t('validation.positive_number'))
            .when(
              ['$licenseMaxCount', '$sublicenses'],
              (licenseMaxCount: number, sublicenses: ISublicense[], schema: any) => {
                const totalSublicenseCount: number = sublicenses
                  ?.map((sublicense: ISublicense) => sublicense.licenseMaxCount)
                  .reduce((previous: number, current: number) => {
                    return previous + current;
                  });
                if (totalSublicenseCount > licenseMaxCount) {
                  // Just added dummy value 0 to trigger this valdiation
                  return schema.max(
                    0,
                    i18n.t('admin.page.licenses.sublicenses.validation.max', {
                      field: licenseMaxCount
                    })
                  );
                }
                return schema;
              }
            )
        })
      )
    };

    return Yup.object(validationSpec);
  },
  handleSubmit: (values, bags) => {
    if (bags.props.license) {
      let updatedFieldsCount: number = 0;

      // We should build the correct object to compare with the existing license array to verify if any field was changed, allowing clicking the 'Update' button.
      const customInitialSublicensesObject: ISublicenseUpdateDTO[] =
        bags.props.license?.sublicenses && bags.props.license?.sublicenses.length > 0
          ? Object.values(bags.props.license?.sublicenses).map((oldLicense: ILicense) => ({
              userEmail: isStringEmptyOrNullOrUndefined(oldLicense.userUUID)
                ? oldLicense.userEmail
                : bags.props.license?.sublicenses.find(
                    (user: IUser) => user.userUUID === oldLicense.userUUID
                  )?.userEmail,
              licenseMaxCount: oldLicense.licenseMaxCount,
              licenseUUID: oldLicense.licenseUUID,
              userUUID: oldLicense.userUUID
            }))
          : [];

      Object.keys(values).forEach((key: string) => {
        if (key == 'entitlements') {
          const newEntitlementsUuidsString: string = JSON.stringify(
            (values[key] as IEntitlement[])
              .map((entitlement: IEntitlement) => entitlement.entitlementUUID)
              .sort()
          );
          const initialEntitlementsUuidsString: string = JSON.stringify(
            bags.props.license[key]
              .map((entitlement: IEntitlement) => entitlement.entitlementUUID)
              .sort()
          );
          if (newEntitlementsUuidsString !== initialEntitlementsUuidsString) {
            updatedFieldsCount++;
          }
        } else if (key == 'licenseStartDate' || key == 'licenseEndDate') {
          if (values[key].toISOString() !== bags.props.license[key]) {
            updatedFieldsCount++;
          }
        } else if (key == 'sublicenses') {
          if (values[key] !== undefined) {
            if (values[key]?.length == bags.props.license[key]?.length) {
              const newSublicensesString: string = JSON.stringify(values[key].sort());
              const initialSublicensesString: string = JSON.stringify(
                customInitialSublicensesObject.sort()
              );
              if (newSublicensesString != initialSublicensesString) {
                updatedFieldsCount++;
              }
            } else {
              updatedFieldsCount++;
            }
          }
        } else if (
          values[key as keyof typeof bags.props.license] !==
            bags.props.license[key as keyof typeof bags.props.license] &&
          // NOTE: We must hardcode this condition here 'key !== 'userFullName' to prevent users clicking in the "Update" button with no new values.
          // NOTE: The field 'userFullName' is used in the server side to log the user full name in the the log activity message.
          // NOTE: The field 'userID' is used to assign the right user reference in the license and it's not changed/managed by the current formik.
          key !== 'userFullName' &&
          key !== 'userID'
        ) {
          updatedFieldsCount++;
        }
      });

      if (updatedFieldsCount > 0) {
        return bags.props.handleSubmit(
          {
            id: bags.props.license.id,
            licenseUUID: bags.props.license.licenseUUID,
            ...values
          },
          bags.resetForm
        );
      }
      return;
    }
    return bags.props.handleSubmit(
      {
        ...values
      },
      bags.resetForm
    );
  }
})(LicenseForm);

export default License;
