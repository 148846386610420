/**
 * LicenseTemplate.ts (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file LicenseTemplate.ts
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import { mapSortingFields, mapFilterFields } from '../Utils/Pagination';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { ILicenseTemplate } from '@abstract/abstractwebcommon-shared/interfaces/license/licenseTemplate';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Create or update a license template
 * @param licenseTemplate License Template information
 * @returns Promise
 */
export const createOrUpdate = async (
  licenseTemplate: any
): Promise<IAPIEntityResponse<ILicenseTemplate>> => {
  if (licenseTemplate.licenseTemplateUUID) {
    return httpClient.post<ILicenseTemplate>(
      `/licenseTemplate/${licenseTemplate.licenseTemplateUUID}/update`,
      licenseTemplate
    );
  }
  return httpClient.post<ILicenseTemplate>(`/licenseTemplate/create`, licenseTemplate);
};

/**
 * Gets license templates
 * @param id license template id
 * @param skip Skip
 * @param limit Limit
 * @param sort Sort
 * @param sortOrder Sort order
 * @param data Data of filter values
 * @param searchTerm Search term to find
 * @returns Promise
 */
export const read = async (
  searchTerm: string,
  id?: string | null,
  skip = 0,
  limit = 10,
  sort = 'created',
  sortOrder = 'DESC',
  data = {}
): Promise<PaginatedAPIEntityResponse<ILicenseTemplate>> => {
  const url: string = `/licenseTemplates/all`;

  if (id) {
    return httpClient.get<ILicenseTemplate>(`${url}/${id}`);
  } else {
    const mappedSort: string = mapSortingFields(sort, sortOrder);
    const mappedFilter: string = mapFilterFields(data, []);
    return httpClient.get<ILicenseTemplate>(
      `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&filter=${mappedFilter}&searchTerm=${encodeURIComponent(
        searchTerm ? searchTerm : ''
      )}`
    );
  }
};

/**
 * Deletes a license template
 * @param payload licenseTemplateUUIDs
 * @returns Promise
 */

export const remove = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post(`/licenseTemplates/delete`, { licenseTemplateUUIDs: payload });
};

/**
 * Service to copy licenseTemplate(s)
 * @param payload licenseTemplateUUIDs
 * @returns Promise
 */
export const copyLicenseTemplate = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post('/licenseTemplates/copy', { licenseTemplateUUIDs: payload });
};
