/*
 * LicenseTotalCount.tsx (License)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2022
 *
 * @file LicenseTotalCount.tsx
 * @author Rafael Rodrigues
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import { useSelector } from 'react-redux';

import { IStateSelectors, ILicenseStateSelector } from '../../../Interfaces/Selectors';
import { getTotalLicenseCountAction } from '../../../Store/Licenses';
import MenuBadgeCount from '@abstract/abstractwebcommon-client/Sidebar/MenuBadgeCount/MenuBadgeCount';

/**
 * Display the total of licenses registered
 * @returns JSX.Element
 */
const LicenseTotalCount = (): JSX.Element => {
  const licenses: ILicenseStateSelector = useSelector((state: IStateSelectors) => state.licenses);

  return (
    <MenuBadgeCount
      getTotalItemCount={getTotalLicenseCountAction}
      itemCount={licenses.licenseCount}
      fetchError={licenses.fetchError}
      itemName="licenses"
    />
  );
};

export default LicenseTotalCount;
