/*
 * ClientPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file ClientPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import {
  fetchDashboardStatisticsAction,
  fetchDashboardVersionAction,
  fetchFeedAction,
  fetchSystemWarningsAction
} from '../../../Store/Dashboard';
// TODO: group 3 (+version) in 1 store
import { useTranslation } from 'react-i18next';
import { CURRENT_VERSION, CURRENT_AWC_VERSION } from '../../../config';
import { ISettingsStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { ThemeMode } from '@abstract/abstractwebcommon-shared/enum/theme';
import Dashboard from '@abstract/abstractwebcommon-client/Dashboard';
import { ISoftwareVersionProperties } from '@abstract/abstractwebcommon-client/Dashboard/SoftwareVersion';
import {
  getDashboardState,
  dashboardActions,
  IDashboardState
} from '@abstract/abstractwebcommon-client/store/DashboardSlice';
import AdminDashboardSkeletonWrapper from '@abstract/abstractwebcommon-client/SkeletonLoader/DashboardSkeleton/AdminDashboard/AdminDashboardSkeletonWrapper';
import { IMappedFeed } from '@abstract/abstractwebcommon-shared/interfaces/feed';
import { LocalStorage } from '@abstract/abstractwebcommon-client/utils/sharedLocalStorage';

const ClientPage: React.FC<any> = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { t } = useTranslation();
  const settings: ISettingsStateSelector = useSelector((state: IStateSelectors) => state.settings);
  const dashboardState: IDashboardState = useSelector(getDashboardState);

  const feedsData: IMappedFeed = dashboardState.feedsData;
  const systemWarnings: Record<string, string>[] = dashboardState.systemWarnings;
  const backendVersion: string = dashboardState.version;
  const themeMode: string = LocalStorage.getThemeMode() || ThemeMode.lightMode;
  const chartData: Record<string, number> = dashboardState.statistics;

  const isLoading: boolean =
    !dashboardState.statistics ||
    !dashboardState.version ||
    !dashboardState.feedsData ||
    !dashboardState.systemWarnings;
  const files = (!isLoading && chartData.files) || 0;
  const entitlements = (!isLoading && chartData.entitlements) || 0;
  const groups = (!isLoading && chartData.groups) || 0;
  const users = (!isLoading && chartData.users) || 0;
  const basicData = {
    labels: [
      t('I18N.admin.dashboard.labels.files'),
      t('I18N.admin.dashboard.labels.entitlements'),
      t('I18N.admin.dashboard.labels.groups'),
      t('I18N.admin.dashboard.labels.users')
    ],
    datasets: [
      {
        label: t('I18N.admin.dashboard.labels.total'),
        backgroundColor: '#42A5F5',
        data: [files, entitlements, groups, users]
      }
    ]
  };

  useEffect(() => {
    dispatch(fetchDashboardStatisticsAction());
    dispatch(fetchFeedAction());
    dispatch(fetchSystemWarningsAction());

    // on unmount
    return () => {
      dispatch(dashboardActions.reset());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchDashboardVersionAction());
  }, [dispatch]);

  // Loader
  if (
    !settings.safeSettings ||
    !dashboardState.statistics ||
    !dashboardState.version ||
    !dashboardState.feedsData ||
    !dashboardState.systemWarnings
  ) {
    return <AdminDashboardSkeletonWrapper />;
  }

  const frontendCommitLink = `https://bitbucket.org/TheAbstractCo/abstractlicense/commits/${CURRENT_VERSION}`;
  const frontendCurrentVersion = `${CURRENT_VERSION}`;
  const backendCommitLink = `https://bitbucket.org/TheAbstractCo/abstractlicense/commits/${backendVersion}`;
  const backendCurrentVersion = backendVersion;
  const awcCommitLink = `https://bitbucket.org/TheAbstractCo/abstractuser/commits/${CURRENT_AWC_VERSION}`;
  const awcCurrentVersion = CURRENT_AWC_VERSION;

  const welcomeMessage: string = settings.safeSettings.applicationTitle
    ? t('admin.page.dashboard.welcome', {
        applicationTitle: settings.safeSettings.applicationTitle
      })
    : t('admin.page.dashboard.page_title');

  const versionData: ISoftwareVersionProperties = {
    frontendCommitLink: frontendCommitLink,
    frontendCurrentVersion: frontendCurrentVersion,
    backendCommitLink: backendCommitLink,
    backendCurrentVersion: backendCurrentVersion,
    awcCommitLink: awcCommitLink,
    awcCurrentVersion: awcCurrentVersion
  }; /**< Software version data. */

  return (
    <Dashboard
      dashboardTitle={welcomeMessage}
      systemWarnings={systemWarnings}
      softwareVersion={versionData}
      feedsData={feedsData}
      isLoading={isLoading}
      themeMode={themeMode}
      systemData={basicData}
      systemDataNotes={t('I18N.admin.dashboard.note_text')}
    />
  );
};

export default ClientPage;
