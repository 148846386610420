/*
 * SublicenseForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2024
 *
 * @file SublicenseForm.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import {
  ILicense,
  ISublicenseCreateDTO
} from '@abstract/abstractwebcommon-shared/interfaces/license/license';
import { Panel } from 'primereact/panel';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import './SublicenseForm.css';
import {
  IAuthStateSelector,
  IClientStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import { useDispatch, useSelector } from 'react-redux';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { AutoComplete } from 'primereact/autocomplete';
import { Dispatch } from 'redux';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { getAllClientsWithoutPagination } from '../../../Store/Clients';
import { isStringEmptyOrNullOrUndefined } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';
import { UserDetails } from '@abstract/abstractwebcommon-client/utils/userDetails';
import { ICachedUser } from '@abstract/abstractwebcommon-shared/interfaces/UserCache';

/**
 * Determines the parameter ENUM for the of the "handleUserChange" method.
 */
enum HandleUserChangeMode {
  Admin = 'admin',
  Client = 'client'
}

/**
 * @interface ISublicenseForm
 */
interface ISublicenseForm {
  values: ILicense[] /**< Sub licenses */;
  touched: any /** Map of field names to whether the field has been touched */;
  errors: any /** Map of field names to specific error for that field */;
  isLoading: boolean /**< Loading state */;
  handleChange: (event: any) => void /**< Handle change event */;
  handleBlur: (event: any) => void /**< Handle blur event */;
  licenseUserUUID: string /**< License user UUID */;
  users: IUser[] /**< User data */;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => void /**< To set formik field value */;
  setFieldError: (field: string, message: string | undefined) => void /**< To set formik error */;
  selectedUserUUID: string /**< Selected user UUID when creating a new license */;
  parentLicenseOwner: ICachedUser /**< Parent license owner */;
}

const SublicenseForm = (properties: ISublicenseForm): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const translation: TFunction = useTranslation().t;
  const authState: IAuthStateSelector = useSelector(
    (state: IStateSelectors) => state.auth
  ); /**< Authstate */
  const isLicenseOwner: boolean = !isStringEmptyOrNullOrUndefined(properties.licenseUserUUID)
    ? properties.licenseUserUUID === authState.userUUID
    : true; /**< Logged-in user is license owner or not */
  const clientState: IClientStateSelector = useSelector(
    (state: IStateSelectors) => state.clients
  ); /**< Client state */
  const parentLicenseUserEmail: string = properties?.parentLicenseOwner?.email;
  const isAdmin: boolean = authState.isAdmin; /**< Logged in user is admin or not */
  const isEditingLicense: boolean = !isStringEmptyOrNullOrUndefined(properties.licenseUserUUID);
  /**
   * Handler for user search
   */
  const handleUserSearch = async (event: any): Promise<void> => {
    if (event?.query.length <= 2) return;

    setTimeout(async () => {
      // If user is not an admin, fetch only that user details. (i.e) Don't allow to fetch all users.
      await asyncErrorHandler(
        dispatch(
          getAllClientsWithoutPagination(
            authState.isAdmin
              ? { filter: event?.query.trim() || '' }
              : { filter: '', clientId: authState.userUUID }
          )
        )
      );
    }, 1500);
  };

  /**
   * Method to validate the following rules:
   * 1. Prevent duplicate license owner email.
   * 2. Prevent duplicate sublicenses owner emails.
   * We use different input types for the admin and client side.
   * For admins, we're displaying the autocomplete component. For clients, we're displaying a regular string field.
   * While on the client side, we only have a regular string field, therefore we should use the variable 'event.target.value'.
   * While on the admin side, we should only use 'event.value' when getting a user from the auto complete component, and we should use 'event.target.value' when no user is found in the auto complete component.
   */
  const handleUserChange = async (
    event: any,
    index: number,
    mode: HandleUserChangeMode
  ): Promise<void> => {
    // We should trigger the following conditions while executing the code for client users.
    if (mode === HandleUserChangeMode.Client) {
      if (event.target && event.target.value) {
        if (parentLicenseUserEmail === event.target?.value) {
          properties.setFieldError(
            `sublicenses.${index}.userEmail`,
            translation('admin.page.licenses.sublicenses.validation.userEmail')
          );
        } else if (parentLicenseUserEmail !== event.target?.value) {
          // We should prevent the same sublicense owner email from being added twice.
          // TODO: We need to add this validation in the server side as well.
          if (
            properties?.values?.some((values: ILicense) => values.userEmail === event.target?.value)
          ) {
            properties.setFieldError(
              `sublicenses.${index}.userEmail`,
              translation('admin.page.licenses.sublicenses.validation.duplicateSublicenseUserEmail')
            );
            return;
          }

          if (typeof event.target.value === 'string') {
            properties.setFieldValue(`sublicenses.${index}.userEmail`, event.target.value);
          }
        }
      } else if (event.target && event.target.value === '') {
        properties.setFieldValue(`sublicenses.${index}.userEmail`, '');
      }
    }

    // We should trigger the following conditions while executing the code for admin users.
    if (mode === HandleUserChangeMode.Admin) {
      if (typeof event.value === 'string') {
        if (parentLicenseUserEmail !== event.value) {
          // TODO: We need to add this validation in the server side as well.
          if (properties?.values?.some((value: ILicense) => value.userEmail === event.value)) {
            properties.setFieldError(
              `sublicenses.${index}.userEmail`,
              translation('admin.page.licenses.sublicenses.validation.duplicateSublicenseUserEmail')
            );
            return;
          }

          properties.setFieldValue(`sublicenses.${index}.userEmail`, event.value);
          properties.setFieldValue(`sublicenses.${index}.userUUID`, null);
          properties.setFieldValue(`sublicenses.${index}.userID`, null);
        } else {
          properties.setFieldError(
            `sublicenses.${index}.userEmail`,
            translation('admin.page.licenses.sublicenses.validation.userEmail')
          );
        }
      } else if (typeof event.value === 'object') {
        const user: IUser = event.value;
        if (user) {
          // TODO: We need to add this validation in the server side as well.
          if (properties?.values?.some((values: ILicense) => values.userEmail === user.email)) {
            properties.setFieldError(
              `sublicenses.${index}.userEmail`,
              translation('admin.page.licenses.sublicenses.validation.duplicateSublicenseUserEmail')
            );
            return;
          }

          if (parentLicenseUserEmail !== user.email) {
            properties.setFieldValue(`sublicenses.${index}.userUUID`, user.userUUID);
            properties.setFieldValue(`sublicenses.${index}.userID`, user.id);
            properties.setFieldValue(`sublicenses.${index}.userEmail`, user.email);
          } else {
            properties.setFieldError(
              `sublicenses.${index}.userEmail`,
              translation('admin.page.licenses.sublicenses.validation.userEmail')
            );
          }
        }
      }
    }
  };

  /**
   * Item template for Autocomplete
   */
  const itemTemplate = (user: IUser): JSX.Element => {
    return <div>{`${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`}</div>;
  };

  // Method to build the sublicense owner details to display in the Sublicensee field for the admin side.
  const getSublicenseOwnerDetails = (sublicense: ILicense): string => {
    const foundUser: ICachedUser = properties.values?.find(
      (user: IUser) => user.userUUID === sublicense.userUUID
    );

    if (foundUser != null) return sublicense.userEmail;

    return UserDetails.getUserDetails(foundUser as IUser);
  };

  const RenderSublicenseList = (formikArray: FieldArrayRenderProps): JSX.Element[] =>
    properties?.values?.map((sublicense: ILicense, index: number) => (
      <Row key={index}>
        <div className="w-100 d-flex mt-2">
          <Col>
            <Button
              className={`p-button-raised p-button-primary float-right sublicense-form-remove-button ${
                authState.isAdmin || isLicenseOwner ? '' : 'custom-disabled-button'
              }`}
              onClick={() => {
                // Admin or license owner only deletes the sublicense.
                if (authState.isAdmin || isLicenseOwner) {
                  formikArray.remove(index);
                }
              }}
              disabled={!authState.isAdmin && !isLicenseOwner}>
              <i className="bi bi-dash btn-icon mr-0"></i>
            </Button>
          </Col>
        </div>
        <Col>
          <Row>
            {isAdmin ? (
              <Form.Group as={Col}>
                <Form.Label htmlFor={`sublicenses.${index}.userEmail`} className="required">
                  {translation('admin.page.licenses.sublicenses.form.userEmail')}
                </Form.Label>
                <PopupInformation
                  id={`sublicenses.${index}.userEmail`}
                  popupText={translation(
                    'admin.page.licenses.sublicenses.form.userEmail_popupText'
                  )}
                />
                <AutoComplete
                  name={`sublicenses.${index}.userEmail`}
                  id={`sublicenses.${index}.userEmail`}
                  value={getSublicenseOwnerDetails(sublicense)}
                  suggestions={clientState.list && clientState.list?.records}
                  completeMethod={handleUserSearch}
                  field="email"
                  placeholder={translation('admin.page.licenses.placeholders.client')}
                  onChange={(event: any) => {
                    handleUserChange(event, index, HandleUserChangeMode.Admin);
                  }}
                  onBlur={properties.handleBlur}
                  size={120}
                  dropdown
                  appendTo="self"
                  itemTemplate={itemTemplate}
                  disabled={properties.isLoading || (!authState.isAdmin && !isLicenseOwner)}
                  className={
                    properties?.touched?.[index]?.userEmail &&
                    properties?.errors?.[index]?.userEmail
                      ? 'p-invalid custom-autocomplete-padding'
                      : 'custom-autocomplete-padding'
                  }
                />
                {properties?.touched?.[index]?.userEmail &&
                properties?.errors?.[index]?.userEmail ? (
                  <small id="email-invalid" className="p-invalid error-text">
                    {properties?.errors?.[index]?.userEmail}
                  </small>
                ) : null}
              </Form.Group>
            ) : (
              <Form.Group as={Col}>
                <InstaInputText
                  label={translation('admin.page.licenses.sublicenses.form.userEmail')}
                  placeholder={translation(
                    'admin.page.licenses.sublicenses.form.userEmailClientViewPlaceholder'
                  )}
                  labelClassName="required"
                  name={`sublicenses.${index}.userEmail`}
                  id={`sublicenses.${index}.userEmail`}
                  isLoading={properties.isLoading}
                  onChange={(event: any) => {
                    handleUserChange(event, index, HandleUserChangeMode.Client);
                  }}
                  onBlur={properties.handleBlur}
                  touched={properties?.touched?.[index]?.userEmail}
                  errors={properties?.errors?.[index]?.userEmail}
                  value={properties?.values?.[index]?.userEmail ?? ''}
                  isShowInformationPopup={true}
                  popupText={translation(
                    'admin.page.licenses.sublicenses.form.userEmail_popupText'
                  )}
                />
              </Form.Group>
            )}
            <Form.Group as={Col} sm="5" md="5" lg="5">
              <InstaInputText
                type="number"
                label={translation('admin.page.licenses.form.licenseMaxActivationCount')}
                name={`sublicenses.${index}.licenseMaxCount`}
                id={`sublicenses.${index}.licenseMaxCount`}
                isLoading={properties.isLoading}
                onChange={properties.handleChange}
                onBlur={properties.handleBlur}
                value={properties?.values?.[index]?.licenseMaxCount}
                touched={properties?.touched?.[index]?.licenseMaxCount}
                errors={properties?.errors?.[index]?.licenseMaxCount}
                labelClassName="required"
                isShowInformationPopup={true}
                popupText={translation(
                  'admin.page.licenses.sublicenses.form.licenseMaxActivationCount_popupText'
                )}
                isReadOnly={!authState.isAdmin && !isLicenseOwner}
              />
            </Form.Group>
          </Row>
        </Col>
      </Row>
    ));

  return (
    <Panel
      header={translation(
        `admin.page.licenses.sublicenses.${isEditingLicense ? 'edit_header' : 'add_header'}`
      )}
      className="p-0 sublicense-panel">
      <FieldArray
        name="sublicenses"
        render={(formikArray) => (
          <>
            {RenderSublicenseList(formikArray)}
            <Row className="justify-content-center mt-2">
              <ActionButton
                className={`d-flex align-items-center ${
                  authState.isAdmin || isLicenseOwner ? '' : 'custom-disabled-button'
                }`}
                onClick={() => {
                  // Admin or license owner only adds the sublicense.
                  if (authState.isAdmin || isLicenseOwner) {
                    const newSublicense: Partial<ISublicenseCreateDTO> = {
                      userEmail: '',
                      licenseMaxCount: 0
                    };
                    formikArray.push(newSublicense);
                  }
                }}
                disabled={!authState.isAdmin && !isLicenseOwner}
              />
            </Row>
          </>
        )}
      />
    </Panel>
  );
};

export default SublicenseForm;
