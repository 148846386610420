/*
 * ClientForm.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file ClientForm.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import InstaInputText from '@abstract/abstractwebcommon-client/FormControl/InstaInputText';
import i18n from '../../../Services/I18n';
import { generatePassword } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { InputText } from 'primereact/inputtext';
import { validators } from '@abstract/abstractwebcommon-shared/validators';
import { IClientFormValues, getAllNonLicenseClients } from '../../../Store/Clients';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import { useSelector, useDispatch } from 'react-redux';
import { AutoComplete } from 'primereact/autocomplete';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { IClientStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import {
  maximumCharactersAllowedInPassword,
  minimumCharactersAllowedInPassword
} from '@abstract/abstractwebcommon-shared/constants';

interface IClientOtherProperties {
  handleSubmit?: any;
  isLoading?: boolean;
}

interface IClientMyFormProperties {
  initialUsername?: string;
  initialFirstname?: string;
  initialLastName?: string;
  initialEmail?: string;
  initialPassword?: string;
  handleSubmit?: any;
  isLoading?: boolean;
}

/**
 * Interface for event from handlers.
 */
interface IEventValue<Entity> {
  value: Entity /**< Event value */;
}

const ClientForm = (properties: IClientOtherProperties & FormikProps<IClientFormValues>) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    isLoading,
    setFieldValue
  } = properties;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isInputTypePassword, setIsInputTypePassword] = useState<boolean>(true);
  const [searchedClient, setSearchedClient] = useState<string>(''); /**< Searched client */
  const clientState: IClientStateSelector = useSelector(
    (state: IStateSelectors) => state.clients
  ); /**< Client state */

  // toggles password visibility
  const toggleViewPassword = () => {
    setIsInputTypePassword(!isInputTypePassword);
  };

  /**
   * Handler for client search
   */
  const handleClientSearch = (event: any): void => {
    if (event?.query.length <= 2) return;

    setTimeout(async () => {
      dispatch(getAllNonLicenseClients(event?.query.trim() || ''));
    }, 3000);
  };

  /**
   * Handler foor selected users
   */
  const handleSelectedUser = (event: IEventValue<IUser>): void => {
    /// Selected user details
    const userUUID: string = event.value.userUUID;
    const username: string = event.value.username;
    const email: string = event.value.email;
    const firstName: string = event.value.firstName;
    const lastName: string = event.value.lastName;

    setFieldValue('firstName', firstName);
    setFieldValue('lastName', lastName);
    setFieldValue('username', username);
    setFieldValue('email', email);
    setFieldValue('userUUID', userUUID);
  };

  /**
   * Item template for Autocomplete
   */
  const itemTemplate = (user: IUser): JSX.Element => {
    return <div>{`${user.firstName} ${user.lastName} (${user.username}) - ${user.email}`}</div>;
  };

  /**
   * Handler for client change
   */
  const handleClientChange = (event: IEventValue<string>): void => {
    setSearchedClient(event.value);
  };

  return (
    <>
      <FormWrapper
        controlButtonLabel={false}
        isLoading={isLoading}
        handleSubmitButton={handleSubmit}>
        <Row>
          <Form.Group as={Col} sm="6" md="6">
            <Form.Label htmlFor="userUUID">{t('admin.page.clients.form.user')}</Form.Label>
            <AutoComplete
              id="userUUID"
              value={searchedClient}
              suggestions={
                clientState.nonLicenseClientList && clientState.nonLicenseClientList?.records
              }
              completeMethod={handleClientSearch}
              field="username"
              placeholder={t('admin.page.clients.form.searchPlaceholder')}
              onChange={handleClientChange}
              size={60}
              dropdown
              onSelect={handleSelectedUser}
              itemTemplate={itemTemplate}
              appendTo="self"
              onClear={() => {
                setFieldValue('userUUID', undefined);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6" md="6">
            <InstaInputText
              label={t('admin.page.clients.form.firstname')}
              labelClassName="required"
              name="firstName"
              id={'firstName'}
              isLoading={isLoading || values.userUUID ? true : false}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.firstName}
              errors={errors.firstName}
              value={values.firstName}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6" md="6">
            <InstaInputText
              label={t('admin.page.clients.form.lastname')}
              labelClassName="required"
              name="lastName"
              id={'lastName'}
              isLoading={isLoading || values.userUUID ? true : false}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.lastName}
              errors={errors.lastName}
              value={values.lastName}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6" md="6">
            <InstaInputText
              label={t('admin.page.clients.form.username')}
              labelClassName="required"
              name="username"
              id={'username'}
              isLoading={isLoading || values.userUUID ? true : false}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.username}
              errors={errors.username}
              value={values.username}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
          <Form.Group as={Col} sm="6" md="6">
            <Form.Label className="required">{t('admin.page.clients.form.password')}</Form.Label>
            <span className="p-input-icon-right w-100">
              <InputText
                type={isInputTypePassword ? 'password' : 'text'}
                autoComplete="new-password"
                name="password"
                disabled={isLoading || values.userUUID ? true : false}
                value={values.userUUID ? '' : values.password}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter') {
                    handleSubmit(values);
                  }
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                className={touched.password && errors.password ? 'p-invalid' : ''}
                {...properties}
              />
              <i
                className={isInputTypePassword ? 'pi pi-eye' : 'pi pi-eye-slash'}
                style={{ cursor: 'pointer' }}
                onClick={toggleViewPassword}></i>
            </span>
            {touched.password && errors.password ? (
              <small id="email-invalid" className="p-invalid error-text">
                {t(errors.password)}
              </small>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} sm="6" md="6">
            <InstaInputText
              label={t('admin.page.clients.form.email')}
              labelClassName="required"
              type="email"
              name="email"
              id={'email'}
              isLoading={isLoading || values.userUUID ? true : false}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={touched.email}
              errors={errors.email}
              value={values.email}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  handleSubmit(values);
                }
              }}
            />
          </Form.Group>
        </Row>
      </FormWrapper>
    </>
  );
};

const Client = withFormik<IClientMyFormProperties, IClientFormValues>({
  mapPropsToValues: (properties) => {
    return {
      username: properties.initialUsername || '',
      firstName: properties.initialFirstname || '',
      lastName: properties.initialLastName || '',
      email: properties.initialEmail || '',
      password: properties.initialPassword || generatePassword()
    };
  },
  validationSchema: () => {
    // checks if string contains only numbers
    const checkForJustNumber = (value: any) => /(?!^\d+$)^.+$/.test(value);
    // username check
    const checkForAlphaNumericCharacters = /^(?![0-9]*$)[a-zA-Z0-9]+$/;
    // validation schema
    const validationSpec = {
      username: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(50, i18n.t('validation.max', { field: '50' }))
        .required(
          i18n.t('validation.required', { field: i18n.t('admin.page.clients.form.username') })
        )
        .test('is-only-number', i18n.t('validation.cant_number'), checkForJustNumber)
        .matches(checkForAlphaNumericCharacters, i18n.t('validation.alpha_numeric')),
      firstName: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(50, i18n.t('validation.max', { field: '50' }))
        .required(
          i18n.t('validation.required', { field: i18n.t('admin.page.clients.form.firstname') })
        )
        .test('is-only-number', i18n.t('validation.cant_number'), checkForJustNumber),
      lastName: Yup.string()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(50, i18n.t('validation.max', { field: '50' }))
        .required(
          i18n.t('validation.required', { field: i18n.t('admin.page.clients.form.lastname') })
        )
        .test('is-only-number', i18n.t('validation.cant_number'), checkForJustNumber),
      password: Yup.string()
        .min(
          minimumCharactersAllowedInPassword,
          i18n.t('validation.min', { field: minimumCharactersAllowedInPassword })
        )
        .max(
          maximumCharactersAllowedInPassword,
          i18n.t('validation.max', { field: maximumCharactersAllowedInPassword })
        )
        .matches(validators.PASSWORD, i18n.t('validation.password_regex'))
        .required(
          i18n.t('validation.required', { field: i18n.t('admin.page.clients.form.password') })
        ),
      email: Yup.string()
        .email()
        .min(2, i18n.t('validation.min', { field: '2' }))
        .max(200, i18n.t('validation.max', { field: '200' }))
        .required(i18n.t('validation.required', { field: i18n.t('admin.page.clients.form.email') }))
    };

    return Yup.object(validationSpec);
  },
  handleSubmit: (values, bags) => {
    return bags.props.handleSubmit({
      ...values
    });
  }
})(ClientForm);

export default Client;
