/*
 * ReportsTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file ReportsTable.tsx
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { formatDate, timeSince } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { translate } from '../../../Utils/Translate';
import { IReport } from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import ReportRowExpansionTemplate from './ReportRowExpansionTemplate';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { Link } from 'react-router-dom';
import { RouteName } from '../../../Utils/routesNames';

import './ReportsTable.css';

/**
 * @interface IReportsTableProperties
 */
interface IReportsTableProperties {
  reports: IReport[] | null /**< reports list. */;
  handleAdd: () => void /**< Open dialog record form. */;
  isLoading: boolean /**< True if loading and false otherwise.*/;
  onSort: (payload: ITablePayload) => void /**< Sort reports table. */;
  onFilter: (searchTerm: string) => void /**< Filter reports table. */;
  multiSortMeta: { [key: string]: any }[] /** sort meta. */;
  handlePageUpdate: (event: any) => void /**< Handle page update. */;
  tablePayload: ITablePayload /**  Table payload. */;
  totalRecords: number /** Total records */;
  handleDeleteReport: (reportUUIDs: string[]) => void /**< Delete Report(s). */;
}

/**
 * File records Table component.
 */
const ReportsTable = (properties: IReportsTableProperties): ReactElement => {
  const reports: IReport[] = properties.reports;
  const tablePayload: ITablePayload = properties.tablePayload;
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>[]>([]);
  const [selectedReports, setSelectedReports] = useState<IReport[]>(null); /**< Selected Reports. */
  const deleteButtonReference: any = useRef(null); /**< Delete Button Reference. */
  const [showConfirmation, setShowConfirmation] =
    useState<boolean>(false); /**< Show Confirmation Popup. */
  const [confirmPopupTarget, setConfirmPopupTarget] =
    useState<any>(null); /**< ConfirmationPopup Target. */

  /// Delete Report(s) on Accept
  const onAccept = async () => {
    const reportUUIDs: string[] = selectedReports?.map(
      (eachReport: IReport) => eachReport.reportUUID
    ); /**< reportUUIDs */
    properties.handleDeleteReport(reportUUIDs);
    setShowConfirmation(false);
    setSelectedReports(null);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setShowConfirmation(false);
  };

  /// Confirmation Popup
  const getConfirmationPopup = () => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={showConfirmation}
        title={translate('/confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={translate('/confirm_messages.no')}
        acceptLabel={translate('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };

  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={
            ((selectedReports && Object.keys(selectedReports).length === 0) || !selectedReports) ??
            false
          }
          buttonReference={deleteButtonReference}
        />{' '}
        {/* To delete Reports. */}
        <ActionButton onClick={properties.handleAdd} />
      </div>
      <SearchBar onSearchTermChanged={(data: string) => properties.onFilter(data)} />
    </div>
  );

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  /// Handles selection change event
  const onSelectionChange = (event: any) => {
    const rows: IReport[] = event.value;
    if (Array.isArray(rows)) {
      const selectedRows: IReport[] = rows.map((row: IReport) => {
        return row;
      });
      setSelectedReports(selectedRows);
    }
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows([event.data]);
    }
  };

  /// Initialize Datatable
  const getDataTable = () => {
    return (
      <BaseDatatable
        value={reports || []}
        totalRecords={properties.totalRecords}
        isLoading={!reports}
        header={header}
        parentClass="reportsDataTable" /**< ClassName for div Component.*/
        first={tablePayload.skip}
        emptyMessage={translate('I18N.admin.page.reports.table.empty_msg')}
        rows={tablePayload.limit}
        bodyStyle={'text-center'}
        onPage={properties.handlePageUpdate}
        onSort={(event: any) => properties.onSort(event)}
        onFilter={(event: any) => properties.onFilter(event)}
        multiSortMeta={properties.multiSortMeta}
        sortMode="multiple"
        rowExpansionTemplate={(rowData: IReport) => (
          <ReportRowExpansionTemplate rowData={rowData} />
        )}
        onRowExpand={expandRow}
        onRowCollapse={() => setExpandedRows([])}
        expandedRows={expandedRows}
        selection={selectedReports}
        onSelectionChange={(event: any) => onSelectionChange(event)}>
        <Column expander className="p-0 col-width-3" headerClassName="p-0 col-width-3" />
        <Column selectionMode="multiple" className="col-width-3" />
        <Column
          sortable
          field="title"
          body={(rowData: IReport) => (
            <DatatableColumn
              title={translate('I18N.admin.page.reports.table.title')}
              data={rowData.title}
            />
          )}
          header={translate('I18N.admin.page.reports.table.title')}
          className="col-width-20"
        />
        <Column
          sortable
          field="status"
          header={translate('I18N.admin.page.reports.table.status')}
          body={(rowData: IReport) => (
            <DatatableColumn
              title={translate('client.page.reports.table.status')}
              data={rowData.status}
            />
          )}
          className="col-width-12 text-nowrap"
        />
        <Column
          sortable
          field="reportedBy"
          body={(rowData: IReport) => (
            <DatatableColumn
              title={translate('I18N.admin.page.reports.table.reportedBy')}
              data={rowData.reportedBy}
            />
          )}
          header={translate('I18N.admin.page.reports.table.reportedBy')}
          className="text-nowrap col-width-15"
        />
        <Column
          sortable
          field="lastReply"
          body={(rowData: IReport) => (
            <DatatableColumn
              title={translate('I18N.admin.page.reports.table.lastReply')}
              data={rowData.lastReply && timeSince(new Date(rowData.lastReply))}
            />
          )}
          header={translate('I18N.admin.page.reports.table.lastReply')}
          className="col-width-10 text-nowrap d-table-cell d-sm-none d-xl-table-cell"
          headerClassName="col-width-10 d-table-cell d-sm-none d-xl-table-cell"
        />
        <Column
          sortable
          field="created"
          header={translate('I18N.admin.page.reports.table.created')}
          body={(rowData: IReport) => (
            <DatatableColumn
              title={translate('I18N.admin.page.reports.table.created')}
              data={formatDate(rowData.created)}
            />
          )}
          className="col-width-7 d-sm-none d-md-table-cell"
          headerClassName="col-width-7 d-sm-none d-md-table-cell"
        />
        <Column
          field="open"
          className="col-width-3 absolute-position-responsive-screen test"
          body={(rowData: IReport) => {
            return (
              <>
                <Link
                  to={`${RouteName.technicalSupportRoute}/${rowData.reportUUID}`}
                  className="custom-action-column-action-position m-md-0 mt-1 mr-2">
                  <i className="bi bi-pencil-square editIcon fa-lg"></i>
                </Link>
              </>
            );
          }}
        />
      </BaseDatatable>
    );
  };

  return (
    <>
      {getDataTable()}
      {getConfirmationPopup()}
    </>
  );
};

export default ReportsTable;
