/*
 * Button.js (AbstractECommerce)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Martin Witczak, 2020
 *
 * @file Button.js
 * @author Martin Witczak
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React from 'react';
import classnames from 'classnames';
import { Button } from 'primereact/button';

export const InstaButton = ({
  fullWidth = true,
  className,
  variant = 'secondary',
  disabled  = false,
  ...otherProps
}) => {
  const classes = classnames(
    { 'btn-block': fullWidth },
    { 'btn-destroy btn-outline-secondary': variant === 'destroy' },
    { 'btn-primary btn-outline-primary': variant === 'primary' },
    { 'btn-secondary btn-outline-secondary': variant === 'secondary' },
    { 'pr-3 pl-3': !fullWidth },
    { 'p-disabled': disabled},
    className || null
  );
  return (
    <Button
      className={classes}
      style={{
        pointerEvents: otherProps.disabled && 'none',
      }} /**< The disabled button should not respond to hover. */
      {...otherProps}
    />
  );
};