/**
* NotFound.tsx (abstractlicense) *

* Copyright © 2022 InstaMaterial GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by Rafael Rodrigues, 2022 
* @file NotFound.tsx
* @author Rafael Rodrigues
* @copyright 2022 InstaMaterial GmbH. All rights reserved.
* @section License
*/

import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IPlaceholder } from '@abstract/abstractwebcommon-shared/interfaces/license/placeholder';
import { ITemplate } from '@abstract/abstractwebcommon-shared/interfaces/license/template';
import { ISettingsStateSelector, IStateSelectors } from '../../Interfaces/Selectors';
import NotFoundBase from '@abstract/abstractwebcommon-client/NotFound/NotFoundBase';
import withErrorBoundary from '@abstract/abstractwebcommon-client/HOC/withErrorBoundary';
import { createLogApi } from '../../Services/Log';

const NotFound = (): JSX.Element => {
  const settingsState: ISettingsStateSelector = useSelector(
    (state: IStateSelectors) => state.settings
  );
  const dispatch: Dispatch<any> = useDispatch();

  const [settingLoaded, setSettingLoaded] = useState<boolean>(false);
  const [templateToShow, setTemplateToShow] = useState<string>(null);

  const setPlaceholdersToTheTemplate = (): void => {
    const placeholdersErrorHandler: IPlaceholder[] =
      settingsState.safeSettings.placeholdersErrorHandler;
    const errorHandlerTemplate: ITemplate = settingsState.safeSettings.errorHandlerTemplate;

    const transformedTemplate = errorHandlerTemplate.html.replace(
      /{{([a-zA-Z]+)}}/g,
      (_, i: string) => {
        const findPlaceholderIndex = placeholdersErrorHandler.findIndex(
          (placeholder) => placeholder.placeholderKey === i
        );
        return placeholdersErrorHandler[findPlaceholderIndex]?.placeholderValue;
      }
    );

    setTemplateToShow(transformedTemplate);
  };

  useEffect(() => {
    if (settingsState.safeSettings) {
      setPlaceholdersToTheTemplate();
      setSettingLoaded(true);
    }
  }, [settingsState, settingLoaded, dispatch]);

  return <NotFoundBase isLoading={settingLoaded} templateToShow={templateToShow} />;
};

export default withErrorBoundary(NotFound, createLogApi);
