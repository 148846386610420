/*
 * NewsletterAnalyticsPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file NewsletterAnalyticsPage.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import {
  IPageEvent,
  ISortEvent,
  ITablePayload
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { INewsletterStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { useHistory } from 'react-router-dom';
import { fetchAllNewsletters } from '../../../Store/Newsletter';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { Column } from 'primereact/column';
import { translate } from '../../../Utils/Translate';
import { INewsletter } from '@abstract/abstractwebcommon-shared/interfaces/license/Newsletter';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { formatDate } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';

/**
 * Newsletter Analytics page component.
 */
const NewsletterAnalyticsPage: React.FC<any> = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const newsletterState: INewsletterStateSelector = useSelector(
    (state: IStateSelectors) => state.newsletter
  ); /**< Newsletter state */
  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    sort: {
      sortField: 'created',
      sortOrder: -1
    },
    searchTerm: ''
  }); /**< Default Payload */
  const history: any = useHistory();

  /// Handle sort update event
  const handleSortUpdate = (event: ISortEvent): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      sort: event
    });
    setPayload(updatedPayload);
    dispatch(fetchAllNewsletters(updatedPayload));
  };

  /// Handle page update event
  const handlePageUpdate = (event: IPageEvent): void => {
    const first: number = event.first;
    const rows: number = event.rows;
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, {
      skip: first,
      limit: rows
    });
    setPayload(updatedPayload);
    dispatch(fetchAllNewsletters(updatedPayload));
  };

  /// Handle filter update event
  const handleFilterUpdate = (event: string): void => {
    const updatedPayload: ITablePayload = payload; /**< Updated Payload. */
    Object.assign(updatedPayload, { searchTerm: event });
    setPayload(updatedPayload);
    dispatch(fetchAllNewsletters(updatedPayload));
  };

  useEffect(() => {
    dispatch(fetchAllNewsletters(payload));
  }, []);

  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer"></div>
      <SearchBar onSearchTermChanged={(data: string) => handleFilterUpdate(data)} />
    </div>
  );

  /// Handle open analytics table
  const handleOpenAnalyticsTable = async (newsletterID: number, title: string): Promise<void> => {
    history.push({
      pathname: `analytics/${newsletterID}`,
      state: title
    });
  };

  return (
    <>
      <Row>
        <Col>
          <BaseDatatable
            value={newsletterState.newsletterList && newsletterState.newsletterList.records}
            totalRecords={
              newsletterState.newsletterList && newsletterState.newsletterList.totalRecords
            }
            isLoading={!newsletterState.newsletterList}
            header={header}
            parentClass="newslettersDataTable" /**< ClassName for div Component.*/
            first={payload.skip}
            emptyMessage={translate('I18N.admin.page.newsletter.table.empty_msg')}
            rows={payload.limit}
            onPage={handlePageUpdate}
            sortField={payload.sort.sortField}
            onSort={handleSortUpdate}
            sortOrder={payload.sort.sortOrder}
            onFilter={handleFilterUpdate}
            bodyStyle={'text-center'}
            responsive
            dataKey="newsletterUUID">
            <Column selectionMode="multiple" className="d-none" />
            <Column
              expander
              className="invisible d-sm-none p-0 col-width-45"
              headerClassName="invisible d-sm-none p-0 col-width-45"
            />
            <Column
              sortable
              field="title"
              body={(rowData: INewsletter) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletter.table.title')}
                  data={rowData.title}
                />
              )}
              header={translate('I18N.admin.page.newsletter.table.title')}
            />
            <Column
              field="totalLicensesCount"
              sortable
              body={(rowData: INewsletter) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletter.table.totalCount')}
                  data={rowData.totalLicensesCount}
                />
              )}
              header={translate('I18N.admin.page.newsletter.table.totalCount')}
              className="d-table-cell d-sm-none d-md-table-cell createdDateCol"
            />
            <Column
              field="sentCount"
              sortable
              body={(rowData: INewsletter) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletterAnalytics.table.sentCount')}
                  data={rowData.sentCount}
                />
              )}
              header={translate('I18N.admin.page.newsletterAnalytics.table.sentCount')}
              className="d-table-cell d-sm-none d-md-table-cell createdDateCol"
            />
            <Column
              field="pendingUsersCount"
              sortable
              body={(rowData: INewsletter) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletter.table.pendingCount')}
                  data={rowData?.pendingUsersCount}
                />
              )}
              header={translate('I18N.admin.page.newsletter.table.pendingCount')}
              className="d-table-cell d-sm-none d-xl-table-cell createdDateCol"
            />
            <Column
              field="openedCount"
              sortable
              body={(rowData: INewsletter) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletter.table.openedCount')}
                  data={rowData.openedCount}
                />
              )}
              header={translate('I18N.admin.page.newsletter.table.openedCount')}
              className="d-table-cell d-sm-none d-xxl-table-cell createdDateCol"
            />
            <Column
              field="sendStatus"
              sortable
              body={(rowData: INewsletter) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletter.table.sendStatus')}
                  data={rowData.sendStatus}
                />
              )}
              header={translate('I18N.admin.page.newsletter.table.sendStatus')}
              className="createdDateCol"
            />
            <Column
              sortable
              field="sentDate"
              body={(rowData: INewsletter) => (
                <DatatableColumn
                  title={translate('I18N.admin.page.newsletter.table.sentDate')}
                  data={formatDate(rowData.sentDate)}
                />
              )}
              header={translate('I18N.admin.page.newsletter.table.sentDate')}
              className="createdDateCol"
              headerClassName="createdDateCol"
            />
            <Column
              field="open"
              body={(rowData: INewsletter) => {
                return (
                  <Button
                    className="custom-action-column-action-position"
                    onClick={() => handleOpenAnalyticsTable(rowData.id, rowData.title)}
                    variant="outline">
                    <i className="bi bi-pencil-square editIcon fa-lg"></i>
                  </Button>
                );
              }}
              className="p-0 col-width-45 absolute-position-responsive-screen"
            />
          </BaseDatatable>
        </Col>
      </Row>
    </>
  );
};

export default NewsletterAnalyticsPage;
