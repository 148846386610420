/*
 * FileRecordTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file FileRecordTable.tsx
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import { Column } from 'primereact/column';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import { formatDate, timeSince } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import FileRecordForm from './FileRecordForm';
import VersionsTable from './VersionsTable';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import './Style.css';
import FileRecordTableExpansion from './FileRecordTableExpansion';
import { IFileRecordStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import ProgressBarDialog from '@abstract/abstractwebcommon-client/ProgressBarDialog';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import ActionButton from '@abstract/abstractwebcommon-client/Buttons/ActionButton';
import { IFileRecord } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecord';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import { IWarningMessage } from '../../../Interfaces/FileRecord';
import ImageRow from '@abstract/abstractwebcommon-client/Table/ImageRow/ImageRow';
import { setAreAllImagesLoaded } from '../../../Store/FileRecords';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';
import { sanitizeHTMLString } from '@abstract/abstractwebcommon-client/utils/dompurifyHelper';

interface IFileRecordTablePropertiesInterface {
  handleDeleteFileRecord: (fileRecordUUIDs: string[]) => void;
  handleSortUpdate: (state: any) => void;
  handleDownload: (data: any) => void;
  handleDeleteVersion: (
    data: any,
    setShowVersionPage: React.Dispatch<
      React.SetStateAction<boolean>
    > /**< To show version table or not */
  ) => void;
  handleUpdateFileRecordVersion: (fileRecordVersion: any) => void;
  handleSubmitFileEntitlement: (values: any) => any;
  handleUpdate: (data: any) => void;
  handleCreateFileRecord: (values: any, resetForm: any) => void;
  handlePageUpdate: (data: any) => void;
  handleSetActive: (data: any) => void;
  handleFetchVersions: (fileRecord: any) => Promise<any>;
  handleUploadFileVersion: (data: any) => void;
  clearPagination: (data: any) => void;
  refresh: () => void;
  handleCancelUpload: () => void;
  payload: ITablePayload;
  handleOnFilter: (searchTerm: string) => void;
}

const FileRecordTable = ({
  handleDeleteFileRecord,
  handleSortUpdate,
  handleDownload,
  handleDeleteVersion,
  handleUpdateFileRecordVersion,
  handleUpdate,
  handleCreateFileRecord,
  handlePageUpdate,
  handleSetActive,
  handleFetchVersions,
  handleUploadFileVersion,
  refresh,
  handleCancelUpload,
  payload,
  handleOnFilter
}: IFileRecordTablePropertiesInterface): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileRecords: IFileRecordStateSelector = useSelector(
    (state: IStateSelectors) => state.fileRecords
  );
  const fileRecordList = fileRecords.list;
  const sort = payload.sort;
  const updateSuccessful = useSelector(
    (state: IStateSelectors) => state.fileRecords.fileRecordUpdateSuccess
  );

  const [fileRecord, setFileRecord] = useState<any>();
  const [isEditPage, toggleIsEditPage] = useState<boolean>(false);
  const [isVersionsPage, toggleIsVersionsPage] = useState<boolean>(false);
  const [isShowConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [isEntitlementDialog, toggleEntitlementDialog] = useState<boolean>(false);
  const [cancelUploadDialog, toggleCancelUploadDialog] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<IFileRecord[]>(null); /**< Selected Files. */
  const deleteButtonReference: any = useRef(null); /**< Delete Button Reference. */
  const [warningMessage, setWarningMessage] = useState<IWarningMessage[]>(); /**< Warning Message */
  const [fileName, setFileName] = useState<string>(); /**< Upload file name. */
  const [isImageLoading, setImageLoading] = useState<boolean>(true);
  const [imageCount, setImageCount] = useState<number>(1);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (fileRecords.fileRecordIsUploading) {
        // won't work on chrome, for security reasons they keep their own message
        return t('admin.page.files.form.navigationWarning');
      }
    };
    window.onunload = () => {
      if (fileRecords.fileRecordIsUploading) {
        handleCancelUpload();
      }
    };
  }, [fileRecords.fileRecordIsUploading]);

  /**
   * handleVersionsPage
   * @param data
   */
  const handleVersionsPage = async (data: any): Promise<void> => {
    setFileRecord(data);
    const fetchVersionsResponse = await asyncErrorHandler(handleFetchVersions(data));
    if (fetchVersionsResponse?.payload) {
      toggleIsVersionsPage(true);
    }
  };

  /**
   * handleEditDialog
   * @param data
   */
  const handleEditDialog = (data: any): void => {
    setFileRecord(data);
    toggleIsEditPage(true);
  };

  useEffect(() => {
    if (updateSuccessful) {
      toggleIsEditPage(false);
    }
  }, [updateSuccessful]);

  const onAccept = async (): Promise<void> => {
    const message: IWarningMessage[] = [];
    selectedFiles?.forEach((eachFile: IFileRecord) => {
      if (eachFile.linkedEntitlements && eachFile.linkedEntitlements.length) {
        const entitlements: string[] = []; /**< Linked Entitlements */
        eachFile.linkedEntitlements.forEach((eachEntitlement: IEntitlement) => {
          entitlements.push(eachEntitlement.name);
        });
        message.push({
          id: eachFile.id,
          fileName: eachFile.name,
          linkedEntitlements: entitlements
        });
      }
    }); /**< Warning Message. */
    setWarningMessage(message);
    if (message && message.length > 0) {
      toggleEntitlementDialog(true);
    } else {
      onWarningAccepted();
    }
    setShowConfirmation(false);
  };

  const hideDialog = () => toggleIsEditPage(false);

  // Dispatch delete action on warning accepted
  const onWarningAccepted = async () => {
    const fileRecordUUIDs: string[] = selectedFiles?.map(
      (eachFile: IFileRecord) => eachFile.fileRecordUUID
    );
    /**< fileRecordUUIDs */
    await asyncErrorHandler(handleDeleteFileRecord(fileRecordUUIDs));
    setShowConfirmation(false);
    toggleEntitlementDialog(false);
    setSelectedFiles(null);
    setWarningMessage([]);
  };

  const onReject = (): void => {
    setShowConfirmation(false);
    toggleEntitlementDialog(false);
    setWarningMessage([]);
  };

  const getConfirmPopup = (): JSX.Element => {
    return (
      <ConfirmationPopup
        target={confirmPopupTarget}
        isShow={isShowConfirmation}
        title={t('/confirm_messages.delete_records')}
        onAccept={onAccept}
        onReject={onReject}
        acceptBtnClass="danger"
        rejectBtnClass="secondary"
        rejectLabel={t('/confirm_messages.no')}
        acceptLabel={t('/confirm_messages.yes')}
        acceptBtnIcon="bi bi-check2-circle"
        rejectBtnIcon="bi bi-x-circle"
        popupPosition="bottom"
      />
    );
  };

  //
  const handleCreateFileRecordAndReset = (values: any): void => {
    const file: File = values?.file; /**< File Name. */
    setFileName(file?.name);
    const toggleAndRefresh = () => {
      refresh();
      toggleIsEditPage(false);
    };
    handleCreateFileRecord(values, toggleAndRefresh);
  };

  /// Action to trigger files addition process
  const handleAdd = async (): Promise<void> => {
    setFileRecord(null);
    toggleIsEditPage(true);
  };

  const renderCancelUploadDialogFooter = (): JSX.Element => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => {
            toggleCancelUploadDialog(false);
          }}
          autoFocus
          className="d-flex align-items-center"
          variant="secondary">
          <i className="bi bi-x-circle btn-icon"></i>
          {t('/confirm_messages.no')}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            handleCancelUpload();
            toggleCancelUploadDialog(false);
            toggleIsEditPage(false);
            toggleIsVersionsPage(false);
          }}
          className="d-flex align-items-center m-0"
          autoFocus>
          <i className="bi bi-check2-circle btn-icon"></i>
          {t('/confirm_messages.yes')}
        </Button>
      </div>
    );
  };
  const getCancelUploadPopup = (): JSX.Element => {
    return (
      <DialogWrapper
        className="custom-dialog-container"
        headerTitle={t('admin.page.files.versions.uploadInProgressHeader')}
        isDialogVisible={cancelUploadDialog && fileRecords.fileRecordIsUploading}
        closable={true}
        footer={renderCancelUploadDialogFooter()}
        onHide={() => toggleCancelUploadDialog(false)}>
        <div className="display-linebreak">
          {t('admin.page.files.versions.uploadInProgressWarning')}
        </div>
      </DialogWrapper>
    );
  };

  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <ActionButton
          variant="danger"
          onClick={(event: any) => deleteButtonClicked(event)}
          isDisabled={
            ((selectedFiles && Object.keys(selectedFiles).length === 0) || !selectedFiles) ?? false
          }
          buttonReference={deleteButtonReference}
        />{' '}
        {/* To delete Files. */}
        <ActionButton onClick={handleAdd} />
      </div>
      <SearchBar onSearchTermChanged={(data: string) => handleOnFilter(data)} />
    </div>
  );

  /// show delete popup
  const deleteButtonClicked = (event: any) => {
    setShowConfirmation(true);
    setConfirmPopupTarget(event.target);
  };

  const renderEntitlementWarningDialogFooter = (): JSX.Element => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <Button
          onClick={() => onReject()}
          autoFocus
          variant="secondary"
          className="d-flex align-items-center">
          <i className="bi bi-x-circle btn-icon"></i>
          {t('/confirm_messages.cancel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => onWarningAccepted()}
          autoFocus
          className="d-flex align-items-center m-0">
          <i className="bi bi-check2-circle btn-icon"></i>
          {t('admin.page.files.delete.confirm')}
        </Button>
      </div>
    );
  };

  /// Handles upload file version.
  const onUploadFileVersion = async (values: Record<string, any>): Promise<void> => {
    const file: File = values?.file;
    setFileName(file?.name);
    handleUploadFileVersion(values);
  };

  const renderProgressBarDialog = () => {
    const uploadingText: string = fileName
      ? `${t('admin.page.files.form.uploading_file')} ${fileName}`
      : ''; /**< Uploading text in Progressbar. */
    return (
      <ProgressBarDialog
        isLoading={fileRecords.fileRecordIsUploading}
        value={fileRecords.fileRecordUploadProgress}
        onHide={handleCancelUpload}
        showCancelDialog={() => toggleCancelUploadDialog(true)}
        uploadingLabel={uploadingText}
      />
    );
  };

  /// Handles selection change event
  const onSelectionChange = (event: any) => {
    const rows: IFileRecord[] = event.value;
    if (Array.isArray(rows)) {
      const selectedRows: IFileRecord[] = rows.map((row: IFileRecord) => {
        return row;
      });
      setSelectedFiles(selectedRows);
    }
  };

  /// Handle delete filerecord version
  const handleDeleteFileRecordVersion = (data: Record<string, any>) => {
    handleDeleteVersion(data, toggleIsVersionsPage);
  };

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows([event.data]);
    }
  };

  useEffect(() => {
    if (!isImageLoading || (fileRecordList?.records && fileRecordList?.records.length === 0)) {
      dispatch(setAreAllImagesLoaded());
    }
  }, [isImageLoading, fileRecordList?.records]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <BaseDatatable
            value={fileRecordList?.records}
            totalRecords={fileRecordList?.totalRecords}
            isLoading={!fileRecordList?.records || fileRecords.isLoadingImages}
            header={header}
            parentClass="fileRecordDataTable" /**< ClassName for div Component.*/
            sortField={sort.sortField}
            onSort={handleSortUpdate}
            sortOrder={sort.sortOrder}
            first={payload.skip}
            emptyMessage={t('I18N.admin.fileManagement.empty_msg')}
            rows={payload.limit}
            bodyStyle={'text-center'}
            onPage={handlePageUpdate}
            expandedRows={expandedRows}
            onRowExpand={expandRow}
            onRowCollapse={() => setExpandedRows([])}
            rowExpansionTemplate={(event: any) => (
              <FileRecordTableExpansion data={event.linkedEntitlements} />
            )}
            selection={selectedFiles}
            onSelectionChange={(event: any) => onSelectionChange(event)}>
            <Column expander className="p-0 col-width-45" headerClassName="p-0 col-width-45" />
            <Column selectionMode="multiple" className="col-width-45" />
            <Column
              field="icon"
              body={(rows: any) => (
                <ImageRow
                  setImageLoading={setImageLoading}
                  imageURL={rows.icon}
                  totalImagesCount={
                    fileRecordList &&
                    fileRecordList.records.filter(
                      (fileRecordItem: IFileRecord) => fileRecordItem.icon
                    ).length
                  }
                  setImageCount={setImageCount}
                  imageCount={imageCount}
                />
              )}
              header={t('admin.page.files.form.icon')}
              className="icon-column-style d-none d-sm-table-cell"
              headerClassName="icon-column-style d-none d-sm-table-cell"
            />
            <Column
              sortable
              field="name"
              body={(rows: any) => (
                <DatatableColumn title={t('admin.page.files.form.name')} data={rows.name} />
              )}
              header={t('admin.page.files.form.name')}
            />
            <Column
              sortable
              field="description"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.files.form.description')}
                  data={sanitizeHTMLString(rows.description)}
                />
              )}
              header={t('admin.page.files.form.description')}
              className="d-table-cell d-sm-none d-lg-table-cell"
              headerClassName="d-table-cell d-sm-none d-lg-table-cell"
            />
            <Column
              sortable
              field="activatedAt"
              className="d-table-cell d-sm-none d-xxl-table-cell"
              headerClassName="d-table-cell d-sm-none d-xxl-table-cell"
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.files.form.activatedAt')}
                  data={
                    rows.activeFileRecordVersion.lastActivatedAt &&
                    t('admin.page.files.form.activated_text', {
                      field: timeSince(new Date(rows.activeFileRecordVersion.lastActivatedAt))
                    })
                  }
                />
              )}
              header={t('admin.page.files.form.activatedAt')}
            />
            <Column
              sortable
              field="updated"
              header={t('admin.page.files.form.updated')}
              body={(rows: any) => (
                <DatatableColumn
                  title={t('admin.page.files.form.updated')}
                  data={formatDate(rows.updated)}
                />
              )}
              className="d-table-cell d-sm-none d-xl-table-cell updatedDateCol"
              headerClassName="d-table-cell d-sm-none d-xl-table-cell updatedDateCol"
            />
            <Column
              field="versions"
              className="version-button-header-container"
              headerClassName="version-button-header-container"
              body={(rowData: any) => {
                return (
                  <div className="d-flex justify-content-center version-button-container">
                    <Button
                      className="d-none d-sm-block"
                      onClick={() => handleVersionsPage(rowData)}>
                      Versions
                    </Button>

                    <Button
                      className="custom-action-column-action-position d-block d-sm-none position-absolute-mr-60"
                      variant="outline"
                      onClick={() => handleVersionsPage(rowData)}>
                      <i className="bi bi-file-diff editIcon fa-lg text-primary"></i>
                    </Button>
                  </div>
                );
              }}
            />
            <Column
              field="download"
              body={(rowData: any) => {
                return (
                  <Button
                    className="custom-action-column-action-position position-absolute-mr-30"
                    variant="outline"
                    disabled={fileRecords.fileRecordIsDownloading}
                    onClick={() => handleDownload(rowData)}>
                    <i className="bi bi-cloud-download editIcon fa-lg"></i>
                  </Button>
                );
              }}
              className="p-0 col-width-45 absolute-position-responsive-screen"
            />
            <Column
              field="edit"
              body={(rowData: any) => {
                return (
                  <Button
                    className="custom-action-column-action-position"
                    variant="outline"
                    onClick={() => handleEditDialog(rowData)}>
                    <i className="bi bi-pencil-square editIcon fa-lg"></i>
                  </Button>
                );
              }}
              className="p-0 col-width-45 absolute-position-responsive-screen"
            />
          </BaseDatatable>
        </Col>
      </Row>

      <DialogWrapper
        isDialogVisible={isEditPage}
        headerTitle={
          fileRecord
            ? t('admin.page.files.form.header.edit_files_dialog')
            : t('admin.page.files.form.header.add_files_dialog')
        }
        onHide={() => {
          if (fileRecords.fileRecordIsUploading) {
            toggleCancelUploadDialog(true);
          } else {
            toggleIsEditPage(false);
          }
        }}>
        <FileRecordForm
          isLoading={fileRecords.fileRecordIsFetching || fileRecords.fileRecordIsChanging}
          handleSubmit={fileRecord ? handleUpdate : handleCreateFileRecordAndReset}
          fileRecord={fileRecord}
          handleDeleteFileRecord={(fileRecordUUIDs: string[]) =>
            handleDeleteFileRecord(fileRecordUUIDs)
          }
          hideDialog={hideDialog}
        />
      </DialogWrapper>

      <DialogWrapper
        isCustomClassName
        headerTitle={t('admin.page.files.delete.header')}
        isDialogVisible={isEntitlementDialog}
        footer={renderEntitlementWarningDialogFooter()}
        onHide={() => toggleEntitlementDialog(false)}>
        <Col className="mb-2 px-0">{t('admin.page.files.delete.delete_header')}</Col>
        <Col className="mb-2 px-0">
          {warningMessage && warningMessage.length > 0 && (
            <Col className="mb-2">
              <table className="w-100">
                <tr>
                  <th>File </th>
                  <th>{t('admin.page.files.delete.linkedEntitlements')}</th>
                </tr>
                {warningMessage.map((message: IWarningMessage) => (
                  <tr key={message.id}>
                    <td>{message.fileName}</td>
                    <td>
                      {message.linkedEntitlements
                        .map((entitlementName: string) => `${entitlementName}`)
                        .join(' ,')}
                    </td>
                  </tr>
                ))}
              </table>
            </Col>
          )}
        </Col>
        <Col className="px-0">{t('admin.page.files.delete.warningMessage')}</Col>
      </DialogWrapper>

      <DialogWrapper
        isDialogVisible={isVersionsPage}
        className="table-width-992"
        headerTitle={t('admin.page.files.versions.header', { field: fileRecord?.name })}
        onHide={() => {
          if (fileRecords.fileRecordIsUploading) {
            toggleCancelUploadDialog(true);
          } else {
            toggleIsVersionsPage(false);
          }
        }}>
        <VersionsTable
          fileRecord={fileRecord}
          handleSetActive={handleSetActive}
          handleUploadFileVersion={onUploadFileVersion}
          fileRecordState={fileRecords}
          handleDownload={handleDownload}
          handleDeleteVersion={handleDeleteFileRecordVersion}
          handleUpdateFileRecordVersion={handleUpdateFileRecordVersion}
          handleActiveItemState={toggleIsVersionsPage}
        />
      </DialogWrapper>
      {getCancelUploadPopup()}
      {getConfirmPopup()}
      {renderProgressBarDialog()}
    </>
  );
};

export default FileRecordTable;
