/*
 * SettingLogo.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file SettingLogo.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISettingsStateSelector } from '../../../Interfaces/Selectors';
import InstaImageUpload, {
  RemoveButtonStyle
} from '@abstract/abstractwebcommon-client/InstaImageUpload/index';
import '@abstract/abstractwebcommon-client/SettingsFormFaviconLogo.css';
import PopupInformation from '@abstract/abstractwebcommon-client/FormControl/PopupInformation';

interface ILogoProperties {
  handleLogoDelete: (event: any) => void;
  handleLogoUpload: (event: any) => void;
  displayCroppedLogo: any;
  settingsState: ISettingsStateSelector;
  children: React.ReactNode /** Children elements */;
  changeInputClassName: () => void /** Change component class name based on settings information */;
}

const SettingLogo = ({
  handleLogoDelete,
  handleLogoUpload,
  displayCroppedLogo,
  settingsState,
  children,
  changeInputClassName
}: ILogoProperties): JSX.Element => {
  const { t } = useTranslation();
  const [toggleFullSizePreview, setToggleFullSizePreview] = useState<boolean>(false);
  return (
    <div id="logo" className="logo-favicon basis-0 flex-grow-1 input-fixed-height">
      <label htmlFor="logo" className="logo-favicon flex-grow-1">
        {t('admin.page.settings.category.logo')}
      </label>
      <PopupInformation id={'logo'} popupText={t('admin.page.settings.category.logo_popupText')} />
      <div className={`p-2 p-inputtext ${changeInputClassName(true)}`}>
        <InstaImageUpload
          showLegend={false}
          imageUrl={settingsState.setting?.logoImageURL}
          removeButtonStyle={RemoveButtonStyle.SEPARATE}
          showDelete={true}
          deleteHandler={handleLogoDelete}
          onChange={handleLogoUpload}
          showUploadBtn={false}
          isHorizontalLayout
          imgClass={'imageUrl rounded'}
          altText={t('admin.page.settings.alt.logo')}
          plainBtnLabel={t('admin.page.settings.button_choose_file')}
          isPlainBtn={true}
          setToggleFullSizePreview={setToggleFullSizePreview}
          toggleFullSizePreview={toggleFullSizePreview}
          croppedImage={displayCroppedLogo}
        />
      </div>
      {children}
    </div>
  );
};

export default SettingLogo;
