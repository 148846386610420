/**
 * report.ts (InstaLOD GmbH)
 *
 * Copyright © 2022 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by James Ugbanu, 2022
 *
 * @file report.ts
 * @author James Ugbanu
 * @copyright 2022 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * Report database status
 */
export enum ReportStatus {
    new = "New",
    waiting_for_support_response = "Waiting for Support Response",
    waiting_for_user_feedback = "Waiting for User Feedback",
    done = "Done"
}