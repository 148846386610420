/**
 * Entitlement.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Entitlement.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { BASE_API_URL } from '../config';
import { mapSortingFields } from '../Utils/Pagination';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IGetClientEntitlementsResponse } from '@abstract/abstractwebcommon-shared/interfaces/license/api';
import { IEntitlement } from '@abstract/abstractwebcommon-shared/interfaces/license/entitlement';
import { IImageUploadResponse } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import { HttpClient } from '@abstract/abstractwebcommon-client/utils/HttpClient';
import { createLogApi } from './Log';

const httpClient = new HttpClient(BASE_API_URL, createLogApi);

/**
 * Service to create or update entitlement
 * @param entitlement Entitlement information
 * @returns Promise
 */
export const createOrUpdate = async (
  entitlement: any
): Promise<IAPIEntityResponse<IEntitlement>> => {
  const url: string = `/entitlement`;

  if (entitlement.entitlementUUID) {
    return httpClient.post<IEntitlement>(
      `${url}/${entitlement.entitlementUUID}/update`,
      entitlement
    );
  }
  return httpClient.post<IEntitlement>(`${url}/create`, entitlement);
};

/**
 * Service to fetch all entitlements
 * @param entitlement Entitlement information
 * @returns Promise
 */
export const getAll = async (): Promise<PaginatedAPIEntityResponse<IEntitlement>> => {
  return httpClient.get<IEntitlement>(`/entitlements/all?skip=0&limit=0`);
};

/**
 * Service to fetch all entitlements for client
 * @param entitlement Entitlement information
 * @returns Promise
 */
export const getClientData = async (
  userUUID: string
): Promise<IAPIEntityResponse<IGetClientEntitlementsResponse>> => {
  return httpClient.get<IGetClientEntitlementsResponse>(`/entitlement/client/${userUUID}`);
};

/**
 * Service to fetch all entitlements with pagination
 * @param entitlement Entitlement information
 * @param searchTerm - search term to filter.
 * @returns Promise
 */
export const read = async (
  searchTerm: string,
  id?: string | null,
  skip = 0,
  limit = 10,
  sort = 'created',
  sortOrder = 'DESC'
): Promise<PaginatedAPIEntityResponse<IEntitlement>> => {
  const url: string = `/entitlements/all`;
  sort = sort || 'created';

  // TODO: better to move get by id to a seperate function
  if (id) {
    return httpClient.get<IEntitlement>(`${url}/${id}`);
  } else {
    const mappedSort: string = mapSortingFields(sort, sortOrder);
    return httpClient.get<IEntitlement>(
      `${url}?skip=${skip}&limit=${limit}&sort=${mappedSort}&searchTerm=${encodeURIComponent(
        searchTerm ? searchTerm : ''
      )}`
    );
  }
};

/**
 * Service to remove a entitlement
 * @param payload EntitlementUUIDs
 * @returns Promise
 */
export const remove = (payload: string[]): Promise<IAPIEntityResponse<void>> => {
  return httpClient.post('/entitlements/delete', { entitlementUUIDs: payload });
};

/**
 * upload entitlement Image
 * @param entitlement Entitlement information
 * @returns Promise
 */
export const uploadImage = (file: any): Promise<IAPIEntityResponse<IImageUploadResponse>> => {
  const formData: FormData = new FormData();
  formData.append('file', file, file.name);
  formData.append('mode', 'entitlements');
  return httpClient.imageUpload<IImageUploadResponse>(`/system/setting/image/upload`, formData);
};
