/*
 * index.ts (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file index.ts
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { authReducer, AUTH_FEATURE_KEY } from './Auth';
import { activationReducer, ACTIVATION_FEATURE_KEY } from './Activations';
import { clientReducer, CLIENTS_FEATURE_KEY } from './Clients';
import { logReducer, LOG_FEATURE_KEY } from './Log';
import { licenseReducer, LICENSE_FEATURE_KEY } from './Licenses';
import { licenseTemplateReducer, LICENSE_TEMPLATE_FEATURE_KEY } from './LicenseTemplates';
import {
  dashboardReducer,
  dashboardFeatureKey
} from '@abstract/abstractwebcommon-client/store/DashboardSlice';
import { fileRecordReducer, FILE_RECORDS_FEATURE_KEY } from './FileRecords';
import { entitlementReducer, ENTITLEMENTS_FEATURE_KEY } from './Entitlements';
import { settingReducer, SETTING_FEATURE_KEY } from './Settings';
import { templateReducer, TEMPLATE_FEATURE_KEY } from './Templates';
import { reportReducer, REPORT_FEATURE_KEY } from './Report';
import {
  staticLinksFeatureKey,
  staticLinksReducer
} from '@abstract/abstractwebcommon-client/store/StaticLinksSlice';
import {
  userAPIFeatureKey,
  userAPIReducer
} from '@abstract/abstractwebcommon-client/store/UserAPISlice';
import { newsletterReducer, NEWSLETTER_FEATURE_KEY } from './Newsletter';

const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authReducer,
  [ACTIVATION_FEATURE_KEY]: activationReducer,
  [CLIENTS_FEATURE_KEY]: clientReducer,
  [LOG_FEATURE_KEY]: logReducer,
  [LICENSE_FEATURE_KEY]: licenseReducer,
  [LICENSE_TEMPLATE_FEATURE_KEY]: licenseTemplateReducer,
  [TEMPLATE_FEATURE_KEY]: templateReducer,
  [FILE_RECORDS_FEATURE_KEY]: fileRecordReducer,
  [ENTITLEMENTS_FEATURE_KEY]: entitlementReducer,
  [SETTING_FEATURE_KEY]: settingReducer,
  [REPORT_FEATURE_KEY]: reportReducer,
  [staticLinksFeatureKey]: staticLinksReducer,
  [userAPIFeatureKey]: userAPIReducer,
  [dashboardFeatureKey]: dashboardReducer,
  [NEWSLETTER_FEATURE_KEY]: newsletterReducer
});

export function initializeStore() {
  const store = configureStore({
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production'
  });
  return store;
}
