/**
 * StaticLinks.ts (abstractuser)
 * Copyright © 2020 InstaMaterial GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all it's contents are proprietary and confidential.
 *
 * Maintained by Polina Ovsiannikova, 2022
 * @file StaticLinks.ts
 * @author Polina Ovsiannikova
 * @copyright 2020 InstaMaterial GmbH. All rights reserved.
 * @section License
 */

import {
  IStaticLinksActionsProperties,
  StaticLinksActions
} from '@abstract/abstractwebcommon-client/store/StaticLinksSlice';
import { createLogApi } from '../Services/Log';
import { BASE_API_URL } from '../config';

const staticLinksActionsProperties: IStaticLinksActionsProperties = {
  baseApiUrl: BASE_API_URL,
  onCreateErrorLog: createLogApi,
  getStaticLinksUrl: '/system/staticLinks/all/paginated',
  getAllStaticLinksUrl: '/system/staticLinks/all',
  uploadIconUrl: '/system/setting/image/upload',
  createStaticLinkUrl: '/system/staticLinks/create',
  generateUpdateStaticLinkUrlId: (staticLinkUUID: string) => {
    return `/system/staticLinks/${staticLinkUUID}/update`;
  },
  deleteStaticLinksUrl: '/system/staticLinks/delete'
};

const staticLinksActions = new StaticLinksActions(staticLinksActionsProperties);

export const getStaticLinksAction = staticLinksActions.getStaticLinksAction;
export const getAllStaticLinksAction = staticLinksActions.getAllStaticLinksAction;
export const createStaticLinkAction = staticLinksActions.createStaticLinkAction;
export const updateStaticLinkAction = staticLinksActions.updateStaticLinkAction;
export const deleteStaticLinkAction = staticLinksActions.deleteStaticLinkAction;
