/**
 * dompurifyHelper.ts (Abstract Web Common)
 *
 * Copyright © 2024 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2024
 *
 * @file dompurifyHelper.ts
 * @author Rafael Rodrigues
 * @copyright 2024 InstaLOD GmbH. All rights reserved.
 * @section Abstract Web Common
 */

import DOMPurify from 'dompurify';

/**
  Method to sanitize a given string removing HTML tags from the text. We return the plain text with no HTML tags.
  @param textToSanitize Defines the text to sanitize
  @return string
*/
export const sanitizeHTMLString = (textToSanitize: string): boolean => DOMPurify.sanitize(textToSanitize, { ALLOWED_TAGS: [] })
