/*
 * ClientFileRecordTable.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2022
 *
 * @file ClientFileRecordTable.tsx
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import {
  formatDate,
  bytesToSize,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { Column } from 'primereact/column';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import Button from 'react-bootstrap/Button';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import {
  ITablePayload,
  ISortEvent,
  IPageEvent
} from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { IFileRecordStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import { useSelector, useDispatch } from 'react-redux';
import ImageRow from '@abstract/abstractwebcommon-client/Table/ImageRow/ImageRow';
import { IFileRecord } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecord';
import { setAreAllImagesLoaded } from '../../../Store/FileRecords';
import { sanitizeHTMLString } from '@abstract/abstractwebcommon-client/utils/dompurifyHelper';

/**
 * @interface IClientFileRecordTableProperties
 */
interface IClientFileRecordTableProperties {
  handleSortUpdate: (event: ISortEvent) => void /**< Handle sort event */;
  handlePageUpdate: (event: IPageEvent) => void /**< Handle page event */;
  handleFilterUpdate: (event: string) => void /**< Handle filter event */;
  payload: ITablePayload /**< Table payload */;
  handleDownloadClick: (file: any) => void /**< Handle download files */;
}

const ClientFileRecordTable: React.FC<any> = (properties: IClientFileRecordTableProperties) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileRecords: IFileRecordStateSelector = useSelector(
    (state: IStateSelectors) => state.fileRecords
  ); /**< File Records */
  const clientFileRecordsList = fileRecords.clientFileRecordsList; /**< Client FileRecords List */
  const payload: ITablePayload = properties.payload; /**< Payload */

  const [isImageLoading, setImageLoading] = useState<boolean>(true);
  const [imageCount, setImageCount] = useState<number>(1);
  const [isIconColumnHide, setIconColumnHide] = useState<boolean | null>(null);

  /// Table header
  const header: JSX.Element = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer"></div>
      <SearchBar onSearchTermChanged={(data: string) => properties.handleFilterUpdate(data)} />
    </div>
  );

  // When there is no data to display, already show the data.
  // isImageLoading useState is used in isLoading BaseDatatable component.
  useEffect(() => {
    if (clientFileRecordsList.totalRecords === 0) {
      setImageLoading(false);
    }
  }, [clientFileRecordsList.totalRecords]);

  // If there is data to display, only display BaseDatatable component after all images are loaded
  useEffect(() => {
    if (
      !isImageLoading ||
      (clientFileRecordsList?.records && clientFileRecordsList?.records.length === 0)
    ) {
      dispatch(setAreAllImagesLoaded());
    }

    if (clientFileRecordsList?.records.length > 0) {
      const isIconFieldEmpty: boolean = !clientFileRecordsList?.records?.some(
        (fileRecord: IFileRecord) => fileRecord.icon !== ''
      );
      setIconColumnHide(isIconFieldEmpty);
    }
  }, [isImageLoading, clientFileRecordsList?.records, fileRecords.isLoadingImages]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <BaseDatatable
            value={clientFileRecordsList && clientFileRecordsList.records}
            totalRecords={clientFileRecordsList && clientFileRecordsList.totalRecords}
            isLoading={!clientFileRecordsList?.records || fileRecords.isLoadingImages}
            header={header}
            dataKey="fileRecordUUID"
            parentClass="clientFilesDataTable" /**< ClassName for div Component.*/
            sortField={payload.sort.sortField}
            onSort={properties.handleSortUpdate}
            sortOrder={payload.sort.sortOrder}
            first={payload.skip}
            emptyMessage={t('I18N.admin.fileManagement.empty_msg')}
            rows={payload.limit}
            bodyStyle={'text-center'}
            onPage={properties.handlePageUpdate}>
            {/* Used to prevent row selection on row click */}
            <Column selectionMode="multiple" className="d-none" />
            <Column
              expander
              className="invisible d-sm-none p-0 col-width-45"
              headerClassName="invisible d-sm-none p-0 col-width-45"
            />
            <Column
              field="icon"
              body={(rows: any) => (
                <ImageRow
                  setImageLoading={setImageLoading}
                  imageURL={rows.icon}
                  totalImagesCount={
                    isIconColumnHide
                      ? clientFileRecordsList &&
                        clientFileRecordsList.records?.filter(
                          (fileRecordItem: IFileRecord) => fileRecordItem.icon !== ''
                        ).length
                      : 0
                  }
                  setImageCount={setImageCount}
                  imageCount={imageCount}
                />
              )}
              header={t('client.page.dashboard.filesTable.icon')}
              className="icon-column-style d-none d-sm-table-cell"
              headerClassName="icon-column-style d-none d-sm-table-cell"
              hidden={isIconColumnHide}
            />
            <Column
              field="name"
              sortable
              body={(row: any) => (
                <DatatableColumn
                  title={t('client.page.dashboard.filesTable.name')}
                  data={row.name}
                  isEllipsisVisible={false}
                />
              )}
              header={t('client.page.dashboard.filesTable.name')}
              className="customNameColumnWidth"
              headerClassName="customNameColumnWidth"
            />
            <Column
              field="extension"
              sortable
              body={(row: any) => (
                <DatatableColumn
                  title={t('client.page.dashboard.filesTable.extension')}
                  data={<span className="p-tag bg-info">{row.extension.toLowerCase()}</span>}
                />
              )}
              header={t('client.page.dashboard.filesTable.extension')}
              className="custom-header-min-width-allowed"
              headerClassName="custom-header-min-width-allowed"
            />
            <Column
              field="description"
              sortable
              body={(row: any) => (
                <DatatableColumn
                  title={t('client.page.dashboard.filesTable.description')}
                  data={sanitizeHTMLString(row.description)}
                />
              )}
              header={t('client.page.dashboard.filesTable.description')}
              className="d-table-cell d-sm-none d-lg-table-cell"
              headerClassName="d-table-cell d-sm-none d-lg-table-cell"
            />
            <Column
              field="versionName"
              sortable
              body={(row: any) => (
                <DatatableColumn
                  title={t('admin.page.files.versions.versionName')}
                  data={row.activeFileRecordVersion?.versionName}
                />
              )}
              header={t('admin.page.files.versions.versionName')}
              className="d-table-cell d-sm-none d-xl-table-cell customNameColumnVersion"
              headerClassName="d-table-cell d-sm-none d-xl-table-cell customNameColumnVersion"
              hidden={
                !clientFileRecordsList?.records?.some(
                  (fileRecord: IFileRecord) =>
                    !isStringEmptyOrNullOrUndefined(fileRecord.activeFileRecordVersion.versionName)
                )
              }
            />
            <Column
              field="size"
              sortable
              header={t('client.page.dashboard.filesTable.size')}
              body={(row: any) => {
                return (
                  <DatatableColumn
                    title={t('client.page.dashboard.filesTable.size')}
                    data={bytesToSize(row.activeFileRecordVersion?.size)}
                  />
                );
              }}
              className="updatedDateCol d-table-cell d-sm-none d-md-table-cell"
              headerClassName="updatedDateCol d-table-cell d-sm-none d-md-table-cell"
            />
            <Column
              field="updated"
              sortable
              header={t('client.page.dashboard.filesTable.date')}
              body={(row: any) => (
                <DatatableColumn
                  title={t('client.page.dashboard.filesTable.date')}
                  data={formatDate(row.activeFileRecordVersion?.lastActivatedAt)}
                />
              )}
              className="updatedDateCol d-table-cell d-sm-none d-xl-table-cell"
              headerClassName="updatedDateCol d-table-cell d-sm-none d-xl-table-cell"
            />
            <Column
              field="download"
              body={(row: any) => {
                return (
                  <Button
                    className="custom-action-column-action-position"
                    variant="outline"
                    onClick={() => properties.handleDownloadClick(row)}>
                    <i className="bi bi-cloud-download editIcon fa-lg"></i>
                  </Button>
                );
              }}
              className="p-0 col-width-45 absolute-position-responsive-screen"
            />
          </BaseDatatable>
        </Col>
      </Row>
    </>
  );
};

export default ClientFileRecordTable;
