/*
 * GalleriaWrapper.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Rafael Rodrigues, 2023
 *
 * @file GalleriaWrapper.tsx
 * @author Rafael Rodrigues
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { useState, useRef, useEffect } from 'react';
import { Galleria } from 'primereact/galleria';

import { getExtension } from '../../Shared/utils/sharedFunctions';
import ConfirmationPopup from '../ConfirmationPopup';

import { IReport, IFile, IReportFile } from '../../Shared/interfaces/license/reports';

import './GalleriaWrapper.css';
import OverflowableText from '../OverflowableText';

//Note: Transalations for the deletion item dialog
//For some reason, the transalation isn't applied to Stage and Production environment.
const transalateHeaderDeletionItemTitle = 'Do you want to delete this record?'
const transalateNoOptionDeletionItem = 'No'
const transalateYesOptionDeletionItem = 'Yes'

/**
 * @interface IGalleriaWrapperProperties
 */
interface IGalleriaWrapperProperties {
  report: IReport /**< Report object. */
  isSupportRole?: boolean /**< Allow to delete file */
  isAdmin?: boolean /**< Allow to delete file */
  displayDownloadAction?: boolean /**< Display download action */
  displayDeleteAction?: boolean /**< Display delete action */
  handleDownloadFile?: (fileUUID: string, reportUUID: string) => void /**< Download requested file */
  handleFileDelete?: (payload: IReportFile) => void /**< Delete requested file */
  isReportObjectChanged?: boolean /**<Is report object changed */;
  setReportFilesLoading?: React.Dispatch<
    React.SetStateAction<boolean>
  > /**< Report files loading state */;
}

/**
 * Display uploaded files as galleria with options:
 * * 1 - Open full screen
 * * 2 - Download file
 * * 3 - Delete file
 * * 
 * @param report Report object with the files
 * @param isSupportRole Allow to delete file
 * @param isAdmin Allow to delete file
 * @param displayDownloadAction Display download actions
 * @param displayDeleteAction Display delete actions
 * @param handleDownloadFile Download requested file
 * @param handleFileDelete Delete requested file
 * @returns JSX.Element
 */
const GalleriaWrapper = (properties: IGalleriaWrapperProperties): JSX.Element => {
  const displayDownloadAction: boolean = properties?.displayDownloadAction ?? false
  const displayDeleteAction: boolean = properties?.displayDeleteAction ?? false
  const isSupportRole: boolean = properties?.isSupportRole ?? false
  const isAdmin: boolean = properties?.isAdmin ?? false

  const [currentFileUUID, setCurrentFileUUID] = useState<string>('');
  const [isShowConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [reportObject, setReportObject] = useState<IReport | null>(null);
  const [isGalleriaTemplateOpened, setGalleriaTemplateOpened] = useState<boolean>(); /**< Is Galleria template opened */
  const [loadedFileCount, setLoadedFileCount] = useState<number>(
    0
  ); /**< Loaded file count. */

  const galleriaRefference = useRef<any>(null);
  enum FileTypeURL {
    Media = '/video.svg',
    File = '/file-document.svg',
    UnsupportedMediaFile = '/unsupported-media.svg'
  } /**< File type URL */

  enum KeyCode {
    Right = 'ArrowRight',
    Left = 'ArrowLeft',
    Esc = 'Escape'
  } /**< Key Code */;
  
  const getFileIcon = (url: string, filename: string) => {
    const fileTypes: string[] = ['mp4', 'doc', 'docx', 'txt', 'pdf', 'mov', 'avi', 'wmv'];
    const extension: string = getExtension(filename);
    const found: string = fileTypes.find((fileType: string) => fileType === extension);

    if (!found) {
      return url;
    }

    if (found === 'mp4' || found === 'mov') {
      return FileTypeURL.Media;
    }

    if(found === 'wmv' || found === 'avi') {
      return FileTypeURL.UnsupportedMediaFile;
    }

    return FileTypeURL.File;
  };

  const handleFileDownload = (fileUUID: string, reportUUID: string): void => {
    setCurrentFileUUID(fileUUID);
    properties.handleDownloadFile(fileUUID, reportUUID);
  };

  const handleDeleteDialog = (event: any, fileUUID: string): void => {
    setCurrentFileUUID(fileUUID);
    setShowConfirmation(true);
    setConfirmPopupTarget(event?.target);
  };

  const onAccept = (): void => {
    setShowConfirmation(false);
    properties.handleFileDelete({ fileUUID: currentFileUUID, reportUUID: reportObject.reportUUID });
  };

  const onReject = (): void => {
    setShowConfirmation(false);
  };

  /// Handles file load event.
  const onFileLoadHandler = () => {
    let count: number = loadedFileCount;
    count++;
    setLoadedFileCount(count);
    if (count === reportObject.files.length) {
      properties.setReportFilesLoading && properties.setReportFilesLoading(false); // When all report files are loaded, set reportfilesloading to false.
    }
  };

  const itemTemplate = (file: IFile): JSX.Element => {
    // Remove the classname from image element.
    const imageElement = document.getElementById('galleria-image');
    if(imageElement) {
      imageElement.classList.remove('placeholder-image');
    }

    const imageURL: string = getFileIcon(file.path, file.name); /**< ImageURL. */

    if(imageURL === FileTypeURL.Media) { // Video file
      return (
        <video height="800" className='w-100 d-block' preload="none" controls src={file.path} />
      );
    } else if (imageURL === FileTypeURL.UnsupportedMediaFile) { // Added video icon for unsupported media files
      return (
        <i className="fas fa-file-video fa-10x"/>
      );
    } else {
      return (
        <img
          id='galleria-image'
          src={imageURL}
          alt={file.name}
          className={`w-100 d-block ${
            (imageURL === FileTypeURL.File || imageURL === FileTypeURL.Media) ? 'placeholder-image' : ''
          }`}
          onError={(event: any) => {
            // Change image src to placeholder image for files that don't render an image
            event.target.src = FileTypeURL.File;
            event.target.className = 'w-100 d-block placeholder-image'
          }}
        />
      );
    }
  };

  const onFileClick = (index: number): void => {
    document.body.classList.add("hide-scroll");
    setGalleriaTemplateOpened(true);
    setActiveIndex(index);
    galleriaRefference.current?.show()
  }

  useEffect(() => {
    if (!reportObject) {
      setReportObject(properties.report)
    }

    return () => {
      setReportObject(null);
    };
  }, [])

  /// Note: Add a key event handler for the arrow and escape keys used in the Galleria template
  useEffect(() => {
    const keyUpHandler = (event: any) => {
      if(isGalleriaTemplateOpened) {
        if(event.key === KeyCode.Right) {
          const index = activeIndex + 1;
          if(reportObject && reportObject.files && reportObject.files.length && index <= reportObject.files.length - 1 ) {
            setActiveIndex(index);
          } else {
            setActiveIndex(0); // Move to first image
          }
        }
        if(event.key === KeyCode.Left) {
          const index = activeIndex - 1;
          if(reportObject && reportObject.files && reportObject.files.length && index >= 0 ) {
            setActiveIndex(index);
          } else {
            setActiveIndex(reportObject.files.length -1); // Move to last image
          }
        }
        if(event.key === KeyCode.Esc) {
          onHideGalleriaTemplate();
        }
      }
      return;
    }
  
    window.addEventListener("keyup", keyUpHandler);
    return () => window.removeEventListener("keyup", keyUpHandler);
  }, [isGalleriaTemplateOpened, activeIndex]);

  const onHideGalleriaTemplate = () => {
    setGalleriaTemplateOpened(false)
    document.body.classList.remove("hide-scroll");
    galleriaRefference.current?.hide();
  }

  /// If report object changes, set the value
  useEffect(() => {
    if(properties.isReportObjectChanged) {
      setReportObject(properties.report);
    }
  }, [properties.report])
  
  return (
    <>
      {reportObject && (
        <>
          <Galleria
            ref={galleriaRefference}
            value={reportObject.files}
            activeIndex={activeIndex}
            onItemChange={(e) => setActiveIndex(e.index)}
            circular
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={itemTemplate}
            // Start of properties used to prevent build step errors
            itemNextIcon=""
            itemPrevIcon=""
            nextThumbnailIcon=""
            prevThumbnailIcon=""
            // End of properties used to prevent build step errors
            onHide={() => onHideGalleriaTemplate()}
            />

          <div className="p-grid d-flex flex-wrap position-relative">
            {reportObject.files &&
              reportObject.files.map((file: IFile, index: number) => {
                const imageURL: string = getFileIcon(file.path, file.name); /**< ImageURL. */

                return (
                  <div className='galleria-wrapper mt-1 mb-1'>
                    <div className="d-flex pt-2 pb-2 mr-3 mb-1 file-attachment">
                      <div className="p-col-12 mr-3 mt-4 galleria-container" key={index}>
                        <div className="galleria-image-container">
                          {imageURL === FileTypeURL.Media ? (
                            <video
                              src={file.path}
                              className="galleria-image-container"
                              onClick={() => onFileClick(index)} 
                              onLoadedData={() => onFileLoadHandler()}
                            />
                          ): imageURL === FileTypeURL.UnsupportedMediaFile ? (
                            <i className="fas fa-file-video fa-9x d-block text-center"
                              onClick={() => onFileClick(index)}
                              onLoad={() => onFileLoadHandler()}
                            />
                          ) : (
                            <img
                              src={imageURL}
                              alt={file.name}
                              className="galleria-image-container"
                              onClick={() => onFileClick(index)}
                              onLoad={() => onFileLoadHandler()}
                              onError={(event: any) => {
                                // Change image src to placeholder image for files that don't render an image
                                event.target.src = FileTypeURL.File;
                                onFileLoadHandler()
                              }}
                            />
                          )}
                        </div>
                        {displayDownloadAction && (
                          <i
                            className={`bi bi-cloud-download fa-md custom-download-action-on-image-container 
                              ${(isSupportRole || isAdmin) ? '' : 'download-icon-position'}`}
                            onClick={() => handleFileDownload(file.fileUUID, reportObject.reportUUID)}></i>
                        )}

                        {displayDeleteAction && (isSupportRole || isAdmin) && (
                          <i
                            className="bi bi-trash fa-md custom-delete-action-on-image-container"
                            onClick={(event) => handleDeleteDialog(event, file.fileUUID)}></i>
                        )}
                      </div>
                    </div>
                    <div className='file-name'>
                      <span><OverflowableText text={file.name} /></span>
                    </div>
                  </div>
                );
              })}
          </div>

          <ConfirmationPopup
            target={confirmPopupTarget}
            isShow={isShowConfirmation}
            title={transalateHeaderDeletionItemTitle}
            onAccept={onAccept}
            onReject={onReject}
            acceptBtnClass="danger"
            rejectBtnClass="secondary"
            rejectLabel={transalateNoOptionDeletionItem}
            acceptLabel={transalateYesOptionDeletionItem}
            acceptBtnIcon="bi bi-check2-circle"
            rejectBtnIcon="bi bi-x-circle"
          />
        </>
      )}
    </>
  );
};

export default GalleriaWrapper;
