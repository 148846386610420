/*
 * FileRecords.ts (AbstractLicensingBackend)
 *
 * Copyright © 2021 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Etienne Daher, 2021
 *
 * @file FileRecords.ts
 * @author Etienne Daher
 * @copyright 2021 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { formatTableSortOrder } from '../Utils/Formatter';
import {
  createFileRecordVersion,
  createOrUpdateAPI,
  fetchVersionsAPI,
  getAllClientFiles,
  getSignedDownloadURLAPI,
  getSignedUploadURLAPI,
  readAPI,
  removeAPI,
  removeVersionAPI,
  setActiveVersionAPI,
  updateFileRecordVersionAPI,
  uploadIconAPI,
  uploadToS3
} from '../Services/FileRecord';
import { IPaginationSort } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import { IFileRecord } from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecord';
import {
  IAPIEntityResponse,
  PaginatedAPIEntityResponse
} from '@abstract/abstractwebcommon-shared/interfaces/api';
import { IMultipartFile } from '@abstract/abstractwebcommon-shared/interfaces/multipartFile';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import axios, { CancelTokenSource } from 'axios';
import {
  IReducerAction,
  PaginationRequestAction,
  PaginationResponseAction
} from '@abstract/abstractwebcommon-shared/interfaces/store';
import { IImageUploadResponse } from '@abstract/abstractwebcommon-shared/interfaces/license/setting';
import {
  IFileRecordVersion,
  IMappedFileRecordVersion
} from '@abstract/abstractwebcommon-shared/interfaces/license/fileRecordVersion';
import { handleError } from '@abstract/abstractwebcommon-client/ErrorHandler/ErrorHandler';
import { showSuccessToast } from '@abstract/abstractwebcommon-client/AlertToast/AlertToast';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

export const FILE_RECORDS_FEATURE_KEY = 'fileRecords';

export interface IFileRecordState {
  list: [];
  fileRecords: {
    list: any;
    fileRecord: {
      name: string;
      description: string;
    };
    fileRecordIsChanging: boolean;
    fileRecordIsFetching: boolean;
    fileRecordIsDownloading: boolean;
    fileRecordUpdateSuccess: boolean;
    sort: IPaginationSort<IFileRecord>;
    skip: number;
    limit: number;
  };
  fileRecordIsFetching: boolean;
  fileRecordsIsFetching: boolean;
  listIsFetching: boolean;
  fileRecordIsChanging: boolean;
  fileRecordIsDownloading: boolean;
  fileRecordIsUploading: boolean;
  fileRecordIsFetchingVersions: boolean;
  fileRecordUploadProgress: number;
  availableVersionsList: [] /**< Available File Record Verions */;
  availableVersionsSort: IPaginationSort<IMappedFileRecordVersion> /**< Versions sort */;
  availableVersionsSkip: number /**< Versions skip */;
  availableVersionsLimit: number /**< Versions limit */;
  fileRecordUpdateSuccess: boolean;
  sort: IPaginationSort<IFileRecord>;
  skip: number;
  limit: number;
  clientFileRecordsList: [] /**< Client File Records */;
  clientFileRecordsIsFetching: boolean /**< Client File Record is fetching */;
  isLoadingImages: boolean /** Control when all images are loading and when are loaded */;
  searchTerm?: string /* Search component value **/;
  availableVersionsSearchTerm?: string /* Search component value used in the File Version modal**/;
}
/**
 * FileRecord Intial state
 */
const fileRecordInitialState: IFileRecordState = {
  list: null,
  fileRecords: {
    list: {},
    fileRecord: {
      name: '',
      description: ''
    },
    fileRecordIsChanging: false,
    fileRecordIsFetching: false,
    fileRecordIsDownloading: false,
    fileRecordUpdateSuccess: false,
    sort: {
      sortField: 'updated',
      sortOrder: -1
    },
    skip: 0,
    limit: defaultTableLimit
  },
  fileRecordIsFetching: false,
  fileRecordsIsFetching: false,
  listIsFetching: false,
  fileRecordIsChanging: false,
  fileRecordIsDownloading: false,
  fileRecordIsUploading: false,
  fileRecordIsFetchingVersions: false,
  fileRecordUploadProgress: 0,
  availableVersionsList: [],
  availableVersionsSort: {
    sortField: 'isActive', /// By default,the activated version should be at the top, followed by newly created versions.
    sortOrder: -1
  },
  availableVersionsSkip: 0,
  availableVersionsLimit: defaultTableLimit,
  fileRecordUpdateSuccess: false,
  sort: {
    sortField: 'updated',
    sortOrder: -1
  },
  skip: 0,
  limit: defaultTableLimit,
  clientFileRecordsList: null,
  clientFileRecordsIsFetching: false,
  isLoadingImages: true,
  searchTerm: '',
  availableVersionsSearchTerm: ''
};

interface IFileRecordAndFiles extends IFileRecord {
  file?: IMultipartFile;
  iconFile?: IMultipartFile;
  versionID?: string /**< File Version ID.*/;
}

interface ICreateAndReset {
  values: IFileRecordAndFiles;
  resetForm: () => void;
}

let cancelUploadSource: CancelTokenSource = axios.CancelToken.source();

export const fileRecordSlice = createSlice({
  name: FILE_RECORDS_FEATURE_KEY,
  initialState: fileRecordInitialState,
  reducers: {
    getFileRecordsRequest(state: IFileRecordState, action: PaginationRequestAction<IFileRecord>) {
      const { skip, limit, sort, searchTerm } = action.payload;
      if (sort) {
        state.sort = sort as IPaginationSort<IFileRecord>;
      }
      state.skip = skip;
      state.limit = limit;
      state.fileRecordsIsFetching = true;
      state.searchTerm = searchTerm;
    },
    getFileRecordsSuccess(state: IFileRecordState, action: PaginationResponseAction<IFileRecord>) {
      state.list = action.payload as any;
      state.fileRecordsIsFetching = false;
    },
    getFileRecordsFailure(state: IFileRecordState) {
      state.list = [];
      state.fileRecordsIsFetching = false;
    },
    addFileRecordRequest(state: IFileRecordState) {
      state.fileRecordIsChanging = true;
      state.fileRecordIsUploading = true;
      state.fileRecordUploadProgress = 0;
    },
    addFileRecordSuccess(state: IFileRecordState) {
      state.fileRecordIsChanging = false;
      state.fileRecordIsUploading = false;
    },
    addFileRecordFailure(state: IFileRecordState) {
      state.fileRecordIsChanging = false;
      state.fileRecordIsUploading = false;
      state.fileRecordUploadProgress = 0;
    },
    updateFileRecordRequest(state: IFileRecordState) {
      state.fileRecordIsChanging = true;
      state.fileRecordUpdateSuccess = false;
    },
    updateFileRecordSuccess(state: IFileRecordState) {
      state.fileRecordIsChanging = false;
      state.fileRecordUpdateSuccess = true;
    },
    updateFileRecordFailure(state: IFileRecordState) {
      state.fileRecordIsChanging = false;
    },
    deleteFileRecordRequest(state: IFileRecordState) {
      state.fileRecordIsChanging = true;
    },
    deleteFileRecordSuccess(state: IFileRecordState) {
      state.fileRecordIsChanging = false;
    },
    deleteFileRecordFailure(state: IFileRecordState) {
      state.fileRecordIsChanging = false;
    },
    downloadFileRequest(state: IFileRecordState) {
      state.fileRecordIsDownloading = true;
      state.fileRecordIsFetchingVersions = true;
    },
    downloadFileSuccess(state: IFileRecordState) {
      state.fileRecordIsDownloading = false;
      state.fileRecordIsFetchingVersions = false;
    },
    downloadFileFailure(state: IFileRecordState) {
      state.fileRecordIsDownloading = false;
      state.fileRecordIsFetchingVersions = false;
    },
    fetchVersionsRequest(
      state: IFileRecordState,
      action: PaginationRequestAction<IMappedFileRecordVersion>
    ) {
      if (action.payload.sort) {
        state.availableVersionsSort = action.payload
          .sort as IPaginationSort<IMappedFileRecordVersion>;
      }
      state.availableVersionsSkip = action.payload?.skip;
      state.availableVersionsLimit = action.payload?.limit;
      state.fileRecordIsFetchingVersions = true;
      state.availableVersionsSearchTerm = action.payload?.searchTerm;
    },
    fetchVersionsSuccess(
      state: IFileRecordState,
      action: PaginationResponseAction<IMappedFileRecordVersion>
    ) {
      state.fileRecordIsFetchingVersions = false;
      state.availableVersionsList = action.payload as any;
    },
    fetchVersionsFailure(state: IFileRecordState) {
      state.fileRecordIsFetchingVersions = false;
    },
    uploadFileVersionRequest(state: IFileRecordState) {
      state.fileRecordIsFetchingVersions = true;
      state.fileRecordIsUploading = true;
      state.fileRecordUploadProgress = 0;
    },
    uploadFileVersionSuccess(state: IFileRecordState) {
      state.fileRecordIsUploading = false;
      state.fileRecordIsFetchingVersions = false;
    },
    uploadFileVersionFailure(state: IFileRecordState) {
      state.fileRecordIsUploading = false;
      state.fileRecordIsFetchingVersions = false;
    },
    updateVersionTableRequest(state: IFileRecordState) {
      state.fileRecordIsFetchingVersions = true;
    },
    updateVersionTableSuccess(state: IFileRecordState) {
      state.fileRecordIsFetchingVersions = false;
    },
    updateVersionTableFailure(state: IFileRecordState) {
      state.fileRecordIsFetchingVersions = false;
    },
    uploadFileProgress(state: IFileRecordState, action: IReducerAction<number>) {
      state.fileRecordUploadProgress = action.payload;
    },
    getClientFileRecordsRequest(state: IFileRecordState) {
      state.clientFileRecordsIsFetching = true;
    },
    getClientFileRecordsSuccess(
      state: IFileRecordState,
      action: PaginationResponseAction<IFileRecord>
    ) {
      state.clientFileRecordsList = action.payload as any;
      state.clientFileRecordsIsFetching = false;
    },
    getClientFileRecordsFailure(state: IFileRecordState) {
      state.clientFileRecordsIsFetching = false;
    },
    areAllImagesLoadedSuccess(state: IFileRecordState) {
      state.isLoadingImages = false;
    }
  },
  extraReducers: {}
});

export const fileRecordReducer = fileRecordSlice.reducer;
export const fileRecordActions = fileRecordSlice.actions;

/**
 * Set isLoadingImages state to false when all images are loaded.
 * @param payload
 */
export const setAreAllImagesLoaded = createAsyncThunk(
  'fileRecord/get/image/all/load/success',
  async (_, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { areAllImagesLoadedSuccess } = fileRecordActions;

    try {
      dispatch(areAllImagesLoadedSuccess());
    } catch (error: any) {
      handleError({ message: error.message });
    }
  }
);

/**
 * Get all file Records with pagniation Action.
 * @param payload
 */
export const getAllFileRecords = createAsyncThunk(
  'fileRecord/all',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { getFileRecordsRequest, getFileRecordsSuccess, getFileRecordsFailure } =
      fileRecordActions;

    try {
      const { skip, limit } = payload;
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */

      dispatch(getFileRecordsRequest({ skip, limit, sort: { sortField, sortOrder }, searchTerm }));

      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';

      const response: PaginatedAPIEntityResponse<IFileRecord> = await asyncErrorHandler(
        readAPI(skip, limit, sortField, formattedSortOrder, searchTerm)
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getFileRecordsFailure());
      } else {
        dispatch(getFileRecordsSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getFileRecordsFailure());
      handleError({ message: error.toString() });
    }
  }
);

/**
 * Create file Record Action.
 * @param payload
 */
export const createFileRecord = createAsyncThunk(
  'fileRecord/create',
  async (mainPayload: ICreateAndReset, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addFileRecordRequest, addFileRecordSuccess, addFileRecordFailure, uploadFileProgress } =
      fileRecordActions;

    // in case of upload error on any stage, remove file created
    const handleFileRecordError = async (error: string, fileRecordUUID?: string): Promise<void> => {
      if (fileRecordUUID) {
        await asyncErrorHandler(removeAPI([fileRecordUUID]));
      }
      dispatch(addFileRecordFailure());
      handleError({ message: error });
      return;
    };

    try {
      dispatch(addFileRecordRequest());
      const payload: IFileRecordAndFiles = mainPayload.values;
      const resetForm: () => void = mainPayload.resetForm;

      // upload logo icon
      let uploadIconURL: string = '';
      if (payload.iconFile) {
        const payloadForUploadIcon: any = {
          file: payload.iconFile
        };
        const uploadIconResult: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadIconAPI(payloadForUploadIcon)
        );
        if (uploadIconResult.error) {
          handleFileRecordError(uploadIconResult.error.message);
          return;
        }
        uploadIconURL = uploadIconResult.data.uploadURL;
      }

      // create record file
      const createPayload: any = { ...payload, icon: uploadIconURL };
      const result: IAPIEntityResponse<IFileRecord> = await asyncErrorHandler(
        createOrUpdateAPI(createPayload)
      );

      if (result.error) {
        handleFileRecordError(result.error.message);
        return;
      }

      // upload main file using file record id
      const record: IFileRecord | null = result && result.data ? result.data : null;
      if (record && payload.file) {
        const payloadForUpload: any = { ...payload, fileRecordUUID: record.fileRecordUUID };
        const signedURLResult: IAPIEntityResponse<{
          signedUploadURL: string;
        }> = await asyncErrorHandler(getSignedUploadURLAPI(payloadForUpload));
        if (signedURLResult.error) {
          // on get signed URL failure, delete created record
          await asyncErrorHandler(
            handleFileRecordError(signedURLResult.error.message, record.fileRecordUUID)
          );
          return;
        }

        cancelUploadSource = axios.CancelToken.source();
        const uploadToS3Response: any = await asyncErrorHandler(
          uploadToS3(
            signedURLResult.data && signedURLResult.data.signedUploadURL,
            payload.file,
            (progress: any) => {
              const percentage: number = Math.round((progress.loaded / progress.total) * 100);
              dispatch(uploadFileProgress(percentage));
            },
            cancelUploadSource
          )
        );

        if (uploadToS3Response.error) {
          await asyncErrorHandler(
            handleFileRecordError(uploadToS3Response.error.message, record.fileRecordUUID)
          );
          return;
        }

        const createFileRecordVersionResponse: IAPIEntityResponse<void> = await asyncErrorHandler(
          createFileRecordVersion(payloadForUpload)
        );
        if (createFileRecordVersionResponse.error) {
          await asyncErrorHandler(
            handleFileRecordError(
              createFileRecordVersionResponse.error.message,
              record.fileRecordUUID
            )
          );
          return;
        }
      }

      // on 3 successful stages
      dispatch(addFileRecordSuccess());
      showSuccessToast(result.message);
      resetForm();
      return true;
    } catch (error: any) {
      handleFileRecordError(error.toString());
    }
  }
);

/**
 * Update file Record Action.
 * @param payload
 */
export const updateFileRecord = createAsyncThunk(
  'fileRecord/update',
  async (payload: IFileRecord, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const { updateFileRecordRequest, updateFileRecordSuccess, updateFileRecordFailure } =
      fileRecordActions;
    try {
      dispatch(updateFileRecordRequest());

      // upload logo icon
      if (payload && payload.croppedIconImage) {
        const payloadForUploadIcon: any = {
          file: payload.croppedIconImage
        };
        const result: IAPIEntityResponse<IImageUploadResponse> = await asyncErrorHandler(
          uploadIconAPI(payloadForUploadIcon)
        );

        if (result.status === 200) {
          payload.icon = result.data.uploadURL;
        } else if (result.error) {
          dispatch(updateFileRecordFailure());
          handleError({ message: result.error.message });
          return;
        }
      }

      const result: IAPIEntityResponse<IFileRecord> = await asyncErrorHandler(
        createOrUpdateAPI(payload)
      );
      if (result.error) {
        dispatch(updateFileRecordFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateFileRecordSuccess());
        showSuccessToast(result.message);
        dispatch(
          getAllFileRecords({
            skip: store.fileRecords.skip,
            limit: store.fileRecords.limit,
            sort: {
              sortField: store.fileRecords.sort.sortField,
              sortOrder: store.fileRecords.sort.sortOrder
            },
            searchTerm: store.fileRecords.searchTerm
          })
        );
      }
      return result;
    } catch (error: any) {
      dispatch(updateFileRecordFailure());
      handleError({ message: error.toString() });
    }
  }
);

/**
 * Delete file Record Action.
 * @param payload
 */
export const deleteFileRecord = createAsyncThunk(
  'fileRecord/delete',
  async (payload: any, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState();
    const { deleteFileRecordRequest, deleteFileRecordSuccess, deleteFileRecordFailure } =
      fileRecordActions;

    try {
      dispatch(deleteFileRecordRequest());
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(removeAPI(payload));
      if (result.error) {
        dispatch(deleteFileRecordFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(deleteFileRecordSuccess());
        showSuccessToast(result.message);
        dispatch(
          getAllFileRecords({
            skip: store.fileRecords.skip,
            limit: store.fileRecords.limit,
            sort: {
              sortField: store.fileRecords.sort.sortField,
              sortOrder: store.fileRecords.sort.sortOrder
            },
            searchTerm: store.fileRecords.searchTerm
          })
        );
      }
    } catch (error: any) {
      dispatch(deleteFileRecordFailure());
      handleError({ message: error.toString() });
    }
  }
);

/**
 * download File Action.
 * @param payload
 */
export const downloadFileRecord = createAsyncThunk(
  'fileRecord/download',
  async (mainPayload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { downloadFileRequest, downloadFileSuccess, downloadFileFailure } = fileRecordActions;
    const fileRecordUUID: string = mainPayload.fileRecordUUID;
    const versionID: string = mainPayload.versionID;
    const userUUID: string = mainPayload.userUUID; /**< UserUUID. */

    try {
      dispatch(downloadFileRequest());
      const payload: any = {
        fileRecordUUID,
        versionID,
        userUUID
      };
      const signedDownloadURLResponse: IAPIEntityResponse<{ signedDownloadURL: string }> =
        await asyncErrorHandler(getSignedDownloadURLAPI(payload));

      if (signedDownloadURLResponse.status === 200) {
        const signedDownloadURL: string = signedDownloadURLResponse.data.signedDownloadURL;
        window.location.href = signedDownloadURL;
      } else {
        dispatch(downloadFileFailure());
        handleError({ message: 'Download failed' });
        return;
      }

      dispatch(downloadFileSuccess());
      showSuccessToast(`Download started successfully`);
    } catch (error: any) {
      dispatch(downloadFileFailure());
      handleError({ message: error.toString() });
    }
  }
);

/**
 * Fetch Versions with pagination Action.
 * @param payload
 */
export const fetchVersions = createAsyncThunk(
  'fileRecord/fetchVersions',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { fetchVersionsRequest, fetchVersionsSuccess, fetchVersionsFailure } = fileRecordActions;

    try {
      const skip: number = payload?.skip; /**< Skip */
      const limit: number = payload?.limit; /**< Limit */
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */
      const fileRecordUUID: string = payload.fileRecordUUID; /**< FileRecordUUID. */

      dispatch(fetchVersionsRequest({ skip, limit, sort: { sortField, sortOrder }, searchTerm }));

      const formattedSortOrder = sortOrder
        ? formatTableSortOrder(sortOrder)
        : 'DESC'; /**> Format Sort order. */

      const result: PaginatedAPIEntityResponse<IMappedFileRecordVersion> = await asyncErrorHandler(
        fetchVersionsAPI(skip, limit, sortField, formattedSortOrder, searchTerm, fileRecordUUID)
      );
      if (result.error) {
        dispatch(fetchVersionsFailure());
        handleError({ message: result.error.message });
        return false;
      } else {
        dispatch(fetchVersionsSuccess(result.data));
        return true;
      }
    } catch (error: any) {
      dispatch(fetchVersionsFailure());
      handleError({ message: error.toString() });
      return false;
    }
  }
);

/**
 * Upload file version Action.
 * @param payload
 */
export const uploadFileVersion = createAsyncThunk(
  'fileRecord/uploadVersion',
  async (mainPayload: ICreateAndReset, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState(); /**< Store state */
    const {
      uploadFileVersionRequest,
      uploadFileVersionSuccess,
      uploadFileVersionFailure,
      uploadFileProgress
    } = fileRecordActions;
    const handleFileRecordError = async (error: string, fileRecordUUID?: string): Promise<void> => {
      if (fileRecordUUID) {
        await asyncErrorHandler(removeAPI([fileRecordUUID]));
      }
      dispatch(uploadFileVersionFailure());
      handleError({ message: error });
      return;
    };
    try {
      dispatch(uploadFileVersionRequest());
      const payload: IFileRecordAndFiles = mainPayload.values; /**< File version data. */
      const resetForm: () => void = mainPayload.resetForm; /**< To reset form. */
      const signedURLResult: IAPIEntityResponse<{
        signedUploadURL: string;
      }> = await asyncErrorHandler(getSignedUploadURLAPI(payload));

      if (signedURLResult.error) {
        handleFileRecordError(signedURLResult.error.message);
      } else {
        cancelUploadSource = axios.CancelToken.source();
        const uploadToS3Response: any = await asyncErrorHandler(
          uploadToS3(
            signedURLResult.data && signedURLResult.data.signedUploadURL,
            payload.file,
            (progress: any) => {
              const percentage: number = Math.round((progress.loaded / progress.total) * 100);
              dispatch(uploadFileProgress(percentage));
            },
            cancelUploadSource
          )
        );
        if (uploadToS3Response.error) {
          handleFileRecordError(uploadToS3Response.error.message);
          return;
        }
        const createFileRecordVersionResponse: IAPIEntityResponse<void> = await asyncErrorHandler(
          createFileRecordVersion(payload)
        );

        if (createFileRecordVersionResponse.error) {
          handleFileRecordError(createFileRecordVersionResponse.error.message);
        } else {
          dispatch(uploadFileVersionSuccess());
          // fetch versions request and is fetching flag will be updated on the next api call: original delete
          dispatch(
            fetchVersions({
              skip: store.fileRecords.availableVersionsSkip,
              limit: store.fileRecords.availableVersionsLimit,
              sort: {
                sortField: 'isActive', /// After uploading the file version, the activated version should be at the top, followed by newly created versions.
                sortOrder: -1
              },
              fileRecordUUID: payload.fileRecordUUID,
              searchTerm: store.fileRecords.availableVersionsSearchTerm
            })
          );

          showSuccessToast(createFileRecordVersionResponse.message);
          resetForm(); /**< To refresh the grid after uploading the file. */
        }
      }
    } catch (error: any) {
      handleFileRecordError(error.toString());
    }
  }
);

/**
 * Update file Record Version Action. Used to update description so far only
 * @param payload
 */
export const updateFileRecordVersion = createAsyncThunk(
  'fileRecord/updateVersion',
  async (mainPayload: ICreateAndReset, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState(); /**< Store state */
    const { updateVersionTableRequest, updateVersionTableSuccess, updateVersionTableFailure } =
      fileRecordActions;
    try {
      dispatch(updateVersionTableRequest());
      const payload: IFileRecord = mainPayload.values; /**< File version data. */
      const resetForm: () => void = mainPayload.resetForm; /**< To reset form. */
      const fileRecordUUID: string = payload.fileRecordUUID; /**< FileRecord UUID. */
      const result: IAPIEntityResponse<IFileRecordVersion> = await asyncErrorHandler(
        updateFileRecordVersionAPI(payload)
      );
      if (result.error) {
        dispatch(updateVersionTableFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateVersionTableSuccess());
        showSuccessToast(result.message);
        dispatch(
          fetchVersions({
            skip: store.fileRecords.availableVersionsSkip,
            limit: store.fileRecords.availableVersionsLimit,
            sort: {
              sortField: store.fileRecords.availableVersionsSort.sortField,
              sortOrder: store.fileRecords.availableVersionsSort.sortOrder
            },
            fileRecordUUID,
            searchTerm: store.fileRecords.availableVersionsSearchTerm
          })
        ); /**< Fetch versions after update */
        resetForm(); /**< To refresh the grid after updating FileVersion. */
      }
      return result;
    } catch (error: any) {
      dispatch(updateVersionTableFailure());
      handleError({ message: error.toString() });
    }
  }
);

/**
 * Download File Action.
 * @param payload
 */
export const deleteFileVersion = createAsyncThunk(
  'fileRecord/deleteFileVersion',
  async (mainPayload: Record<string, any>, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState(); /**< Store state */
    const { updateVersionTableRequest, updateVersionTableSuccess, updateVersionTableFailure } =
      fileRecordActions;

    try {
      dispatch(updateVersionTableRequest());
      const payload: Record<string, any> = mainPayload.values; /**< File version data to delete. */
      const resetForm: () => void = mainPayload.resetForm; /**< To reset form. */
      const fileRecordUUID: string = payload.fileRecordUUID; /**< FileRecord UUID. */
      const versionIDs: string[] = payload.versionIDs; /**< VersionIDs. */
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        removeVersionAPI(fileRecordUUID, versionIDs)
      );

      if (result.error) {
        dispatch(updateVersionTableFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateVersionTableSuccess());
        showSuccessToast(result.message);
        /// If versions are deleted, show version table with updated versions
        if (result.message === 'File version(s) deleted successfully') {
          dispatch(
            fetchVersions({
              skip: store.fileRecords.availableVersionsSkip,
              limit: store.fileRecords.availableVersionsLimit,
              sort: {
                sortField: store.fileRecords.availableVersionsSort.sortField,
                sortOrder: store.fileRecords.availableVersionsSort.sortOrder
              },
              fileRecordUUID,
              searchTerm: store.fileRecords.availableVersionsSearchTerm
            })
          );
          resetForm(); /**< To refresh the grid after deleting the file. */
        } else {
          /// Otherwise entire file record is deleted.
          /// Show file table after deleting the entire fileRecord
          mainPayload.setShowVersionPage(false);
          dispatch(
            getAllFileRecords({
              skip: store.fileRecords.skip,
              limit: store.fileRecords.limit,
              sort: {
                sortField: store.fileRecords.sort.sortField,
                sortOrder: store.fileRecords.sort.sortOrder
              },
              searchTerm: store.fileRecords.searchTerm
            })
          ); /**< Fetch updated File records. */
        }
      }
    } catch (error: any) {
      dispatch(updateVersionTableFailure());
      handleError({ message: error.toString() });
    }
  }
);

/**
 * Set active version
 * @param payload
 */
export const setActive = createAsyncThunk(
  'fileRecord/setActive',
  async (mainPayload: ICreateAndReset, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const store: any = getState(); /**< Store state */
    const { updateVersionTableRequest, updateVersionTableSuccess, updateVersionTableFailure } =
      fileRecordActions;

    try {
      dispatch(updateVersionTableRequest());
      const payload: IFileRecordAndFiles = mainPayload.values; /**< File version data. */
      const resetForm: () => void = mainPayload.resetForm; /**< To reset form. */
      const fileRecordUUID: string = payload.fileRecordUUID;
      const versionID: string = payload.versionID;
      const result: IAPIEntityResponse<void> = await asyncErrorHandler(
        setActiveVersionAPI(fileRecordUUID, versionID)
      );
      if (result.error) {
        dispatch(updateVersionTableFailure());
        handleError({ message: result.error.message });
      } else {
        dispatch(updateVersionTableSuccess());
        showSuccessToast(result.message);
        dispatch(
          fetchVersions({
            skip: store.fileRecords.availableVersionsSkip,
            limit: store.fileRecords.availableVersionsLimit,
            sort: {
              sortField: 'isActive', /// After activating the file version, the activated version should be at the top, followed by newly created versions.
              sortOrder: -1
            },
            fileRecordUUID,
            searchTerm: store.fileRecords.availableVersionsSearchTerm
          })
        );
        resetForm(); /**< To refresh the grid after activating the file Version. */
      }
    } catch (error: any) {
      dispatch(updateVersionTableFailure());
      handleError({ message: error.toString() });
    }
  }
);

/**
 * Aborts axios's fetch for uploading to S3
 */
export const cancelS3Upload = createAsyncThunk(
  'fileRecord/cancelUpload',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { addFileRecordFailure } = fileRecordActions;
    dispatch(addFileRecordFailure());
    cancelUploadSource.cancel();
  }
);

/**
 * Get all client file Records with pagniation Action.
 * @param payload
 */
export const getAllPaginatedClientFiles = createAsyncThunk(
  'fileRecord/allClientFiles',
  async (payload: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const {
      getClientFileRecordsRequest,
      getClientFileRecordsSuccess,
      getClientFileRecordsFailure
    } = fileRecordActions;

    try {
      const { skip, limit } = payload;
      const sortField: string = payload.sort?.sortField; /**< Sort field. */
      const sortOrder: number = payload.sort?.sortOrder; /**< Sort order. */
      const searchTerm: string = payload?.searchTerm; /**< Search term to find. */
      const entitlementUUID: string = payload?.selectedProductUUID; /**< Entitlement UUID. */
      const userUUID: string = payload?.userUUID; /**< User UUID. */

      dispatch(getClientFileRecordsRequest());

      const formattedSortOrder = sortOrder ? formatTableSortOrder(sortOrder) : 'DESC';

      const response: PaginatedAPIEntityResponse<IFileRecord> = await asyncErrorHandler(
        getAllClientFiles(
          skip,
          limit,
          sortField,
          formattedSortOrder,
          searchTerm,
          entitlementUUID,
          userUUID
        )
      );
      if (response.error) {
        handleError({ message: response.error.message });
        dispatch(getClientFileRecordsFailure());
      } else {
        dispatch(getClientFileRecordsSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(getClientFileRecordsFailure());
      handleError({ message: error.toString() });
    }
  }
);
