/*
 * config.js (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file config.js
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

export const BASE_API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8081/api/v1';
export const BASE_DATE_FORMAT = process.env.REACT_APP_BASE_DATE_FORMAT || 'dd.MM.yyyy';
export const DATE_TIME_FORMAT = process.env.REACT_APP_DATE_TIME_FORMAT || 'dd.MM.yyyy hh:mm:ss';
export const TWENTY_FOUR_DATE_TIME_FORMAT =
  process.env.REACT_APP_TWENTY_FOUR_DATE_TIME_FORMAT || 'dd.MM.yyyy HH:mm:ss';
export const CURRENT_VERSION = process.env.REACT_APP_CURRENT_VERSION;
export const CURRENT_AWC_VERSION = process.env.REACT_APP_CURRENT_AWC_VERSION;
export const REACT_APP_USER_FRONTEND_BASE_URL = process.env.REACT_APP_USER_FRONTEND_BASE_URL;
export const REPORTS_EXPERIMENTAL_FEATURE_NAME = 'reports';
export const maximumFileSize = 2147483648; /** Maximum file size in bytes(2048 MB) */
