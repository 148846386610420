/**
 * Formatter.js (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file Formatter.js
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

export const getDateFormat = (date = new Date()): Date => {
  return new Date(date);
};

export const parseJsonAndGet = (name: string, json: string): string => {
  const parsed = JSON.parse(json);
  if (parsed && parsed[name]) {
    return parsed[name];
  }
  return '';
};

export const formatTableSortOrder = (order: number): string => {
  if (order === -1) {
    return 'DESC';
  } else {
    return 'ASC';
  }
};

/**
 * Format template
 * @param template
 * @param params
 * @returns emailTemplate
 */
export const formatTemplate = async (
  template = '',
  params: Record<string, any>
): Promise<string> => {
  let html = template;
  for (let i = 0; i < Object.keys(params).length; i++) {
    html = html.replace(`{{${Object.keys(params)[i]}}}`, params[Object.keys(params)[i]]);
  }
  return html;
};

/**
 * Compare 2 objects to sort them
 *
 * @param a first object
 * @param b second object
 * @param field field name
 * @param order sorting order 1 or -1 (which means ascending or descending)
 * @returns 1, -1, 0 for sorting function
 */
export const sortCompare = (a: object, b: object, field: string, order: number): number => {
  if (a[field] < b[field]) {
    return order;
  }
  if (a[field] > b[field]) {
    return order * -1;
  }
  return 0;
};
