/*
 * ActivationPage.tsx (AbstractLicensingBackend)
 *
 * Copyright © 2020 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Timothy Fadayini, 2020
 *
 * @file ActivationPage.tsx
 * @author Timothy Fadayini
 * @copyright 2020 InstaLOD GmbH. All rights reserved.
 * @section License
 */

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'primereact/column';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import BaseDatatable from '@abstract/abstractwebcommon-client/Table/BaseDatatable';
import {
  deactivateSelectedActivations,
  getAllActivations,
  updateActivation
} from '../../../Store/Activations';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import DatatableColumn from '@abstract/abstractwebcommon-client/Table/DatatableColumn';
import ConfirmationPopup from '@abstract/abstractwebcommon-client/ConfirmationPopup';
import { defaultTableLimit } from '@abstract/abstractwebcommon-client/Constants';
import { IActivationStateSelector, IStateSelectors } from '../../../Interfaces/Selectors';
import UserDetailsPopup from '@abstract/abstractwebcommon-client/UserDetailsPopup';
import { ITablePayload } from '@abstract/abstractwebcommon-shared/interfaces/pagination';
import SearchBar from '@abstract/abstractwebcommon-client/SearchBar/SearchBar';
import { Tooltip } from 'primereact/tooltip';
import {
  formatDate,
  isStringEmptyOrNullOrUndefined
} from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import ExpansionRow from '@abstract/abstractwebcommon-client/Table/ExpansionRow/ExpansionRow';
import { IActivation } from '@abstract/abstractwebcommon-shared/interfaces/license/activation';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import DialogWrapper from '@abstract/abstractwebcommon-client/DialogWrapper/DialogWrapper';
import ActivationForm from './ActivationForm';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

const ActivationPage = (): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activations: IActivationStateSelector = useSelector(
    (state: IStateSelectors) => state.activations
  );
  const [selectedActivations, setSelectedActivations] = useState(null);
  const ref: any = useRef(null);
  const [isShowingConfirmation, setIsShowingConfirmation] = useState<boolean>(false);
  const [confirmPopupTarget, setConfirmPopupTarget] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState<IActivation>({});
  const userData: IUser[] = activations.userData; /**< User data */
  const [activationRowData, setActivationRowData] =
    useState<IActivation>(); /**< Activation row data. */
  const [isShowEditDialog, setShowEditDialog] =
    useState<boolean>(false); /**< To Show Edit Dialog. */

  const [payload, setPayload] = useState<ITablePayload>({
    limit: defaultTableLimit,
    skip: 0,
    filter: {},
    sort: {
      sortField: 'activationDate',
      sortOrder: -1
    }
  }); /**< Default Payload */

  /// Delete roles on Accept
  const onAccept = () => {
    dispatch(deactivateSelectedActivations({ selectedActivations }));
    setIsShowingConfirmation(false);
    setSelectedActivations(null);
  };

  /// Hide confirmation on reject
  const onReject = () => {
    setIsShowingConfirmation(false);
  };

  /// show delete popup
  const deactivateSelectedActivationsHandler = (event: any) => {
    setIsShowingConfirmation(true);
    setConfirmPopupTarget(event?.target);
  };

  /// Initialize confirmation Popup
  const getConfirmPopup = () => {
    return (
      <>
        <ConfirmationPopup
          target={confirmPopupTarget}
          isShow={isShowingConfirmation}
          title={t('/confirm_messages.deactivate_activation')}
          onAccept={onAccept}
          onReject={onReject}
          acceptBtnClass="danger"
          rejectBtnClass="secondary"
          rejectLabel={t('/confirm_messages.no')}
          acceptLabel={t('/confirm_messages.yes')}
          acceptBtnIcon="bi bi-check2-circle"
          rejectBtnIcon="bi bi-x-circle"
          popupPosition="bottom"
        />
      </>
    );
  };

  const handleSortUpdate = (e: any) => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllActivations(updatedPayload));
  };

  const handlePageUpdate = (e: any) => {
    const { first, rows } = e;
    const updatedPayload = {
      skip: first,
      limit: rows,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };
    setPayload(updatedPayload);
    dispatch(getAllActivations(updatedPayload));
  };

  /// Triggers on every checkbox selection change in the UI.
  const onSelectionChange = (event: any) => {
    const selectedValue: [] = event.value;
    if (Array.isArray(selectedValue)) {
      const selectedRow = selectedValue.map((row: any) => row);
      setSelectedActivations(selectedRow);
    }
  };

  const onFilter = (event: any): void => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: event.filters,
      searchTerm: event
    };
    setPayload(updatedPayload);
    dispatch(getAllActivations(updatedPayload));
  };

  /// Div containing header action buttons
  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <div className="headerTableContainer">
        <Button
          className="d-flex align-items-center mr-2"
          onClick={(event: any) => deactivateSelectedActivationsHandler(event)}
          ref={ref}
          disabled={
            ((selectedActivations && Object.keys(selectedActivations).length === 0) ||
              !selectedActivations) ??
            false
          }
          variant="danger">
          <i className="bi bi-x btn-icon"></i>
          {t('admin.page.activations.deactivate')}
        </Button>
      </div>

      <SearchBar onSearchTermChanged={(data: string) => onFilter(data)} />
    </div>
  );

  useEffect(() => {
    const updatedPayload = {
      skip: payload.skip,
      limit: payload.limit,
      sort: {
        sortField: payload.sort.sortField,
        sortOrder: payload.sort.sortOrder
      },
      filter: payload.filter,
      searchTerm: payload.searchTerm
    };

    dispatch(getAllActivations(updatedPayload));
  }, [
    dispatch,
    payload.filter,
    payload.limit,
    payload.skip,
    payload.sort.sortField,
    payload.sort.sortOrder
  ]);

  const handleUserName = (userUUID: string, users: IUser[]) => {
    const userNameResult: IUser = users.find(
      (user: IUser) => user.userUUID === userUUID
    ); /**< Get user information. */
    return (
      userNameResult?.username ?? (
        <div className="p-d-flex p-ai-center">
          <Tooltip target=".custom-target-icon" />
          <i
            className="custom-target-icon text-warning bi bi-exclamation-triangle mr-2"
            data-pr-tooltip={t('awc:/.tooltip.deletedUser')}
            data-pr-position="top"
            data-pr-my="left center-2"></i>
          {userNameResult}
        </div>
      )
    );
  };

  const GetRowExpansionTemplate = (values: { rowData: IActivation }) => (
    <>
      <tr>
        <th>{t('admin.page.activations.form.username')}</th>
        <td>
          {values.rowData['userUUID'] ? handleUserName(values.rowData.userUUID, userData) : ''}
        </td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.machine')}</th>
        <td>{values.rowData['machine']}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.ip')}</th>
        <td>{values.rowData['machineIP']}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.licenseProject')}</th>
        <td>{`${values.rowData.license ? values.rowData.license.projectName : '-'}`}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.notes')}</th>
        <td>{values.rowData.notes}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.activationDate')}</th>
        <td>{values.rowData.activationDate ? formatDate(values.rowData.activationDate) : ''}</td>
      </tr>
      <tr>
        <th>{t('admin.page.activations.form.deactivationDate')}</th>
        <td>
          {values.rowData.deactivationDate ? formatDate(values.rowData.deactivationDate) : ''}
        </td>
      </tr>
    </>
  );

  const renderExpansionRows = (rowData: IActivation) => (
    <>
      <ExpansionRow>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>

      <ExpansionRow isSmallBreakpoint={true}>
        <GetRowExpansionTemplate rowData={rowData} />
      </ExpansionRow>
    </>
  );

  /// Triggerd on rowExpand
  const expandRow = (event: any): void => {
    if (event.data) {
      setExpandedRows({ [event.data.activationUUID]: true });
    }
  };

  /**
   * Notes edit handler
   * @param data
   */
  const handleNotesEditDialog = (data: IActivation): void => {
    setActivationRowData(data);
    setShowEditDialog(true);
  };

  /**
   * Handler to update activation
   * @param updatedPayload
   * @param activationUUID
   */
  const handleUpdate = async (
    updatedPayload: IActivation,
    activationUUID: string
  ): Promise<void> => {
    await asyncErrorHandler(
      dispatch(
        updateActivation({
          ...updatedPayload,
          activationUUID
        })
      )
    );
    setShowEditDialog(false);
    await asyncErrorHandler(dispatch(getAllActivations(payload)));
  };

  // Edit notes dialog
  const getEditDialog = () => {
    return (
      <DialogWrapper
        isDialogVisible={isShowEditDialog}
        headerTitle={
          activationRowData && !isStringEmptyOrNullOrUndefined(activationRowData?.notes)
            ? t('admin.page.activations.editNotesHeader')
            : t('admin.page.activations.addNotesHeader')
        }
        onHide={() => {
          setShowEditDialog(false);
        }}>
        <ActivationForm
          isLoading={!activations.records}
          handleSubmit={handleUpdate}
          activation={activationRowData}
        />
      </DialogWrapper>
    );
  };

  return (
    <Row>
      <Col xs={12}>
        <BaseDatatable
          value={activations.records}
          totalRecords={activations.totalRecords}
          parentClass="activationDataTable" /**< ClassName for div Component.*/
          isLoading={!activations.records}
          expandedRows={expandedRows}
          dataKey="activationUUID"
          onRowExpand={expandRow}
          onRowCollapse={() => setExpandedRows({})}
          rowExpansionTemplate={renderExpansionRows}
          header={header}
          onPage={handlePageUpdate}
          selection={selectedActivations}
          onSelectionChange={(event: any) => onSelectionChange(event)}
          filters={payload.filter}
          sortField={payload.sort.sortField}
          onSort={handleSortUpdate}
          emptyMessage={t('I18N.admin.activations.empty_msg')}
          sortOrder={payload.sort.sortOrder}
          first={payload.skip}
          rows={payload.limit}>
          <Column expander className="p-0 col-width-3" />
          <Column selectionMode="multiple" className="col-width-3" />
          <Column
            field="username"
            header={t('admin.page.activations.form.username')}
            body={(rows: any) => {
              if (userData && userData.length) {
                return (
                  <>
                    <div className="d-block d-sm-none">
                      <DatatableColumn
                        title={t('admin.page.clients.form.username')}
                        data={handleUserName(rows.userUUID, userData)}
                      />
                    </div>

                    <div className="d-none d-sm-block">
                      <UserDetailsPopup users={userData} userUUID={rows.userUUID} key={rows.id} />
                    </div>
                  </>
                );
              }
            }}
          />
          <Column
            field="machine"
            header={t('admin.page.activations.form.machine')}
            sortable
            body={(rows: any) => (
              <DatatableColumn
                title={t('admin.page.activations.form.machine')}
                data={rows.machine}
              />
            )}
            className="col-width-25"
          />
          <Column
            sortable
            field="machineIP"
            header={t('admin.page.activations.form.ip')}
            className="d-table-cell d-sm-none d-xl-table-cell col-width-10"
            headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-10"
            body={(rows: any) => (
              <DatatableColumn title={t('admin.page.activations.form.ip')} data={rows.machineIP} />
            )}
          />
          <Column
            field="licenseProject"
            header={t('admin.page.activations.form.licenseProject')}
            sortable
            className="d-table-cell d-sm-none d-md-table-cell col-width-15"
            headerClassName="d-table-cell d-sm-none d-md-table-cell col-width-15"
            body={(rows: any) => (
              <DatatableColumn
                title={t('admin.page.activations.form.licenseProject')}
                data={rows.license ? rows.license.projectName : '-'}
              />
            )}
          />
          <Column
            field="notes"
            header={t('admin.page.activations.form.notes')}
            sortable
            className="d-table-cell d-sm-none d-xl-table-cell col-width-20 cursor-pointer"
            headerClassName="d-table-cell d-sm-none d-xl-table-cell col-width-20"
            body={(rows: any) => (
              <DatatableColumn
                title={t('admin.page.activations.form.notes')}
                onClickEventHandler={() => handleNotesEditDialog(rows)}
                data={
                  !isStringEmptyOrNullOrUndefined(rows.notes) ? (
                    rows.notes
                  ) : (
                    <span className="editableDescription-placeholder datatablecolumn-screen-l">
                      {t('admin.page.files.versions.addDescription')}
                    </span>
                  )
                }
              />
            )}
          />
          <Column
            field="activationDate"
            header={t('admin.page.activations.form.activationDate')}
            sortable
            body={(rows: any) => (
              <>
                <DatatableColumn
                  title={t('admin.page.activations.form.activationDate')}
                  data={formatDate(rows.activationDate)}
                />
              </>
            )}
            className="createdDateCol"
          />
          <Column
            field="deactivationDate"
            header={t('admin.page.activations.form.deactivationDate')}
            sortable
            className="createdDateCol d-table-cell d-sm-none d-xxl-table-cell"
            headerClassName="createdDateCol d-table-cell d-sm-none d-xxl-table-cell"
            body={(rows: any) => (
              <DatatableColumn
                title={t('admin.page.activations.form.deactivationDate')}
                data={formatDate(rows.deactivationDate)}
              />
            )}
          />
        </BaseDatatable>
      </Col>
      {getConfirmPopup()}
      {getEditDialog()}
    </Row>
  );
};

export default ActivationPage;
