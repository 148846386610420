/**
* ReportForm.tsx (abstractuser) *

* Copyright © 2022 InstaLOD GmbH - All Rights Reserved. *

* Unauthorized copying of this file, via any medium is strictly prohibited.
* This file and all it's contents are proprietary and confidential. *

* Maintained by James Ugbanu, 2022
* @file ReportForm.tsx
* @author James Ugbanu
* @copyright 2022 InstaLOD GmbH. All rights reserved.
* @section License
*/

import React, { useState } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-multi-email/style.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { translate } from '../../../Utils/Translate';
import { IReport } from '@abstract/abstractwebcommon-shared/interfaces/license/reports';
import FileUpload, { removeSVGFile } from '@abstract/abstractwebcommon-client/FileUpload';
import { maximumFileSize } from '../../../config';
import { FileWithPath } from 'react-dropzone';
import {
  IAuthStateSelector,
  ISettingsStateSelector,
  IStateSelectors
} from '../../../Interfaces/Selectors';
import FormWrapper from '@abstract/abstractwebcommon-client/FormControl/FormWrapper';
import EmailAutoComplete from '../../SubComponents/EmailAutoComplete';
import { removeDuplicatesOnArrayOfObject } from '@abstract/abstractwebcommon-shared/utils/sharedFunctions';
import { getAllClientsWithoutPagination } from '../../../Store/Clients';
import { IUser } from '@abstract/abstractwebcommon-shared/interfaces/user/user';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import './ReportsTable.css';
import LimitRatio from '@abstract/abstractwebcommon-client/FormControl/LimitRatio';
import { asyncErrorHandler } from '@abstract/abstractwebcommon-shared/utils/AsyncErrorHandler';

/**
 * @interface IReportFormProperties
 */
interface IReportFormProperties {
  handleSubmit: (payload: IReport) => void /**< handle file report submit function*/;
  isLoading: boolean /**< checks for any report API request. True if there is, false otherwise */;
  settings: ISettingsStateSelector /** Application system settings */;
}

/**
 * ReportForm form component.
 */
const ReportForm = (properties: IReportFormProperties): JSX.Element => {
  const [uploadedFiles, setUploadedFiles] = useState<FileWithPath[]>([]);
  const maximumCharactersAllowedInTitle: number = 100; /**< MaximumCharacters allowed in title */
  const [emailSuggestions, setEmailSuggestions] = useState<string[]>(
    []
  ); /**< Email Suggestions list. */
  const [enteredEmails, setEnteredEmails] = useState<string[]>(
    []
  ); /**< This variable is used to store the entered emails in the EmailAutoComplete component. */
  const dispatch: Dispatch<any> = useDispatch();
  const authState: IAuthStateSelector = useSelector(
    (state: IStateSelectors) => state.auth
  ); /**< Authstate. */
  const allHMTLElements: NodeListOf<Element> = document.querySelectorAll(
    '.add-participant-dialog button, .add-participant-dialog input, .add-participant-dialog textarea, .add-participant-dialog div'
  ); /**< All HTML elements in this dialog */

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      description: '',
      emailTo: [],
      files: []
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .min(2, translate('validation.min', { field: '2' }))
        .max(
          maximumCharactersAllowedInTitle,
          translate('validation.max', { field: maximumCharactersAllowedInTitle.toString() })
        )
        .required(
          translate('validation.required', { field: translate('client.page.reports.form.title') })
        ),
      description: Yup.string()
        .min(2, translate('validation.min', { field: '2' }))
        .required(
          translate('validation.required', {
            field: translate('client.page.reports.form.descriptionField')
          })
        ),
      emailTo: Yup.array().notRequired(),
      files: Yup.array().notRequired()
    }),
    onSubmit: (data) => {
      const payload: any = {};
      Object.keys(data).forEach((key: string, i) => {
        if (
          data[key as keyof typeof formik.initialValues] !==
          formik.initialValues[key as keyof typeof formik.initialValues]
        ) {
          payload[key] = data[key as keyof typeof formik.initialValues];
        }
        if (i === Object.keys(data).length - 1) {
          if (payload.emailTo) {
            payload.emailTo = payload.emailTo.join(', ');
          }

          if (uploadedFiles.length) {
            payload.files = uploadedFiles;
          }
          properties.handleSubmit(payload);
        }
      });
    }
  });

  const getUploadedFiles = (file: FileWithPath[]) => {
    setUploadedFiles(file);
  };

  /// Search User email to show suggestions
  const searchUserEmail = async (event: any) => {
    const emails: string[] = event?.query
      .split(/(\s+)/)
      .filter((email) => email.trim().length > 0); /**< Entered emails. */
    const userEmailList: string[] = []; /**< Get searched email list */

    // Only admin can view the suggestions of own users when adding participant.
    if (authState.isAdmin) {
      const lastEmailToSearch: string =
        emails[emails.length - 1]; /** Email to search in user backend */
      const response: any = await asyncErrorHandler(
        dispatch(getAllClientsWithoutPagination({ searchTerm: lastEmailToSearch }))
      ); /**< Get filtered users. */

      if (response && response.payload && response.payload.status === 200) {
        if (response.payload.data && response.payload.data.records) {
          response.payload.data.records.map((user: IUser) => {
            userEmailList.push(user && user.email);
          });
        }
      }
    }

    setEmailSuggestions(userEmailList);
    setEnteredEmails(emails);
  };

  /// Drag & dropped files.
  const getDroppedFiles = (files: FileWithPath[]) => {
    files = removeSVGFile(files); // To remove svg file
    const formattedFiles: FileWithPath[] = files.map((eachFile: FileWithPath) => {
      Object.assign(eachFile, {
        path: eachFile.name
      });
      return eachFile;
    }); /**< Add path into files.*/

    const mergedFileArray: FileWithPath[] = removeDuplicatesOnArrayOfObject(
      [...uploadedFiles, ...formattedFiles],
      'name'
    );
    setUploadedFiles(mergedFileArray);
  };

  return (
    <>
      <FormWrapper
        controlButtonLabel={false}
        isLoading={properties.isLoading}
        handleSubmitButton={() => formik.handleSubmit()}>
        <Row>
          <Col xs={12}>
            <label className="required" htmlFor="title">
              {translate('client.page.reports.form.title')}
            </label>
            <InputTextarea
              id="title"
              value={formik.values.title}
              onChange={(event) => {
                document.getElementById('title-count').innerHTML = `${
                  (event.target as HTMLInputElement).value.length
                }`;
                formik.handleChange(event);
              }}
              className={formik.touched.title && formik.errors.title ? 'p-invalid' : ''}
              onBlur={formik.handleBlur}
              rows={1}
              autoResize
            />
            <LimitRatio
              id="title-count"
              allowedLimit={maximumCharactersAllowedInTitle}
              className={formik.touched.title && formik.errors.title ? 'p-invalid' : ''}
            />
            {formik.touched.title && formik.errors.title ? (
              <small id="title-invalid" className="p-invalid">
                {formik.errors.title}
              </small>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="report-form-email">
            <label htmlFor="title">{translate('client.page.reports.form.email')}</label>
            <EmailAutoComplete
              name="emailTo"
              value={formik.values.emailTo}
              suggestions={emailSuggestions}
              completeMethod={searchUserEmail}
              onChange={(event) => {
                formik.setFieldValue('emailTo', event.value);
                setEnteredEmails([]);
              }}
              setFieldValue={formik.setFieldValue}
              className={formik.touched.emailTo && formik.errors.emailTo ? 'p-invalid' : ''}
              onFocus={() => {
                // To prevent the tab when adding emails
                for (let i = 0; i < allHMTLElements.length; i++) {
                  allHMTLElements[i].setAttribute('tabindex', '-1');
                }
              }}
              onBlur={() => {
                for (let i = 0; i < allHMTLElements.length; i++) {
                  allHMTLElements[i].removeAttribute('tabindex');
                }
              }}
              enteredEmails={enteredEmails}
              setEnteredEmails={setEnteredEmails}
              setEmailSuggestions={setEmailSuggestions}
              isAddButtonVisible={false}
            />
            {formik.touched.emailTo && formik.errors.emailTo ? (
              <small id="emailTo-invalid" className="p-invalid">
                {formik.errors.emailTo}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <label htmlFor="description" className="required">
              {translate('client.page.reports.form.describeInDetails')}
            </label>
            <InputTextarea
              id="description"
              value={formik.values.description}
              onChange={(event) => formik.handleChange(event)}
              className={formik.touched.description && formik.errors.description ? 'p-invalid' : ''}
              onBlur={formik.handleBlur}
              rows={5}
              autoResize
              onDrop={(event) => {
                const files: File[] = Array.from(event.dataTransfer.files);
                getDroppedFiles(files);
              }}
              placeholder={translate('client.page.reports.form.descriptionPlaceholder')}
            />
            {formik.touched.description && formik.errors.description ? (
              <small id="description-invalid" className="p-invalid">
                {formik.errors.description}
              </small>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="shared-files">
            <FileUpload
              maximumTotalUploadSize={maximumFileSize}
              getUploadedFiles={getUploadedFiles}
              droppedFiles={uploadedFiles}
              isFileSizeLimitVisible={true}
            />
          </Col>
        </Row>
      </FormWrapper>
      <div className="d-flex">
        {formik.status && formik.status.message !== undefined ? (
          <small className="p-invalid">{formik.status.message}</small>
        ) : null}
      </div>
    </>
  );
};

export default ReportForm;
