/**
 * Newsletter.ts (InstaLOD GmbH)
 *
 * Copyright © 2023 InstaLOD GmbH - All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * This file and all its contents are proprietary and confidential.
 *
 * Maintained by Alaguvelammal Alagusubbiah, 2023
 *
 * @file Newsletter.ts
 * @author Alaguvelammal Alagusubbiah
 * @copyright 2023 InstaLOD GmbH. All rights reserved.
 * @section License
 */

/**
 * Newsletter License type
 */
export enum NewsletterLicenseType {
  all = "All",
  custom = "Custom",
  active = "Active"
}

/**
 * Send Status of a newsletter
 */
export enum NewsletterSendStatus {
  inProgress = "In Progress",
  inQueue = "In Queue",
  done = "Done"
}